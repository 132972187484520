import moment from 'moment'
import api from '@/utils/api'
import * as types from '@/store/mutationTypes'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import attachRelations from '@/utils/attachRelations'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import { isLessThenDate } from '@/utils/dateUtils'

const type = 'reviewProcesses'

const storeGetters = {
  loadingReviewProcesses: (_state, getters) => getters.isLoading(type),

  reviewProcesses: (_state, getters) => {
    const reviewProcesses = getters.resources(type)
    return reviewProcesses.map(attachRelations(['user']))
  },

  reviewProcessesForArchive:
    (_state, getters) =>
    ({ search = '', ascending, category }) => {
      const loweredSearch = search.toLowerCase()
      const sortDirection = ascending ? 1 : -1

      let archive = getters[type].slice()

      switch (category) {
        case 'overdue':
          archive = archive.filter(({ overdue }) => overdue === true)
          break
        case 'upcoming':
          archive = archive.filter(
            ({ nextReviewAt, overdue }) =>
              isLessThenDate(7, 'days', nextReviewAt) && !overdue
          )
          break
        case 'all':
        default:
          break
      }

      if (search) {
        archive = archive.filter(({ title, description, user, overdue }) => {
          return (
            (title || '').toLowerCase().includes(loweredSearch) ||
            (description || '').toLowerCase().includes(loweredSearch) ||
            user.name.toLowerCase().includes(loweredSearch) ||
            ('forfalden'.includes(loweredSearch) && overdue === true)
          )
        })
      }

      archive.sort((a, b) => {
        return (
          sortDirection *
          moment.utc(a.nextReviewAt).diff(moment.utc(b.nextReviewAt))
        )
      })

      return archive
    },

  reviewProcess: (_state, getters) => {
    const reviewProcess = getters.resource(type, getters.params.reviewProcessId)
    if (!reviewProcess) return reviewProcess

    return {
      ...attachRelations(['reviews'])(reviewProcess),
      customQuestionAnswers: getters
        .relations(reviewProcess, 'customQuestionAnswers', 'flow')
        .slice()
        .reverse(),
      reviews: getters
        .relations(reviewProcess, 'reviews', 'flow')
        .slice()
        .reverse(),
    }
  },
}

const actions = {
  fetchReviewProcesses: ({ getters: { organizationId } }) => {
    const params = { include: ['reviews', 'user'] }
    const request = api
      .get(`organizations/${organizationId}/review_processes`, { params })
      // There's no need to flush all users here, since possibly deleted users won't have an effect
      .then(flushTypes([type, 'reviews']))

    return wrapResourceRequest(type, request)
  },

  createReviewProcess: ({ commit, getters }, reviewProcess) => {
    const { organizationId } = getters
    const params = snakeCaseKeys({ reviewProcess })

    const request = api
      .post(`organizations/${organizationId}/review_processes`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },

  fetchReviewProcess: ({ getters, getters: { organizationId } }, id) => {
    id = id || getters.params.reviewProcessId

    const params = { include: ['reviews', 'user', 'custom_question_answers'] }
    const request = api.get(
      `organizations/${organizationId}/review_processes/${id}`,
      { params }
    )
    return wrapResourceRequest(type, request)
  },

  saveReviewProcess: ({ commit, getters }, reviewProcess) => {
    const { organizationId } = getters
    const params = snakeCaseKeys({ reviewProcess })

    const request = api
      .put(
        `organizations/${organizationId}/review_processes/${reviewProcess.id}`,
        params
      )
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },

  deleteReviewProcess: ({ getters: { organizationId } }, reviewProcess) => {
    const request = api.delete(
      `organizations/${organizationId}/review_processes/${reviewProcess.id}`
    )
    return wrapResourceRequest(type, request)
  },

  flushReviewProcessRelations: ({ commit, getters }, reviewProcess) => {
    const reviews = getters.relations(reviewProcess, 'reviews')
    const customQuestionAnswers = getters
      .relations(reviewProcess, 'customQuestionAnswers', 'flow')
      .slice()
      .reverse()
    const flowReviews = getters
      .relations(reviewProcess, 'reviews', 'flow')
      .slice()
      .reverse()

    commit(types.FLUSH_RESOURCES, [
      ...reviews,
      ...flowReviews,
      ...customQuestionAnswers,
    ])
  },
}

export default {
  actions,
  getters: storeGetters,
}
