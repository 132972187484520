<template>
  <li class="TimelineItem">
    <div class="TimelineItem__line" />

    <template v-if="type === 'future'">
      <span class="TimelineItem__bullet" />
    </template>

    <template v-else-if="type === 'current'">
      <span class="TimelineItem__bullet TimelineItem__bullet--current" />
    </template>

    <template v-else-if="type === 'past'">
      <span class="TimelineItem__bullet TimelineItem__bullet--past">
        <i class="fa fa-check" />
      </span>
    </template>

    <div class="TimelineItem__content">
      <h2 class="TimelineItem__date">
        {{ date | formatDateHuman }}
      </h2>
      <h3 v-if="resource && resource.edited">
        {{ resource.updatedAt | formatDateHuman }}
        <span>{{ t('shared.edited') }}</span>
      </h3>
      <p
        :class="{ 'TimelineItem__content--overdue': overdue }"
        class="TimelineItem__content"
      >
        <slot />
      </p>
    </div>
  </li>
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'

export default {
  name: 'TimelineItem',
  props: {
    date: { type: String },
    resource: { type: Object },
    type: VueTypes.oneOf(['future', 'current', 'past']).isRequired,
    overdue: VueTypes.bool.def(false),
  },
}
</script>
