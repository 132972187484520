<template>
  <MitigationMeasureForm
    v-if="mitigationMeasure"
    :mitigation-measure="mitigationMeasure"
    :is-saving="loadingMitigationMeasures"
    @submit="handleSubmit"
  />
</template>

<script>
import router from '@/routes'
import MitigationMeasureForm from '@/forms/MitigationMeasureForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MitigationMeasuresEdit',
  components: {
    MitigationMeasureForm,
  },
  computed: {
    ...mapGetters(['mitigationMeasure', 'loadingMitigationMeasures']),
  },
  methods: {
    ...mapActions(['saveMitigationMeasure', 'showFlashMessage']),

    handleSubmit(mitigationMeasure) {
      this.saveMitigationMeasure(mitigationMeasure)
        .then(() => {
          router.push({ name: 'RiskAssessmentsShow' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.mitigation_measure') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.mitigation_measure') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
