import api from '@/utils/api'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import attachRelations from '@/utils/attachRelations'

const type = 'exports'

const storeGetters = {
  loadingExports: (_state, getters) => getters.isLoading(type),
  exports: (_state, getters) => {
    const exports = getters.resources(type)
    return exports.map(attachRelations(['user'])).reverse()
  },
}

const actions = {
  fetchExports: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/exports`)
      .then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },

  createExport: ({ getters: { organizationId } }, dataType) => {
    const request = api.post(
      `organizations/${organizationId}/exports`,
      dataType
    )
    return wrapResourceRequest(type, request)
  },

  deleteExport: ({ getters: { organizationId } }, resource) => {
    const request = api.delete(
      `organizations/${organizationId}/exports/${resource.id}`
    )
    return wrapResourceRequest(type, request)
  },
}

export default {
  actions,
  getters: storeGetters,
}
