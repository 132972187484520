<template>
  <router-link v-if="to" :to="to" class="ModalCloseButton">
    <span /><span />
  </router-link>
  <div v-else class="ModalCloseButton" @click="$emit('click', $event)">
    <span /><span />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'ModalCloseButton',
  props: {
    to: { type: Object, required: false, default: null },
  },
}
</script>
