<template>
  <div>
    <ResourceHeader
      :back-link="{
        name: 'DataMappingsShow',
        params: {
          dataMappingId: riskAssessment && riskAssessment.dataMappingId,
        },
      }"
      :title="riskAssessment && riskAssessment.title"
      :timestamp="riskAssessment && riskAssessment.updatedAt"
      :back-link-text="t('shared.back_to_resource')"
      :title-prefix="dataMapping.title"
      :title-prefix-secondary="t('risk_assessment.title')"
    />

    <Loader v-if="!riskAssessment" />
    <RiskAssessmentForm
      v-else
      :risk-assessment="riskAssessment"
      :is-saving="loadingRiskAssessments"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/routes'
import RiskAssessmentForm from '@/forms/RiskAssessmentForm'
import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'

export default {
  name: 'RiskAssessmentsEdit',
  components: { ResourceHeader, RiskAssessmentForm, Loader },
  computed: {
    ...mapGetters(['riskAssessment', 'loadingRiskAssessments', 'dataMapping']),
  },
  beforeMount() {
    this.fetchDataMapping()
    this.fetchRiskAssessment()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'fetchDataMapping',
      'fetchRiskAssessment',
      'saveRiskAssessment',
      'showFlashMessage',
    ]),
    handleSubmit(riskAssessment) {
      this.saveRiskAssessment(riskAssessment)
        .then(() => {
          router.push({
            name: 'DataMappingsShow',
            params: { dataMappingId: riskAssessment.dataMappingId },
          })
          this.showFlashMessage({
            message:
              this.t('flash_messages.risk_assessment') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.risk_assessment') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
