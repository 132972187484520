<template>
  <div v-if="hasNextStep()" @click="goToNextStep">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Step',
  inject: ['goToNextStep', 'hasNextStep'],
}
</script>
