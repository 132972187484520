<template>
  <router-link :to="{ name: `UsersEdit` }" class="SettingsButton">
    <span><i class="fa fa-cog" />{{ t('shared.header.settings') }}</span>
  </router-link>
</template>

<script>
import './style.scss'
import { mapGetters } from 'vuex'

export default {
  name: 'SettingsButton',
  computed: {
    ...mapGetters(['user']),
  },
}
</script>
