import { create } from 'axios'
import store from '../store'

const axios = create({ baseURL: '/api/' })

const authInterceptor = config => {
  const { token } = store.getters.session

  if (token) {
    config.headers.common['X-Auth-Token'] = token
  }

  return config
}

const unauthorizedInterceptor = error => {
  if (
    error.response.status === 401 &&
    !/api\/session/.test(error.request.responseURL)
  ) {
    return store.dispatch('logOut').then(() => Promise.reject(error))
  }

  return Promise.reject(error)
}

axios.interceptors.request.use(authInterceptor)
axios.interceptors.response.use(undefined, unauthorizedInterceptor)

export default axios
