<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'RequestsArchive' }"
      :title="request && request.name"
      :timestamp="request && request.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('request.archive.title')"
    />

    <Loader v-if="!request" />
    <RequestForm
      v-else
      :request="request"
      :is-saving="loadingRequests"
      :completing="true"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/routes'
import RequestForm from '@/forms/RequestForm/RequestForm'
import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'

export default {
  name: 'RequestsManage',
  components: { ResourceHeader, RequestForm, Loader },
  computed: {
    ...mapGetters(['request', 'loadingRequests']),
  },
  beforeMount() {
    this.fetchRequest()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'fetchRequest',
      'saveRequest',
      'showFlashMessage',
    ]),
    handleSubmit(request) {
      this.saveRequest(request)
        .then(() => {
          router.push({ name: 'RequestsArchive' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.request') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.request') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
