<template>
  <flat-pickr
    :value="value"
    :config="config"
    :placeholder="placeholder"
    class="DatePicker"
    @on-change="onChange"
  />
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { Danish } from 'flatpickr/dist/l10n/da.js'
import './style.scss'
import 'flatpickr/dist/flatpickr.css'
import moment from 'moment'

export default {
  name: 'DatePicker',
  components: {
    flatPickr,
  },
  props: {
    placeholder: {
      type: String,
      default: 'Vælg dato',
      required: false,
    },
    value: {
      type: String,
      default: '',
      required: false,
    },
    maxDateValidator: { type: Boolean, default: false },
    minDateValidator: { type: Boolean, default: false },
  },
  data() {
    return {
      config: {
        altInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'j. F Y',
        locale: Danish,
        maxDate:
          this.maxDateValidator === true
            ? moment().format('Y-M-D').toString()
            : false,
        minDate:
          this.minDateValidator === true
            ? moment().add(1, 'day').format('Y-M-D').toString()
            : false,
      },
    }
  },
  methods: {
    onChange(selectedDates, dateStr) {
      this.$emit('input', moment(dateStr).format('Y-M-D'))
    },
  },
}
</script>
