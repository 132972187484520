<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'ReviewProcessesShow' }"
      :title="reviewProcess && reviewProcess.title"
      :timestamp="reviewProcess && reviewProcess.updatedAt"
      :back-link-text="t('shared.back_to_resource')"
      :title-prefix="t('review_process.archive.title')"
    />

    <Loader v-if="!reviewProcess" />
    <ReviewProcessForm
      v-else
      :review-process="reviewProcess"
      :is-saving="loadingReviewProcesses"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'
import ReviewProcessForm from '@/forms/ReviewProcessForm'
import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'

export default {
  name: 'ReviewProcessesEdit',
  components: { ResourceHeader, ReviewProcessForm, Loader },
  computed: {
    ...mapGetters(['loadingReviewProcesses', 'reviewProcess']),
  },
  beforeMount() {
    this.fetchReviewProcess()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'fetchReviewProcess',
      'saveReviewProcess',
      'showFlashMessage',
    ]),

    handleSubmit(reviewProcess) {
      this.saveReviewProcess(reviewProcess)
        .then(() => {
          router.push({ name: 'ReviewProcessesArchive' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.review_process') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.review_process') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
