import * as types from '../mutationTypes'

const initialState = {
  sensitive: [],
  nonsensitive: [],
}

const actions = {
  storeDataTypes({ commit }, { sensitive, nonsensitive }) {
    commit(types.STORE_DATA_TYPES, { sensitive, nonsensitive })
  },
}

const mutations = {
  [types.STORE_DATA_TYPES]: (state, { sensitive, nonsensitive }) => {
    state.sensitive = sensitive
    state.nonsensitive = nonsensitive
  },
}

export default {
  state: initialState,
  actions,
  mutations,
}
