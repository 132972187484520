<template>
  <div class="Invites">
    <Loader v-if="!organizationInvites" />
    <div v-else>
      <h3>{{ t('organization.edit.tabs.invites.title') }}</h3>
      <ThinButton @click="handleClick">
        {{ t('organization.edit.tabs.invites.remove_expired') }}
      </ThinButton>
      <div class="table__wrapper">
        <table>
          <thead>
            <th>{{ t('organization.edit.tabs.invites.receiver') }}</th>
            <th>{{ t('organization.edit.tabs.invites.sender') }}</th>
            <th>{{ t('organization.edit.tabs.invites.sent_at') }}</th>
            <th>{{ t('organization.edit.tabs.invites.expires_at') }}</th>
          </thead>
          <tbody>
            <tr
              v-for="organizationInvite in organizationInvites"
              :key="organizationInvite.id"
            >
              <td>{{ organizationInvite.email }}</td>
              <td>{{ organizationInvite.sender.email }}</td>
              <td>{{ organizationInvite.createdAt | formatDateHuman }}</td>
              <td>{{ organizationInvite.expiresAt | formatDateHuman }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import './style.scss'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/Loader'
import ThinButton from '@/components/ThinButton'

export default {
  name: 'Invites',
  components: { Loader, ThinButton },
  computed: {
    ...mapGetters(['organizationInvites']),
  },
  mounted() {
    this.fetchOrganizationInvites()
  },
  methods: {
    ...mapActions([
      'fetchOrganizationInvites',
      'deleteExpiredOrganizationInvites',
    ]),

    handleClick() {
      this.deleteExpiredOrganizationInvites().then(result => {
        if (result instanceof Error) return
        this.fetchOrganizationInvites()
      })
    },
  },
}
</script>
