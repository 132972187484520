<template>
  <div id="app">
    <FlashMessage />
    <router-view />
  </div>
</template>

<script>
import './style.scss'
import { mapGetters } from 'vuex'

import FlashMessage from '@/components/FlashMessage'

export default {
  name: 'App',
  components: { FlashMessage },
  props: {
    dataTypes: { type: Object, required: true },
    legalBases: { type: Object, required: true },
    safetyMeasures: { type: Array, required: true },
  },
  computed: {
    ...mapGetters(['modalIsVisible']),
  },
  watch: {
    modalIsVisible: function (modalIsVisible) {
      const className = 'body--modal-visible'

      if (modalIsVisible) {
        document.body.classList.add(className)
      } else {
        document.body.classList.remove(className)
      }
    },
  },
  mounted() {
    this.$store.dispatch('storeDataTypes', this.$props.dataTypes)
    this.$store.dispatch('storeSafetyMeasures', this.$props.safetyMeasures)
    this.$store.dispatch('storeLegalBases', this.$props.legalBases)
  },
}
</script>
