<template>
  <div class="Actions">
    <slot />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'Actions',
}
</script>
