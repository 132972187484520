<template>
  <div :class="{ 'FormSelect--fill': fill }" class="FormSelect">
    <i class="fa fa-angle-down" />
    <select :value="value" @input="$emit('input', $event.target.value)">
      <slot />
    </select>
  </div>
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'

export default {
  name: 'FormSelect',
  props: {
    value: VueTypes.any,
    fill: { type: Boolean, required: false, defaultValue: false },
  },
}
</script>
