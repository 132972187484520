<template>
  <Modal title="Fjern afdeling fra organisation?">
    <ModalCloseButton :to="{ name: 'OrganizationsEdit' }" />
    <div class="form">
      <button v-if="isSaving" class="button--save">
        {{ t('organization.edit.tabs.departments.destroy.deleting') }}
      </button>
      <button v-else class="button--save" @click="handleSubmit()">
        {{ t('organization.edit.tabs.departments.destroy.remove') }}
      </button>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import Modal from '@/components/Modal'
import ModalCloseButton from '@/components/ModalCloseButton'
import router from '@/routes'

export default {
  name: 'DepartmentsDelete',
  components: { Modal, ModalCloseButton },
  data() {
    return {
      isSaving: false,
    }
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions(['collapseSidebar', 'showFlashMessage', 'deleteDepartment']),

    handleSubmit() {
      this.isSaving = true
      this.deleteDepartment(this.$route.params.id)
        .then(() => {
          this.isSaving = false
          router.push({ name: 'OrganizationsEdit' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.department') +
              this.t('flash_messages.delete.success'),
          })
        })
        .catch(() => {
          this.isSaving = false
          this.showFlashMessage({
            message:
              this.t('flash_messages.department') +
              this.t('flash_messages.delete.error'),
          })
        })
    },
  },
}
</script>
