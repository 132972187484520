<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'SystemsArchive' }"
      :title="system && system.name"
      :timestamp="system && system.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('system.archive.title')"
    />

    <Loader v-if="!system" />
    <SystemForm
      v-else
      :system="system"
      :is-saving="loadingSystems"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/routes'
import SystemForm from '@/forms/SystemForm'
import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'

export default {
  name: 'SystemsEdit',
  components: { ResourceHeader, SystemForm, Loader },
  computed: {
    ...mapGetters(['system', 'loadingSystems']),
  },
  beforeMount() {
    if (this.system) this.flushSystemRelations(this.system)
    this.fetchSystem()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'fetchSystem',
      'saveSystem',
      'showFlashMessage',
      'flushSystemRelations',
    ]),
    handleSubmit(system) {
      this.saveSystem(system)
        .then(() => {
          router.push({ name: 'SystemsArchive' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.system') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.system') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
