<template>
  <div>
    <ArchiveHeader v-model="search" />

    <Loader v-if="loadingReviewProcesses" />
    <ContentSection v-else class="fade_in_right">
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('review_process.archive.title') }}</ArchiveTitle>
        <ArchiveCreateButton :to="{ name: 'ReviewProcessesNew' }" />
      </ArchiveActionSection>

      <TableWrapper>
        <SortedReviewTable
          v-if="filteredReviewProcesses('active', 'internal').length"
          :review-processes="filteredReviewProcesses('active', 'internal')"
          :title="t('review_process.categories.internal')"
        />
      </TableWrapper>

      <TableWrapper>
        <SortedReviewTable
          v-if="filteredReviewProcesses('active', 'data_processor').length"
          :review-processes="
            filteredReviewProcesses('active', 'data_processor')
          "
          :title="t('review_process.categories.data_processor')"
        />
      </TableWrapper>

      <TableWrapper>
        <SortedReviewTable
          v-if="filteredReviewProcesses('active', 'awareness').length"
          :review-processes="filteredReviewProcesses('active', 'awareness')"
          :title="t('review_process.categories.awareness')"
        />
      </TableWrapper>

      <TableWrapper>
        <SortedReviewTable
          v-if="filteredReviewProcesses('active', 'meet').length"
          :review-processes="filteredReviewProcesses('active', 'meet')"
          :title="t('review_process.categories.meeting')"
        />
      </TableWrapper>

      <TableWrapper>
        <SortedReviewTable
          v-if="filteredReviewProcesses('active', 'other').length"
          :review-processes="filteredReviewProcesses('active', 'other')"
          :title="t('review_process.categories.other')"
        />
      </TableWrapper>

      <TableWrapper>
        <SortedReviewTable
          v-if="filteredInactiveReviewProcesses().length"
          :review-processes="filteredInactiveReviewProcesses()"
          :inactive="true"
          :title="t('review_process.categories.inactive')"
        />
      </TableWrapper>
    </ContentSection>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ArchiveHeader from '@/components/ArchiveHeader'
import ArchiveTitle from '@/components/ArchiveTitle'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ArchiveCreateButton from '@/components/ArchiveCreateButton'
import SortedReviewTable from '@/components/SortedReviewTable'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import TableWrapper from '@/components/TableWrapper'

export default {
  name: 'ReviewProcessesArchive',
  components: {
    ArchiveHeader,
    ArchiveTitle,
    Loader,
    ContentSection,
    ArchiveCreateButton,
    SortedReviewTable,
    ArchiveActionSection,
    TableWrapper,
  },
  data: () => ({
    search: '',
    ascending: true,
  }),
  computed: {
    ...mapGetters(['loadingReviewProcesses']),

    reviewProcesses() {
      return this.$store.getters.reviewProcessesForArchive({
        search: this.search,
        ascending: this.ascending,
        category: this.selectedCategory,
      })
    },
  },
  mounted() {
    this.fetchReviewProcesses()
    this.expandSidebar()
  },
  methods: {
    ...mapActions(['fetchReviewProcesses', 'expandSidebar']),

    filteredInactiveReviewProcesses() {
      const filteredReviewProcesses = this.reviewProcesses.filter(
        ({ active }) => active === false
      )

      return filteredReviewProcesses
    },

    filteredReviewProcesses(statusValue, categoryName) {
      const boolStatus = statusValue === 'active' ? true : false

      let filteredReviewProcesses = this.reviewProcesses.filter(
        ({ category }) => category === categoryName
      )
      filteredReviewProcesses = filteredReviewProcesses.filter(
        ({ active }) => active === boolStatus
      )

      return filteredReviewProcesses
    },
  },
}
</script>
