<template>
  <button
    :class="{
      [`Button--${type}`]: type,
      [`Button--${size}`]: size,
    }"
    :disabled="disabled"
    class="Button"
    @click="$emit('click', $event)"
  >
    <slot />
  </button>
</template>

<script>
import './style.scss'

export default {
  name: 'Button',
  props: {
    type: { type: String },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: 'md' },
  },
}
</script>
