import api from '../../utils/api'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import * as types from '../mutationTypes'

const type = 'questions'

const storeGetters = {
  questions: (_state, getters) => {
    const questions = getters.resources(type)

    return questions
  },
}

const actions = {
  fetchCustomQuestions: (
    { commit, getters, getters: { organizationId } },
    flow_type
  ) => {
    const questions = getters.resources('questions')

    if (questions.length > 0) {
      commit(types.FLUSH_RESOURCES, [...questions])
    }
    const params = {
      flow_type: flow_type,
    }
    const request = api.get(`organizations/${organizationId}/questions`, {
      params,
    })

    return wrapResourceRequest(type, request)
  },
}

export default {
  getters: storeGetters,
  actions,
}
