<template>
  <div class="ArchiveTableDropdown">
    <popper
      :options="{
        placement: 'right',
      }"
      trigger="click"
    >
      <div class="popper">
        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <router-link v-if="toView" :to="toView" class="dropdown-item">
            {{ t('shared.actions.view') }}
          </router-link>
          <router-link v-if="toReview" :to="toReview" class="dropdown-item">
            {{ t('shared.actions.review') }}
          </router-link>
          <div v-if="toCustomTitle">
            <router-link v-if="toCustom" :to="toCustom" class="dropdown-item">
              {{ toCustomTitle }}
            </router-link>
            <a
              v-else
              class="dropdown-item"
              href="#"
              @click="$emit('custom', resource.id)"
              >{{ toCustomTitle }}</a
            >
          </div>

          <router-link v-if="toEdit" :to="toEdit" class="dropdown-item">
            {{ t('shared.actions.edit') }}
          </router-link>
          <div v-if="!deleteDisabled">
            <router-link v-if="toDelete" :to="toDelete" class="dropdown-item">
              {{ t('shared.actions.delete') }}
            </router-link>
            <a
              v-else
              class="dropdown-item"
              href="#"
              @click="$emit('delete', resource.id)"
              >{{ t('shared.actions.delete') }}</a
            >
          </div>
        </div>
      </div>

      <span slot="reference" class="ArchiveTableDropdown__button"
        ><i class="fa fa-ellipsis-h"
      /></span>
    </popper>
  </div>
</template>

<script>
import './style.scss'
import Popper from 'vue-popperjs'

export default {
  name: 'ArchiveTableDropdown',
  components: { Popper },
  props: {
    toCustom: { type: Object },
    toCustomTitle: { type: String },
    toReview: { type: Object },
    toView: { type: Object },
    toEdit: { type: Object },
    toDelete: { type: Object },
    resource: { type: Object },
    deleteDisabled: { type: Boolean, default: false },
  },
}
</script>
