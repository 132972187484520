<template>
  <Loader v-if="!user || !organization || !organizationUsers" />
  <div v-else class="Settings">
    <ArchiveHeader>
      <template v-slot:header-title>
        <h1>{{ t('shared.header.settings') }}</h1>
      </template>
      <TabsWrapper>
        <router-link :to="{ name: `UsersEdit` }">
          <div :class="{ active: activeTab === 'UsersEdit' }" class="tab">
            <p>{{ user.name }}</p>
          </div>
        </router-link>
        <router-link :to="{ name: `OrganizationsEdit` }">
          <div
            :class="{ active: activeTab === 'OrganizationsEdit' }"
            class="tab"
          >
            <p>{{ organization.name }}</p>
          </div>
        </router-link>
        <router-link :to="{ name: `OrganizationsExports` }">
          <div
            :class="{ active: activeTab === 'OrganizationsExports' }"
            class="tab"
          >
            <p>{{ t('organization.exports.title') }}</p>
          </div>
        </router-link>
      </TabsWrapper>
    </ArchiveHeader>
    <ContentSection>
      <slot />
    </ContentSection>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ArchiveHeader from '@/components/ArchiveHeader/ArchiveHeaderWithSlots'
import ContentSection from '@/components/ContentSection'
import Loader from '@/components/Loader'
import TabsWrapper from '@/components/TabsWrapper'

export default {
  name: 'ProfileContent',
  components: {
    ArchiveHeader,
    ContentSection,
    Loader,
    TabsWrapper,
  },
  computed: {
    ...mapGetters(['user', 'organization', 'organizationUsers']),

    activeTab() {
      return this.$route.name
    },
  },
  mounted() {
    this.fetchOrganization()
    this.fetchUser()
    this.fetchOrganizationUsers()
  },
  methods: {
    ...mapActions(['fetchUser', 'fetchOrganization', 'fetchOrganizationUsers']),
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
