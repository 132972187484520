<template>
  <li class="LocaleSelector">
    <div :class="sidebarExpanded ? 'expanded' : ''">
      <a
        :class="languageExpanded ? 'active' : ''"
        href="#"
        @click="languageExpanded = languageExpanded ? false : true"
      >
        <div class="bg" />
        <img
          style="width: 27px; left: 38px"
          class="collapsed_img"
          src="../../components/Sidebar/assets/language.svg"
        />
        <img
          style="width: 27px; left: 38px"
          class="expanded_img"
          src="../../components/Sidebar/assets/language_active.svg"
        />
        <span>{{ t('shared.navigation.language.title') }}</span>
      </a>
      <ul v-if="languageExpanded">
        <li
          :class="currentLocale() === 'da' ? 'current' : ''"
          @click="setLocale('da')"
        >
          <span v-if="sidebarExpanded">{{
            t('shared.navigation.language.danish')
          }}</span>
          <span v-else>DK</span>
        </li>
        <li
          :class="currentLocale() === 'en' ? 'current' : ''"
          @click="setLocale('en')"
        >
          <span v-if="sidebarExpanded">{{
            t('shared.navigation.language.english')
          }}</span>
          <span v-else>EN</span>
        </li>
        <li
          :class="currentLocale() === 'de' ? 'current' : ''"
          @click="setLocale('de')"
        >
          <span v-if="sidebarExpanded">{{
            t('shared.navigation.language.german')
          }}</span>
          <span v-else>DE</span>
        </li>
        <li
          :class="currentLocale() === 'fr' ? 'current' : ''"
          @click="setLocale('fr')"
        >
          <span v-if="sidebarExpanded">{{
            t('shared.navigation.language.french')
          }}</span>
          <span v-else>FR</span>
        </li>
        <li
          :class="currentLocale() === 'pl' ? 'current' : ''"
          @click="setLocale('pl')"
        >
          <span v-if="sidebarExpanded">{{
            t('shared.navigation.language.polish')
          }}</span>
          <span v-else>PL</span>
        </li>                
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: 'LocaleSelector',
  props: {
    sidebarExpanded: { type: Boolean, defaultValue: false, required: true },
  },
  data() {
    return {
      languageExpanded: false,
    }
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
