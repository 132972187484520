import api from '../../utils/api'
import wrapResourceRequest from '@/utils/wrapResourceRequest'

const type = 'customCategories'

const storeGetters = {
  customCategories: (_state, getters) => {
    const customCategories = getters.resources(type)

    return customCategories
  },
}

const actions = {
  fetchCustomCategories: ({ getters: { organizationId } }) => {
    const request = api.get(`organizations/${organizationId}/custom_categories`)

    return wrapResourceRequest(type, request)
  },
}

export default {
  getters: storeGetters,
  actions,
}
