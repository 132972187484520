<template>
  <h2 class="ColumnHeader">
    <slot />
  </h2>
</template>

<script>
import './style.scss'

export default {
  name: 'ColumnHeader',
}
</script>
