import * as types from '../mutationTypes'
import api from '@/utils/api'
import router from '@/routes'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import appendPairToFormData from '@/utils/appendPairToFormData'
import appendFilesToFormData from '@/utils/appendFilesToFormData'
import appendCustomQuestionAnswersToFormData from '@/utils/appendCustomQuestionAnswersToFormData'
import i18n from '@/mixins/i18n.js.erb'
import moment from 'moment'

const type = 'incidents'

const toRequestFormData = request => {
  const { custom_question_answers, files, ...attributes } = request
  const formData = new FormData()

  Object.entries(attributes).forEach(appendPairToFormData(formData, 'incident'))
  appendFilesToFormData(formData, 'incident', files)

  if (custom_question_answers.length > 0) {
    appendCustomQuestionAnswersToFormData(
      formData,
      'incident',
      custom_question_answers
    )
  }
  return formData
}

const storeGetters = {
  loadingIncidents: (_state, getters) => getters.isLoading(type),

  incident: (_state, getters) => {
    const incident = getters.resource(type, getters.params.incidentId)
    if (!incident) return incident

    return {
      ...incident,
      customQuestionAnswers: getters
        .relations(incident, 'customQuestionAnswers', 'flow')
        .slice()
        .reverse(),
    }
  },

  incidents: (_state, _getters, rootState) => {
    const incidentsById = rootState.resourceStore.byId[type] || {}

    return Object.values(incidentsById).map(incident => ({
      ...incident,
    }))
  },

  incidentsForArchive:
    (_state, getters) =>
    ({ search, ascending }) => {
      const loweredSearch = search.toLowerCase()
      // TODO:
      // eslint-disable-next-line
      const sortDirection = ascending ? 1 : -1

      let archive = getters[type].slice()

      if (search) {
        archive = archive.filter(({ title, description }) => {
          return (
            (title || '').toLowerCase().includes(loweredSearch) ||
            (description || '').toLowerCase().includes(loweredSearch)
          )
        })
      }

      archive.sort((a, b) => {
        if (!a.detectedAt) {
          return moment(a.createdAt) - moment(b.createdAt)
        } else {
          return moment(a.detectedAt) - moment(b.detectedAt)
        }
      })

      return archive
    },
}

const actions = {
  fetchIncidents: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/incidents`)
      .then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },

  fetchIncident: ({ getters, getters: { organizationId } }, id) => {
    id = id || getters.params.incidentId

    const params = {
      include: ['custom_question_answers'],
    }

    const request = api.get(`organizations/${organizationId}/incidents/${id}`, {
      params,
    })
    return wrapResourceRequest(type, request)
  },

  createIncident: ({ commit, state, getters, dispatch }, incident) => {
    const { organizationId } = getters

    const request = api
      .post(
        `organizations/${organizationId}/incidents`,
        toRequestFormData(snakeCaseKeys(incident))
      )
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        dispatch('showFlashMessage', {
          message:
            i18n.methods.t('flash_messages.incident') +
            i18n.methods.t('flash_messages.update.success'),
        })
        router.push({ name: 'IncidentsArchive' })
      })
      .catch(({ response }) => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        dispatch('showFlashMessage', {
          message:
            i18n.methods.t('flash_messages.incident') +
            i18n.methods.t('flash_messages.update.success'),
        })
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })
  },

  saveIncident: ({ getters, getters: { organizationId } }, incident) => {
    const params = toRequestFormData(snakeCaseKeys(incident))

    const request = api.put(
      `organizations/${organizationId}/incidents/${incident.id}`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  deleteIncident: ({ getters: { organizationId } }, incident) => {
    const request = api.delete(
      `organizations/${organizationId}/incidents/${incident.id}`
    )
    return wrapResourceRequest(type, request)
  },

  flushIncidentRelations: ({ commit, getters }, incident) => {
    const customQuestionAnswers = getters
      .relations(incident, 'customQuestionAnswers', 'flow')
      .slice()
      .reverse()

    commit(types.FLUSH_RESOURCES, [...customQuestionAnswers])
  },
}

export default {
  actions,
  getters: storeGetters,
}
