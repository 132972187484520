import * as types from '../mutationTypes'
import api from '@/utils/api'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import humanizeErrors from '@/utils/humanizeErrors'

const type = 'users'

const initialState = {
  errors: [],
  createdUser: {},
}

const userGetters = {
  loadingUser: (_state, getters) => getters.isLoading(type),

  user: (_state, getters) => getters.resource(type, getters.session.userId),
}

const actions = {
  fetchUser({ commit }) {
    const request = api
      .get('user')
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
      })
      .catch(({ response }) => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })
  },

  saveUser: ({ commit, getters }, user) => {
    const params = snakeCaseKeys({ user })

    const request = api
      .put(`user`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },
  createUser: ({ commit, getters }, user) => {
    const params = snakeCaseKeys({ user })

    const request = api
      .post(`user`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
        commit(types.ADD_RESOURCE_RESPONSE_USER_DETAILS, response)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },
}

const mutations = {
  [types.ADD_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = humanizeErrors(response)
  },
  [types.REMOVE_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = []
  },
  [types.ADD_RESOURCE_RESPONSE_USER_DETAILS]: (state, response) => {
    state.createdUser = response.data.data.attributes.currentUser
  },
}

export default {
  state: initialState,
  getters: userGetters,
  actions,
  mutations,
}
