<template>
  <label class="LogInField">
    <input
      :type="type"
      :value="value"
      :placeholder="placeholder"
      :name="name"
      :autocomplete="autocomplete"
      @input="updateValue"
      @change="updateValue"
      @blur="$emit('blur', $event.target.value)"
    />
    <p class="LogInField__label">{{ placeholder }}</p>
  </label>
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'

export default {
  name: 'Field',
  props: {
    type: { type: String, required: false, defaultValue: 'text' },
    value: VueTypes.any,
    placeholder: { type: String, required: false, defaultValue: '' },
    autocomplete: { type: String, required: false, defaultValue: '' },
    fill: { type: Boolean, required: false, defaultValue: false },
    name: { type: String },
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e.target.value)
    },
  },
}
</script>
