<template>
  <div class="OrganizationsDropdown">
    <div v-if="organizations.length > 1">
      <popper
        :options="{
          placement: 'left',
        }"
        trigger="click"
      >
        <div class="popper">
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div v-for="organization in organizations" :key="organization.id">
              <router-link
                :to="{ path: `/organizations/${organization.id}/dashboard` }"
                :class="
                  currentOrganization === organization
                    ? 'dropdown-item active'
                    : 'dropdown-item'
                "
              >
                {{ organization.name }}
              </router-link>
            </div>
          </div>
        </div>

        <h1 slot="reference">
          <span v-if="currentOrganization">{{ currentOrganization.name }}</span>
          <i class="arrow fa fa-angle-down" />
        </h1>
      </popper>
    </div>

    <div v-else>
      <h1 v-if="currentOrganization">
        {{ currentOrganization.name }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Popper from 'vue-popperjs'
import './style.scss'

export default {
  name: 'OrganizationsDropdown',
  components: { Popper },
  computed: {
    ...mapGetters({
      loading: 'loadingOrganizations',
      organizations: 'organizations',
      currentOrganization: 'organization',
    }),
  },
  watch: {
    $route(to, from) {
      if (this.$children.length > 0) {
        this.$children[0].showPopper = false
      }
    },
  },
  mounted() {
    this.$store.dispatch('fetchOrganizations')
  },
}
</script>
