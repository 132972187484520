<template>
  <div class="CheckboxFieldCreator">
    <Field
      :value="value"
      :placeholder="placeholder"
      class="CheckboxFieldCreator__input"
      @input="$emit('input', $event)"
    />
    <button class="CheckboxFieldCreator__add" @click="$emit('create')">
      <span /><span />
    </button>
  </div>
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'
import Field from '@/components/Field'

export default {
  name: 'CheckboxField',
  components: { Field },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: VueTypes.any,
    placeholder: VueTypes.string,
  },
}
</script>
