<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'DataProcessorsArchive' }"
      :title="dataProcessor && dataProcessor.name"
      :timestamp="dataProcessor && dataProcessor.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('data_processor.archive.title')"
    />

    <Loader v-if="!dataProcessor" />
    <ContentSection v-else>
      <ResourceActions>
        <ButtonLink :to="{ name: 'DataProcessorsReviews' }" type="primary">
          {{ t('shared.buttons.reviews') }}
        </ButtonLink>
        <ButtonLink :to="{ name: 'DataProcessorsEdit' }" type="primary">
          {{ t('shared.actions.edit') }}
        </ButtonLink>
        <Button @click="handleDelete">
          {{ t('shared.actions.delete') }}
        </Button>
      </ResourceActions>

      <Columns>
        <div>
          <ColumnHeader>{{ t('data_processor.show.details') }}</ColumnHeader>

          <FieldSubHeader>{{ t('data_processor.show.type') }}</FieldSubHeader>
          <ColumnParagraph v-if="dataProcessor.technical === 'technical'">
            {{ t('data_processor.show.technical') }}
          </ColumnParagraph>
          <ColumnParagraph v-else-if="dataProcessor.technical === 'other'">
            {{ t('data_processor.show.other') }}
          </ColumnParagraph>
          <ColumnParagraph
            v-else-if="dataProcessor.technical === 'subprocessor'"
          >
            {{ t('data_processor.show.subprocessor') }}
          </ColumnParagraph>

          <FieldSubHeader>
            {{ t('data_processor.show.purpose') }}
          </FieldSubHeader>
          <ColumnParagraph>{{ dataProcessor.purpose }}</ColumnParagraph>

          <FieldSubHeader>
            {{ t('data_processor.show.risk_assessment') }}
          </FieldSubHeader>
          <ColumnParagraph>{{ dataProcessor.riskAssessment }}</ColumnParagraph>
          <template v-for="question in questions">
            <div v-if="findQuestionAnswer(question.id)" :key="question.id">
              <FieldSubHeader>{{ question.question }}</FieldSubHeader>
              <ColumnParagraph>
                {{ findQuestionAnswer(question.id).answer }}
              </ColumnParagraph>
            </div>
          </template>
        </div>

        <div>
          <ColumnHeader>
            {{ t('data_processor.show.data_processor_agreement') }}
          </ColumnHeader>
          <ColumnParagraph
            v-if="dataProcessor.dataProcessingAgreements.length === 0"
          >
            {{ t('data_processor.show.no_data_processor_agreements') }}
          </ColumnParagraph>
          <template v-else>
            <List>
              <DataProcessingAgreementItem
                v-for="dataProcessingAgreement in dataProcessor.dataProcessingAgreements"
                :key="dataProcessingAgreement.id"
                :data-processing-agreement="dataProcessingAgreement"
                @delete="
                  handleDataProcessingAgreementDelete(dataProcessingAgreement)
                "
              />
            </List>
          </template>

          <Actions>
            <ButtonLink
              :to="{ name: 'DataProcessingAgreementsNew' }"
              type="primary"
              size="md"
            >
              {{ t('data_processor.show.add_appointment_button') }}
            </ButtonLink>
          </Actions>
        </div>
      </Columns>
      <ResourceActions>
        <ButtonLink
          :to="{
            name: 'ReviewProcessesNew',
            query: { 'data-processor-id': dataProcessor.id },
          }"
          type="primary"
        >
          {{ t('data_processor.show.new_control_button') }}
        </ButtonLink>
      </ResourceActions>

      <TableWrapper v-if="filteredActiveReviewProcesses().length">
        <h2>{{ t('data_processor.show.active_controls') }}</h2>
        <table>
          <thead class="column-count-2">
            <th />
            <th />
          </thead>
          <tbody class="column-count-2">
            <tr
              v-for="reviewProcess in filteredActiveReviewProcesses()"
              :key="reviewProcess.id"
            >
              <td>
                {{ reviewProcess.title }}
                <Label
                  v-if="reviewProcess.overdue && reviewProcess.active"
                  type="alert"
                  >{{ t('shared.table.overdue') }}</Label
                >
              </td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{ path: `../review-processes/${reviewProcess.id}` }"
                  :to-edit="{
                    path: `../review-processes/${reviewProcess.id}/edit`,
                  }"
                  :resource="reviewProcess"
                  @delete="handleReviewProcessDelete"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>

      <TableWrapper v-if="filteredInactiveReviewProcesses().length">
        <h2>{{ t('data_processor.show.inactive_controls') }}</h2>
        <table>
          <thead class="column-count-2">
            <th />
            <th />
          </thead>
          <tbody class="column-count-2">
            <tr
              v-for="reviewProcess in filteredInactiveReviewProcesses()"
              :key="reviewProcess.id"
            >
              <td>
                {{ reviewProcess.title }}
              </td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{ path: `../review-processes/${reviewProcess.id}` }"
                  :to-edit="{
                    path: `../review-processes/${reviewProcess.id}/edit`,
                  }"
                  :resource="reviewProcess"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </ContentSection>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'

import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ResourceActions from '@/components/ResourceActions'
import Button from '@/components/Button'
import ButtonLink from '@/components/ButtonLink'
import FieldSubHeader from '@/components/FieldSubHeader'
import Columns from '@/components/Columns'
import ColumnHeader from '@/components/ColumnHeader'
import ColumnParagraph from '@/components/ColumnParagraph'
import List from '@/components/List'
import DataProcessingAgreementItem from '@/components/DataProcessingAgreementItem'
import Actions from '@/components/Actions'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import TableWrapper from '@/components/TableWrapper'

export default {
  name: 'DataProcessorShow',
  components: {
    Loader,
    ResourceHeader,
    ContentSection,
    ResourceActions,
    ButtonLink,
    Button,
    FieldSubHeader,
    Columns,
    ColumnHeader,
    ColumnParagraph,
    List,
    DataProcessingAgreementItem,
    Actions,
    ArchiveTableDropdown,
    TableWrapper,
  },
  computed: {
    ...mapGetters(['dataProcessor', 'questions']),
  },
  mounted() {
    if (this.dataProcessor) this.flushDataProcessorRelations(this.dataProcessor)
    this.collapseSidebar()
    this.fetchCustomQuestions('data_processors')
    this.fetchDataProcessor()
  },
  methods: {
    ...mapActions([
      'flushDataProcessorRelations',
      'fetchDataProcessor',
      'collapseSidebar',
      'deleteDataProcessingAgreement',
      'deleteDataProcessor',
      'showFlashMessage',
      'fetchCustomQuestions',
      'deleteReviewProcess',
    ]),

    handleDataProcessingAgreementDelete(dataProcessingAgreement) {
      if (
        !confirm(
          this.t('flash_messages.custom.data_processor_agreement_confirm')
        )
      )
        return

      this.deleteDataProcessingAgreement(dataProcessingAgreement)
    },

    handleDelete() {
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') + this.dataProcessor.name
        )
      ) {
        this.deleteDataProcessor(this.dataProcessor)
          .then(() => {
            router.push({ name: 'DataProcessorsArchive' })
            this.showFlashMessage({
              message:
                this.t('flash_messages.data_processor') +
                this.t('flash_messages.delete.success'),
            })
          })
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.data_processor') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },

    handleReviewProcessDelete(id) {
      const reviewProcessObject = this.$store.getters.reviewProcesses.find(
        reviewProcess => reviewProcess.id === id
      )
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') +
            reviewProcessObject.title
        )
      ) {
        this.deleteReviewProcess(reviewProcessObject)
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.review_process') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.review_process') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },

    findQuestionAnswer(questionId) {
      const questionAnswer = this.dataProcessor.customQuestionAnswers.find(
        customQuestionAnswer =>
          customQuestionAnswer.questionId === parseInt(questionId)
      )

      return questionAnswer
    },

    filteredInactiveReviewProcesses() {
      const filteredReviewProcesses = this.dataProcessor.reviewProcesses.filter(
        ({ active }) => active === false
      )

      return filteredReviewProcesses
    },

    filteredActiveReviewProcesses() {
      const filteredReviewProcesses = this.dataProcessor.reviewProcesses.filter(
        ({ active }) => active === true
      )

      return filteredReviewProcesses
    },
  },
}
</script>
