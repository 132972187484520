import * as types from '../mutationTypes'
import api from '@/utils/api'
import router from '@/routes'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import i18n from '@/mixins/i18n.js.erb'

const type = 'riskAssessments'

const storeGetters = {
  loadingRiskAssessments: (_state, getters) => getters.isLoading(type),

  riskAssessment: (_state, getters) => {
    const riskAssessment = getters.resource(
      type,
      getters.params.riskAssessmentId
    )
    if (!riskAssessment) return riskAssessment

    return {
      ...riskAssessment,
      revisions: getters.relations(riskAssessment, 'revisions', 'revisable'),
      mitigationMeasures: getters.relations(
        riskAssessment,
        'mitigationMeasures'
      ),
    }
  },
}

const actions = {
  fetchRiskAssessment: (
    { getters, getters: { organizationId } },
    id,
    dataMappingId
  ) => {
    id = id || getters.params.riskAssessmentId
    dataMappingId = dataMappingId || getters.params.dataMappingId

    const params = {
      include: ['revisions', 'mitigation_measures'],
    }
    const request = api.get(
      `organizations/${organizationId}/data_mappings/${dataMappingId}/risk_assessments/${id}`,
      { params }
    )
    return wrapResourceRequest(type, request)
  },

  createRiskAssessment: (
    { commit, state, getters, dispatch },
    riskAssessment,
    dataMappingId
  ) => {
    const { organizationId } = getters
    dataMappingId = dataMappingId || getters.params.dataMappingId

    const request = api
      .post(
        `organizations/${organizationId}/data_mappings/${dataMappingId}/risk_assessments`,
        snakeCaseKeys({ riskAssessment })
      )
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        dispatch('showFlashMessage', {
          message:
            i18n.methods.t('flash_messages.risk_assessment') +
            i18n.methods.t('flash_messages.create.success'),
        })
        router.push({
          name: 'DataMappingsShow',
          params: { dataMappingId: dataMappingId },
        })
      })
      .catch(({ response }) => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        dispatch('showFlashMessage', {
          message:
            i18n.methods.t('flash_messages.risk_assessment') +
            i18n.methods.t('flash_messages.create.error'),
        })
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })
  },

  saveRiskAssessment: (
    { getters, getters: { organizationId } },
    riskAssessment
  ) => {
    const params = snakeCaseKeys(riskAssessment)
    const dataMappingId =
      riskAssessment.dataMappingId || getters.params.dataMappingId

    const request = api.put(
      `organizations/${organizationId}/data_mappings/${dataMappingId}/risk_assessments/${riskAssessment.id}`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  deleteRiskAssessment: (
    { getters, getters: { organizationId } },
    riskAssessment
  ) => {
    const dataMappingId =
      riskAssessment.dataMappingId || getters.params.dataMappingId
    const request = api.delete(
      `organizations/${organizationId}/data_mappings/${dataMappingId}/risk_assessments/${riskAssessment.id}`
    )

    return wrapResourceRequest(type, request)
  },
}

export default {
  actions,
  getters: storeGetters,
}
