import * as types from '../mutationTypes'

const actions = {
  storeSafetyMeasures({ commit }, safetyMeasures) {
    commit(types.STORE_SAFETY_MEASURES, safetyMeasures)
  },
}

const mutations = {
  [types.STORE_SAFETY_MEASURES]: (state, safetyMeasures) => {
    state.splice(0, state.length, ...safetyMeasures)
  },
}

export default {
  state: [],
  actions,
  mutations,
}
