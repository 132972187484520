<template>
  <div>
    <RequestFormComplete
      v-if="isCompleting"
      v-bind="$props"
      @submit="handleSubmit"
    />
    <RequestFormIncomplete
      v-if="!isCompleting"
      v-bind="$props"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import RequestFormComplete from '@/forms/RequestForm/RequestFormComplete'
import RequestFormIncomplete from '@/forms/RequestForm/RequestFormIncomplete'

export default {
  name: 'RequestForm',
  components: {
    RequestFormComplete,
    RequestFormIncomplete,
  },
  props: {
    request: { type: Object },
  },
  computed: {
    isCompleting() {
      return this.$attrs.completing || this.request.status === 'completed'
    },
  },
  methods: {
    handleSubmit(request) {
      this.$emit('submit', request)
    },
  },
}
</script>
