<template>
  <LogInModal>
    <form @submit.prevent="logIn()">
      <LogInField
        v-model="email"
        :placeholder="t('login.email')"
        autocomplete="email"
      />
      <LogInField
        v-model="password"
        :placeholder="t('login.password')"
        type="password"
        class="LogIn__password"
        autocomplete="current-password"
      />

      <LogInModalSaveButton>{{ t('login.log_in') }}</LogInModalSaveButton>
      <LocaleSelectorLogin />
      <LogInModalBackLink :to="{ name: 'PasswordForgotten' }">
        {{ t('login.forgot_password') }}
      </LogInModalBackLink>
    </form>
  </LogInModal>
</template>

<script>
import LogInModal from '@/components/LogInModal'
import LogInField from '@/components/LogInField'
import LogInModalSaveButton from '@/components/LogInModalSaveButton'
import LogInModalBackLink from '@/components/LogInModalBackLink'
import LocaleSelectorLogin from '@/components/LocaleSelectorLogin'

export default {
  name: 'LogIn',
  components: {
    LogInModal,
    LogInModalSaveButton,
    LogInModalBackLink,
    LogInField,
    LocaleSelectorLogin,
  },
  data() {
    return {
      email: '',
      password: '',
    }
  },
  methods: {
    logIn() {
      this.$store.dispatch('logIn', this.$data)
    },
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
