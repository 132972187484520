import * as types from '../mutationTypes'
import api from '@/utils/api'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import appendPairToFormData from '@/utils/appendPairToFormData'
import appendFilesToFormData from '@/utils/appendFilesToFormData'

const type = 'dataProcessingAgreements'

const toFormData = dataProcessingAgreement => {
  const { files, system_ids, ...attributes } = dataProcessingAgreement
  const formData = new FormData()

  if (system_ids) {
    ;[...system_ids, ''].forEach(id => {
      formData.append('data_processing_agreement[system_ids][]', id)
    })
  }

  Object.entries(attributes).forEach(
    appendPairToFormData(formData, 'data_processing_agreement')
  )
  appendFilesToFormData(formData, 'data_processing_agreement', files)

  return formData
}

const storeGetters = {
  loadingDataProcessingAgreements: (_state, getters) => getters.isLoading(type),

  dataProcessingAgreement: (_state, getters) =>
    getters.resource(type, getters.params.dataProcessingAgreementId),
}

const actions = {
  createDataProcessingAgreement: ({ getters }, dataProcessingAgreement) => {
    const { organizationId, dataProcessorId } = getters.params

    const path = [
      'organizations',
      organizationId,
      'data_processors',
      dataProcessorId,
      'data_processing_agreements',
    ].join('/')

    const request = api.post(
      path,
      toFormData(snakeCaseKeys(dataProcessingAgreement))
    )
    return wrapResourceRequest(type, request)
  },

  saveDataProcessingAgreement: (store, dataProcessingAgreement) => {
    const path = [
      'organizations',
      store.getters.organizationId,
      'data_processors',
      dataProcessingAgreement.dataProcessorId,
      'data_processing_agreements',
      dataProcessingAgreement.id,
    ].join('/')

    const request = api
      .put(path, toFormData(snakeCaseKeys(dataProcessingAgreement)))
      .then(response => {
        store.dispatch(
          'flushDataProcessingAgreementRelations',
          dataProcessingAgreement
        )
        return response
      })

    return wrapResourceRequest(type, request)
  },

  deleteDataProcessingAgreement: (store, dataProcessingAgreement) => {
    const path = [
      'organizations',
      store.getters.organizationId,
      'data_processors',
      dataProcessingAgreement.dataProcessorId,
      'data_processing_agreements',
      dataProcessingAgreement.id,
    ].join('/')

    const request = api.delete(path).then(response => {
      store.dispatch(
        'flushDataProcessingAgreementRelations',
        dataProcessingAgreement
      )
      return response
    })
    return wrapResourceRequest(type, request)
  },

  flushDataProcessingAgreementRelations: (
    { commit, getters },
    dataProcessingAgreement
  ) => {
    const dataProcessingAgreementSystems = getters.relations(
      dataProcessingAgreement,
      'dataProcessingAgreementSystems'
    )
    commit(types.FLUSH_RESOURCES, dataProcessingAgreementSystems)
  },
}

export default {
  actions,
  getters: storeGetters,
}
