import SystemsArchive from '@/pages/SystemsArchive'
import SystemsNew from '@/pages/SystemsNew'
import SystemsShow from '@/pages/SystemsShow'
import SystemsEdit from '@/pages/SystemsEdit'
import SystemsReviews from '@/pages/SystemsReviews'

const routes = [
  {
    path: 'systems/new',
    component: SystemsNew,
    name: 'SystemsNew',
  },
  {
    path: 'systems',
    component: SystemsArchive,
    name: 'SystemsArchive',
  },
  {
    path: 'systems/:systemId',
    component: SystemsShow,
    name: 'SystemsShow',
  },
  {
    path: 'systems/:systemId/reviews',
    component: SystemsReviews,
    name: 'SystemsReviews',
  },
  {
    path: 'systems/:systemId/edit',
    component: SystemsEdit,
    name: 'SystemsEdit',
  },
]
export default routes
