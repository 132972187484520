<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'DocumentsRevisions' }"
      :title="document && document.title"
      :timestamp="document && document.updatedAt"
      :back-link-text="t('shared.back_to_resource')"
      :title-prefix="t('document.archive.title')"
    />

    <Loader v-if="!document" />
    <DocumentForm v-else :document="document" @submit="handleSubmit" />
  </div>
</template>

<script>
import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'
import DocumentForm from '@/forms/DocumentForm'
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'

export default {
  name: 'DocumentsEdit',
  components: {
    ResourceHeader,
    Loader,
    DocumentForm,
  },
  computed: {
    ...mapGetters(['document']),
  },
  beforeMount() {
    this.fetchDocument()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'fetchDocument',
      'collapseSidebar',
      'saveDocument',
      'showFlashMessage',
    ]),

    handleSubmit(document) {
      this.saveDocument(document)
        .then(() => {
          router.push({
            name: 'DocumentsRevisions',
            params: { id: document.id },
          })
          this.showFlashMessage({
            message:
              this.t('flash_messages.document') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.document') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
