<template>
  <div class="VueEditorContainer">
    <slot />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'VueEditorContainer',
}
</script>
