import * as types from '../mutationTypes'
import api from '../../utils/api'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import humanizeErrors from '@/utils/humanizeErrors'
import * as moment from 'moment'
import router from '@/routes'

const type = 'organizations'

const initialState = {
  errors: [],
}

const resourceGetters = {
  loadingOrganizations: (_state, _getters, globalState) => {
    const requests = globalState.resourceStore.requests[type] || []
    return requests.length !== 0
  },

  loadingOrganization: (_state, getters) => getters.isLoading(type),

  organizations: (_state, _getters, globalState) => {
    const map = globalState.resourceStore.byId[type] || {}
    return Object.values(map)
  },

  organization: (_state, getters) =>
    getters.resource(type, getters.organizationId),

  organizationExportLink: (_state, { organizationId, session: { token } }) => {
    return `/api/organizations/${organizationId}/export?token=${token}`
  },
}

const actions = {
  fetchOrganizations: () => {
    const request = api.get('organizations').then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },

  fetchOrganization: ({ getters: { organizationId } }) => {
    const request = api.get(`organizations/${organizationId}`)
    return wrapResourceRequest(type, request)
  },

  fetchOrganizationStatus: ({ getters: { organizationId } }) => {
    api.get(`organizations/${organizationId}`).then(response => {
      const organization = response.data.data

      if (organization.attributes.subscriptionStatus !== 'active') {
        const CurrentDate = moment()
        const CreatedAt = moment(organization.attributes.createdAt)
        const trialDays = CurrentDate.diff(CreatedAt, 'days')

        if (trialDays > 30) {
          router.push({
            path: 'subscribe',
          })
        }
      }
    })
  },

  saveOrganization: ({ commit, getters: { organizationId } }, organization) => {
    const params = snakeCaseKeys({ organization })

    const request = api
      .put(`organizations/${organizationId}`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },
}

const mutations = {
  [types.ADD_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = humanizeErrors(response)
  },
  [types.REMOVE_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = []
  },
}

export default {
  state: initialState,
  getters: resourceGetters,
  actions,
  mutations,
}
