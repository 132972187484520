<template>
  <Modal
    :title="
      isNew ? t('dashboard.todos.new.title') : t('dashboard.todos.edit.title')
    "
  >
    <ModalCloseButton :to="{ name: 'Dashboard' }" />

    <Field
      v-model="formData.title"
      v-validate="'required'"
      :error="errors.first('title')"
      :class="hasError('title')"
      fill
      type="text"
      :placeholder="t('dashboard.todos.new.title_placeholder')"
      name="title"
    />
    <VueEditorContainer>
      <vue-editor
        v-model="formData.description"
        :editor-toolbar="customToolbar"
      />
    </VueEditorContainer>

    <FormSelect v-model="formData.userId" fill style="margin-top: 50px">
      <option :key="0" :value="null">
        {{ null | formatUser }}
      </option>
      <option v-for="user in users" :key="user.id" :value="user.id">
        {{ user.name }}
      </option>
    </FormSelect>

    <ModalSaveButton @click="$emit('submit', formData)">
      {{
        isNew ? t('dashboard.todos.new.save') : t('dashboard.todos.edit.save')
      }}
    </ModalSaveButton>
  </Modal>
</template>

<script>
import VueTypes from 'vue-types'
import { VueEditor } from 'vue2-editor'
import { cloneDeep } from 'lodash'
import { mapGetters, mapActions } from 'vuex'

import Modal from '@/components/Modal'
import ModalCloseButton from '@/components/ModalCloseButton'
import ModalSaveButton from '@/components/ModalSaveButton'
import Field from '@/components/Field'
import VueEditorContainer from '@/components/VueEditorContainer'
import FormSelect from '@/components/FormSelect'
import vueEditorToolbarConfig from '@/utils/vueEditorToolbarConfig'

export default {
  name: 'TodoForm',
  components: {
    Modal,
    ModalCloseButton,
    ModalSaveButton,
    Field,
    VueEditorContainer,
    FormSelect,
    VueEditor,
  },
  props: {
    todo: VueTypes.shape({
      id: String,
      title: String,
      description: String,
      userId: VueTypes.oneOfType([String, null]),
    }).loose,
    isSaving: VueTypes.bool.isRequired,
  },

  data() {
    return {
      formData: cloneDeep(this.todo),
      customToolbar: vueEditorToolbarConfig,
    }
  },
  computed: {
    ...mapGetters(['userOptions', 'users']),
    isNew() {
      return !this.todo.id
    },
  },

  mounted() {
    this.fetchOrganizationUsers()
  },

  methods: {
    ...mapActions(['fetchOrganizationUsers']),
    hasError(field) {
      let items = this.errors.items.map(item => item.field)

      if (items.includes(field)) {
        return 'Field--error'
      }
    },
  },
}
</script>
