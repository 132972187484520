<template>
  <label class="IntervalField">
    <Field
      :value="value"
      class="IntervalField__input"
      type="number"
      @input="$emit('input', $event)"
    /><span class="IntervalField__unit">
      {{ value == 1 ? t('interval_field.day') : t('interval_field.days') }}
    </span>
  </label>
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'
import Field from '@/components/Field'

export default {
  name: 'IntervalField',
  components: { Field },
  props: { value: VueTypes.any },
}
</script>
