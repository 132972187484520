import DataMappingsArchive from '@/pages/DataMappingsArchive'
import DataMappingsNew from '@/pages/DataMappingsNew'
import DataMappingsShow from '@/pages/DataMappingsShow'
import DataMappingsEdit from '@/pages/DataMappingsEdit'
import DataMappingsReviews from '@/pages/DataMappingsReviews'

const routes = [
  {
    path: 'data-mappings',
    component: DataMappingsArchive,
    name: 'DataMappingsArchive',
  },
  {
    path: 'data-mappings/new',
    component: DataMappingsNew,
    name: 'DataMappingsNew',
  },
  {
    path: 'data-mappings/:dataMappingId',
    component: DataMappingsShow,
    name: 'DataMappingsShow',
  },
  {
    path: 'data-mappings/:dataMappingId/edit',
    component: DataMappingsEdit,
    name: 'DataMappingsEdit',
  },
  {
    path: 'data-mappings/:dataMappingId/reviews',
    component: DataMappingsReviews,
    name: 'DataMappingsReviews',
  },
]
export default routes
