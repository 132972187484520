import RiskAssessmentsNew from '@/pages/RiskAssessmentsNew'
import RiskAssessmentsEdit from '@/pages/RiskAssessmentsEdit'
import RiskAssessmentsShow from '@/pages/RiskAssessmentsShow'

import MitigationMeasuresNew from '@/pages/MitigationMeasuresNew'
import MitigationMeasuresEdit from '@/pages/MitigationMeasuresEdit'

const routes = [
  {
    path: 'data-mappings/:dataMappingId/risk-assessments/new',
    component: RiskAssessmentsNew,
    name: 'RiskAssessmentsNew',
  },
  {
    path: 'data-mappings/:dataMappingId/risk-assessments/:riskAssessmentId/edit',
    component: RiskAssessmentsEdit,
    name: 'RiskAssessmentsEdit',
  },
  {
    path: 'data-mappings/:dataMappingId/risk-assessments/:riskAssessmentId',
    component: RiskAssessmentsShow,
    name: 'RiskAssessmentsShow',
    children: [
      {
        path: 'mitigation-measures/new',
        component: MitigationMeasuresNew,
        name: 'MitigationMeasuresNew',
      },
      {
        path: 'mitigation-measures/:mitigationMeasureId/edit',
        component: MitigationMeasuresEdit,
        name: 'MitigationMeasuresEdit',
      },
    ],
  },
]
export default routes
