import OrganizationsEdit from '@/pages/OrganizationsEdit'
import OrganizationsExports from '@/pages/OrganizationsExports'
import OrganizationUsersNew from '@/pages/OrganizationUsersNew'
import OrganizationUsersEdit from '@/pages/OrganizationUsersEdit'
import OrganizationUsersDelete from '@/pages/OrganizationUsersDelete'
import DepartmentsNew from '@/pages/DepartmentsNew'
import DepartmentsEdit from '@/pages/DepartmentsEdit'
import DepartmentsDelete from '@/pages/DepartmentsDelete'

const routes = [
  {
    path: 'exports',
    component: OrganizationsExports,
    name: 'OrganizationsExports',
  },
  {
    path: 'edit',
    component: OrganizationsEdit,
    name: 'OrganizationsEdit',
    children: [
      {
        path: 'organization-users/new',
        component: OrganizationUsersNew,
        name: 'OrganizationUsersNew',
      },
      {
        path: 'organization-users/:id/edit',
        component: OrganizationUsersEdit,
        name: 'OrganizationUsersEdit',
      },
      {
        path: 'organization-users/:id/delete',
        component: OrganizationUsersDelete,
        name: 'OrganizationUsersDelete',
      },
      {
        path: 'departments/new',
        component: DepartmentsNew,
        name: 'DepartmentsNew',
      },
      {
        path: 'department/:id/edit',
        component: DepartmentsEdit,
        name: 'DepartmentsEdit',
      },
      {
        path: 'department/:id/delete',
        component: DepartmentsDelete,
        name: 'DepartmentsDelete',
      },
    ],
  },
]
export default routes
