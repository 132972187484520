import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDateHuman', function (value) {
  if (value) {
    return moment(String(value)).format('DD[.] MMMM YYYY')
  }
})

Vue.filter('formatDateTimeHuman', function (value) {
  if (value) {
    return moment(String(value)).format('DD[.] MMMM HH:mm')
  }
})
