<template>
  <tr>
    <td>{{ department.name }}</td>
    <td>{{ department.createdAt | formatDateHuman }}</td>
    <td>{{ department.updatedAt | formatDateHuman }}</td>
    <td>
      <ArchiveTableDropdown
        v-if="department.name !== 'Generelt'"
        :to-edit="{ path: `edit/department/${department.id}/edit` }"
        :to-delete="{ path: `edit/department/${department.id}/delete` }"
        :resource="department"
      />
    </td>
  </tr>
</template>

<script>
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'

export default {
  name: 'DepartmentTableRow',
  components: { ArchiveTableDropdown },
  props: {
    department: { type: Object, required: true },
  },
}
</script>
