<template>
  <DataProcessingAgreementForm
    :data-processing-agreement="dataProcessingAgreement"
    :is-saving="loadingDataProcessingAgreements"
    @submit="handleSubmit"
  />
</template>

<script>
import router from '@/routes'
import DataProcessingAgreementForm from '@/forms/DataProcessingAgreementForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DataProcessingAgreementsNew',
  components: {
    DataProcessingAgreementForm,
  },
  data() {
    return {
      dataProcessingAgreement: {
        title: '',
        systemIds: [],
        files: [],
      },
    }
  },
  computed: {
    ...mapGetters(['loadingDataProcessingAgreements']),
  },
  methods: {
    ...mapActions(['createDataProcessingAgreement', 'showFlashMessage']),

    handleSubmit(dataProcessingAgreement) {
      this.createDataProcessingAgreement(dataProcessingAgreement)
        .then(() => {
          router.push({ name: 'DataProcessorsShow' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.data_processor_agreement') +
              this.t('flash_messages.create.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.data_processor_agreement') +
              this.t('flash_messages.create.error'),
          })
        })
    },
  },
}
</script>
