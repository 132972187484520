<template>
  <main>
    <Sidebar />
    <div
      :class="{ 'OrganizationsShow__page--expanded': expanded }"
      class="OrganizationsShow__page"
    >
      <router-view />
    </div>
  </main>
</template>

<script>
import './style.scss'
import { mapState, mapActions } from 'vuex'

import Sidebar from '@/components/Sidebar'

export default {
  name: 'OrganizationsShow',
  components: { Sidebar },
  computed: mapState({
    expanded: state => !state.sidebar.expanded,
  }),
  mounted() {
    this.fetchOrganization()
    this.$store.dispatch('fetchOrganizationStatus')
  },
  methods: {
    ...mapActions(['fetchOrganization']),
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.dispatch('fetchOrganizationStatus')
    next()
  },
}
</script>
