import * as types from '../mutationTypes'
import api from '@/utils/api'
import appendPairToFormData from '@/utils/appendPairToFormData'
import appendFilesToFormData from '@/utils/appendFilesToFormData'
import appendCompletedFilesToFormData from '@/utils/appendCompletedFilesToFormData'
import appendCustomQuestionAnswersToFormData from '@/utils/appendCustomQuestionAnswersToFormData'
import router from '@/routes'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import moment from 'moment'

const type = 'requests'

const toRequestFormData = request => {
  const { files, completed_files, custom_question_answers, ...attributes } =
    request
  const formData = new FormData()

  Object.entries(attributes).forEach(appendPairToFormData(formData, 'request'))
  appendFilesToFormData(formData, 'request', files)
  appendCompletedFilesToFormData(formData, 'request', completed_files)

  if (custom_question_answers.length > 0) {
    appendCustomQuestionAnswersToFormData(
      formData,
      'request',
      custom_question_answers
    )
  }
  return formData
}

const storeGetters = {
  loadingRequests: (_state, getters) => getters.isLoading(type),

  request: (_state, getters) => {
    const request = getters.resource(type, getters.params.requestId)
    if (!request) return request

    return {
      ...request,
      customQuestionAnswers: getters
        .relations(request, 'customQuestionAnswers', 'flow')
        .slice()
        .reverse(),
    }
  },

  requests: (_state, _getters, rootState) => {
    const requestsById = rootState.resourceStore.byId[type] || {}

    return Object.values(requestsById).map(request => ({
      ...request,
    }))
  },

  requestsForArchive:
    (_state, getters) =>
    ({ search, ascending }) => {
      const loweredSearch = search.toLowerCase()
      // TODO:
      // eslint-disable-next-line
      const sortDirection = ascending ? 1 : -1

      let archive = getters[type].slice()

      if (search) {
        archive = archive.filter(({ name, address }) => {
          return (
            (name || '').toLowerCase().includes(loweredSearch) ||
            (address || '').toLowerCase().includes(loweredSearch)
          )
        })
      }

      archive.sort((a, b) => {
        if (!a.deadline) {
          return moment(a.completedAt) - moment(b.completedAt)
        } else {
          return moment(a.deadline) - moment(b.deadline)
        }
      })

      return archive
    },
}

const actions = {
  fetchRequests: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/requests`)
      .then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },

  fetchRequest: ({ getters, getters: { organizationId } }, id) => {
    id = id || getters.params.requestId

    const params = {
      include: ['custom_question_answers'],
    }
    const request = api.get(`organizations/${organizationId}/requests/${id}`, {
      params,
    })

    return wrapResourceRequest(type, request)
  },

  createRequest: ({ commit, state, getters, dispatch }, request) => {
    const { organizationId } = getters

    const apiRequest = api
      .post(
        `organizations/${organizationId}/requests`,
        toRequestFormData(snakeCaseKeys(request))
      )
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, apiRequest })
        dispatch('showFlashMessage', { message: 'Henvendelsen blev oprettet' })
        router.push({ name: 'RequestsArchive' })
      })
      .catch(({ response }) => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, apiRequest })
        dispatch('showFlashMessage', {
          message: 'Henvendelsen kunne ikke oprettes',
        })
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, apiRequest })
  },

  saveRequest: ({ getters, getters: { organizationId } }, request) => {
    const params = toRequestFormData(snakeCaseKeys(request))

    const apiRequest = api.put(
      `organizations/${organizationId}/requests/${request.id}`,
      params
    )
    return wrapResourceRequest(type, apiRequest)
  },

  deleteRequest: ({ getters: { organizationId } }, request) => {
    const apiRequest = api.delete(
      `organizations/${organizationId}/requests/${request.id}`
    )
    return wrapResourceRequest(type, apiRequest)
  },

  flushRequestRelations: ({ commit, getters }, request) => {
    const customQuestionAnswers = getters
      .relations(request, 'customQuestionAnswers', 'flow')
      .slice()
      .reverse()

    commit(types.FLUSH_RESOURCES, [...customQuestionAnswers])
  },
}

export default {
  actions,
  getters: storeGetters,
}
