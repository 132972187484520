import api from '../../utils/api'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'

const type = 'invites'

const storeGetters = {
  organizationInvites: (_state, getters) => getters.resources(type),
}

const actions = {
  deleteExpiredOrganizationInvites: ({ getters: { organizationId } }) => {
    const request = api.delete(
      `organizations/${organizationId}/invites/expired`
    )
    return wrapResourceRequest(type, request)
  },

  fetchOrganizationInvites: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/invites`)
      .then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },
}

export default {
  getters: storeGetters,
  actions,
}
