<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'RequestsArchive' }"
      :title="request && request.name"
      :timestamp="request && request.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('request.archive.title')"
    />

    <Loader v-if="!request" />
    <ContentSection v-else>
      <ResourceActions>
        <ButtonLink :to="{ name: 'RequestsEdit' }" type="primary">
          {{ t('shared.actions.edit') }}
        </ButtonLink>
        <Button @click="handleDelete">
          {{ t('shared.actions.delete') }}
        </Button>
      </ResourceActions>

      <Columns>
        <div>
          <ColumnHeader>{{ request.name }}</ColumnHeader>

          <FieldSubHeader>{{
            t('request.show.contact_information')
          }}</FieldSubHeader>
          <ColumnParagraph>
            {{ t('request.show.address') }} {{ request.address }} <br />
            {{ t('request.show.email') }} {{ request.email }} <br />
            {{ t('request.show.phone') }} {{ request.phone }}
          </ColumnParagraph>

          <FieldSubHeader>{{
            t('request.show.responsible_user')
          }}</FieldSubHeader>
          <ColumnParagraph>
            {{ request.responsibleName }}
          </ColumnParagraph>

          <FieldSubHeader>{{
            t('request.show.executing_user')
          }}</FieldSubHeader>
          <ColumnParagraph>
            {{ request.executingName }}
          </ColumnParagraph>

          <FieldSubHeader>{{ t('request.show.type') }}</FieldSubHeader>
          <ColumnParagraph v-if="request.kind == 'right_of_access'">
            <strong>{{ t('request.kind.right_of_access') }}</strong> <br />
            {{ t('request.kind_info.right_of_access') }}
          </ColumnParagraph>
          <ColumnParagraph
            v-if="request.kind == 'right_to_restrict_procesessing'"
          >
            <strong>{{
              t('request.kind.right_to_restrict_procesessing')
            }}</strong>
            <br />
            {{ t('request.kind_info.right_to_restrict_procesessing') }}
          </ColumnParagraph>
          <ColumnParagraph v-if="request.kind == 'right_of_rectification'">
            <strong>{{ t('request.kind.right_of_rectification') }}</strong>
            <br />
            {{ t('request.kind_info.right_of_rectification') }}
          </ColumnParagraph>
          <ColumnParagraph v-if="request.kind == 'right_to_erasure'">
            <strong>{{ t('request.kind.right_to_erasure') }}</strong> <br />
            {{ t('request.kind_info.right_to_erasure') }}
          </ColumnParagraph>
          <ColumnParagraph v-if="request.kind == 'right_of_data_portability'">
            <strong>{{ t('request.kind.right_of_data_portability') }}</strong>
            <br />
            {{ t('request.kind_info.right_of_data_portability') }}
          </ColumnParagraph>
          <ColumnParagraph v-if="request.kind == 'other'">
            {{ t('request.kind_info.other') }}
          </ColumnParagraph>

          <FieldSubHeader>{{ t('request.show.status') }}</FieldSubHeader>
          <ColumnParagraph v-if="request.status == 'received'">
            <strong>{{ t('request.show.receved_status_help_text') }}</strong>
            <br />
            {{ t('request.show.received_at') }}
            {{ request.receivedAt | formatDateHuman }}
          </ColumnParagraph>
          <ColumnParagraph v-if="request.status == 'started'">
            <strong>{{ t('request.show.started_status_help_text') }}</strong>
            <br />
            {{ t('request.show.received_at') }}
            {{ request.receivedAt | formatDateHuman }} <br />
            {{ t('request.show.started_at') }}
            {{ request.startedAt | formatDateHuman }}
          </ColumnParagraph>
          <ColumnParagraph v-if="request.status == 'completed'">
            <strong>{{ t('request.show.completed_status_help_text') }}</strong>
            <br />
            {{ t('request.show.received_at') }}
            {{ request.receivedAt | formatDateHuman }} <br />
            {{ t('request.show.started_at') }}
            {{ request.startedAt | formatDateHuman }} <br />
            {{ t('request.show.completed_at') }}
            {{ request.completedAt | formatDateHuman }}
          </ColumnParagraph>

          <FieldSubHeader>{{
            t('request.show.request_reffered')
          }}</FieldSubHeader>
          <ColumnParagraph>{{ request.concerns }}</ColumnParagraph>

          <FieldSubHeader>{{
            t('request.show.company_responded')
          }}</FieldSubHeader>
          <ColumnParagraph>{{ request.ourReaction }}</ColumnParagraph>
          <template v-for="question in questions">
            <div v-if="findQuestionAnswer(question.id)" :key="question.id">
              <FieldSubHeader>{{ question.question }}</FieldSubHeader>
              <ColumnParagraph>{{
                findQuestionAnswer(question.id).answer
              }}</ColumnParagraph>
            </div>
          </template>
        </div>
      </Columns>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'

import ResourceHeader from '@/components/ResourceHeader'
import ResourceActions from '@/components/ResourceActions'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ButtonLink from '@/components/ButtonLink'
import Button from '@/components/Button'
import FieldSubHeader from '@/components/FieldSubHeader'
import Columns from '@/components/Columns'
import ColumnHeader from '@/components/ColumnHeader'
import ColumnParagraph from '@/components/ColumnParagraph'

export default {
  name: 'RequestsShow',
  components: {
    Loader,
    ResourceHeader,
    ResourceActions,
    ContentSection,
    ButtonLink,
    Button,
    FieldSubHeader,
    Columns,
    ColumnHeader,
    ColumnParagraph,
  },
  computed: {
    ...mapGetters(['request', 'questions']),
  },
  mounted() {
    if (this.request) this.flushRequestRelations(this.request)
    this.collapseSidebar()
    this.fetchCustomQuestions('requests')
    this.fetchRequest()
  },
  methods: {
    ...mapActions([
      'fetchRequest',
      'collapseSidebar',
      'deleteRequest',
      'showFlashMessage',
      'flushRequestRelations',
      'fetchCustomQuestions',
    ]),

    handleDelete() {
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') + this.request.name
        )
      ) {
        this.deleteRequest(this.request)
          .then(() => {
            router.push({ name: 'RequestsArchive' })
            this.showFlashMessage({
              message:
                this.t('flash_messages.request') +
                this.t('flash_messages.delete.success'),
            })
          })
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.request') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },

    findQuestionAnswer(questionId) {
      const questionAnswer = this.request.customQuestionAnswers.find(
        customQuestionAnswer =>
          customQuestionAnswer.questionId === parseInt(questionId)
      )

      return questionAnswer
    },
  },
}
</script>
