<template>
  <div class="OrganizationUsers">
    <div>
      <h3>{{ t('organization.edit.tabs.users.title') }}</h3>
      <router-link :to="{ name: 'OrganizationUsersNew' }">
        <AddNewButton
          :title="t('organization.edit.tabs.users.new_button_title')"
        />
      </router-link>
      <div class="table__wrapper">
        <table>
          <thead>
            <th>{{ t('organization.edit.tabs.users.name') }}</th>
            <th>{{ t('organization.edit.tabs.users.email') }}</th>
            <th>{{ t('organization.edit.tabs.users.position') }}</th>
            <th>{{ t('organization.edit.tabs.users.added_by') }}</th>
            <th>{{ t('organization.edit.tabs.users.updated_at') }}</th>
          </thead>
          <tbody>
            <UserTableRow
              v-for="organizationUser in organizationUsers"
              :key="organizationUser.id"
              :organization-user="organizationUser"
              :current-organization-user="currentOrganizationUser"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserTableRow from '@/components//UserTableRow'
import AddNewButton from '@/components/AddNewButton'

export default {
  name: 'OrganizationUsers',
  components: { UserTableRow, AddNewButton },
  computed: {
    ...mapGetters(['organizationUsers', 'currentOrganizationUser']),
  },
}
</script>
