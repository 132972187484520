import * as types from '../mutationTypes'
import api from '@/utils/api'
import attachRelations from '@/utils/attachRelations'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import appendPairToFormData from '@/utils/appendPairToFormData'
import appendCustomQuestionAnswersToFormData from '@/utils/appendCustomQuestionAnswersToFormData'

const type = 'dataProcessors'

const toDataProcessorFormData = request => {
  const { custom_question_answers, ...attributes } = request
  const formData = new FormData()

  Object.entries(attributes).forEach(
    appendPairToFormData(formData, 'data_processor')
  )

  if (custom_question_answers.length > 0) {
    appendCustomQuestionAnswersToFormData(
      formData,
      'data_processor',
      custom_question_answers
    )
  }
  return formData
}

const storeGetters = {
  loadingDataProcessors: (_state, getters) => getters.isLoading(type),

  dataProcessor: (_state, getters) => {
    const dataProcessor = getters.resource(type, getters.params.dataProcessorId)
    if (!dataProcessor) return dataProcessor

    return {
      ...attachRelations([
        'dataProcessingAgreements',
        'reviewProcesses',
        'dataProcessingAgreements.dataProcessingAgreementSystems',
        'dataProcessingAgreements.dataProcessingAgreementSystems.system',
      ])(dataProcessor),
      customQuestionAnswers: getters
        .relations(dataProcessor, 'customQuestionAnswers', 'flow')
        .slice()
        .reverse(),
      reviews: getters
        .relations(dataProcessor, 'reviews', 'flow')
        .slice()
        .reverse(),
    }
  },

  dataProcessors: (_state, getters) => {
    const dataProcessors = getters.resources(type)
    return dataProcessors.map(attachRelations(['dataProcessingAgreements']))
  },

  dataProcessorById: (_state, getters) => id => {
    return getters.resource(type, id)
  },

  dataProcessorsForArchive:
    (_state, getters) =>
    ({ search, ascending }) => {
      const loweredSearch = search.toLowerCase()
      const sortDirection = ascending ? 1 : -1

      let archive = getters[type].slice()

      if (search) {
        archive = archive.filter(({ name, purpose }) => {
          return (
            (name || '').toLowerCase().includes(loweredSearch) ||
            (purpose || '').toLowerCase().includes(loweredSearch)
          )
        })
      }

      archive.sort((a, b) => sortDirection * a.name.localeCompare(b.name))

      return archive
    },
}

const actions = {
  fetchDataProcessors: ({ getters: { organizationId } }) => {
    const params = { include: ['data_processing_agreements'] }
    const request = api
      .get(`organizations/${organizationId}/data_processors`, { params })
      .then(flushTypes(type))

    return wrapResourceRequest(type, request)
  },

  fetchDataProcessor: ({ getters, getters: { organizationId } }, id) => {
    id = id || getters.params.dataProcessorId

    const params = {
      include: [
        'data_processing_agreements',
        'review_processes',
        'data_processing_agreements.data_processing_agreement_systems',
        'data_processing_agreements.data_processing_agreement_systems.system',
        'custom_question_answers',
        'reviews',
      ],
    }
    const request = api.get(
      `organizations/${organizationId}/data_processors/${id}`,
      { params }
    )
    return wrapResourceRequest(type, request)
  },

  createDataProcessor: (
    { commit, state, getters, dispatch },
    dataProcessor
  ) => {
    const { organizationId } = getters

    const request = api.post(
      `organizations/${organizationId}/data_processors`,
      toDataProcessorFormData(snakeCaseKeys(dataProcessor))
    )
    return wrapResourceRequest(type, request)
  },

  saveDataProcessor: (
    { getters, getters: { organizationId } },
    dataProcessor
  ) => {
    const params = toDataProcessorFormData(snakeCaseKeys(dataProcessor))

    const request = api.put(
      `organizations/${organizationId}/data_processors/${dataProcessor.id}`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  deleteDataProcessor: ({ getters: { organizationId } }, dataProcessor) => {
    const request = api.delete(
      `organizations/${organizationId}/data_processors/${dataProcessor.id}`
    )
    return wrapResourceRequest(type, request)
  },

  flushDataProcessorRelations: ({ commit, getters }, dataProcessor) => {
    // Flush related data processing agreements
    const dataProcessingAgreements = getters.relations(
      dataProcessor,
      'dataProcessingAgreements'
    )
    const customQuestionAnswers = getters
      .relations(dataProcessor, 'customQuestionAnswers', 'flow')
      .slice()
      .reverse()
    const reviews = getters
      .relations(dataProcessor, 'reviews', 'flow')
      .slice()
      .reverse()
    // Nested relations
    const dataProcessingAgreementSystems = dataProcessingAgreements.reduce(
      (records, dataProcessingAgreement) =>
        records.concat(
          getters.relations(
            dataProcessingAgreement,
            'dataProcessingAgreementSystems'
          )
        ),
      []
    )

    commit(types.FLUSH_RESOURCES, [
      ...dataProcessingAgreements,
      ...dataProcessingAgreementSystems,
      ...customQuestionAnswers,
      ...reviews,
    ])
  },
}

export default {
  actions,
  getters: storeGetters,
}
