<template>
  <ContentSection>
    <Steps :step-names="[t('request.steps.status_response')]">
      <Step :step-name="t('request.steps.status_response')">
        <FieldContainer>
          <FieldHeader
            >{{ t('request.form.request_status_question')
            }}<HelpTooltip tooltip-key="request.status"
          /></FieldHeader>

          <RadioField v-model="formData.status" value="completed">
            {{ t('request.status.completed') }}
          </RadioField>

          <div>
            <FieldSubHeader>{{
              t('request.form.received_date')
            }}</FieldSubHeader>
            <DatePicker
              v-model="formData.receivedAt"
              :placeholder="t('shared.form.select_date_placeholder')"
            />
          </div>

          <div>
            <FieldSubHeader>{{
              t('request.form.started_date')
            }}</FieldSubHeader>
            <DatePicker
              v-model="formData.startedAt"
              :placeholder="t('shared.form.select_date_placeholder')"
            />
          </div>

          <div>
            <FieldSubHeader>{{
              t('request.form.completed_date')
            }}</FieldSubHeader>
            <DatePicker
              v-model="formData.completedAt"
              :placeholder="t('shared.form.select_date_placeholder')"
            />
          </div>
        </FieldContainer>
        <FieldContainer>
          <FieldHeader>
            {{ t('request.show.company_responded') }}
            <HelpTooltip tooltip-key="request.ourReaction" />
          </FieldHeader>
          <Field
            v-model="formData.ourReaction"
            type="textarea"
            :placeholder="t('shared.form.enter_here_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('request.form.select_file_question') }}
            <HelpTooltip tooltip-key="request.files" />
          </FieldHeader>
          <FileUpload
            :existing-files="existingCompletedFiles"
            @onFilesChange="onCompletedFilesChange"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>
    </Steps>
  </ContentSection>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { cloneDeep } from 'lodash'

import ContentSection from '@/components/ContentSection'
import { Steps, Step } from '@/components/Steps'
import DatePicker from '@/components/DatePicker'
import FileUpload from '@/components/FileUpload'
import HelpTooltip from '@/components/HelpTooltip'
import FieldContainer from '@/components/FieldContainer'
import FieldHeader from '@/components/FieldHeader'
import FieldSubHeader from '@/components/FieldSubHeader'
import Field from '@/components/Field'
import RadioField from '@/components/RadioField'
import FormControls from '@/components/FormControls'
import withCustomQuestions from '@/mixins/withCustomQuestions'

export default {
  name: 'RequestForm',
  components: {
    ContentSection,
    FileUpload,
    DatePicker,
    HelpTooltip,
    FieldContainer,
    FieldHeader,
    FieldSubHeader,
    Field,
    RadioField,
    FormControls,
    Steps,
    Step,
  },
  mixins: [withCustomQuestions],
  props: {
    request: VueTypes.shape({
      id: String,
      name: String,
      address: String,
      phone: String,
      email: String,
      kind: String,
      status: String,
      concerns: String,
      ourReaction: String,
      receivedAt: VueTypes.oneOfType([String, null]),
      startedAt: VueTypes.oneOfType([String, null]),
      completedAt: VueTypes.oneOfType([String, null]),
      responsibleId: VueTypes.oneOfType([String, null]),
      executingId: VueTypes.oneOfType([String, null]),
      files: VueTypes.oneOfType([Array, null]),
      completedFiles: VueTypes.oneOfType([Array, null]),
      customQuestionAnswers: VueTypes.oneOfType([Array, null]),
    }).loose,
  },
  data() {
    return {
      formData: cloneDeep(this.request),
      existingFiles: this.request.files,
      existingCompletedFiles: this.request.completedFiles,
      cancelLink: { name: 'RequestsArchive' },
    }
  },
  computed: {
    ...mapGetters(['questions', 'users']),
  },
  watch: {
    request: function (newRequest) {
      this.formData = cloneDeep(newRequest)
    },
  },
  mounted() {
    this.collapseSidebar()
    this.fetchCustomQuestions('requests')
    // unconditionally mark completed within this special purpose component
    this.formData.status = 'completed'
    this.observeScroll()
  },

  methods: {
    ...mapActions(['collapseSidebar', 'fetchCustomQuestions', 'observeScroll']),

    save() {
      this.$validator
        .validateAll()
        .then(() => {
          this.$emit('submit', this.formData)
        })
        .catch(() => {
          console.log('errors exist', this.errors)
        })
    },
    onFilesChange(files) {
      this.formData.files = files
    },
    onCompletedFilesChange(completedFiles) {
      this.formData.completedFiles = completedFiles
    },
    clearStatusDates(status) {
      if (this.formData.status === 'received') {
        this.formData.startedAt = null
        this.formData.completedAt = null
      } else if (this.formData.status === 'started') {
        this.formData.completedAt = null
      }
    },
  },
}
</script>
