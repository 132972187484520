<template>
  <div class="ArchiveHeader">
    <Header>
      <OrganizationsDropdown />
      <HeaderActions>
        <div class="actions">
          <HeaderSearch :value="value" @input="handleInput" />
          <SettingsButton />
          <PartnerLogo />
        </div>
      </HeaderActions>
    </Header>
  </div>
</template>

<script>
import Header from '@/components/Header'
import HeaderActions from '@/components/HeaderActions'
import HeaderSearch from '@/components/HeaderSearch'
import SettingsButton from '@/components/SettingsButton'
import PartnerLogo from '@/components/PartnerLogo'
import OrganizationsDropdown from '@/components/OrganizationsDropdown'
import './style.scss'

export default {
  name: 'ArchiveHeader',
  components: {
    Header,
    HeaderActions,
    HeaderSearch,
    SettingsButton,
    PartnerLogo,
    OrganizationsDropdown,
  },
  props: {
    value: { type: String },
  },
  methods: {
    handleInput(value) {
      this.$emit('input', value)
    },
  },
}
</script>
