import * as types from '../mutationTypes'

// We are storing this list outside of vuex because we don't want it to be traversed
const modals = []

const initialState = {
  modalsCount: 0,
}

const getters = {
  modalIsVisible: state => state.modalsCount !== 0,
}

const actions = {
  registerModal({ commit }, instance) {
    commit(types.REGISTER_MODAL, instance)
  },

  unregisterModal({ commit }, instance) {
    commit(types.UNREGISTER_MODAL, instance)
  },
}

const mutations = {
  [types.REGISTER_MODAL]: (state, instance) => {
    if (modals.indexOf(instance) !== -1)
      throw new Error('Modal instance is already registered')
    modals.push(instance)
    state.modalsCount = modals.length
  },
  [types.UNREGISTER_MODAL]: (state, instance) => {
    const index = modals.indexOf(instance)
    if (index === -1)
      throw new Error('Cannot unregister a modal that was never registered')
    modals.splice(index, 1)
    state.modalsCount = modals.length
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
}
