<template>
  <ContentSection>
    <Steps
      :step-names="[
        t('risk_assessment.steps.start'),
        t('risk_assessment.steps.probablility_consequence'),
      ]"
    >
      <Step :step-name="t('risk_assessment.steps.start')">
        <FieldContainer>
          <FieldHeader>
            {{ t('risk_assessment.form.risk_of_question') }}
            <HelpTooltip tooltip-key="riskAssessment.title" />
          </FieldHeader>
          <Field
            v-model="formData.title"
            v-validate="'required'"
            :error="errors.first('title')"
            :placeholder="t('shared.form.enter_here_placeholder')"
            name="title"
            type="text"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('risk_assessment.form.description_question') }}
            <HelpTooltip tooltip-key="riskAssessment.description" />
          </FieldHeader>
          <Field
            v-model="formData.description"
            type="textarea"
            :placeholder="t('shared.form.enter_here_placeholder')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('risk_assessment.steps.probablility_consequence')">
        <FieldContainer>
          <FieldHeader>
            {{ t('risk_assessment.form.probablility_question') }}
            <HelpTooltip tooltip-key="riskAssessment.probability" />
          </FieldHeader>
          <RadioField v-model="formData.probability" inline value="small">
            {{ t('risk_assessment.probability.low') }}
          </RadioField>
          <RadioField v-model="formData.probability" inline value="moderate">
            {{ t('risk_assessment.probability.moderate') }}
          </RadioField>
          <RadioField v-model="formData.probability" inline value="high">
            {{ t('risk_assessment.probability.high') }}
          </RadioField>
          <RadioField v-model="formData.probability" inline value="very_high">
            {{ t('risk_assessment.probability.very_high') }}
          </RadioField>

          <br />

          <FieldDisclaimer v-if="formData.probability == 'small'">
            {{ t('risk_assessment.form.small_probability_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.probability == 'moderate'">
            {{ t('risk_assessment.form.medium_probability_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.probability == 'high'">
            {{ t('risk_assessment.form.high_probability_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.probability == 'very_high'">
            {{ t('risk_assessment.form.imminent_probability_help_text') }}
          </FieldDisclaimer>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('risk_assessment.form.consequence_question') }}
            <HelpTooltip tooltip-key="riskAssessment.consequences" />
          </FieldHeader>
          <RadioField v-model="formData.consequences" inline value="small">
            {{ t('risk_assessment.consequences.low') }}
          </RadioField>
          <RadioField v-model="formData.consequences" inline value="moderate">
            {{ t('risk_assessment.consequences.moderate') }}
          </RadioField>
          <RadioField v-model="formData.consequences" inline value="severe">
            {{ t('risk_assessment.consequences.serious') }}
          </RadioField>
          <RadioField
            v-model="formData.consequences"
            inline
            value="catastrophic"
          >
            {{ t('risk_assessment.consequences.catastrophic') }}
          </RadioField>

          <br />

          <FieldDisclaimer v-if="formData.consequences == 'small'">
            {{ t('risk_assessment.form.low_consequence_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.consequences == 'moderate'">
            {{ t('risk_assessment.form.moderate_consequence_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.consequences == 'severe'">
            {{ t('risk_assessment.form.serious_consequence_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.consequences == 'catastrophic'">
            {{ t('risk_assessment.form.catastrophic_consequence_help_text') }}
          </FieldDisclaimer>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('risk_assessment.form.assessnent_explantaion_question') }}
            <HelpTooltip tooltip-key="riskAssessment.reasoning" />
          </FieldHeader>
          <Field
            v-model="formData.reasoning"
            type="textarea"
            :placeholder="t('shared.form.enter_here_placeholder')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>
    </Steps>
  </ContentSection>
</template>

<script>
import { mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { cloneDeep } from 'lodash'

import { Steps, Step } from '@/components/Steps'
import ContentSection from '@/components/ContentSection'
import FieldContainer from '@/components/FieldContainer'
import FieldHeader from '@/components/FieldHeader'
import FieldDisclaimer from '@/components/FieldDisclaimer'
import Field from '@/components/Field'
import RadioField from '@/components/RadioField'
import FormControls from '@/components/FormControls'
import HelpTooltip from '@/components/HelpTooltip'

export default {
  name: 'RiskAssessmentForm',
  components: {
    Steps,
    Step,
    ContentSection,
    FieldContainer,
    FieldHeader,
    FieldDisclaimer,
    Field,
    RadioField,
    FormControls,
    HelpTooltip,
  },
  props: {
    riskAssessment: VueTypes.shape({
      id: String,
      title: String,
      description: String,
      probability: String,
      consequences: String,
      reasoning: String,
      updatedAt: String,
    }).loose,
  },
  data() {
    return {
      formData: cloneDeep(this.riskAssessment),
      cancelLink: {
        name: 'DataMappingsShow',
        params: {
          dataMappingId:
            this.riskAssessment.dataMappingId ||
            this.$route.params.dataMappingId,
        },
      },
    }
  },
  mounted() {
    this.observeScroll()
  },
  methods: {
    ...mapActions(['observeScroll']),
    save() {
      this.$validator
        .validateAll()
        .then(() => {
          this.$emit('submit', this.formData)
        })
        .catch(() => {
          console.log('errors exist', this.errors)
        })
    },
  },
}
</script>
