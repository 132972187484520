<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'IncidentsArchive' }"
      :title="incident && incident.title"
      :timestamp="incident && incident.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('incident.archive.title')"
    />

    <Loader v-if="!incident" />
    <ContentSection v-else>
      <ResourceActions>
        <ButtonLink :to="{ name: 'IncidentsEdit' }" type="primary">
          {{ t('shared.actions.edit') }}
        </ButtonLink>
        <Button @click="handleDelete">
          {{ t('shared.actions.delete') }}
        </Button>
      </ResourceActions>

      <Columns>
        <div>
          <ColumnHeader>{{ incident.title }}</ColumnHeader>

          <FieldSubHeader>{{ t('incident.show.description') }}</FieldSubHeader>
          <ColumnParagraph>{{ incident.description }}</ColumnParagraph>

          <FieldSubHeader>{{ t('incident.show.narrative') }}</FieldSubHeader>
          <ColumnParagraph>
            {{ t('incident.show.happened_at') }}
            {{ incident.occuredAt | formatDateHuman }} <br />
            {{ t('incident.show.realized_at') }}
            {{ incident.detectedAt | formatDateHuman }} <br />
            {{ t('incident.show.stopped_at') }}
            {{ incident.stoppedAt | formatDateHuman }}
          </ColumnParagraph>

          <FieldSubHeader>{{ t('incident.show.responsible') }}</FieldSubHeader>
          <ColumnParagraph>{{ incident.responsibleName }}</ColumnParagraph>

          <FieldSubHeader>{{ t('incident.show.executing') }}</FieldSubHeader>
          <ColumnParagraph>{{ incident.executingName }}</ColumnParagraph>

          <FieldSubHeader>{{
            t('incident.show.data_breach_type')
          }}</FieldSubHeader>
          <ColumnParagraph v-if="incident.physicalBreach">
            {{ t('incident.show.physical') }}
          </ColumnParagraph>
          <ColumnParagraph v-else>
            {{ t('incident.show.technical') }}
          </ColumnParagraph>

          <FieldSubHeader>{{
            t('incident.show.incident_type')
          }}</FieldSubHeader>
          <ColumnParagraph>
            {{ t('incident.show.private') }}
            <span v-if="incident.confidentialityBreach">
              <strong>{{ t('shared.yes_reply') }}</strong></span
            >
            <span v-else> {{ t('shared.no_reply') }}</span>
            <br />
            {{ t('incident.show.availability') }}
            <span v-if="incident.availabilityBreach">
              <strong>{{ t('shared.yes_reply') }}</strong></span
            >
            <span v-else> {{ t('shared.no_reply') }}</span>
            <br />
            {{ t('incident.show.integrity') }}
            <span v-if="incident.integrityBreach">
              <strong>{{ t('shared.yes_reply') }}</strong></span
            >
            <span v-else> {{ t('shared.no_reply') }}</span>
          </ColumnParagraph>

          <FieldSubHeader>{{ t('incident.show.consequences') }}</FieldSubHeader>
          <ColumnParagraph>{{ incident.consequences }}</ColumnParagraph>

          <FieldSubHeader>{{
            t('incident.show.measures_taken')
          }}</FieldSubHeader>
          <ColumnParagraph>{{ incident.mitigatingActions }}</ColumnParagraph>

          <FieldSubHeader>{{
            t('incident.show.risk_assessment')
          }}</FieldSubHeader>
          <ColumnParagraph v-if="incident.potentialRisk === false">
            {{ t('incident.show.no_risk_help_text') }}
            {{ incident.potentialRiskReasoning }}
          </ColumnParagraph>
          <ColumnParagraph v-else>
            <span v-if="incident.potentialHighRisk">
              {{ t('incident.show.high_risk_help_text') }}
              <a target="_blank" href="http://datatilsynet.dk"
                >{{ t('incident.show.link_title') }}.</a
              >{{ t('incident.show.high_risk_help_text_2') }}
            </span>
            <span v-else>
              {{ t('incident.show.risk_help_text') }}
              <a target="_blank" href="http://datatilsynet.dk">{{
                t('incident.show.link_title')
              }}</a>
            </span>
          </ColumnParagraph>

          <FieldSubHeader>{{ t('incident.show.follow_up') }}</FieldSubHeader>
          <ColumnParagraph>{{
            incident.followUp ? t('shared.yes_reply') : t('shared.no_reply')
          }}</ColumnParagraph>

          <div v-if="incident.followUp">
            <FieldSubHeader>{{
              t('incident.show.follow_up_necessary')
            }}</FieldSubHeader>
            <ColumnParagraph>{{
              incident.followUpDescription
            }}</ColumnParagraph>
          </div>

          <div v-else>
            <FieldSubHeader>{{
              t('incident.show.follow_up_not_necessary')
            }}</FieldSubHeader>
            <ColumnParagraph>{{
              incident.noFollowUpDescription
            }}</ColumnParagraph>
          </div>
          <template v-for="question in questions">
            <div v-if="findQuestionAnswer(question.id)" :key="question.id">
              <FieldSubHeader>{{ question.question }}</FieldSubHeader>
              <ColumnParagraph>{{
                findQuestionAnswer(question.id).answer
              }}</ColumnParagraph>
            </div>
          </template>
        </div>
      </Columns>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'

import ResourceHeader from '@/components/ResourceHeader'
import ResourceActions from '@/components/ResourceActions'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ButtonLink from '@/components/ButtonLink'
import Button from '@/components/Button'
import FieldSubHeader from '@/components/FieldSubHeader'
import Columns from '@/components/Columns'
import ColumnHeader from '@/components/ColumnHeader'
import ColumnParagraph from '@/components/ColumnParagraph'

export default {
  name: 'IncidentShow',
  components: {
    Loader,
    ResourceHeader,
    ResourceActions,
    ContentSection,
    ButtonLink,
    Button,
    FieldSubHeader,
    Columns,
    ColumnHeader,
    ColumnParagraph,
  },
  computed: {
    ...mapGetters(['incident', 'questions']),
  },
  mounted() {
    if (this.incident) this.flushIncidentRelations(this.incident)
    this.collapseSidebar()
    this.fetchCustomQuestions('incidents')
    this.fetchIncident()
  },
  methods: {
    ...mapActions([
      'fetchIncident',
      'collapseSidebar',
      'deleteIncident',
      'showFlashMessage',
      'flushIncidentRelations',
      'fetchCustomQuestions',
    ]),

    handleDelete() {
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') + this.incident.title
        )
      ) {
        this.deleteIncident(this.incident)
          .then(() => {
            router.push({ name: 'IncidentsArchive' })
            this.showFlashMessage({
              message:
                this.t('flash_messages.incident') +
                this.t('flash_messages.delete.success'),
            })
          })
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.incident') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
    findQuestionAnswer(questionId) {
      const questionAnswer = this.incident.customQuestionAnswers.find(
        customQuestionAnswer =>
          customQuestionAnswer.questionId === parseInt(questionId)
      )

      return questionAnswer
    },
  },
}
</script>
