import Vue from 'vue'
import Vuex from 'vuex'
import flashMessage from './modules/flashMessage'
import modal from './modules/modal'
import sidebar from './modules/sidebar'
import observeScroll from './modules/observeScroll'
import dataTypes from './modules/dataTypes'
import safetyMeasures from './modules/safetyMeasures'
import legalBases from './modules/legalBases'
import session from './modules/session'
import resourceStore from './modules/resourceStore'
import organizationUsers from './modules/organizationUsers'
import users from './modules/users'
import organizations from './modules/organizations'
import dataMappings from './modules/dataMappings'
import departments from './modules/departments'
import documents from './modules/documents'
import incidents from './modules/incidents'
import systems from './modules/systems'
import dataProcessors from './modules/dataProcessors'
import dataProcessingAgreements from './modules/dataProcessingAgreements'
import requests from './modules/requests'
import todos from './modules/todos'
import riskAssessments from './modules/riskAssessments'
import mitigationMeasures from './modules/mitigationMeasures'
import user from './modules/user'
import reviewProcesses from './modules/reviewProcesses'
import reviews from './modules/reviews'
import revisions from './modules/revisions'
import invites from './modules/invites'
import password from './modules/password'
import exports from './modules/exports'
import questions from './modules/questions'
import customCategories from './modules/customCategories'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  getters: {
    params: state => state.route.params,
    query: state => state.route.query,
    organizationId: state => state.route.params.organizationId,
  },
  modules: {
    flashMessage,
    sidebar,
    dataTypes,
    safetyMeasures,
    legalBases,
    session,
    resourceStore,
    organizationUsers,
    users,
    organizations,
    dataMappings,
    departments,
    documents,
    incidents,
    systems,
    dataProcessors,
    dataProcessingAgreements,
    requests,
    todos,
    riskAssessments,
    mitigationMeasures,
    user,
    reviewProcesses,
    reviews,
    revisions,
    invites,
    modal,
    password,
    exports,
    questions,
    customCategories,
    observeScroll,
  },
  strict: debug,
})
