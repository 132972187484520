import Vue from 'vue'
import * as types from '../mutationTypes'
import api from '@/utils/api'
import router from '@/routes'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import appendPairToFormData from '@/utils/appendPairToFormData'
import appendFilesToFormData from '@/utils/appendFilesToFormData'
import appendCustomQuestionAnswersToFormData from '@/utils/appendCustomQuestionAnswersToFormData'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import { isLessThenDate } from '@/utils/dateUtils'
import i18n from '@/mixins/i18n.js.erb'

const type = 'documents'

const toDocumentFormData = request => {
  const { files, custom_question_answers, ...attributes } = request
  const formData = new FormData()

  Object.entries(attributes).forEach(appendPairToFormData(formData, 'document'))
  appendFilesToFormData(formData, 'document', files)

  if (custom_question_answers.length > 0) {
    appendCustomQuestionAnswersToFormData(
      formData,
      'document',
      custom_question_answers
    )
  }

  return formData
}

const storeGetters = {
  loadingDocuments: (_state, getters) => getters.isLoading(type),
  documents: (_state, getters, rootState) => {
    const documentsById = rootState.resourceStore.byId[type] || {}
    const departmentsById = rootState.resourceStore.byId.departments || {}

    return Object.values(documentsById).map(document => ({
      ...document,
      department: departmentsById[document.departmentId],
    }))
  },

  document: (_state, getters) => {
    const document = getters.resource(type, getters.params.id)
    if (!document) return document

    return {
      ...document,
      customQuestionAnswers: getters
        .relations(document, 'customQuestionAnswers', 'flow')
        .slice()
        .reverse(),
      revisions: getters
        .relations(document, 'revisions', 'revisable')
        .slice()
        .reverse(),
    }
  },

  documentsForArchive:
    (_state, getters) =>
    ({ search = '', ascending, category }) => {
      const loweredSearch = search.toLowerCase()
      const sortDirection = ascending ? 1 : -1

      let archive = getters.documents.slice()

      switch (category) {
        case 'upcoming':
          archive = archive.filter(({ nextRevisionDate }) =>
            isLessThenDate(7, 'days', nextRevisionDate)
          )
      }

      if (search) {
        archive = archive.filter(({ title, content, department }) => {
          const departmentName = Vue.filter('formatDepartment')(department)

          return (
            (title || '').toLowerCase().includes(loweredSearch) ||
            (content || '').toLowerCase().includes(loweredSearch) ||
            departmentName.toLowerCase().includes(loweredSearch)
          )
        })
      }
      archive.sort((a, b) => sortDirection * a.title.localeCompare(b.title))

      return archive
    },
}

const actions = {
  fetchDocuments: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/documents`)
      .then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },

  fetchDocument: ({ getters, getters: { organizationId } }, id) => {
    id = id || getters.params.id
    const params = {
      include: ['custom_question_answers', 'revisions'],
    }
    const request = api.get(`organizations/${organizationId}/documents/${id}`, {
      params,
    })

    return wrapResourceRequest(type, request)
  },

  createDocument: ({ commit, state, getters, dispatch }, document) => {
    const { organizationId } = getters

    const request = api
      .post(
        `organizations/${organizationId}/documents`,
        toDocumentFormData(snakeCaseKeys(document))
      )
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        dispatch('showFlashMessage', {
          message:
            i18n.methods.t('flash_messages.document') +
            i18n.methods.t('flash_messages.create.success'),
        })
        router.push({ name: 'DocumentsArchive' })
      })
      .catch(({ response }) => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        dispatch('showFlashMessage', {
          message:
            i18n.methods.t('flash_messages.document') +
            i18n.methods.t('flash_messages.create.error'),
        })
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })
  },

  saveDocument: ({ getters, getters: { organizationId } }, document) => {
    const params = toDocumentFormData(snakeCaseKeys(document))
    const request = api.put(
      `organizations/${organizationId}/documents/${document.id}`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  deleteDocument: ({ getters: { organizationId } }, documents) => {
    const request = api.delete(
      `organizations/${organizationId}/documents/${documents.id}`
    )
    return wrapResourceRequest(type, request)
  },

  deleteDocumentFile: (
    { getters: { organizationId } },
    { documentId, fileIndex }
  ) => {
    const request = api.delete(
      `organizations/${organizationId}/documents/${documentId}/files/${fileIndex}`
    )
    return wrapResourceRequest(type, request)
  },

  flushDocumentRelations: ({ commit, getters }, document) => {
    const customQuestionAnswers = getters
      .relations(document, 'customQuestionAnswers', 'flow')
      .slice()
      .reverse()

    commit(types.FLUSH_RESOURCES, [...customQuestionAnswers])
  },
}

export default {
  actions,
  getters: storeGetters,
}
