const actions = {
  observeScroll() {
    setTimeout(function () {
      if (
        document.documentElement.scrollTop !==
        document.documentElement.scrollHeight -
          document.documentElement.clientHeight
      ) {
        document.querySelector('.FormControl').classList.add('Scrollable')
      }
    }, 300)

    document.addEventListener('scroll', function (e) {
      if (document.querySelector('.FormControl') !== null) {
        if (
          document.documentElement.scrollTop ===
          document.documentElement.scrollHeight -
            document.documentElement.clientHeight
        ) {
          document.querySelector('.FormControl').classList.remove('Scrollable')
        } else {
          if (document.querySelector('.Scrollable') === null) {
            document.querySelector('.FormControl').classList.add('Scrollable')
          }
        }
      }
    })
  },
}

export default {
  actions,
}
