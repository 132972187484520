<template>
  <div>
    <ArchiveHeader v-model="search" />

    <Loader v-if="loading" />
    <ContentSection v-else class="fade_in_right">
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('system.archive.title') }}</ArchiveTitle>
        <ArchiveCreateButton :to="{ name: 'SystemsNew' }" />
      </ArchiveActionSection>

      <TableWrapper v-if="filteredSystems('physical').length">
        <div class="wrapper">
          <h2>{{ t('system.archive.physical') }}</h2>
          <table>
            <thead class="column-count-4">
              <th />
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-4">
              <tr
                v-for="system in filteredSystems('physical')"
                :key="system.id"
              >
                <td>
                  <router-link :to="{ path: `systems/${system.id}` }">
                    {{ system.name }}
                  </router-link>
                </td>
                <td>
                  <Label v-if="system.hosted" type="default">{{
                    t('system.archive.hosted')
                  }}</Label>
                  <Label v-else type="default">{{
                    t('system.archive.on_premise')
                  }}</Label>
                </td>
                <td v-if="system.sensitiveInformation">
                  {{ t('system.archive.sensitive') }}
                </td>
                <td v-else />
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `systems/${system.id}` }"
                    :to-edit="{ path: `systems/${system.id}/edit` }"
                    :to-review="{ path: `systems/${system.id}/reviews` }"
                    :resource="system"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
      <TableWrapper v-if="filteredSystems('technical').length">
        <div class="wrapper">
          <h2>{{ t('system.archive.technical') }}</h2>
          <table>
            <thead class="column-count-4">
              <th />
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-4">
              <tr
                v-for="system in filteredSystems('technical')"
                :key="system.id"
              >
                <td>
                  <router-link :to="{ path: `systems/${system.id}` }">
                    {{ system.name }}
                  </router-link>
                </td>
                <td>
                  <Label v-if="system.hosted" type="default">{{
                    t('system.archive.hosted')
                  }}</Label>
                  <Label v-else type="default">{{
                    t('system.archive.on_premise')
                  }}</Label>
                </td>
                <td v-if="system.sensitiveInformation">
                  {{ t('system.archive.sensitive') }}
                </td>
                <td v-else />
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `systems/${system.id}` }"
                    :to-edit="{ path: `systems/${system.id}/edit` }"
                    :to-review="{ path: `systems/${system.id}/reviews` }"
                    :resource="system"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ArchiveHeader from '@/components/ArchiveHeader'
import ArchiveTitle from '@/components/ArchiveTitle'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ArchiveCreateButton from '@/components/ArchiveCreateButton'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import Label from '@/components/Label'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import TableWrapper from '@/components/TableWrapper'

export default {
  name: 'SystemsArchive',
  components: {
    ArchiveHeader,
    ArchiveTitle,
    Loader,
    ContentSection,
    ArchiveCreateButton,
    ArchiveTableDropdown,
    Label,
    ArchiveActionSection,
    TableWrapper,
  },
  data: () => ({
    search: '',
    ascending: true,
  }),
  computed: {
    loading() {
      return this.$store.getters.loadingSystems
    },
    systems() {
      return this.$store.getters.systemsForArchive({
        search: this.search,
        ascending: this.ascending,
      })
    },
  },
  mounted() {
    this.fetchSystems()
    this.expandSidebar()
  },
  methods: {
    ...mapActions([
      'fetchSystems',
      'expandSidebar',
      'deleteSystem',
      'showFlashMessage',
    ]),

    handleDelete(id) {
      const system = this.$store.getters.resource('systems', id)
      if (confirm(this.t('flash_messages.delete.confirmation') + system.name)) {
        this.deleteSystem(system)
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.system') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.system') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
    filteredSystems(filterBy) {
      const filterByBoolean = filterBy === 'technical' ? true : false
      const filteredSystems = this.systems.filter(
        ({ technical }) => technical === filterByBoolean
      )

      return filteredSystems
    },
  },
}
</script>
