import api from '@/utils/api'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import appendPairToFormData from '@/utils/appendPairToFormData'
import appendFilesToFormData from '@/utils/appendFilesToFormData'
import * as types from '@/store/mutationTypes'

const type = 'reviews'

const toReviewFormData = request => {
  const { files, ...attributes } = request
  const formData = new FormData()

  Object.entries(attributes).forEach(appendPairToFormData(formData, 'review'))
  appendFilesToFormData(formData, 'review', files)

  return formData
}

const storeGetters = {
  loadingReviews: (_state, getters) => getters.isLoading(type),
}

const actions = {
  createReview: ({ commit, getters }, review) => {
    const { organizationId } = getters
    const params = toReviewFormData(snakeCaseKeys(review))

    const request = api
      .post(`organizations/${organizationId}/reviews`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },

  saveReview: ({ commit, getters: { organizationId } }, review) => {
    const params = toReviewFormData(snakeCaseKeys(review))

    const request = api
      .put(`organizations/${organizationId}/reviews/${review.id}`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },
}

export default {
  actions,
  getters: storeGetters,
}
