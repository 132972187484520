<template>
  <router-view v-if="loggedIn" />
  <LogIn v-else />
</template>

<script>
import LogIn from '@/pages/LogIn'
import { mapGetters } from 'vuex'

export default {
  name: 'LogInBarrier',
  components: { LogIn },
  computed: { ...mapGetters(['loggedIn']) },
}
</script>
