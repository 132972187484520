<template>
  <label
    :class="{
      'CheckboxField--checked': shouldBeChecked,
      [`CheckboxField--${size}`]: true,
    }"
    class="CheckboxField"
  >
    <input
      :value="value"
      :checked="shouldBeChecked"
      type="checkbox"
      @change="handleInput"
    />
    <span class="CheckboxField__radio"
      ><i class="fa fa-check" aria-hidden="true"
    /></span>
    <span class="CheckboxField__description"><slot /></span>
  </label>
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'

export default {
  name: 'CheckboxField',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: VueTypes.any,
    modelValue: VueTypes.any,
    checked: { type: Boolean },
    size: { type: String, default: 'md' },
    trueValue: { type: Boolean, default: true },
    falseValue: { type: Boolean, default: false },
  },
  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value)
      } else if (this.modelValue !== undefined) {
        return this.modelValue === this.trueValue
      } else {
        return this.checked
      }
    },
  },
  methods: {
    handleInput(event) {
      let isChecked = event.target.checked

      // Handle non-array case up front
      if (!Array.isArray(this.modelValue)) {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue)
        return
      }

      let newValue = [...this.modelValue]
      if (isChecked) {
        newValue.push(this.value)
      } else {
        newValue.splice(newValue.indexOf(this.value), 1)
      }

      this.$emit('change', newValue)
    },
  },
}
</script>
