<template>
  <div class="Modal">
    <div class="Modal__scroll-container">
      <div class="Modal__inner">
        <h4>{{ title }}</h4>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import './style.scss'
import { mapActions } from 'vuex'

export default {
  name: 'Modal',
  props: {
    title: { type: String, required: false },
  },
  mounted() {
    this.registerModal(this)
  },
  destroyed() {
    this.unregisterModal(this)
  },
  methods: {
    ...mapActions(['registerModal', 'unregisterModal']),
  },
}
</script>
