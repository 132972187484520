<template>
  <div>
    <StepNavigator :step-names="stepNames" :step-errors="stepErrors" />
    <slot />
    <FormControls
      v-if="showControls"
      :cancel-link="cancelLink"
      @finalize="handleFinalize"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FormControls from '@/components/FormControls'
import StepNavigator from '@/components/StepNavigator'

const getVNodeIsStep = vnode => {
  return vnode.componentOptions && vnode.componentOptions.Ctor.options.name === 'Step'
}

export default {
  name: 'Steps',
  components: {
    FormControls,
    StepNavigator,
  },
  props: {
    cancelLink: { type: Object, required: false },
    isSaving: { type: Boolean, optional: true },
    showControls: { type: Boolean, defaultValue: false },
    stepNames: { type: Array },
    stepErrors: { type: Object, optional: true },
  },
  data: () => ({
    currentStepIndex: 0,
    steps: [],
  }),
  computed: {
    maxIndex() {
      return this.steps.length - 1
    },
  },
  methods: {
    ...mapActions(['observeScroll']),
    handleFinalize() {
      this.$emit('finalize')
    },
    addStep(step) {
      const index = this.$slots.default.filter(getVNodeIsStep).indexOf(step.$vnode)

      this.steps.splice(index, 0, step)

      if (this.steps.length > 1 && index <= this.currentStepIndex) {
        this.currentStepIndex++
      }
    },
    removeStep(step) {
      const index = this.steps.indexOf(step)

      if (index !== -1) {
        this.steps.splice(index, 1)

        if (index <= this.currentStepIndex) {
          this.currentStepIndex = Math.max(0, this.currentStepIndex - 1)
        }
      }
    },
    stepIsActive(step) {
      return this.steps[this.currentStepIndex] === step
    },
    goToNextStep() {
      if (this.hasNextStep()) this.currentStepIndex++
      this.observeScroll()
    },
    goToPreviousStep() {
      if (this.hasPreviousStep()) this.currentStepIndex--
      this.observeScroll()
    },
    hasPreviousStep() {
      return this.currentStepIndex > 0
    },
    hasNextStep() {
      return this.currentStepIndex < this.maxIndex
    },
    goToStep(stepName) {
      const stepIndex = this.steps.findIndex(step => step.stepName === stepName)

      if (stepIndex !== undefined) {
        this.currentStepIndex = stepIndex
      }
      this.observeScroll()
    },
    getActiveStep() {
      if (this.steps[this.currentStepIndex] === undefined) {
        return 0
      } else {
        return this.steps[this.currentStepIndex].stepName
      }
    },
    getStepByName(stepName) {
      if (this.steps.length === 0) return null
      return this.steps.find(step => step.stepName === stepName) || null
    },
    getStepRequiredFieldsByName(stepName) {
      const step = this.getStepByName(stepName)
      if (step === null) return []
      return step.stepRequiredFields || []
    },
    isOptionalStep(stepName) {
      if (this.steps.length > 0) {
        const findStep = this.steps.findIndex(
          step => step.stepName === stepName
        )

        if (findStep === -1) {
          return true
        } else {
          return false
        }
      }
    },
  },
  provide() {
    return {
      goToNextStep: this.goToNextStep,
      goToPreviousStep: this.goToPreviousStep,
      addStep: this.addStep,
      removeStep: this.removeStep,
      stepIsActive: this.stepIsActive,
      hasNextStep: this.hasNextStep,
      hasPreviousStep: this.hasPreviousStep,
      goToStep: this.goToStep,
      getActiveStep: this.getActiveStep,
      getStepRequiredFieldsByName: this.getStepRequiredFieldsByName,
      isOptionalStep: this.isOptionalStep,
    }
  },
}
</script>
