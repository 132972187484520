<template>
  <div class="ListHeader">
    <h2 class="ListHeader__title">
      {{ title }}
    </h2>
    <slot />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'ListHeader',
  props: {
    title: { type: String, required: false, default: '' },
  },
}
</script>
