<template>
  <span :class="`Label--${type}`" class="Label"><slot /></span>
</template>

<script>
import './style.scss'

export default {
  name: 'Label',
  props: {
    type: { type: String, required: false },
  },
}
</script>
