<template>
  <div class="HeaderBackLink">
    <router-link :to="to">
      <slot />
    </router-link>
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'HeaderBackLink',
  props: {
    to: { type: Object, required: true },
  },
}
</script>
