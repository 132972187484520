import * as types from '../mutationTypes'
import api from '@/utils/api'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import router from '@/routes'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import attachRelations from '@/utils/attachRelations'
import i18n from '@/mixins/i18n.js.erb'

const type = 'systems'

const storeGetters = {
  loadingSystems: (_state, getters) => getters.isLoading(type),

  systems: (_state, _getters, globalState) => {
    const map = globalState.resourceStore.byId[type] || {}
    return Object.values(map)
  },

  system: (_state, getters) => {
    const system = getters.resource(type, getters.params.systemId)
    if (!system) return system

    return {
      ...attachRelations(['collectedDataTypes', 'inUseSafetyMeasures'])(system),
      customQuestionAnswers: getters
        .relations(system, 'customQuestionAnswers', 'flow')
        .slice()
        .reverse(),
      reviews: getters.relations(system, 'reviews', 'flow').slice().reverse(),
    }
  },

  systemsForArchive:
    (_state, getters) =>
    ({ search, ascending }) => {
      const loweredSearch = search.toLowerCase()
      const sortDirection = ascending ? 1 : -1

      let archive = getters[type].slice()

      if (search) {
        archive = archive.filter(({ name, provider, description }) => {
          return (
            (name || '').toLowerCase().includes(loweredSearch) ||
            (provider || '').toLowerCase().includes(loweredSearch) ||
            (description || '').toLowerCase().includes(loweredSearch)
          )
        })
      }

      archive.sort((a, b) => sortDirection * a.name.localeCompare(b.name))

      return archive
    },
}

const actions = {
  fetchSystems: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/systems`)
      .then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },

  fetchSystem: ({ getters, getters: { organizationId } }, id) => {
    id = id || getters.params.systemId

    const params = {
      include: [
        'custom_question_answers',
        'collected_data_types',
        'in_use_safety_measures',
        'reviews',
      ],
    }
    const request = api.get(`organizations/${organizationId}/systems/${id}`, {
      params,
    })

    return wrapResourceRequest(type, request)
  },

  createSystem: ({ commit, state, getters, dispatch }, system) => {
    const { organizationId } = getters

    const request = api
      .post(
        `organizations/${organizationId}/systems`,
        snakeCaseKeys({ system })
      )
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        dispatch('showFlashMessage', {
          message:
            i18n.methods.t('flash_messages.system') +
            i18n.methods.t('flash_messages.create.success'),
        })
        router.push({ name: 'SystemsArchive' })
      })
      .catch(({ response }) => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        dispatch('showFlashMessage', {
          message:
            i18n.methods.t('flash_messages.system') +
            i18n.methods.t('flash_messages.create.error'),
        })
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })
  },

  saveSystem: ({ getters, getters: { organizationId } }, system) => {
    const params = snakeCaseKeys({ system })

    const request = api.put(
      `organizations/${organizationId}/systems/${system.id}`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  deleteSystem: ({ getters: { organizationId } }, system) => {
    const request = api.delete(
      `organizations/${organizationId}/systems/${system.id}`
    )
    return wrapResourceRequest(type, request)
  },

  flushSystemRelations: ({ commit, getters }, system) => {
    const customQuestionAnswers = getters
      .relations(system, 'customQuestionAnswers', 'flow')
      .slice()
      .reverse()
    const collectedDataTypes = getters
      .relations(system, 'collectedDataTypes')
      .slice()
      .reverse()
    const safetyMeasures = getters.relations(system, 'inUseSafetyMeasures')
    const reviews = getters
      .relations(system, 'reviews', 'flow')
      .slice()
      .reverse()

    commit(types.FLUSH_RESOURCES, [
      ...customQuestionAnswers,
      ...collectedDataTypes,
      ...safetyMeasures,
      ...reviews,
    ])
  },
}

export default {
  getters: storeGetters,
  actions,
}
