<template>
  <li class="TodoListItem">
    <Checkbox
      :checked="todo.completed"
      class="TodoListItem__checkbox"
      @change="$emit('check')"
    />
    <router-link
      :to="{
        name: 'TodosEdit',
        params: { todoId: todo.id },
      }"
      class="TodoListItem__link"
    >
      <h6 class="TodoListItem__title">
        {{ todo.title }}
      </h6>
      <p class="TodoListItem__user">
        {{ todo.user | formatUser }}
      </p>
    </router-link>
    <i
      v-if="todo.description && !todo.completed"
      class="TodoListItem__content-toggle fa fa-angle-down"
      @click="open = !open"
    />
    <p v-if="open" class="TodoListItem__content">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="todo.description" />
    </p>
    <span
      v-if="todo.completed"
      class="TodoListItem__delete"
      @click="$emit('delete')"
      >{{ t('shared.actions.delete') }}</span
    >
  </li>
</template>

<script>
import './style.scss'

import Checkbox from '@/components/Checkbox'

export default {
  name: 'TodoListItem',
  components: { Checkbox },
  props: {
    todo: { type: Object },
  },
  data() {
    return {
      open: false,
    }
  },
}
</script>
