<template>
  <ContentSection>
    <Steps
      :step-names="[
        t('review_process.steps.start'),
        t('review_process.steps.relation'),
        t('review_process.steps.other'),
        t('review_process.steps.status'),
      ]"
    >
      <Step :step-name="t('review_process.steps.start')">
        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.title_question') }}
            <HelpTooltip tooltip-key="reviewProcess.title" />
          </FieldHeader>
          <Field
            v-model="formData.title"
            v-validate="'required'"
            :error="errors.first('title')"
            :placeholder="t('shared.form.enter_here_placeholder')"
            name="title"
            type="text"
          />
        </FieldContainer>
        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.description_question') }}
            <HelpTooltip tooltip-key="reviewProcess.description" />
          </FieldHeader>
          <Field
            v-model="formData.description"
            v-validate="'required'"
            :error="errors.first('description')"
            :placeholder="t('shared.form.enter_here_placeholder')"
            name="description"
            type="text"
          />
        </FieldContainer>
        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.category_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.category">
            <option :value="null">
              {{ t('review_process.form.select_category_placeholder') }}
            </option>
            <option
              v-for="category in categories"
              :key="category.value"
              :value="category.value"
            >
              {{ t('review_process.categories.' + category.translation_key) }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <Step :step-name="t('review_process.steps.relation')">
        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.treatment_activity_question') }}
            <HelpTooltip tooltip-key="reviewProcess.dataMapping" />
          </FieldHeader>
          <RadioField v-model="hasDataMapping" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="hasDataMapping" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>
        <br />
        <FormSelect v-if="hasDataMapping" v-model="formData.dataMappingId">
          <option :value="null">
            {{ t('review_process.form.select_treatment_activity_placeholder') }}
          </option>
          <option
            v-for="dataMapping in dataMappings"
            :key="dataMapping.id"
            :value="dataMapping.id"
          >
            {{ dataMapping.title }}
          </option>
        </FormSelect>

        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.related_to_procedure_question') }}
            <HelpTooltip tooltip-key="reviewProcess.procedure" />
          </FieldHeader>
          <RadioField v-model="hasProcedure" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="hasProcedure" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>
        <br />
        <FormSelect v-if="hasProcedure" v-model="formData.documentProcedureId">
          <option :value="null">
            {{ t('review_process.form.select_related_procedure_placeholder') }}
          </option>
          <option
            v-for="document in documentProcedures"
            :key="document.id"
            :value="document.id"
          >
            {{ document.title }}
          </option>
        </FormSelect>

        <FieldContainer>
          <FieldHeader>{{
            t('review_process.form.related_to_data_processor_question')
          }}</FieldHeader>
          <RadioField v-model="hasDataProcessor" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="hasDataProcessor" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>
        <br />
        <FormSelect v-if="hasDataProcessor" v-model="formData.dataProcessorId">
          <option :value="null">
            {{
              t('review_process.form.select_related_data_processor_placeholder')
            }}
          </option>
          <option
            v-for="dataProcessor in dataProcessors"
            :key="dataProcessor.id"
            :value="dataProcessor.id"
          >
            {{ dataProcessor.name }}
          </option>
        </FormSelect>

        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.responsible_user_question') }}
            <HelpTooltip tooltip-key="reviewProcess.user" />
          </FieldHeader>

          <FormSelect v-model="formData.responsibleId">
            <option :value="null">
              {{ t('shared.form.select_responsible_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.executing_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.executingId">
            <option :value="null">
              {{ t('shared.form.select_executing_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <Step
        v-if="questions.length"
        :step-name="t('review_process.steps.other')"
      >
        <FieldContainer v-for="question in questions" :key="question.id">
          <FieldHeader>{{ question.question }}</FieldHeader>
          <Field
            :placeholder="question.placeholder"
            :value="findOrBuildQuestionAnswer(question.id).answer"
            name="title"
            type="text"
            @input="
              value => {
                updateQuestionAnswer(question.id, value)
              }
            "
          />
        </FieldContainer>
        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <Step :step-name="t('review_process.steps.status')">
        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.recurring_check_question') }}
          </FieldHeader>
          <RadioField v-model="formData.recurring" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.recurring" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.first_control_date_question') }}
            <HelpTooltip tooltip-key="reviewProcess.firstReviewAt" />
          </FieldHeader>
          <DatePicker
            v-if="isNew || !formData.firstReviewAt"
            v-model="formData.firstReviewAt"
            :min-date-validator="true"
            :placeholder="t('shared.form.select_date_placeholder')"
          />
          <FieldDisclaimer v-else>
            {{ formData.firstReviewAt | formatDateHuman }}
          </FieldDisclaimer>
        </FieldContainer>

        <FieldContainer v-if="formData.recurring === true">
          <FieldHeader>
            {{ t('review_process.form.interval_question') }}
            <HelpTooltip tooltip-key="reviewProcess.intervalDays" />
          </FieldHeader>
          <IntervalField v-model="formData.intervalDays" />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('review_process.form.status_question') }}
          </FieldHeader>
          <RadioField v-model="formData.active" :value="true">
            {{ t('review_process.form.active') }}
          </RadioField>
          <RadioField v-model="formData.active" :value="false">
            {{ t('review_process.form.inactive') }}
          </RadioField>
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>
    </Steps>
  </ContentSection>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { cloneDeep } from 'lodash'

import { Steps, Step } from '@/components/Steps'
import ContentSection from '@/components/ContentSection'
import DatePicker from '@/components/DatePicker'
import FieldContainer from '@/components/FieldContainer'
import FieldDisclaimer from '@/components/FieldDisclaimer'
import FieldHeader from '@/components/FieldHeader'
import Field from '@/components/Field'
import FormControls from '@/components/FormControls'
import RadioField from '@/components/RadioField'
import FormSelect from '@/components/FormSelect'
import IntervalField from '@/components/IntervalField'
import HelpTooltip from '@/components/HelpTooltip'
import withCustomQuestions from '@/mixins/withCustomQuestions'

export default {
  name: 'ReviewProcessForm',
  components: {
    ContentSection,
    Steps,
    Step,
    DatePicker,
    FieldContainer,
    FieldDisclaimer,
    FieldHeader,
    Field,
    FormControls,
    RadioField,
    FormSelect,
    IntervalField,
    HelpTooltip,
  },
  mixins: [withCustomQuestions],
  props: {
    reviewProcess: VueTypes.shape({
      id: String,
      responsibleId: VueTypes.oneOfType([String, null]),
      executingId: VueTypes.oneOfType([String, null]),
      dataMappingId: VueTypes.oneOfType([String, null]),
      documentProcedureId: VueTypes.oneOfType([String, null]),
      dataProcessorId: VueTypes.oneOfType([String, null]),
      title: VueTypes.string,
      description: VueTypes.string,
      firstReviewAt: VueTypes.string,
      intervalDays: VueTypes.number,
      active: VueTypes.oneOfType([Boolean, null]),
      category: VueTypes.oneOfType([String, null]),
      recurring: VueTypes.oneOfType([Boolean, null]),
    }).loose,
    isSaving: VueTypes.bool.isRequired,
  },
  data() {
    return {
      formData: cloneDeep(this.reviewProcess),
      hasDataMapping: !!this.reviewProcess.dataMappingId,
      hasProcedure: !!this.reviewProcess.documentProcedureId,
      hasDataProcessor: !!this.reviewProcess.dataProcessorId,
      cancelLink: { name: 'ReviewProcessesArchive' },
    }
  },
  computed: {
    ...mapGetters([
      'dataMappings',
      'documents',
      'dataProcessors',
      'users',
      'questions',
    ]),

    isNew() {
      return !this.reviewProcess.id
    },

    categories() {
      return [
        { value: 'internal', translation_key: 'internal' },
        { value: 'data_processor', translation_key: 'data_processor' },
        { value: 'awareness', translation_key: 'awareness' },
        { value: 'meet', translation_key: 'meeting' },
        { value: 'other', translation_key: 'other' },
      ]
    },

    documentProcedures() {
      let filteredDocuments = this.documents.filter(
        ({ category }) => category === 'procedure'
      )

      return filteredDocuments
    },
  },
  watch: {
    hasDataMapping: function (hasDataMapping) {
      if (!hasDataMapping) this.formData.dataMappingId = null
    },

    hasProcedure: function (hasProcedure) {
      if (!hasProcedure) this.formData.documentProcedureId = null
    },

    hasDataProcessor: function (hasDataProcessor) {
      if (!hasDataProcessor) this.formData.dataProcessorId = null
    },

    reviewProcess: function (newReviewProcess) {
      this.formData = cloneDeep(newReviewProcess)
    },
  },
  mounted() {
    this.fetchDataMappings()
    this.fetchDocuments()
    this.fetchDataProcessors()
    this.fetchOrganizationUsers()
    this.fetchCustomQuestions('review_processes')
    this.observeScroll()
  },
  methods: {
    ...mapActions([
      'fetchDataMappings',
      'fetchDocuments',
      'fetchDataProcessors',
      'fetchOrganizationUsers',
      'fetchCustomQuestions',
      'observeScroll',
    ]),

    save() {
      this.$validator
        .validateAll()
        .then(() => {
          this.$emit('submit', this.formData)
        })
        .catch(() => {
          console.log('errors exist', this.errors)
        })
    },
  },
}
</script>
