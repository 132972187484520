import * as types from '../mutationTypes'

const initialState = {
  show: false,
  message: '',
  type: '',
  name: '',
}

const getters = {
  flashMessage: state => state,
}

const actions = {
  showFlashMessage({ commit }, { message, type, name }) {
    commit(types.SHOW_FLASH_MESSAGE, { message, type, name })
    setTimeout(() => commit(types.HIDE_FLASH_MESSAGE), 4000)
  },
}

const mutations = {
  [types.SHOW_FLASH_MESSAGE]: (state, payload) => {
    state.show = true
    state.type = payload.type
    state.name = payload.name
    state.message = payload.message
  },
  [types.HIDE_FLASH_MESSAGE]: state => {
    state.show = false
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
}
