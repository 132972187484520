var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ArchiveHeader',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),(_vm.loading)?_c('Loader'):_c('ContentSection',{staticClass:"fade_in_right"},[_c('ArchiveActionSection',[_c('ArchiveTitle',[_vm._v(_vm._s(_vm.t('incident.archive.title')))]),_vm._v(" "),_c('ArchiveCreateButton',{attrs:{"to":{ name: 'IncidentsNew' }}})],1),_vm._v(" "),(_vm.filteredIncidents('physical').length)?_c('TableWrapper',[_c('div',{staticClass:"wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.t('incident.archive.physical')))]),_vm._v(" "),_c('table',[_c('thead',{staticClass:"column-count-6"},[_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-6"},_vm._l((_vm.filteredIncidents('physical')),function(incident){return _c('tr',{key:incident.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: ("incidents/" + (incident.id)) }}},[_vm._v("\n                  "+_vm._s(incident.title)+"\n                ")])],1),_vm._v(" "),_c('td',[(incident.overdue)?_c('Label',{attrs:{"type":"alert"}},[_vm._v(_vm._s(_vm.t('shared.table.overdue')))]):_vm._e()],1),_vm._v(" "),_c('td',[(incident.confidentialityBreach)?_c('Label',{attrs:{"type":"default"}},[_vm._v(_vm._s(_vm.t('incident.archive.private')))]):_vm._e(),_vm._v(" "),(incident.availabilityBreach)?_c('Label',{attrs:{"type":"default"}},[_vm._v(_vm._s(_vm.t('incident.archive.availability')))]):_vm._e(),_vm._v(" "),(incident.integrityBreach)?_c('Label',{attrs:{"type":"default"}},[_vm._v(_vm._s(_vm.t('incident.archive.integrity')))]):_vm._e()],1),_vm._v(" "),_c('td',{class:!incident.potentialRisk
                    ? 'green'
                    : incident.potentialHighRisk
                    ? 'red'
                    : ''},[_vm._v("\n                "+_vm._s(_vm.t('incident.archive.' + _vm.findRiskType(incident)))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("formatDateHuman")(incident.detectedAt)))]),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: ("incidents/" + (incident.id)) },"to-edit":{ path: ("incidents/" + (incident.id) + "/edit") },"resource":incident},on:{"delete":_vm.handleDelete}})],1)])}),0)])])]):_vm._e(),_vm._v(" "),(_vm.filteredIncidents('technical').length)?_c('TableWrapper',[_c('div',{staticClass:"wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.t('incident.archive.technical')))]),_vm._v(" "),_c('table',[_c('thead',{staticClass:"column-count-6"},[_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-6"},_vm._l((_vm.filteredIncidents('technical')),function(incident){return _c('tr',{key:incident.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: ("incidents/" + (incident.id)) }}},[_vm._v("\n                  "+_vm._s(incident.title)+"\n                ")])],1),_vm._v(" "),_c('td',[(incident.overdue)?_c('Label',{attrs:{"type":"alert"}},[_vm._v(_vm._s(_vm.t('shared.table.overdue')))]):_vm._e()],1),_vm._v(" "),_c('td',[(incident.confidentialityBreach)?_c('Label',{attrs:{"type":"default"}},[_vm._v(_vm._s(_vm.t('incident.archive.private')))]):_vm._e(),_vm._v(" "),(incident.availabilityBreach)?_c('Label',{attrs:{"type":"default"}},[_vm._v(_vm._s(_vm.t('incident.archive.availability')))]):_vm._e(),_vm._v(" "),(incident.integrityBreach)?_c('Label',{attrs:{"type":"default"}},[_vm._v(_vm._s(_vm.t('incident.archive.integrity')))]):_vm._e()],1),_vm._v(" "),_c('td',{class:!incident.potentialRisk
                    ? 'green'
                    : incident.potentialHighRisk
                    ? 'red'
                    : ''},[_vm._v("\n                "+_vm._s(_vm.t('incident.archive.' + _vm.findRiskType(incident)))+"\n              ")]),_vm._v(" "),_c('td',[_vm._v(_vm._s(_vm._f("formatDateHuman")(incident.detectedAt)))]),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: ("incidents/" + (incident.id)) },"to-edit":{ path: ("incidents/" + (incident.id) + "/edit") },"resource":incident},on:{"delete":_vm.handleDelete}})],1)])}),0)])])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }