<template>
  <input
    :placeholder="t('shared.search')"
    class="HeaderSearch"
    type="text"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
import './style.scss'

export default {
  name: 'HeaderSearch',
}
</script>
