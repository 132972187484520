<template>
  <DataProcessingAgreementForm
    v-if="dataProcessingAgreement"
    :data-processing-agreement="dataProcessingAgreement"
    :is-saving="loadingDataProcessingAgreements"
    @submit="handleSubmit"
  />
</template>

<script>
import router from '@/routes'
import DataProcessingAgreementForm from '@/forms/DataProcessingAgreementForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DataProcessingAgreementsEdit',
  components: {
    DataProcessingAgreementForm,
  },
  computed: {
    ...mapGetters(['loadingDataProcessingAgreements']),

    dataProcessingAgreement() {
      let { dataProcessingAgreement, relations } = this.$store.getters

      if (!dataProcessingAgreement) return dataProcessingAgreement

      const dataProcessingAgreementSystems = relations(
        dataProcessingAgreement,
        'dataProcessingAgreementSystems'
      )

      return {
        ...dataProcessingAgreement,
        systemIds: dataProcessingAgreementSystems.map(dpas => dpas.systemId),
      }
    },
  },
  methods: {
    ...mapActions(['saveDataProcessingAgreement', 'showFlashMessage']),

    handleSubmit(dataProcessingAgreement) {
      this.saveDataProcessingAgreement(dataProcessingAgreement)
        .then(() => {
          router.push({ name: 'DataProcessorsShow' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.data_processor_agreement') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.data_processor_agreement') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
