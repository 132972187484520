<template>
  <router-link :to="to" class="ArchiveCreateButton">
    <button>
      <span>{{ t('shared.buttons.new') }}</span>
    </button>
  </router-link>
</template>

<script>
import './style.scss'

export default {
  name: 'ArchiveCreateButton',
  props: {
    to: { type: Object, required: true },
  },
}
</script>
