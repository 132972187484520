<template>
  <div>
    <div class="form__input-wrapper">
      <label>{{ t('organization.edit.tabs.departments.name') }}</label>
      <input v-model="formData.name" type="text" @input="enableSave" />
    </div>
    <button v-if="isSaving" class="button--save">
      {{ t('organization.edit.tabs.departments.new.saving') }}
    </button>
    <button
      v-else
      :disabled="disabled"
      class="button--save"
      @click="handleSubmit()"
    >
      {{ t('organization.edit.tabs.departments.new.save') }}
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'

const buildDepartment = () => ({
  name: '',
})

export default {
  name: 'Form',
  props: {
    department: { type: Object, required: false, default: buildDepartment },
    isSaving: { type: Boolean, required: true },
  },
  data() {
    return {
      formData: cloneDeep(this.department),
      disabled: true,
    }
  },
  computed: {
    ...mapState({
      expanded: state => !state.sidebar.expanded,
    }),
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', {
        department: this.formData,
      })
      this.disabled = true
    },
    enableSave() {
      this.disabled = false
    },
  },
}
</script>
