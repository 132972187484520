<template>
  <div>
    <Settings>
      <UserForm
        :user="user"
        :is-saving="isSavingUser"
        @submit="handleUserSubmit"
      />
      <section>
        <div class="row">
          <h3>{{ t('organization_user.edit.position_title') }}</h3>
        </div>
        <Loader v-if="!currentOrganizationUser" />
        <div v-else class="form">
          <OrganizationUserForm
            :organization-user="currentOrganizationUser"
            :is-saving="isSavingOrganizationUser"
            @submit="handleOrganizationUserSubmit"
          />
        </div>
      </section>
    </Settings>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserForm from '@/forms/UserForm'
import OrganizationUserForm from '@/forms/OrganizationUserForm'
import Settings from '@/components/Settings'

export default {
  name: 'UsersEdit',
  components: {
    UserForm,
    OrganizationUserForm,
    Settings,
  },
  data() {
    return {
      isSavingUser: false,
      isSavingOrganizationUser: false,
    }
  },
  computed: {
    ...mapGetters(['user', 'currentOrganizationUser']),
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'saveUser',
      'saveOrganizationUser',
      'showFlashMessage',
    ]),

    handleUserSubmit({ user }) {
      this.isSavingUser = true
      this.saveUser(user)
        .then(() => {
          this.isSavingUser = false
          this.showFlashMessage({
            message:
              this.t('flash_messages.user') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.isSavingUser = false
          this.showFlashMessage({
            message:
              this.t('flash_messages.user') +
              this.t('flash_messages.update.error'),
          })
        })
    },

    handleOrganizationUserSubmit({ organizationUser }) {
      this.isSaving = true
      this.saveOrganizationUser(organizationUser)
        .then(() => {
          this.isSaving = false
          this.showFlashMessage({
            message:
              this.t('flash_messages.user') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.isSaving = false
          this.showFlashMessage({
            message:
              this.t('flash_messages.user') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
