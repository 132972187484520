<template>
  <Modal :title="t('organization.edit.tabs.users.update.title')">
    <ModalCloseButton :to="{ name: 'OrganizationsEdit' }" />
    <Loader v-if="!organizationUser" />
    <div v-else class="form">
      <OrganizationUserForm
        :organization-user="organizationUser"
        :is-saving="isSaving"
        @submit="handleSubmit"
      />
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Modal from '@/components/Modal'
import ModalCloseButton from '@/components/ModalCloseButton'
import Loader from '@/components/Loader'
import OrganizationUserForm from '@/forms/OrganizationUserForm'
import router from '@/routes'

export default {
  name: 'OrganizationUsersEdit',
  components: { Loader, Modal, ModalCloseButton, OrganizationUserForm },
  data() {
    return {
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters(['organizationUser']),
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'saveOrganizationUser',
      'showFlashMessage',
    ]),

    handleSubmit({ organizationUser }) {
      this.isSaving = true
      this.saveOrganizationUser(organizationUser)
        .then(() => {
          this.isSaving = false
          router.push({ name: 'OrganizationsEdit' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.user') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.isSaving = false
          this.showFlashMessage({
            message:
              this.t('flash_messages.user') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
