<template>
  <footer class="FormControl">
    <router-link v-if="cancelLink" :to="cancelLink">
      <Button class="FormControl__cancel">
        {{ t('shared.form_footer.cancel') }}
      </Button>
    </router-link>
    <Button v-if="isSaving" class="FormControl__finalize">
      {{ t('shared.form_footer.saving') }}
    </Button>
    <Button
      v-if="!isSaving && !hideSave"
      class="FormControl__finalize"
      type="primary"
      @click="$emit('finalize')"
    >
      {{ t('organization.edit.save') }}
    </Button>
  </footer>
</template>

<script>
import './style.scss'
import Button from '@/components/Button'

export default {
  name: 'FormControlsSaveClose',
  components: { Button },
  props: {
    hideSave: { type: Boolean, defaultValue: false },
    isSaving: { type: Boolean, defaultValue: false },
    cancelLink: { type: Object, required: false },
  },
}
</script>
