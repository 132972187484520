<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'DataMappingsArchive' }"
      :back-link-text="t('shared.back_to_archive')"
      :title="t('data_mapping.new.title')"
      :title-prefix="t('data_mapping.archive.title')"
    />
    <DataMappingForm
      :data-mapping="dataMapping"
      :is-saving="loadingDataMappings"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'
import DataMappingForm from '@/forms/DataMappingForm'

import ResourceHeader from '@/components/ResourceHeader'

export default {
  name: 'DataMappingsNew',
  components: {
    ResourceHeader,
    DataMappingForm,
  },
  data: () => ({
    dataMapping: {
      title: '',
      purpose: '',
      dataSubject: '',
      departmentId: null,
      dataSources: [],
      dataSubjects: [],
      dataRecipients: [],
      inUseSafetyMeasures: [],
      inHouseViewers: [],
      systemIds: [],
      customQuestionAnswers: [],
      dataProcessorIds: [],
      storageDuration: '',
      deletionProcedureId: null,
      customCategoryId: null,
      intervalDays: 365,
      errors: [],
    },
  }),
  computed: {
    ...mapGetters(['loadingDataMappings']),
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'createDataMapping',
      'collapseSidebar',
      'showFlashMessage',
      'createTodo',
    ]),

    handleSubmit({ dataMapping, todos }) {
      this.createDataMapping(dataMapping).then(payload => {
        if (payload instanceof Error) {
          let errors = payload.payload

          this.errors.clear()

          errors.map(error => {
            this.errors.add({ field: error.source.param })
          })

          let translated_error_messages = errors.map(error => {
            return this.t(`data_mapping.error_messages.${error.source.param}`)
          })

          this.showFlashMessage({
            message:
              this.t('flash_messages.missing_fields') +
              '</br>' +
              translated_error_messages.join('</br>'),
          })

          return dataMapping
        }

        router.push({
          name: 'DataMappingsShow',
          params: { dataMappingId: payload.data.id },
        })

        this.showFlashMessage({
          message:
            this.t('flash_messages.data_mapping') +
            this.t('flash_messages.create.success'),
        })

        if (todos.system) {
          this.createTodo({
            title:
              this.t('flash_messages.custom.data_mapping_system') +
              dataMapping.title,
          })
        }

        if (todos.dataProcessor) {
          this.createTodo({
            title:
              this.t('flash_messages.custom.data_mapping_data_processor') +
              dataMapping.title,
          })
        }

        if (todos.deletionProcedure) {
          this.createTodo({
            title:
              this.t('flash_messages.custom.data_mapping_deletion_procedure') +
              dataMapping.title,
          })
        }
      })
    },
  },
}
</script>
