<template>
  <span class="Loader">{{ t('shared.loader.loading') }}</span>
</template>

<script>
import './style.scss'

export default {
  name: 'Loader',
}
</script>
