<template>
  <transition
    enter-active-class="fade_in_down"
    leave-active-class="fade_out_up"
  >
    <div v-if="flashMessage.show" class="FlashMessage">
      <img src="./assets/logo.svg" />
      <h6>{{ flashMessage.name }} <span v-html="flashMessage.message" /></h6>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FlashMessage',
  computed: {
    ...mapGetters(['flashMessage']),
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
