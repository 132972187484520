<template>
  <div class="TabsWrapper">
    <section class="tabs__wrapper">
      <div class="tabs__box">
        <slot />
      </div>
    </section>
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'TabsWrapper',
}
</script>
