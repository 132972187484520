<template>
  <div>
    <ArchiveHeader v-model="search" />

    <Loader v-if="loading" />
    <ContentSection v-else class="fade_in_right">
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('request.archive.title') }}</ArchiveTitle>
        <ArchiveCreateButton :to="{ name: 'RequestsNew' }" />
      </ArchiveActionSection>

      <TableWrapper v-if="filteredRequests('received').length">
        <div class="wrapper">
          <h2>{{ t('request.status.received') }}</h2>
          <table>
            <thead class="column-count-4">
              <th />
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-4">
              <tr
                v-for="request in filteredRequests('received')"
                :key="request.id"
              >
                <td>
                  <router-link :to="{ path: `requests/${request.id}` }">
                    {{ request.name }}
                  </router-link>
                </td>
                <td v-if="request.deadline == undefined" />
                <td v-else-if="calculateDeadline(request.deadline) === 0">
                  {{ t('shared.table.overdue') }}
                </td>
                <td v-else>
                  {{ t('request.archive.deadline') }}
                  {{ calculateDeadline(request.deadline) }}
                  {{ t('request.archive.days') }}
                </td>
                <td>{{ request.receivedAt | formatDateHuman }}</td>
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `requests/${request.id}` }"
                    :to-edit="{ path: `requests/${request.id}/edit` }"
                    :to-custom="{ path: `requests/${request.id}/manage` }"
                    :resource="request"
                    :to-custom-title="t('request.archive.handle')"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>

      <TableWrapper v-if="filteredRequests('started').length">
        <div class="wrapper">
          <h2>{{ t('request.status.started') }}</h2>
          <table>
            <thead class="column-count-4">
              <th />
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-4">
              <tr
                v-for="request in filteredRequests('started')"
                :key="request.id"
              >
                <td>
                  <router-link :to="{ path: `requests/${request.id}` }">
                    {{ request.name }}
                  </router-link>
                </td>
                <td v-if="request.deadline == undefined" />
                <td v-if="calculateDeadline(request.deadline) === 0">
                  {{ t('shared.table.overdue') }}
                </td>
                <td v-else>
                  {{ t('request.archive.deadline') }}
                  {{ calculateDeadline(request.deadline) }}
                  {{ t('request.archive.days') }}
                </td>
                <td>{{ request.receivedAt | formatDateHuman }}</td>
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `requests/${request.id}` }"
                    :to-edit="{ path: `requests/${request.id}/edit` }"
                    :to-custom="{ path: `requests/${request.id}/manage` }"
                    :resource="request"
                    :to-custom-title="t('request.archive.handle')"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>

      <TableWrapper v-if="filteredRequests('completed').length">
        <div class="wrapper">
          <h2>{{ t('request.status.completed') }}</h2>
          <table>
            <thead class="column-count-4">
              <th />
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-4">
              <tr
                v-for="request in filteredRequests('completed')"
                :key="request.id"
              >
                <td>
                  <router-link :to="{ path: `requests/${request.id}` }">
                    {{ request.name }}
                  </router-link>
                </td>
                <td />
                <td>{{ request.completedAt | formatDateHuman }}</td>
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `requests/${request.id}` }"
                    :to-edit="{ path: `requests/${request.id}/edit` }"
                    :to-custom="{ path: `requests/${request.id}/manage` }"
                    :resource="request"
                    :to-custom-title="t('request.archive.handle')"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ArchiveHeader from '@/components/ArchiveHeader'
import ArchiveTitle from '@/components/ArchiveTitle'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ArchiveCreateButton from '@/components/ArchiveCreateButton'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import TableWrapper from '@/components/TableWrapper'
import moment from 'moment'

export default {
  name: 'RequestsArchive',
  components: {
    ArchiveHeader,
    ArchiveTitle,
    Loader,
    ContentSection,
    ArchiveCreateButton,
    ArchiveActionSection,
    ArchiveTableDropdown,
    TableWrapper,
  },
  data: () => ({
    search: '',
    ascending: true,
  }),
  computed: {
    loading() {
      return this.$store.getters.loadingRequests
    },
    requests() {
      return this.$store.getters.requestsForArchive({
        search: this.search,
        ascending: this.ascending,
      })
    },
  },
  mounted() {
    this.fetchRequests()
    this.expandSidebar()
  },
  methods: {
    ...mapActions([
      'fetchRequests',
      'expandSidebar',
      'deleteRequest',
      'showFlashMessage',
    ]),

    handleDelete(id) {
      const request = this.$store.getters.resource('requests', id)
      if (
        confirm(this.t('flash_messages.delete.confirmation') + request.name)
      ) {
        this.deleteRequest(request)
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.request') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.request') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
    filteredRequests(type) {
      const filteredRequests = this.requests.filter(
        ({ status }) => status === type
      )

      return filteredRequests
    },
    calculateDeadline(deadline) {
      const currentDate = moment()
      let deadlineDate = moment(deadline)
      let difference = deadlineDate.diff(currentDate, 'days')

      if (difference < 0) {
        difference = 0
      }

      return difference
    },
  },
}
</script>
