import store from '@/store'
import * as types from '@/store/mutationTypes'

export default (type, request) => {
  store.commit(types.ADD_RESOURCE_REQUEST, { type, request })

  return request
    .then(response => {
      store.commit(types.UPDATE_RESOURCES, response)
      store.commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
      return response.data
    })
    .catch(({ response }) => {
      store.commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
      const error = new Error('Request error')
      error.status = response.status

      if (response.data && response.data.errors) {
        error.payload = response.data.errors
      }

      return error
    })
}
