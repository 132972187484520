<template>
  <div class="Departments">
    <Loader v-if="!departments" />
    <div v-else>
      <h3>{{ t('organization.edit.tabs.departments.title') }}</h3>
      <router-link :to="{ name: `DepartmentsNew` }">
        <AddNewButton
          :title="t('organization.edit.tabs.departments.new_button_title')"
        />
      </router-link>
      <div class="table__wrapper">
        <table>
          <thead>
            <th>{{ t('organization.edit.tabs.departments.name') }}</th>
            <th>{{ t('organization.edit.tabs.departments.created_at') }}</th>
            <th>{{ t('organization.edit.tabs.departments.updated_at') }}</th>
          </thead>
          <tbody>
            <DepartmentTableRow
              v-for="department in departments"
              :key="department.id"
              :department="department"
            />
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import './style.scss'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/Loader'
import AddNewButton from '@/components/AddNewButton'
import DepartmentTableRow from '@/components/DepartmentTableRow'

export default {
  name: 'Departments',
  components: { Loader, DepartmentTableRow, AddNewButton },
  computed: {
    ...mapGetters(['departments']),
  },
  mounted() {
    this.fetchDepartments()
  },
  methods: {
    ...mapActions(['fetchDepartments']),
  },
}
</script>
