import * as types from '../mutationTypes'
import api from '@/utils/api'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import humanizeErrors from '@/utils/humanizeErrors'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'

const type = 'departments'

const defaultDepartment = {
  name: 'Generelt',
  id: null,
}

const initialState = {
  errors: [],
}

const storeGetters = {
  loadingDepartments: (_state, getters) => getters.isLoading(type),

  departments: (_state, _getters, globalState) => {
    const map = globalState.resourceStore.byId[type] || {}
    const departments = Object.values(map)
    departments.unshift(defaultDepartment)
    return departments
  },
  department: (_state, getters) => {
    const departmentId = getters.params.id
    return getters.departments.find(
      department => department.id === departmentId
    )
  },
}

const actions = {
  fetchDepartments: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/departments`)
      .then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },

  saveDepartment: ({ commit, getters }, department) => {
    const { organizationId } = getters
    const params = snakeCaseKeys({ department })

    const request = api
      .put(
        `organizations/${organizationId}/departments/${department.id}`,
        params
      )
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },

  createDepartment: ({ commit, getters }, department) => {
    const { organizationId } = getters
    const params = snakeCaseKeys({ department })

    const request = api
      .post(`organizations/${organizationId}/departments`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },

  deleteDepartment: ({ getters: { organizationId } }, department_id) => {
    const request = api.delete(
      `organizations/${organizationId}/departments/${department_id}`
    )
    return wrapResourceRequest(type, request)
  },
}

const mutations = {
  [types.ADD_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = humanizeErrors(response)
  },
  [types.REMOVE_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = []
  },
}

export default {
  state: initialState,
  getters: storeGetters,
  actions,
  mutations,
}
