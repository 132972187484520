<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'HorizontalAlign',
  props: {
    alignment: { type: String, defaultValue: 'center' },
  },
  computed: {
    classes() {
      return `HorizontalAlign HorizontalAlign--${this.$props.alignment}`
    },
  },
}
</script>
