<template>
  <div>
    <ArchiveHeader v-model="search" />

    <Loader v-if="loading" />
    <ContentSection v-else class="fade_in_right">
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('incident.archive.title') }}</ArchiveTitle>
        <ArchiveCreateButton :to="{ name: 'IncidentsNew' }" />
      </ArchiveActionSection>

      <TableWrapper v-if="filteredIncidents('physical').length">
        <div class="wrapper">
          <h2>{{ t('incident.archive.physical') }}</h2>
          <table>
            <thead class="column-count-6">
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-6">
              <tr
                v-for="incident in filteredIncidents('physical')"
                :key="incident.id"
              >
                <td>
                  <router-link :to="{ path: `incidents/${incident.id}` }">
                    {{ incident.title }}
                  </router-link>
                </td>
                <td>
                  <Label v-if="incident.overdue" type="alert">{{
                    t('shared.table.overdue')
                  }}</Label>
                </td>
                <td>
                  <Label v-if="incident.confidentialityBreach" type="default">{{
                    t('incident.archive.private')
                  }}</Label>
                  <Label v-if="incident.availabilityBreach" type="default">{{
                    t('incident.archive.availability')
                  }}</Label>
                  <Label v-if="incident.integrityBreach" type="default">{{
                    t('incident.archive.integrity')
                  }}</Label>
                </td>
                <td
                  :class="
                    !incident.potentialRisk
                      ? 'green'
                      : incident.potentialHighRisk
                      ? 'red'
                      : ''
                  "
                >
                  {{ t('incident.archive.' + findRiskType(incident)) }}
                </td>
                <td>{{ incident.detectedAt | formatDateHuman }}</td>
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `incidents/${incident.id}` }"
                    :to-edit="{ path: `incidents/${incident.id}/edit` }"
                    :resource="incident"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>

      <TableWrapper v-if="filteredIncidents('technical').length">
        <div class="wrapper">
          <h2>{{ t('incident.archive.technical') }}</h2>
          <table>
            <thead class="column-count-6">
              <th />
              <th />
              <th />
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-6">
              <tr
                v-for="incident in filteredIncidents('technical')"
                :key="incident.id"
              >
                <td>
                  <router-link :to="{ path: `incidents/${incident.id}` }">
                    {{ incident.title }}
                  </router-link>
                </td>
                <td>
                  <Label v-if="incident.overdue" type="alert">{{
                    t('shared.table.overdue')
                  }}</Label>
                </td>
                <td>
                  <Label v-if="incident.confidentialityBreach" type="default">{{
                    t('incident.archive.private')
                  }}</Label>
                  <Label v-if="incident.availabilityBreach" type="default">{{
                    t('incident.archive.availability')
                  }}</Label>
                  <Label v-if="incident.integrityBreach" type="default">{{
                    t('incident.archive.integrity')
                  }}</Label>
                </td>
                <td
                  :class="
                    !incident.potentialRisk
                      ? 'green'
                      : incident.potentialHighRisk
                      ? 'red'
                      : ''
                  "
                >
                  {{ t('incident.archive.' + findRiskType(incident)) }}
                </td>
                <td>{{ incident.detectedAt | formatDateHuman }}</td>
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `incidents/${incident.id}` }"
                    :to-edit="{ path: `incidents/${incident.id}/edit` }"
                    :resource="incident"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import ArchiveHeader from '@/components/ArchiveHeader'
import ArchiveTitle from '@/components/ArchiveTitle'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ArchiveCreateButton from '@/components/ArchiveCreateButton'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import Label from '@/components/Label'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import TableWrapper from '@/components/TableWrapper'

export default {
  name: 'IncidentsArchive',
  components: {
    ArchiveHeader,
    ArchiveTitle,
    Loader,
    ContentSection,
    ArchiveCreateButton,
    ArchiveTableDropdown,
    Label,
    ArchiveActionSection,
    TableWrapper,
  },
  data: () => ({
    search: '',
    ascending: true,
  }),
  computed: {
    loading() {
      return this.$store.getters.loadingIncidents
    },
    incidents() {
      return this.$store.getters.incidentsForArchive({
        search: this.search,
        ascending: this.ascending,
      })
    },
  },
  mounted() {
    this.fetchIncidents()
    this.expandSidebar()
  },
  methods: {
    ...mapActions([
      'fetchIncidents',
      'expandSidebar',
      'deleteIncident',
      'showFlashMessage',
    ]),

    handleDelete(id) {
      const incident = this.$store.getters.resource('incidents', id)
      if (
        confirm(this.t('flash_messages.delete.confirmation') + incident.title)
      ) {
        this.deleteIncident(incident)
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.incident') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.incident') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
    filteredIncidents(type) {
      const boolStatus = type === 'physical' ? true : false

      const filteredIncidents = this.incidents.filter(
        ({ physicalBreach }) => physicalBreach === boolStatus
      )

      return filteredIncidents
    },
    findRiskType(incident) {
      if (!incident.potentialRisk) {
        return 'no_risk'
      } else if (incident.potentialRisk && !incident.potentialHighRisk) {
        return 'medium_risk'
      } else {
        return 'high_risk'
      }
    },
  },
}
</script>
