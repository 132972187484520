import Vue from 'vue'
import api from '@/utils/api'
import attachRelations from '@/utils/attachRelations'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import snakeCaseKeys from '@/utils/snakeCaseKeys'

const type = 'todos'

const storeGetters = {
  loadingTodos: (_state, getters) => getters.isLoading(type),

  todo: (_state, getters) => getters.resource(type, getters.params.todoId),

  todos: (_state, getters) => {
    const todos = getters.resources(type)
    return todos.map(attachRelations(['user']))
  },

  todosForArchive:
    (_state, getters) =>
    ({ search, ascending, filterUserId, showCompleted }) => {
      const loweredSearch = search.toLowerCase()
      const sortDirection = ascending ? 1 : -1

      let archive = getters[type].slice()

      if (showCompleted === 'open') {
        archive = archive.filter(({ completed }) => completed === false)
      } else if (showCompleted === 'closed') {
        archive = archive.filter(({ completed }) => completed === true)
      }

      if (search) {
        archive = archive.filter(({ title, description, user }) => {
          const userName = Vue.filter('formatUser')(user)

          return (
            (title || '').toLowerCase().includes(loweredSearch) ||
            (description || '').toLowerCase().includes(loweredSearch) ||
            userName.toLowerCase().includes(loweredSearch)
          )
        })
      }

      if (filterUserId) {
        archive = archive.filter(({ userId }) => userId === filterUserId)
      }

      archive.sort((a, b) => sortDirection * (a.id - b.id))

      return archive
    },
}

const actions = {
  fetchTodos: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/todos`)
      .then(flushTypes(type))
    return wrapResourceRequest(type, request)
  },

  createTodo: ({ getters: { organizationId } }, todo) => {
    const params = snakeCaseKeys({ todo })
    const request = api.post(`organizations/${organizationId}/todos`, params)
    return wrapResourceRequest(type, request)
  },

  saveTodo: ({ getters: { organizationId } }, todo) => {
    const params = snakeCaseKeys({ todo })

    const request = api.put(
      `organizations/${organizationId}/todos/${todo.id}`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  deleteTodo: ({ getters: { organizationId } }, todo) => {
    const request = api.delete(
      `organizations/${organizationId}/todos/${todo.id}`
    )
    return wrapResourceRequest(type, request)
  },
}

export default {
  actions,
  getters: storeGetters,
}
