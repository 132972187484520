<template>
  <div>
    <ArchiveHeader v-model="search" />

    <Loader v-if="loading" />
    <ContentSection v-else class="fade_in_right">
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('data_mapping.archive.title') }}</ArchiveTitle>
        <ArchiveCreateButton :to="{ name: 'DataMappingsNew' }" />
      </ArchiveActionSection>

      <DataMappingDepartmentWrapper
        v-for="department in departments"
        :key="department.id"
      >
        <div
          v-if="filteredDataMappingsByDepartment(department).length"
          class="DataMappingDepartmentWrapper__box"
        >
          <h2>{{ department.name }}</h2>
          <!-- With department but without category -->

          <div
            v-if="
              filteredDataMappingsWithDepartmentWithoutCategory(department)
                .length
            "
            class="DataMappingDepartmentWrapper__categories"
          >
            <TableWrapper class="active">
              <table>
                <thead class="column-count-2">
                  <th />
                  <th />
                </thead>
                <tbody class="column-count-2">
                  <tr
                    v-for="dataMapping in filteredDataMappingsWithDepartmentWithoutCategory(
                      department
                    )"
                    :key="dataMapping.id"
                  >
                    <td>
                      <router-link
                        :to="{ path: `data-mappings/${dataMapping.id}` }"
                      >
                        {{ dataMapping.title }}
                      </router-link>
                      <i
                        v-if="!dataMapping.anyRiskAssessments"
                        class="fa fa-exclamation-triangle RiskWarning"
                        @click="showWarning"
                      />
                    </td>
                    <td>
                      <ArchiveTableDropdown
                        :to-view="{ path: `data-mappings/${dataMapping.id}` }"
                        :to-edit="{
                          path: `data-mappings/${dataMapping.id}/edit`,
                        }"
                        :to-review="{
                          path: `data-mappings/${dataMapping.id}/reviews`,
                        }"
                        :resource="dataMapping"
                        @delete="handleDelete"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableWrapper>
          </div>

          <!-- With department and category -->

          <div v-for="category in customCategories" :key="category.id">
            <div
              v-if="filteredDataMappingsByCategory(category, department).length"
              class="DataMappingDepartmentWrapper__categories"
            >
              <h4 @click="toggleTable(category.id)">
                <span
                  :class="
                    activeTableIds.includes(category.id)
                      ? 'fa fa-caret-down'
                      : 'fa fa-caret-right'
                  "
                />
                {{ category.title }}
              </h4>
              <TableWrapper
                :class="activeTableIds.includes(category.id) ? 'active' : ''"
              >
                <table>
                  <thead class="column-count-2">
                    <th />
                    <th />
                  </thead>
                  <tbody class="column-count-2">
                    <tr
                      v-for="dataMapping in filteredDataMappingsByCategory(
                        category,
                        department
                      )"
                      :key="dataMapping.id"
                    >
                      <td>
                        <router-link
                          :to="{ path: `data-mappings/${dataMapping.id}` }"
                        >
                          {{ dataMapping.title }}
                        </router-link>
                        <i
                          v-if="!dataMapping.anyRiskAssessments"
                          class="fa fa-exclamation-triangle RiskWarning"
                          @click="showWarning"
                        />
                      </td>
                      <td>
                        <ArchiveTableDropdown
                          :to-view="{ path: `data-mappings/${dataMapping.id}` }"
                          :to-edit="{
                            path: `data-mappings/${dataMapping.id}/edit`,
                          }"
                          :to-review="{
                            path: `data-mappings/${dataMapping.id}/reviews`,
                          }"
                          :resource="dataMapping"
                          @delete="handleDelete"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TableWrapper>
            </div>
          </div>
        </div>
      </DataMappingDepartmentWrapper>

      <DataMappingDepartmentWrapper
        v-if="filteredDataMappingsWithoutDepartment().length"
      >
        <h2>{{ t('data_mapping.archive.general') }}</h2>

        <div class="DataMappingDepartmentWrapper__box">
          <!-- General without category -->

          <div
            v-if="filteredDataMappingsWithoutDepartmentWithoutCategory().length"
            class="DataMappingDepartmentWrapper__categories"
          >
            <TableWrapper class="active">
              <table>
                <thead class="column-count-2">
                  <th />
                  <th />
                </thead>
                <tbody class="column-count-2">
                  <tr
                    v-for="dataMapping in filteredDataMappingsWithoutDepartmentWithoutCategory()"
                    :key="dataMapping.id"
                  >
                    <td>
                      <router-link
                        :to="{ path: `data-mappings/${dataMapping.id}` }"
                      >
                        {{ dataMapping.title }}
                      </router-link>
                      <i
                        v-if="!dataMapping.anyRiskAssessments"
                        class="fa fa-exclamation-triangle RiskWarning"
                        @click="showWarning"
                      />
                    </td>
                    <td>
                      <ArchiveTableDropdown
                        :to-view="{ path: `data-mappings/${dataMapping.id}` }"
                        :to-edit="{
                          path: `data-mappings/${dataMapping.id}/edit`,
                        }"
                        :to-review="{
                          path: `data-mappings/${dataMapping.id}/reviews`,
                        }"
                        :resource="dataMapping"
                        @delete="handleDelete"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </TableWrapper>
          </div>

          <!-- General with category -->

          <div v-for="category in customCategories" :key="category.id">
            <div
              v-if="
                filteredDataMappingsWithoutDepartmentWithCategory(category)
                  .length
              "
              class="DataMappingDepartmentWrapper__categories"
            >
              <h4 @click="toggleTable('general-category-' + category.id)">
                <span
                  :class="
                    activeTableIds.includes('general-category-' + category.id)
                      ? 'fa fa-caret-down'
                      : 'fa fa-caret-right'
                  "
                />
                {{ category.title }}
              </h4>
              <TableWrapper
                :class="
                  activeTableIds.includes('general-category-' + category.id)
                    ? 'active'
                    : ''
                "
              >
                <table>
                  <thead class="column-count-2">
                    <th />
                    <th />
                  </thead>
                  <tbody class="column-count-2">
                    <tr
                      v-for="dataMapping in filteredDataMappingsWithoutDepartmentWithCategory(
                        category
                      )"
                      :key="dataMapping.id"
                    >
                      <td>
                        <router-link
                          :to="{ path: `data-mappings/${dataMapping.id}` }"
                        >
                          {{ dataMapping.title }}
                        </router-link>
                        <i
                          v-if="!dataMapping.anyRiskAssessments"
                          class="fa fa-exclamation-triangle RiskWarning"
                          @click="showWarning"
                        />
                      </td>
                      <td>
                        <ArchiveTableDropdown
                          :to-view="{ path: `data-mappings/${dataMapping.id}` }"
                          :to-edit="{
                            path: `data-mappings/${dataMapping.id}/edit`,
                          }"
                          :to-review="{
                            path: `data-mappings/${dataMapping.id}/reviews`,
                          }"
                          :resource="dataMapping"
                          @delete="handleDelete"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </TableWrapper>
            </div>
          </div>
        </div>
      </DataMappingDepartmentWrapper>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Loader from '@/components/Loader'
import ArchiveHeader from '@/components/ArchiveHeader'
import ArchiveTitle from '@/components/ArchiveTitle'
import ContentSection from '@/components/ContentSection'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import ArchiveCreateButton from '@/components/ArchiveCreateButton'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import TableWrapper from '@/components/TableWrapper'
import DataMappingDepartmentWrapper from '@/components/DataMappingDepartmentWrapper'

export default {
  name: 'DataMappingsArchive',
  components: {
    Loader,
    ArchiveHeader,
    ArchiveTitle,
    ContentSection,
    ArchiveCreateButton,
    ArchiveTableDropdown,
    ArchiveActionSection,
    TableWrapper,
    DataMappingDepartmentWrapper,
  },
  data: () => ({
    search: '',
    ascending: true,
    activeTableIds: [],
  }),
  computed: {
    ...mapGetters(['departments', 'customCategories']),
    loading() {
      return this.$store.getters.loadingDataMappings
    },
    dataMappings() {
      return this.$store.getters.dataMappingsForArchive({
        search: this.search,
        ascending: this.ascending,
      })
    },
  },
  mounted() {
    this.fetchDataMappings()
    this.fetchCustomCategories()
    this.expandSidebar()
  },
  methods: {
    ...mapActions([
      'fetchDataMappings',
      'expandSidebar',
      'deleteDataMapping',
      'showFlashMessage',
      'fetchCustomCategories',
    ]),

    handleDelete(id) {
      const dataMapping = this.$store.getters.resource('dataMappings', id)
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') + dataMapping.title
        )
      ) {
        this.deleteDataMapping(dataMapping)
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.data_mapping') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.data_mapping') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
    showWarning() {
      this.showFlashMessage({
        message: this.t('flash_messages.custom.missing_risk_assessments'),
      })
    },
    filteredDataMappingsByDepartment(department) {
      const filteredDataMappings = this.dataMappings.filter(dataMapping => {
        if (!dataMapping.department) {
          return
        } else {
          return dataMapping.department.name === department.name
        }
      })

      return filteredDataMappings
    },

    filteredDataMappingsByCategory(category, department) {
      const dataMappings = this.filteredDataMappingsByDepartment(department)

      const filteredDataMappings = dataMappings.filter(dataMapping => {
        if (!dataMapping.customCategoryId) {
          return
        } else {
          return dataMapping.customCategoryId === category.id
        }
      })

      return filteredDataMappings
    },

    filteredDataMappingsWithoutDepartment() {
      const filteredDataMappings = this.dataMappings.filter(dataMapping => {
        return !dataMapping.department
      })

      return filteredDataMappings
    },

    filteredDataMappingsWithDepartmentWithoutCategory(department) {
      const dataMappings = this.filteredDataMappingsByDepartment(department)

      const filteredDataMappings = dataMappings.filter(dataMapping => {
        return !dataMapping.customCategoryId
      })

      return filteredDataMappings
    },

    filteredDataMappingsWithoutDepartmentWithCategory(category) {
      const dataMappings = this.filteredDataMappingsWithoutDepartment()

      const filteredDataMappings = dataMappings.filter(dataMapping => {
        if (!dataMapping.customCategoryId) {
          return
        } else {
          return dataMapping.customCategoryId === category.id
        }
      })

      return filteredDataMappings
    },

    filteredDataMappingsWithoutDepartmentWithoutCategory() {
      const dataMappings = this.filteredDataMappingsWithoutDepartment()

      const filteredDataMappings = dataMappings.filter(dataMapping => {
        return !dataMapping.customCategoryId
      })

      return filteredDataMappings
    },

    toggleTable(id) {
      if (this.activeTableIds.includes(id)) {
        const activeId = this.activeTableIds.findIndex(i => i === id)

        this.activeTableIds.splice(activeId, 1)
      } else {
        this.activeTableIds.push(id)
      }
    },
  },
}
</script>
