<template>
  <tr>
    <td>{{ organizationUser.user.name }}</td>
    <td>{{ organizationUser.user.email }}</td>
    <td>{{ organizationUser.role }}</td>
    <td>
      {{
        organizationUser.invitedBy ? organizationUser.invitedBy.email : 'Admin'
      }}
    </td>
    <td>
      {{
        latestDate(organizationUser.user.updatedAt, organizationUser.updatedAt)
          | formatDateHuman
      }}
    </td>
    <td>
      <ArchiveTableDropdown
        v-if="!isCurrentOrganizationUser"
        :to-edit="{
          path: `edit/organization-users/${organizationUser.id}/edit`,
        }"
        :to-delete="{
          path: `edit/organization-users/${organizationUser.id}/delete`,
        }"
        :resource="organizationUser"
      />
    </td>
  </tr>
</template>

<script>
import moment from 'moment'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'

export default {
  name: 'UserTableRow',
  components: { ArchiveTableDropdown },
  props: {
    organizationUser: { type: Object, required: true },
    currentOrganizationUser: { type: Object, required: true },
  },
  computed: {
    isCurrentOrganizationUser: function () {
      return this.organizationUser.id === this.currentOrganizationUser.id
    },
  },
  methods: {
    latestDate(orgUserDate, userDate) {
      const date = orgUserDate > userDate ? orgUserDate : userDate
      return moment(date)
    },
  },
}
</script>
