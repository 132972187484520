import * as types from '../mutationTypes'
import api from '@/utils/api'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import humanizeErrors from '@/utils/humanizeErrors'

const type = 'password'

const initialState = {
  errors: [],
}

const actions = {
  sendResetInstructions: ({ commit }, resetParams) => {
    const params = snakeCaseKeys({ passwordReset: resetParams })

    const request = api
      .post('password_resets', params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },

  resetPassword: ({ commit }, { token, passwordParams }) => {
    const params = snakeCaseKeys({ password: passwordParams })

    const request = api
      .put(`passwords/${token}`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },
}

const mutations = {
  [types.ADD_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = humanizeErrors(response)
  },
  [types.REMOVE_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = []
  },
}

export default {
  state: initialState,
  actions,
  mutations,
}
