<template>
  <div v-if="logoUrl" class="PartnerLogo">
    <img :src="logoUrl" class="PartnerLogo__image" />
    <p class="PartnerLogo__text">
      {{ t('shared.header.partner_logo_text') }}
    </p>
  </div>
</template>

<script>
import './style.scss'
import { mapGetters } from 'vuex'

export default {
  name: 'PartnerLogo',
  computed: {
    ...mapGetters(['organization']),
    logoUrl: function () {
      return this.organization && this.organization.partnerLogoUrl
    },
  },
}
</script>
