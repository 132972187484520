<template>
  <div class="Dashboard">
    <ArchiveHeader v-model="search" />

    <Loader v-if="loading" />
    <ContentSection v-else class="fade_in_right">
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('dashboard.title') }}</ArchiveTitle>
        <TabsWrapper class="no-margin-bottom">
          <a
            href="#"
            :class="{ active: activeTab === 'todo' }"
            class="tab"
            @click="activeTab = 'todo'"
          >
            <p>{{ t('dashboard.tabs.todo') }}</p>
          </a>
          <a
            href="#"
            :class="{ active: activeTab === 'reviews' }"
            class="tab"
            @click="activeTab = 'reviews'"
          >
            <p>{{ t('dashboard.tabs.reviews') }}</p>
          </a>
        </TabsWrapper>
      </ArchiveActionSection>

      <section v-if="activeTab === 'todo'" class="Dashboard__section">
        <ListHeader title="To-do">
          <ListHeaderTab
            :active="showCompleted == 'closed'"
            @click="showCompleted = 'closed'"
          >
            {{ t('dashboard.todos.filter.completed') }}
          </ListHeaderTab>
          <ListHeaderTab
            :active="showCompleted == 'open'"
            @click="showCompleted = 'open'"
          >
            {{ t('dashboard.todos.filter.open') }}
          </ListHeaderTab>
          <ListHeaderTab
            :active="showCompleted == 'all'"
            @click="showCompleted = 'all'"
          >
            {{ t('dashboard.todos.filter.all') }}
          </ListHeaderTab>
        </ListHeader>

        <FormSelect v-model="filterUserId">
          <option :key="0" :value="null">
            {{ null | formatUser }}
          </option>
          <option v-for="user in users" :key="user.id" :value="user.id">
            {{ user.name }}
          </option>
        </FormSelect>

        <List>
          <router-link :to="{ name: 'TodosNew' }">
            <TodoListItemNew />
          </router-link>
          <TodoListItem
            v-for="todo in todos"
            :key="todo.id"
            :todo="todo"
            @check="toggleTodoCompleted(todo)"
            @delete="deleteTodo(todo)"
          />
        </List>
      </section>

      <section
        v-if="activeTab === 'reviews'"
        :key="organization.id"
        class="Review__section"
      >
        <InlineContent>
          <TabsWrapper class="no-margin-bottom">
            <a
              href="#"
              :class="{ active: activeChildTab === 'upcoming' }"
              class="tab"
              @click="activeChildTab = 'upcoming'"
            >
              <p>{{ t('dashboard.tabs.upcoming') }}</p>
            </a>
            <a
              href="#"
              :class="{ active: activeChildTab === 'all' }"
              class="tab"
              @click="activeChildTab = 'all'"
            >
              <p>{{ t('dashboard.tabs.all') }}</p>
            </a>
          </TabsWrapper>
          <FormSelect v-model="filterReviewUserId">
            <option :key="0" :value="null">
              {{ null | formatUser }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </InlineContent>

        <section
          v-if="activeChildTab === 'upcoming'"
          :key="organization.id"
          class="Dashboard__section"
        >
          <DueDateTable
            :user-id="filterReviewUserId"
            :review-path="true"
            :title="t('dashboard.reviews.data_mappings')"
            resource-path="data-mappings/"
            resource-type="dataMappings"
          />
          <DueDateTable
            :user-id="filterReviewUserId"
            :review-path="false"
            :title="t('dashboard.reviews.documents')"
            resource-path="documents/"
            resource-type="documents"
          />
          <DueDateTable
            :user-id="filterReviewUserId"
            :review-path="false"
            :title="t('dashboard.reviews.review_processes')"
            resource-path="review-processes/"
            resource-type="reviewProcesses"
          />
          <DueDateTable
            :user-id="filterReviewUserId"
            :review-path="true"
            :title="t('dashboard.reviews.systems')"
            resource-path="systems/"
            resource-type="systems"
          />
          <DueDateTable
            :user-id="filterReviewUserId"
            :review-path="true"
            :title="t('dashboard.reviews.data_processors')"
            resource-path="data-processors/"
            resource-type="dataProcessors"
          />
        </section>
        <section
          v-if="activeChildTab === 'all'"
          :key="organization.id"
          class="Dashboard__section"
        >
          <ReviewedTable
            :user-id="filterReviewUserId"
            :review-path="true"
            :title="t('dashboard.reviews.data_mappings')"
            resource-path="data-mappings/"
            resource-type="dataMappings"
          />
          <ReviewedTable
            :user-id="filterReviewUserId"
            :review-path="false"
            :title="t('dashboard.reviews.documents')"
            resource-path="documents/"
            resource-type="documents"
          />
          <ReviewedTable
            :user-id="filterReviewUserId"
            :review-path="false"
            :title="t('dashboard.reviews.review_processes')"
            resource-path="review-processes/"
            resource-type="reviewProcesses"
          />
          <ReviewedTable
            :user-id="filterReviewUserId"
            :review-path="true"
            :title="t('dashboard.reviews.systems')"
            resource-path="systems/"
            resource-type="systems"
          />
          <ReviewedTable
            :user-id="filterReviewUserId"
            :review-path="true"
            :title="t('dashboard.reviews.data_processors')"
            resource-path="data-processors/"
            resource-type="dataProcessors"
          />
        </section>
      </section>
    </ContentSection>
    <router-view />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loader from '@/components/Loader'
import ArchiveHeader from '@/components/ArchiveHeader'
import ContentSection from '@/components/ContentSection'
import ListHeader from '@/components/ListHeader'
import ListHeaderTab from '@/components/ListHeaderTab'
import List from '@/components/List'
import TodoListItemNew from '@/components/TodoListItemNew'
import TodoListItem from '@/components/TodoListItem'
import TabsWrapper from '@/components/TabsWrapper'
import DueDateTable from '@/components/DueDateTable'
import ReviewedTable from '@/components/ReviewedTable'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import ArchiveTitle from '@/components/ArchiveTitle'
import FormSelect from '@/components/FormSelect'
import InlineContent from '@/components/InlineContent'
import * as types from '@/store/mutationTypes'

export default {
  name: 'Dashboard',
  components: {
    Loader,
    ArchiveHeader,
    ContentSection,
    ListHeader,
    ListHeaderTab,
    List,
    TodoListItemNew,
    TodoListItem,
    TabsWrapper,
    DueDateTable,
    ReviewedTable,
    ArchiveActionSection,
    ArchiveTitle,
    FormSelect,
    InlineContent,
  },
  data: () => ({
    loading: true,
    search: '',
    ascending: true,
    showCompleted: 'all',
    filterUserId: null,
    filterReviewUserId: null,
    activeTab: 'todo',
    activeChildTab: 'upcoming',
  }),
  computed: {
    ...mapGetters(['todosForArchive', 'users', 'organization', 'currentUser']),
    todos() {
      return this.todosForArchive({
        search: this.search,
        ascending: this.ascending,
        showCompleted: this.showCompleted,
        filterUserId: this.filterUserId,
      })
    },
  },
  watch: {
    $route(to, from) {
      Promise.all([
        this.$store.commit(types.FLUSH_RESOURCE_TYPES, [
          'dataMappings',
          'documents',
          'reviewProcesses',
          'systems',
          'dataProcessors',
        ]),
        this.fetchTodos(),
        this.fetchOrganizationUsers(),
      ]).then(() => (this.loading = false))
    },
  },

  mounted: function () {
    Promise.all([this.fetchTodos(), this.fetchOrganizationUsers()]).then(
      () => (this.loading = false)
    )
    this.expandSidebar()
    this.setFilterReviewUser()
  },
  methods: {
    ...mapActions([
      'fetchTodos',
      'expandSidebar',
      'fetchOrganizationUsers',
      'saveTodo',
      'deleteTodo',
      'showFlashMessage',
    ]),
    setFilterReviewUser() {
      if (this.filterReviewUserId === null) {
        this.filterReviewUserId = this.currentUser.id.toString()
      }
    },
    toggleTodoCompleted(todo) {
      this.saveTodo({ ...todo, completed: !todo.completed })
        .then(() => {
          if (todo.completed) {
            this.showFlashMessage({
              message:
                this.t('flash_messages.todo') +
                this.t('flash_messages.custom.reopened'),
            })
          } else {
            this.showFlashMessage({
              message:
                this.t('flash_messages.todo') +
                this.t('flash_messages.custom.completed'),
            })
          }
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.todo') +
              this.t('flash_messages.custom.completed_error'),
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
