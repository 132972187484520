<template>
  <li class="FileListItem">
    <button
      v-if="showClose"
      type="button"
      class="FileListItem__close"
      @click="handleFileRemove(fileIndex)"
    >
      <i class="fa fa-trash" />
    </button>
    <a :href="file.url" target="_blank">
      <li v-if="!showClose" class="fa fa-paperclip" />
      <span class="FileListItem__name">{{ file.name }}</span>
    </a>
  </li>
</template>

<script>
import './style.scss'

export default {
  name: 'FileListItem',
  props: {
    file: { type: Object },
    fileIndex: { type: Number, optional: true },
    showClose: { type: Boolean, defaultValue: false },
  },
  methods: {
    handleFileRemove(fileIndex) {
      this.$emit('removed', fileIndex)
    },
  },
}
</script>
