<template>
  <router-link :to="to">
    <Button :type="type" :size="size">
      <slot />
    </Button>
  </router-link>
</template>

<script>
import Button from '@/components/Button'

export default {
  name: 'ButtonLink',
  components: { Button },
  props: {
    to: { type: Object, required: true },
    type: { type: String },
    size: { type: String },
  },
}
</script>
