<template>
  <Header>
    <HeaderBackLink :to="backLink">
      {{ backLinkText }}
    </HeaderBackLink>
    <HeaderTitle v-if="title">
      <div v-if="titlePrefix" class="HeaderTitle__prefix">
        <span>{{ titlePrefix }}</span>
        <i class="fa fa-angle-right" />
        <div v-if="titlePrefixSecondary">
          <span>{{ titlePrefixSecondary }}</span>
          <i class="fa fa-angle-right" />
        </div>
        {{ title }}
      </div>
      <div v-else>
        {{ title }}
      </div>
    </HeaderTitle>
    <HeaderTimestamp :date="timestamp" />
  </Header>
</template>

<script>
import Header from '@/components/Header'
import HeaderTitle from '@/components/HeaderTitle'
import HeaderBackLink from '@/components/HeaderBackLink'
import HeaderTimestamp from '@/components/HeaderTimestamp'

export default {
  name: 'ResourceHeader',
  components: {
    Header,
    HeaderBackLink,
    HeaderTimestamp,
    HeaderTitle,
  },
  props: {
    backLink: { type: Object },
    backLinkText: { type: String },
    title: { type: String, default: '' },
    titlePrefix: { type: String },
    titlePrefixSecondary: { type: String },
    timestamp: { type: String, default: '' },
  },
}
</script>
