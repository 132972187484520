<template>
  <div class="ThinButton" @click="$emit('click', $event)">
    <slot />
    <slot name="image" />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'ThinButton',
  props: {
    title: { type: String },
  },
}
</script>
