<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'ReviewProcessesArchive' }"
      :title="reviewProcess && reviewProcess.title"
      :timestamp="reviewProcess && reviewProcess.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('review_process.archive.title')"
    />

    <Loader v-if="!reviewProcess" />
    <ContentSection v-else>
      <ResourceActions>
        <ButtonLink :to="{ name: 'ReviewProcessesEdit' }" type="primary">
          {{ t('shared.reviews.edit') }}
        </ButtonLink>
        <Button @click="handleDelete">
          {{ t('shared.reviews.delete') }}
        </Button>
      </ResourceActions>

      <Columns>
        <div>
          <ColumnHeader>{{ t('shared.reviews.logbook') }}</ColumnHeader>
          <List>
            <TimelineItem
              :type="canReview ? 'current' : 'future'"
              :date="reviewProcess.nextReviewAt"
              :overdue="overdue"
            >
              {{
                canReview
                  ? t('shared.reviews.ready_for_check')
                  : t('shared.reviews.upcoming_checks')
              }}
            </TimelineItem>

            <TimelineItem
              v-for="review in sortedReviews(reviewProcess.reviews)"
              :key="review.id"
              :date="review.reviewedAt"
              :overdue="review.overdue"
              :resource="review"
              type="past"
            >
              {{ review.description }}
              <div v-if="review.files.length">
                <List>
                  <FileListItem
                    v-for="file in review.files"
                    :key="file.name"
                    :file="file"
                  />
                </List>
              </div>
              <Button
                v-if="reviewProcess.reviews[0] === review"
                class="Button--sm-flex"
                @click="handleEdit(review)"
              >
                {{ t('shared.reviews.edit') }}
              </Button>
            </TimelineItem>
          </List>
        </div>
        <div>
          <div v-if="canReview || isEditing" class="log_form">
            <ColumnHeader>
              {{ t('shared.reviews.describe_execution_title') }}
            </ColumnHeader>
            <Field
              v-model="formData.description"
              v-validate="'required'"
              type="textarea"
              :placeholder="t('shared.form.description_placeholder')"
              class="Field--textarea-wide"
              name="description"
              :error="errors.first('description')"
            />
            <p v-if="isEditing">
              {{ t('shared.reviews.edit_warning') }}
            </p>
            <FileUpload
              :existing-files="existingFiles"
              @onFilesChange="onFilesChange"
            />
            <Actions v-if="isEditing">
              <Button type="primary" @click="handleSave()">
                {{ t('shared.reviews.submit') }}
              </Button>
              <Button @click="resetFormData()">
                {{ t('shared.reviews.cancel') }}
              </Button>
            </Actions>
            <Actions v-else>
              <Button type="primary" @click="handleCreate()">
                {{ t('shared.reviews.submit') }}
              </Button>
            </Actions>
          </div>
          <div v-else>
            <ColumnHeader>
              {{ t('shared.reviews.up_to_date_title') }}
            </ColumnHeader>
            <ColumnParagraph>
              {{ t('shared.reviews.up_to_date_info') }}
            </ColumnParagraph>
          </div>
        </div>
        <div class="DetailsAndRelationsColumn">
          <ColumnHeader>
            {{ t('shared.form.description_placeholder') }}
          </ColumnHeader>
          <ColumnParagraph>
            {{ reviewProcess.description }}
          </ColumnParagraph>

          <ColumnParagraph>
            <ButtonLink
              v-if="reviewProcess.dataMappingId"
              :to="{
                name: 'DataMappingsShow',
                params: { dataMappingId: reviewProcess.dataMappingId },
              }"
              type="primary"
            >
              {{ t('review_process.show.related_data_mapping') }}
            </ButtonLink>

            <ButtonLink
              v-if="reviewProcess.documentProcedureId"
              :to="{
                name: 'DocumentsRevisions',
                params: { id: reviewProcess.documentProcedureId },
              }"
              type="primary"
            >
              {{ t('review_process.show.related_procedure') }}
            </ButtonLink>

            <ButtonLink
              v-if="reviewProcess.dataProcessorId"
              :to="{
                name: 'DataProcessorsShow',
                params: { dataProcessorId: reviewProcess.dataProcessorId },
              }"
              type="primary"
            >
              {{ t('review_process.show.related_data_processor') }}
            </ButtonLink>
          </ColumnParagraph>
        </div>
      </Columns>
    </ContentSection>
  </div>
</template>

<script>
import './style.scss'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import router from '../../routes'
import { cloneDeep } from 'lodash'
import i18n from '@/mixins/i18n.js.erb'

import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ResourceActions from '@/components/ResourceActions'
import Columns from '@/components/Columns'
import ButtonLink from '@/components/ButtonLink'
import List from '@/components/List'
import TimelineItem from '@/components/TimelineItem'
import ColumnParagraph from '@/components/ColumnParagraph'
import ColumnHeader from '@/components/ColumnHeader'
import Field from '@/components/Field'
import Actions from '@/components/Actions'
import Button from '@/components/Button'
import FileUpload from '@/components/FileUpload'
import FileListItem from '@/components/FileListItem'

export default {
  name: 'ReviewProcessesShow',
  components: {
    ResourceHeader,
    Loader,
    ContentSection,
    ResourceActions,
    Columns,
    ButtonLink,
    List,
    TimelineItem,
    ColumnParagraph,
    ColumnHeader,
    Field,
    Actions,
    Button,
    FileUpload,
    FileListItem,
  },
  data() {
    return {
      formData: {
        description: '',
        reviewedAt: moment().format('YYYY-MM-DD'),
        flow_type: 'ReviewProcess',
      },
      isEditing: false,
      existingFiles: [],
    }
  },
  computed: {
    ...mapGetters(['reviewProcess']),

    canReview() {
      const reviewDate = moment(this.reviewProcess.nextReviewAt).format(
        'YYYYMMDD'
      )
      const currentDate = moment().format('YYYYMMDD')

      return parseInt(reviewDate) <= parseInt(currentDate)
    },

    overdue() {
      const reviewDate = moment(this.reviewProcess.nextReviewAt).format(
        'YYYYMMDD'
      )
      const currentDate = moment().format('YYYYMMDD')

      return parseInt(reviewDate) < parseInt(currentDate)
    },
  },
  mounted() {
    if (this.reviewProcess) this.flushReviewProcessRelations(this.reviewProcess)
    this.collapseSidebar()
    this.fetchReviewProcess()
  },
  methods: {
    ...mapActions([
      'flushReviewProcessRelations',
      'fetchReviewProcess',
      'collapseSidebar',
      'createReview',
      'saveReview',
      'deleteReviewProcess',
      'showFlashMessage',
    ]),

    handleCreate() {
      this.formData.flow_id = this.reviewProcess.id

      this.createReview(this.formData)
        .then(() => {
          this.showFlashMessage({
            message:
              i18n.methods.t('shared.review') +
              i18n.methods.t('flash_messages.create.success'),
          })
          this.flushReviewProcessRelations(this.reviewProcess)
          this.fetchReviewProcess()
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              i18n.methods.t('shared.review') +
              i18n.methods.t('flash_messages.create.error'),
          })
        })
    },
    handleSave() {
      if (confirm(i18n.methods.t('shared.reviews.edit_confirmation'))) {
        this.saveReview(this.formData)
          .then(() => {
            this.showFlashMessage({
              message:
                i18n.methods.t('shared.review') +
                i18n.methods.t('flash_messages.update.success'),
            })
            this.flushReviewProcessRelations(this.reviewProcess)
            this.fetchReviewProcess()
            this.resetFormData()
          })
          .catch(() => {
            this.showFlashMessage({
              message:
                i18n.methods.t('shared.review') +
                i18n.methods.t('flash_messages.update.error'),
            })
          })
      }
    },
    handleEdit(review) {
      this.formData = cloneDeep(review)
      this.formData.files = []
      this.isEditing = true
    },
    resetFormData() {
      this.formData.description = []
      this.formData.id = null
      this.formData.reviewedAt = moment().format('YYYY-MM-DD')
      this.formData.flow_type = 'ReviewProcess'
      this.isEditing = false
    },
    handleDelete() {
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') +
            this.reviewProcess.title
        )
      ) {
        this.deleteReviewProcess(this.reviewProcess)
          .then(() => {
            router.push({ name: 'ReviewProcessesArchive' })
            this.showFlashMessage({
              message:
                this.t('flash_messages.review_process') +
                this.t('flash_messages.delete.success'),
            })
          })
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.review_process') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
    sortedReviews(reviews) {
      reviews.sort((a, b) => {
        const sortDirection = -1

        return (
          sortDirection *
          moment.utc(a.reviewedAt).diff(moment.utc(b.reviewedAt))
        )
      })

      return reviews
    },
    onFilesChange(files) {
      this.formData.files = files
    },
  },
}
</script>
