<template>
  <div class="FieldContainer">
    <slot />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'FieldContainer',
}
</script>
