<template>
  <div class="form">
    <div class="form__input-wrapper">
      <label>{{ t('subscribe.form.client') }}</label>
      <input v-model="formData.name" type="text" />
    </div>
    <div class="form__input-wrapper">
      <label>{{ t('subscribe.form.cvr') }}</label>
      <input v-model="formData.cvr" type="text" />
    </div>
    <div class="form__input-wrapper">
      <label>{{ t('subscribe.form.phone_number') }}</label>
      <input v-model="formData.phoneNumber" type="text" />
    </div>
    <div class="form__input-wrapper">
      <label>{{ t('subscribe.form.invoice_email') }}</label>
      <input v-model="formData.invoicingEmail" type="text" />
    </div>
    <input
      id="acceptTerms"
      v-model="acceptedTerms"
      :value="acceptedTerms"
      type="checkbox"
      @change="updateStatus"
    />
    <label for="acceptTerms"
      >{{ t('subscribe.form.accept_terms') }}
      <a
        class="termsLabel"
        href="https://compliancelog.dk/#/handelsbetingelser"
        target="_blank"
        >{{ t('subscribe.form.link') }}</a
      ></label
    >
    <Button
      :disabled="!acceptedTerms"
      class="Button--primary"
      @click="handleOrganizationSubmit()"
    >
      {{ t('subscribe.form.subscribe') }}
    </Button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import * as moment from 'moment'
import Button from '@/components/Button'

const buildOrganization = () => ({
  name: '',
  cvr: '',
  phoneNumber: '',
  subscriptionStatus: '',
  invoicingEmail: '',
  termsAcceptedAt: '',
})

export default {
  name: 'SubscriptionForm',
  components: {
    Button,
  },
  props: {
    organization: { type: Object, required: false, default: buildOrganization },
  },
  data() {
    return {
      formData: cloneDeep(this.organization),
      acceptedTerms: false,
    }
  },
  methods: {
    ...mapActions(['showFlashMessage']),
    handleOrganizationSubmit() {
      if (this.formData.invoicingEmail === '') {
        this.showFlashMessage({
          message: 'Udfyld venligst de manglende felter.',
        })
      } else {
        this.$emit('submit', {
          organization: this.formData,
        })
      }
    },
    updateStatus() {
      if (this.acceptedTerms) {
        this.formData.subscriptionStatus = 'active'
        this.formData.termsAcceptedAt = moment.parseZone().utc(2).format()
      }
    },
  },
}
</script>
