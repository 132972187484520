// Data types and legal bases (options for data mapping form)
export const STORE_DATA_TYPES = 'STORE_DATA_TYPES'
export const STORE_LEGAL_BASES = 'STORE_LEGAL_BASES'

// Safety Mesasures
export const STORE_SAFETY_MEASURES = 'STORE_SAFETY_MEASURES'

// Flash message
export const SHOW_FLASH_MESSAGE = 'SHOW_FLASH_MESSAGE'
export const HIDE_FLASH_MESSAGE = 'HIDE_FLASH_MESSAGE'

// Session
export const STORE_SESSION = 'STORE_SESSION'
export const REMOVE_STORE_SESSION = 'REMOVE_STORE_SESSION'

// Resource store
export const UPDATE_RESOURCES = 'UPDATE_RESOURCES'
export const ADD_RESOURCE_REQUEST = 'ADD_RESOURCE_REQUEST'
export const REMOVE_RESOURCE_REQUEST = 'REMOVE_RESOURCE_REQUEST'
export const FLUSH_RESOURCES = 'FLUSH_RESOURCES'
export const FLUSH_RESOURCE_TYPES = 'FLUSH_RESOURCE_TYPES'
export const FLUSH_ALL_RESOURCES = 'FLUSH_ALL_RESOURCES'

// Side bar
export const EXPAND_SIDEBAR = 'EXPAND_SIDEBAR'
export const COLLAPSE_SIDEBAR = 'COLLAPSE_SIDEBAR'

// Form Errors
export const ADD_RESOURCE_RESPONSE_ERRORS = 'ADD_RESOURCE_RESPONSE_ERRORS'
export const REMOVE_RESOURCE_RESPONSE_ERRORS = 'REMOVE_RESOURCE_RESPONSE_ERRORS'

// Return New User
export const ADD_RESOURCE_RESPONSE_USER_DETAILS =
  'ADD_RESOURCE_RESPONSE_USER_DETAILS'

// Modal
export const REGISTER_MODAL = 'REGISTER_MODAL'
export const UNREGISTER_MODAL = 'UNREGISTER_MODAL'
