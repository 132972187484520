<template>
  <div>
    <ResourceHeader
      :back-link="{
        name: 'DataMappingsShow',
        params: { dataMappingId: dataMapping && dataMapping.id },
      }"
      :title="dataMapping && dataMapping.title"
      :timestamp="dataMapping && dataMapping.updatedAt"
      :back-link-text="t('shared.back_to_resource')"
      :title-prefix="t('data_mapping.archive.title')"
    />

    <Loader v-if="!dataMapping" />
    <DataMappingForm
      v-else
      :data-mapping="dataMapping"
      :is-saving="loadingDataMappings"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'
import DataMappingForm from '@/forms/DataMappingForm'
import Loader from '@/components/Loader'
import ResourceHeader from '@/components/ResourceHeader'

export default {
  name: 'DataMappingsEdit',
  components: {
    ResourceHeader,
    DataMappingForm,
    Loader,
  },
  computed: {
    ...mapGetters(['loadingDataMappings']),

    dataMapping() {
      const dataMapping = this.$store.getters.dataMapping
      if (!dataMapping) return dataMapping

      return {
        ...dataMapping,
        systemIds: dataMapping.dataMappingSystems.map(dms => dms.systemId),
        dataProcessorIds: dataMapping.dataMappingDataProcessors.map(
          dmdp => dmdp.dataProcessorId
        ),
      }
    },
  },
  beforeMount() {
    // Ensure that we always start out with a fresh version of the data mapping
    if (this.dataMapping) this.flushDataMappingRelations(this.dataMapping)
    this.fetchDataMapping()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'flushDataMappingRelations',
      'fetchDataMapping',
      'saveDataMapping',
      'showFlashMessage',
      'createTodo',
    ]),

    handleSubmit({ dataMapping, todos }) {
      this.saveDataMapping(dataMapping).then(payload => {
        if (payload instanceof Error) {
          let errors = payload.payload

          this.errors.clear()

          errors.map(error => {
            this.errors.add({ field: error.source.param })
          })

          let translated_error_messages = errors.map(error => {
            return this.t(`data_mapping.error_messages.${error.source.param}`)
          })

          this.showFlashMessage({
            message:
              this.t('flash_messages.missing_fields') +
              '</br>' +
              translated_error_messages.join('</br>'),
          })

          return dataMapping
        }

        router.push({
          name: 'DataMappingsShow',
          params: { dataMappingId: dataMapping.id },
        })
        this.showFlashMessage({
          message:
            this.t('flash_messages.data_mapping') +
            this.t('flash_messages.update.success'),
        })

        if (todos.system) {
          this.createTodo({
            title:
              this.t('flash_messages.custom.data_mapping_system') +
              dataMapping.title,
          })
        }

        if (todos.dataProcessor) {
          this.createTodo({
            title:
              this.t('flash_messages.custom.data_mapping_data_processor') +
              dataMapping.title,
          })
        }

        if (todos.deletionProcedure) {
          this.createTodo({
            title:
              this.t('flash_messages.custom.data_mapping_deletion_procedure') +
              dataMapping.title,
          })
        }
      })
    },
  },
}
</script>
