import ReviewProcessesArchive from '@/pages/ReviewProcessesArchive'
import ReviewProcessesNew from '@/pages/ReviewProcessesNew'
import ReviewProcessesShow from '@/pages/ReviewProcessesShow'
import ReviewProcessesEdit from '@/pages/ReviewProcessesEdit'

const routes = [
  {
    path: 'review-processes',
    component: ReviewProcessesArchive,
    name: 'ReviewProcessesArchive',
  },
  {
    path: 'review-processes/new',
    component: ReviewProcessesNew,
    name: 'ReviewProcessesNew',
  },
  {
    path: 'review-processes/:reviewProcessId',
    component: ReviewProcessesShow,
    name: 'ReviewProcessesShow',
  },
  {
    path: 'review-processes/:reviewProcessId/edit',
    component: ReviewProcessesEdit,
    name: 'ReviewProcessesEdit',
  },
]
export default routes
