var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ArchiveHeader',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_vm._v(" "),(_vm.loading)?_c('Loader'):_c('ContentSection',{staticClass:"fade_in_right"},[_c('ArchiveActionSection',[_c('ArchiveTitle',[_vm._v(_vm._s(_vm.t('data_processor.archive.title')))]),_vm._v(" "),_c('ArchiveCreateButton',{attrs:{"to":{ name: 'DataProcessorsNew' }}})],1),_vm._v(" "),(_vm.filteredDataProcessors('technical').length)?_c('TableWrapper',[_c('div',{staticClass:"wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.t('data_processor.archive.technical')))]),_vm._v(" "),_c('table',[_c('thead',{staticClass:"column-count-2"},[_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-2"},_vm._l((_vm.filteredDataProcessors('technical')),function(dataProcessor){return _c('tr',{key:dataProcessor.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: ("data-processors/" + (dataProcessor.id)) }}},[_vm._v("\n                  "+_vm._s(dataProcessor.name)+"\n                  "),(!_vm.hasAgreementAttachements(dataProcessor))?_c('i',{staticClass:"fa fa-exclamation-triangle RiskWarning"}):_vm._e()])],1),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: ("data-processors/" + (dataProcessor.id)) },"to-edit":{
                    path: ("data-processors/" + (dataProcessor.id) + "/edit"),
                  },"to-review":{
                    path: ("data-processors/" + (dataProcessor.id) + "/reviews"),
                  },"resource":dataProcessor},on:{"delete":_vm.handleDelete}})],1)])}),0)])])]):_vm._e(),_vm._v(" "),(_vm.filteredDataProcessors('other').length)?_c('TableWrapper',[_c('div',{staticClass:"wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.t('data_processor.archive.other')))]),_vm._v(" "),_c('table',[_c('thead',{staticClass:"column-count-2"},[_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-2"},_vm._l((_vm.filteredDataProcessors('other')),function(dataProcessor){return _c('tr',{key:dataProcessor.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: ("data-processors/" + (dataProcessor.id)) }}},[_vm._v("\n                  "+_vm._s(dataProcessor.name)+"\n                  "),(!_vm.hasAgreementAttachements(dataProcessor))?_c('i',{staticClass:"fa fa-exclamation-triangle RiskWarning"}):_vm._e()])],1),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: ("data-processors/" + (dataProcessor.id)) },"to-edit":{
                    path: ("data-processors/" + (dataProcessor.id) + "/edit"),
                  },"to-review":{
                    path: ("data-processors/" + (dataProcessor.id) + "/reviews"),
                  },"resource":dataProcessor},on:{"delete":_vm.handleDelete}})],1)])}),0)])])]):_vm._e(),_vm._v(" "),(_vm.filteredDataProcessors('subprocessor').length)?_c('TableWrapper',[_c('div',{staticClass:"wrapper"},[_c('h2',[_vm._v(_vm._s(_vm.t('data_processor.archive.subprocessor')))]),_vm._v(" "),_c('table',[_c('thead',{staticClass:"column-count-2"},[_c('th'),_vm._v(" "),_c('th')]),_vm._v(" "),_c('tbody',{staticClass:"column-count-2"},_vm._l((_vm.filteredDataProcessors('subprocessor')),function(dataProcessor){return _c('tr',{key:dataProcessor.id},[_c('td',[_c('router-link',{attrs:{"to":{ path: ("data-processors/" + (dataProcessor.id)) }}},[_vm._v("\n                  "+_vm._s(dataProcessor.name)+"\n                  "),(!_vm.hasAgreementAttachements(dataProcessor))?_c('i',{staticClass:"fa fa-exclamation-triangle RiskWarning"}):_vm._e()])],1),_vm._v(" "),_c('td',[_c('ArchiveTableDropdown',{attrs:{"to-view":{ path: ("data-processors/" + (dataProcessor.id)) },"to-edit":{
                    path: ("data-processors/" + (dataProcessor.id) + "/edit"),
                  },"to-review":{
                    path: ("data-processors/" + (dataProcessor.id) + "/reviews"),
                  },"resource":dataProcessor},on:{"delete":_vm.handleDelete}})],1)])}),0)])])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }