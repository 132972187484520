import Vue from 'vue'
import Router from 'vue-router'
import LogInBarrier from '@/components/LogInBarrier'
import LogIn from '@/pages/LogIn'
import OrganizationsArchive from '@/pages/OrganizationsArchive'
import OrganizationsShow from '@/pages/OrganizationsShow'
import UsersNew from '@/pages/UsersNew'
import Subscribe from '@/pages/Subscribe'

import passwordRoutes from './password'
import dashboardRoutes from './dashboard'
import incidentRoutes from './incident'
import requestRoutes from './request'
import documentRoutes from './document'
import dataMappingRoutes from './dataMapping'
import dataProcessorRoutes from './dataProcessor'
import systemRoutes from './system'
import riskAssessmentRoutes from './riskAssessment'
import userRoutes from './user'
import reviewProcessesRoutes from './reviewProcesses'
import organizationRoutes from './organization'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    // Routes outside login barrier
    {
      path: '/log-in',
      name: 'LogIn',
      component: LogIn,
    },
    {
      path: '/user/new',
      name: 'UsersNew',
      component: UsersNew,
    },
    ...passwordRoutes,
    {
      path: '',
      component: LogInBarrier,
      children: [
        // Routes inside login barrier
        {
          path: '',
          component: OrganizationsArchive,
          name: 'OrganizationsArchive',
        },
        {
          path: 'organizations/:organizationId/subscribe',
          component: Subscribe,
          name: 'Subscribe',
        },
        {
          path: 'organizations/:organizationId',
          component: OrganizationsShow,
          children: [
            ...dashboardRoutes,
            ...incidentRoutes,
            ...requestRoutes,
            ...documentRoutes,
            ...dataProcessorRoutes,
            ...systemRoutes,
            ...riskAssessmentRoutes,
            ...dataMappingRoutes,
            ...reviewProcessesRoutes,
            ...userRoutes,
            ...organizationRoutes,
          ],
        },
      ],
    },
  ],
})
