export default (formData, resourceName, answers) => {
  for (let x = 0; x < answers.length; x++) {
    formData.append(
      `${resourceName}[custom_question_answers][][answer]`,
      answers[x].answer
    )
    formData.append(
      `${resourceName}[custom_question_answers][][question_id]`,
      answers[x].question_id
    )
  }
}
