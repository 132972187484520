<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'IncidentsArchive' }"
      :title="incident && incident.title"
      :timestamp="incident && incident.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('incident.archive.title')"
    />

    <Loader v-if="!incident" />
    <IncidentForm
      v-else
      :incident="incident"
      :is-saving="loadingIncidents"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/routes'
import IncidentForm from '@/forms/IncidentForm'
import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'

export default {
  name: 'IncidentsEdit',
  components: { ResourceHeader, IncidentForm, Loader },
  computed: {
    ...mapGetters(['incident', 'loadingIncidents']),
  },
  beforeMount() {
    this.fetchIncident()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'fetchIncident',
      'saveIncident',
      'showFlashMessage',
    ]),
    handleSubmit(incident) {
      this.saveIncident(incident)
        .then(() => {
          router.push({ name: 'IncidentsArchive' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.incident') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.incident') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
