<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'IncidentsArchive' }"
      :title="t('incident.new.title')"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('incident.archive.title')"
    />

    <IncidentForm :incident="$data" @submit="createIncident" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import IncidentForm from '@/forms/IncidentForm'
import ResourceHeader from '@/components/ResourceHeader'

export default {
  name: 'IncidentNew',
  components: {
    ResourceHeader,
    IncidentForm,
  },
  data() {
    return {
      title: '',
      status: 'draft',
      description: '',
      occuredAt: '',
      detectedAt: '',
      stoppedAt: '',
      confidentialityBreach: '',
      availabilityBreach: '',
      integrityBreach: '',
      consequences: '',
      mitigatingActions: '',
      potentialRisk: false,
      potentialHighRisk: false,
      potentialRiskReasoning: '',
      customQuestionAnswers: [],
      physicalBreach: true,
      followUp: false,
      files: [],
    }
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions(['createIncident', 'collapseSidebar']),
  },
}
</script>
