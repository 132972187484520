import Vue from 'vue'
import api from '@/utils/api'
import * as types from '@/store/mutationTypes'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import attachRelations from '@/utils/attachRelations'

const type = 'dataMappings'

const storeGetters = {
  loadingDataMappings: (_state, getters) => getters.isLoading(type),

  dataMapping: (_state, getters) => {
    const dataMapping = getters.resource(type, getters.params.dataMappingId)
    if (!dataMapping) return dataMapping

    return {
      ...attachRelations([
        'dataSources',
        'dataSources.collectedDataTypes',
        'dataRecipients',
        'dataRecipients.sentDataTypes',
        'inHouseViewers',
        'dataMappingSystems',
        'dataMappingSystems.system',
        'dataMappingDataProcessors',
        'dataMappingDataProcessors.dataProcessor',
        'department',
        'inUseSafetyMeasures',
        'customCategory',
        'riskAssessments',
        'dataSubjects',
      ])(dataMapping),
      customQuestionAnswers: getters
        .relations(dataMapping, 'customQuestionAnswers', 'flow')
        .slice()
        .reverse(),
      reviews: getters
        .relations(dataMapping, 'reviews', 'flow')
        .slice()
        .reverse(),
    }
  },

  dataMappings: (_state, getters) => {
    const dataMappings = getters.resources(type)
    return dataMappings.map(attachRelations(['department']))
  },

  dataMappingsForArchive:
    (_state, getters) =>
    ({ search, ascending }) => {
      const loweredSearch = search.toLowerCase()
      const sortDirection = ascending ? 1 : -1

      let archive = getters[type].slice()

      if (search) {
        archive = archive.filter(({ title, content, department }) => {
          const departmentName = Vue.filter('formatDepartment')(department)

          return (
            (title || '').toLowerCase().includes(loweredSearch) ||
            (content || '').toLowerCase().includes(loweredSearch) ||
            departmentName.toLowerCase().includes(loweredSearch)
          )
        })
      }

      archive.sort((a, b) => sortDirection * a.title.localeCompare(b.title))

      return archive
    },
}

const actions = {
  fetchDataMappings: ({ getters: { organizationId } }) => {
    const request = api
      .get(`organizations/${organizationId}/data_mappings`)
      .then(flushTypes(type))

    return wrapResourceRequest(type, request)
  },

  createDataMapping: ({ getters: { organizationId } }, dataMapping) => {
    const params = snakeCaseKeys({ dataMapping })
    const request = api.post(
      `organizations/${organizationId}/data_mappings`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  fetchDataMapping: ({ getters, getters: { organizationId } }, id) => {
    id = id || getters.params.dataMappingId

    const params = {
      include: [
        'in_use_safety_measures',
        'in_house_viewers',
        'data_mapping_systems.system',
        'data_mapping_data_processors.data_processor',
        'data_sources.collected_data_types',
        'data_recipients.sent_data_types',
        'custom_question_answers',
        'custom_category',
        'risk_assessments',
        'reviews',
        'data_subjects',
      ],
    }
    const request = api.get(
      `organizations/${organizationId}/data_mappings/${id}`,
      { params }
    )
    return wrapResourceRequest(type, request)
  },

  saveDataMapping: ({ getters, getters: { organizationId } }, dataMapping) => {
    const params = snakeCaseKeys({ dataMapping })

    const request = api.put(
      `organizations/${organizationId}/data_mappings/${dataMapping.id}`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  deleteDataMapping: ({ getters: { organizationId } }, dataMapping) => {
    const request = api.delete(
      `organizations/${organizationId}/data_mappings/${dataMapping.id}`
    )
    return wrapResourceRequest(type, request)
  },

  flushDataMappingRelations: ({ commit, getters }, dataMapping) => {
    // Direct relations
    const dataSources = getters.relations(dataMapping, 'dataSources')
    const dataRecipients = getters.relations(dataMapping, 'dataRecipients')
    const dataMappingSystems = getters.relations(
      dataMapping,
      'dataMappingSystems'
    )
    const dataMappingDataProcessors = getters.relations(
      dataMapping,
      'dataMappingDataProcessors'
    )
    const inHouseViewers = getters.relations(dataMapping, 'inHouseViewers')
    const safetyMeasures = getters.relations(dataMapping, 'inUseSafetyMeasures')
    const customQuestionAnswers = getters
      .relations(dataMapping, 'customQuestionAnswers', 'flow')
      .slice()
      .reverse()
    const reviews = getters
      .relations(dataMapping, 'reviews', 'flow')
      .slice()
      .reverse()
    const customCategory = getters.relations(dataMapping, 'customCategory')
    const riskAssessments = getters.relations(dataMapping, 'riskAssessments')
    const customCategories = getters.resources('customCategories')
    const dataSubjects = getters.relations(dataMapping, 'dataSubjects')

    // Nested relations
    const collectedDataTypes = dataSources.reduce(
      (records, dataSource) =>
        records.concat(getters.relations(dataSource, 'collectedDataTypes')),
      []
    )

    const sentDataTypes = dataRecipients.reduce(
      (records, dataRecipient) =>
        records.concat(getters.relations(dataRecipient, 'sentDataTypes')),
      []
    )

    // Flush all
    commit(types.FLUSH_RESOURCES, [
      ...sentDataTypes,
      ...collectedDataTypes,
      ...dataSources,
      ...dataRecipients,
      ...dataMappingSystems,
      ...dataMappingDataProcessors,
      ...inHouseViewers,
      ...safetyMeasures,
      ...customQuestionAnswers,
      ...customCategory,
      ...riskAssessments,
      ...reviews,
      ...customCategories,
      ...dataSubjects,
    ])
  },
}

export default {
  actions,
  getters: storeGetters,
}
