<template>
  <div>
    <StepNavigator v-if="!isEmpty" :step-names="stepNames" />
    <slot />
    <FormControls
      :cancel-link="cancelLink"
      :hide-save="isActiveStepIsolated"
      @finalize="handleFinalize"
    />
  </div>
</template>

<script>
import FormControls from '@/components/FormControls/FormControlsSaveClose'
import StepNavigator from '@/components/StepNavigator'

export default {
  name: 'FlowSaveClose',
  components: {
    FormControls,
    StepNavigator,
  },
  props: {
    cancelLink: { type: Object, required: false },
    stepNames: { type: Array },
  },
  data() {
    return {
      currentStepIndex: 0,
      steps: [],
    }
  },
  computed: {
    isEmpty() {
      return this.steps.length === 0
    },
    isActiveStepIsolated() {
      const activeStep = this.getActiveStepObject()
      if (activeStep === null) return false
      return activeStep.stepIsolated
    },
  },
  methods: {
    handleFinalize() {
      this.$emit('finalize')
    },
    addStep(step) {
      this.steps.push(step)
    },
    getActiveStep() {
      if (this.steps.length === 0) return null
      return this.steps[this.currentStepIndex].stepName
    },
    getActiveStepObject() {
      if (this.steps.length === 0) return null
      return this.steps[this.currentStepIndex]
    },
    getStepByName(stepName) {
      if (this.steps.length === 0) return null
      return this.steps.find(step => step.stepName === stepName) || null
    },
    getStepRequiredFieldsByName() {
      return []
    },
    goToStep(stepName) {
      this.currentStepIndex =
        this.steps.indexOf(this.getStepByName(stepName)) || 0
    },
    isOptionalStep(stepName) {
      return this.getStepByName(stepName) === null
    },
    hasNextStep() {
      return false
    },
    hasPreviousStep() {
      return false
    },
    removeStep(step) {
      this.steps.push(step)
    },
    stepIsActive(step) {
      return this.steps[this.currentStepIndex] === step
    },
  },
  provide() {
    return {
      addStep: this.addStep,
      getActiveStep: this.getActiveStep,
      getStepRequiredFieldsByName: this.getStepRequiredFieldsByName,
      goToStep: this.goToStep,
      hasNextStep: this.hasNextStep,
      hasPreviousStep: this.hasPreviousStep,
      isOptionalStep: this.isOptionalStep,
      removeStep: this.removeStep,
      stepIsActive: this.stepIsActive,
    }
  },
}
</script>
