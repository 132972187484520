<template>
  <div v-if="active">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Step',
  inject: [
    'addStep',
    'removeStep',
    'stepIsActive',
    'hasPreviousStep',
    'hasNextStep',
  ],
  props: {
    stepName: { type: String },
    stepIsolated: { type: Boolean, defaultValue: false },
    stepRequiredFields: { type: Array, optional: true },
  },
  computed: {
    active() {
      return this.stepIsActive(this)
    },
  },
  mounted() {
    this.addStep(this)
  },
  destroyed() {
    this.removeStep(this)
  },
  requiredFields() {
    return this.$attrs.stepRequiredFields || []
  },
  provide() {
    return {
      hasPreviousStep: this.hasPreviousStep,
      hasNextStep: this.hasNextStep,
    }
  },
}
</script>
