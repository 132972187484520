<template>
  <div class="ArchiveTitle">
    <slot />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'ArchiveTitle',
  props: {
    type: { type: String },
    disabled: { type: Boolean, default: false },
    size: { type: String, default: 'lg' },
  },
}
</script>
