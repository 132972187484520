<template>
  <Modal
    :title="
      isNew
        ? t('data_processor.show.new_data_processor_agreement.title')
        : formData.title
    "
  >
    <ModalCloseButton :to="{ name: 'DataProcessorsShow' }" />

    <Field
      v-model="formData.title"
      fill
      type="text"
      :placeholder="
        t('data_processor.show.new_data_processor_agreement.title_placeholder')
      "
    />

    <Field
      v-model="formData.link"
      fill
      type="text"
      :placeholder="
        t('data_processor.show.new_data_processor_agreement.link_placeholder')
      "
    />

    <FieldSubHeader>{{
      t('data_processor.show.new_data_processor_agreement.files')
    }}</FieldSubHeader>
    <FileUpload
      :existing-files="existingFiles"
      @onFilesChange="onFilesChange"
    />

    <FieldSubHeader>{{
      t(
        'data_processor.show.new_data_processor_agreement.associated_systems_question'
      )
    }}</FieldSubHeader>
    <CheckboxField
      v-for="system in systems"
      :key="system.id"
      v-model="formData.systemIds"
      :value="system.id"
      size="sm"
    >
      {{ system.name }}
    </CheckboxField>

    <ModalSaveButton @click="$emit('submit', formData)">
      {{
        isNew
          ? t('data_processor.show.new_data_processor_agreement.submit')
          : t('data_processor.show.edit_data_processor_agreement.submit')
      }}
    </ModalSaveButton>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import { cloneDeep } from 'lodash'

import Modal from '@/components/Modal'
import ModalCloseButton from '@/components/ModalCloseButton'
import ModalSaveButton from '@/components/ModalSaveButton'
import Field from '@/components/Field'
import FieldSubHeader from '@/components/FieldSubHeader'
import FileUpload from '@/components/FileUpload'
import CheckboxField from '@/components/CheckboxField'

export default {
  name: 'DataProcessingAgreementForm',
  components: {
    Modal,
    ModalCloseButton,
    ModalSaveButton,
    Field,
    FieldSubHeader,
    FileUpload,
    CheckboxField,
  },
  props: {
    dataProcessingAgreement: VueTypes.shape({
      id: String,
      title: String,
      link: String,
      files: VueTypes.any,
      systemIds: VueTypes.arrayOf(String).isRequired,
    }).loose,
    isSaving: VueTypes.bool.isRequired,
  },

  data() {
    return {
      formData: cloneDeep(this.dataProcessingAgreement),
      existingFiles: this.dataProcessingAgreement.files || [],
    }
  },
  computed: {
    ...mapGetters(['systems']),

    isNew() {
      return !this.dataProcessingAgreement.id
    },
  },
  mounted() {
    this.fetchSystems()
  },
  methods: {
    ...mapActions(['fetchSystems']),

    onFilesChange(files) {
      this.formData.files = files
    },
  },
}
</script>
