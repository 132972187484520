<template>
  <div class="OrganizationsExports">
    <Settings>
      <h4>{{ t('organization.exports.tabs.full_report') }}</h4>
      <br />
      <TabsWrapper>
        <div
          :class="{ active: activeTab === 'fullReport' }"
          class="tab"
          @click="activeTab = 'fullReport'"
        >
          <p>{{ t('organization.exports.tabs.full_report') }}</p>
        </div>
        <div
          :class="{ active: activeTab === 'dataMapping' }"
          class="tab"
          @click="activeTab = 'dataMapping'"
        >
          <p>{{ t('organization.exports.tabs.data_mapping') }}</p>
        </div>
        <div
          :class="{ active: activeTab === 'sessions' }"
          class="tab"
          @click="activeTab = 'sessions'"
        >
          <p>{{ t('organization.exports.tabs.login_history') }}</p>
        </div>
        <div
          :class="{ active: activeTab === 'changelog' }"
          class="tab"
          @click="activeTab = 'changelog'"
        >
          <p>{{ t('organization.exports.tabs.activity_log') }}</p>
        </div>
        <div
          :class="{ active: activeTab === 'reviews' }"
          class="tab"
          @click="activeTab = 'reviews'"
        >
          <p>{{ t('organization.exports.tabs.reviews') }}</p>
        </div>
      </TabsWrapper>

      <TableContainer v-if="activeTab === 'fullReport'">
        <div class="OrganizationsExports__head-content">
          <h3>
            {{ t('organization.exports.full_report.title') }}
          </h3>
          <p>
            {{ t('organization.exports.full_report.description') }}
          </p>
          <br />
          <FormSelect v-model="exportLocale">
            <option
              v-for="locale in locales"
              :key="locale.value"
              :value="locale.value"
            >
              {{ t('shared.navigation.language.' + locale.translation_key) }}
            </option>
          </FormSelect>
          <div>
            <br />
            <Button
              type="primary"
              size="md"
              @click="handleExportCreateClick('all', exportLocale)"
            >
              {{ t('organization.exports.full_report.export_button') }}
            </Button>
          </div>
        </div>
        <table class="OrganizationsExports__table">
          <thead>
            <tr>
              <th />
              <th>{{ t('organization.exports.date') }}</th>
              <th>{{ t('organization.exports.created_at') }}</th>
              <th>{{ t('shared.navigation.language.title') }}</th>
              <th>{{ t('organization.exports.links') }}</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr v-for="resource in filteredExports('all')" :key="resource.id">
              <td>
                <span v-if="resource.generatedAt"
                  ><i class="fa fa-check"
                /></span>
                <span v-else-if="!resource.generationFailedAt"
                  ><i class="fa fa-refresh fa-spin"
                /></span>
                <span v-else>
                  <HelpTooltip tooltip-key="export.failed">
                    <i class="fa fa-exclamation-triangle" />
                  </HelpTooltip>
                </span>
              </td>
              <td class="OrganizationsExports__date">
                {{ resource.createdAt | formatDateTimeHuman }}
              </td>
              <td>{{ resource.user && resource.user.name }}</td>
              <td>{{ t('shared.navigation.language.' + resource.locale) }}</td>
              <td>
                <template v-if="resource.generatedAt">
                  <a :href="resource.pdfUrl" target="_blank">
                    <i class="fa-regular fa-file-pdf" />
                    <span class="OrganizationsExports__result-name"
                      >PDF</span
                    > </a
                  >,
                  <a :href="resource.zipUrl">
                    <i class="fa-regular fa-file-zipper" />
                    <span class="OrganizationsExports__result-name">ZIP</span>
                  </a>
                </template>
                <template v-else> - </template>
              </td>
              <td>
                <button
                  :data-id="resource.id"
                  class="OrganizationsExports__delete"
                  @click="handleExportDeleteClick"
                >
                  {{ t('organization.exports.delete') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>

      <TableContainer v-else-if="activeTab === 'sessions'">
        <div class="OrganizationsExports__head-content">
          <h3>
            {{ t('organization.exports.login_history.title') }}
          </h3>
          <p>
            {{ t('organization.exports.login_history.description') }}
          </p>
          <Button
            type="primary"
            size="md"
            @click="handleExportCreateClick('sessions')"
          >
            {{ t('organization.exports.login_history.export_button') }}
          </Button>
        </div>
        <table class="OrganizationsExports__table">
          <thead>
            <tr>
              <th />
              <th>{{ t('organization.exports.date') }}</th>
              <th>{{ t('organization.exports.created_at') }}</th>
              <th>{{ t('organization.exports.links') }}</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="resource in filteredExports('session')"
              :key="resource.id"
            >
              <td>
                <span v-if="resource.generatedAt"
                  ><i class="fa fa-check"
                /></span>
                <span v-else-if="!resource.generationFailedAt"
                  ><i class="fa fa-refresh fa-spin"
                /></span>
                <span v-else>
                  <HelpTooltip tooltip-key="export.failed">
                    <i class="fa fa-exclamation-triangle" />
                  </HelpTooltip>
                </span>
              </td>
              <td class="OrganizationsExports__date">
                {{ resource.createdAt | formatDateTimeHuman }}
              </td>
              <td>{{ resource.user && resource.user.name }}</td>
              <td>
                <template v-if="resource.generatedAt">
                  <a :href="resource.csvUrl" target="_blank">
                    <i class="fa-regular fa-file-excel" />
                    <span class="OrganizationsExports__result-name">CSV</span>
                  </a>
                </template>
                <template v-else> - </template>
              </td>
              <td>
                <button
                  :data-id="resource.id"
                  class="OrganizationsExports__delete"
                  @click="handleExportDeleteClick"
                >
                  {{ t('organization.exports.delete') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>

      <TableContainer v-else-if="activeTab === 'dataMapping'">
        <div class="OrganizationsExports__head-content">
          <h3>
            {{ t('organization.exports.data_mapping.title') }}
          </h3>
          <p>
            {{ t('organization.exports.data_mapping.description') }}
          </p>
          <br />
          <FormSelect v-model="exportFilter">
            <option
              v-for="filter in filters"
              :key="filter.value"
              :value="filter.value"
            >
              {{
                t(
                  'organization.exports.data_mapping.filters.' +
                    filter.translation_key
                )
              }}
            </option>
          </FormSelect>
          <FormSelect v-model="exportLocale">
            <option
              v-for="locale in locales"
              :key="locale.value"
              :value="locale.value"
            >
              {{ t('shared.navigation.language.' + locale.translation_key) }}
            </option>
          </FormSelect>
          <div>
            <br />
            <Button
              type="primary"
              size="md"
              @click="
                handleExportCreateClick(
                  'dataMapping',
                  exportLocale,
                  exportFilter
                )
              "
            >
              {{ t('organization.exports.data_mapping.export_button') }}
            </Button>
          </div>
        </div>
        <table class="OrganizationsExports__table">
          <thead>
            <tr>
              <th />
              <th>{{ t('organization.exports.date') }}</th>
              <th>{{ t('organization.exports.created_at') }}</th>
              <th>{{ t('shared.navigation.language.title') }}</th>
              <th>
                {{ t('organization.exports.data_mapping.filters.title') }}
              </th>
              <th>{{ t('organization.exports.links') }}</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="resource in filteredExports('dataMapping')"
              :key="resource.id"
            >
              <td>
                <span v-if="resource.generatedAt"
                  ><i class="fa fa-check"
                /></span>
                <span v-else-if="!resource.generationFailedAt"
                  ><i class="fa fa-refresh fa-spin"
                /></span>
                <span v-else>
                  <HelpTooltip tooltip-key="export.failed">
                    <i class="fa fa-exclamation-triangle" />
                  </HelpTooltip>
                </span>
              </td>
              <td class="OrganizationsExports__date">
                {{ resource.createdAt | formatDateTimeHuman }}
              </td>
              <td>{{ resource.user && resource.user.name }}</td>
              <td>{{ t('shared.navigation.language.' + resource.locale) }}</td>
              <td>
                {{
                  t(
                    'organization.exports.data_mapping.filters.' +
                      resource.filter
                  )
                }}
              </td>
              <td>
                <template v-if="resource.generatedAt">
                  <a :href="resource.pdfUrl" target="_blank">
                    <i class="fa-regular fa-file-pdf" />
                    <span class="OrganizationsExports__result-name"
                      >PDF</span
                    > </a
                  >,
                  <a :href="resource.zipUrl">
                    <i class="fa-regular fa-file-zipper" />
                    <span class="OrganizationsExports__result-name">ZIP</span>
                  </a>
                </template>
                <template v-else> - </template>
              </td>
              <td>
                <button
                  :data-id="resource.id"
                  class="OrganizationsExports__delete"
                  @click="handleExportDeleteClick"
                >
                  {{ t('organization.exports.delete') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>

      <TableContainer v-else-if="activeTab === 'changelog'">
        <div class="OrganizationsExports__head-content">
          <h3>
            {{ t('organization.exports.activity_log.title') }}
          </h3>
          <p>
            {{ t('organization.exports.activity_log.description') }}
          </p>
          <Button
            type="primary"
            size="md"
            @click="handleExportCreateClick('changelog')"
          >
            {{ t('organization.exports.activity_log.export_button') }}
          </Button>
        </div>
        <table class="OrganizationsExports__table">
          <thead>
            <tr>
              <th />
              <th>{{ t('organization.exports.date') }}</th>
              <th>{{ t('organization.exports.created_at') }}</th>
              <th>{{ t('organization.exports.links') }}</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="resource in filteredExports('changelog')"
              :key="resource.id"
            >
              <td>
                <span v-if="resource.generatedAt"
                  ><i class="fa fa-check"
                /></span>
                <span v-else-if="!resource.generationFailedAt"
                  ><i class="fa fa-refresh fa-spin"
                /></span>
                <span v-else>
                  <HelpTooltip tooltip-key="export.failed">
                    <i class="fa fa-exclamation-triangle" />
                  </HelpTooltip>
                </span>
              </td>
              <td class="OrganizationsExports__date">
                {{ resource.createdAt | formatDateTimeHuman }}
              </td>
              <td>{{ resource.user && resource.user.name }}</td>
              <td>
                <template v-if="resource.generatedAt">
                  <a :href="resource.csvUrl" target="_blank">
                    <i class="fa-regular fa-file-excel" />
                    <span class="OrganizationsExports__result-name">CSV</span>
                  </a>
                </template>
                <template v-else> - </template>
              </td>
              <td>
                <button
                  :data-id="resource.id"
                  class="OrganizationsExports__delete"
                  @click="handleExportDeleteClick"
                >
                  {{ t('organization.exports.delete') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>

      <TableContainer v-else>
        <div class="OrganizationsExports__head-content">
          <h3>
            {{ t('organization.exports.reviews.title') }}
          </h3>
          <p>
            {{ t('organization.exports.reviews.description') }}
          </p>
          <br />
          <FormSelect v-model="exportLocale">
            <option
              v-for="locale in locales"
              :key="locale.value"
              :value="locale.value"
            >
              {{ t('shared.navigation.language.' + locale.translation_key) }}
            </option>
          </FormSelect>
          <div>
            <br />
            <Button
              type="primary"
              size="md"
              @click="handleExportCreateClick('reviews', exportLocale)"
            >
              {{ t('organization.exports.reviews.export_button') }}
            </Button>
          </div>
        </div>
        <table class="OrganizationsExports__table">
          <thead>
            <tr>
              <th />
              <th>{{ t('organization.exports.date') }}</th>
              <th>{{ t('organization.exports.created_at') }}</th>
              <th>{{ t('shared.navigation.language.title') }}</th>
              <th>{{ t('organization.exports.links') }}</th>
              <th />
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="resource in filteredExports('reviews')"
              :key="resource.id"
            >
              <td>
                <span v-if="resource.generatedAt"
                  ><i class="fa fa-check"
                /></span>
                <span v-else-if="!resource.generationFailedAt"
                  ><i class="fa fa-refresh fa-spin"
                /></span>
                <span v-else>
                  <HelpTooltip tooltip-key="export.failed">
                    <i class="fa fa-exclamation-triangle" />
                  </HelpTooltip>
                </span>
              </td>
              <td class="OrganizationsExports__date">
                {{ resource.createdAt | formatDateTimeHuman }}
              </td>
              <td>{{ resource.user && resource.user.name }}</td>
              <td>{{ t('shared.navigation.language.' + resource.locale) }}</td>
              <td>
                <template v-if="resource.generatedAt">
                  <a :href="resource.pdfUrl" target="_blank">
                    <i class="fa-regular fa-file-pdf" />
                    <span class="OrganizationsExports__result-name"
                      >PDF</span
                    > </a
                  >,
                  <a :href="resource.zipUrl">
                    <i class="fa-regular fa-file-zipper" />
                    <span class="OrganizationsExports__result-name">ZIP</span>
                  </a>
                </template>
                <template v-else> - </template>
              </td>
              <td>
                <button
                  :data-id="resource.id"
                  class="OrganizationsExports__delete"
                  @click="handleExportDeleteClick"
                >
                  {{ t('organization.exports.delete') }}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </TableContainer>
    </Settings>
  </div>
</template>

<script>
import './style.scss'

import { mapGetters, mapActions } from 'vuex'
import Settings from '@/components/Settings'
import Button from '@/components/Button'
import TableContainer from '@/components/TableContainer'
import HelpTooltip from '@/components/HelpTooltip'
import TabsWrapper from '@/components/TabsWrapper'
import FormSelect from '@/components/FormSelect'

export default {
  name: 'OrganizationsExports',
  components: {
    Settings,
    Button,
    TableContainer,
    HelpTooltip,
    TabsWrapper,
    FormSelect,
  },
  data() {
    return {
      activeTab: 'fullReport',
      exportLocale: 'da',
      exportFilter: 'all',
    }
  },
  computed: {
    ...mapGetters(['exports', 'loadingExports']),

    loading: function () {
      return this.exports.length === 0 && this.loadingExports
    },
    locales() {
      return [
        { value: 'da', translation_key: 'danish' },
        { value: 'en', translation_key: 'english' },
        { value: 'de', translation_key: 'german' },
        { value: 'fr', translation_key: 'french' },
        { value: 'pl', translation_key: 'polish' },
      ]
    },
    filters() {
      return [
        { value: 'all', translation_key: 'all' },
        { value: 'article_30', translation_key: 'article_30' },
      ]
    },
  },
  mounted() {
    this.collapseSidebar()
    this.fetchExports()
  },
  updated() {
    if (this.exports.find(e => !e.generatedAt && !e.generationFailedAt)) {
      this.scheduleRefetch()
    } else {
      this.cancelScheduledRefetch()
    }
  },
  methods: {
    ...mapActions([
      'fetchExports',
      'collapseSidebar',
      'createExport',
      'deleteExport',
      'showFlashMessage',
    ]),

    filteredExports(dataType) {
      const filteredExports = this.exports.filter(function (exp) {
        return exp.dataType.match(dataType)
      })

      return filteredExports
    },

    scheduleRefetch() {
      this.cancelScheduledRefetch()
      this.refetchTimeout = setTimeout(() => {
        this.fetchExports()
      }, 5000)
    },

    cancelScheduledRefetch() {
      clearTimeout(this.refetchTimeout)
    },

    handleExportCreateClick(dataType, exportLocale, exportFilter = null) {
      this.createExport({
        type: dataType,
        locale: exportLocale,
        filter: exportFilter,
      })
        .then(() =>
          this.showFlashMessage({
            message:
              this.t('flash_messages.export') +
              this.t('flash_messages.create.success'),
          })
        )
        .catch(() =>
          this.showFlashMessage({
            message:
              this.t('flash_messages.export') +
              this.t('flash_messages.create.error'),
          })
        )
    },

    handleExportDeleteClick({ target }) {
      if (confirm(this.t('flash_messages.custom.export_confirm'))) {
        this.deleteExport({ id: target.dataset.id })
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.export') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.export') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
  },
}
</script>
