<template>
  <MitigationMeasureForm
    :mitigation-measure="mitigationMeasure"
    :is-saving="loadingMitigationMeasures"
    @submit="handleSubmit"
  />
</template>

<script>
import router from '@/routes'
import MitigationMeasureForm from '@/forms/MitigationMeasureForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MitigationMeasuresNew',
  components: {
    MitigationMeasureForm,
  },
  data() {
    return {
      mitigationMeasure: {
        title: '',
        description: '',
        probability: 'none',
        consequences: 'none',
      },
    }
  },
  computed: {
    ...mapGetters(['loadingMitigationMeasures']),
  },
  methods: {
    ...mapActions(['createMitigationMeasure', 'showFlashMessage']),

    handleSubmit(mitigationMeasure) {
      this.createMitigationMeasure(mitigationMeasure)
        .then(() => {
          router.push({ name: 'RiskAssessmentsShow' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.mitigation_measure') +
              this.t('flash_messages.create.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.mitigation_measure') +
              this.t('flash_messages.create.error'),
          })
        })
    },
  },
}
</script>
