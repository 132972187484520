<template>
  <ThinButton>
    {{ title }}
    <template v-slot:image>
      <img src="./assets/plus.svg" style="width: 10px" />
    </template>
  </ThinButton>
</template>

<script>
import ThinButton from '@/components/ThinButton'

export default {
  name: 'AddNewButton',
  components: {
    ThinButton,
  },
  props: {
    title: { type: String },
  },
}
</script>
