<template>
  <ContentSection>
    <Steps
      :step-names="[
        t('incident.steps.data_breach_type'),
        t('incident.steps.responsible'),
        t('incident.steps.when'),
        t('incident.steps.consequences'),
        t('incident.steps.other'),
        t('incident.steps.risk'),
      ]"
    >
      <Step :step-name="t('incident.steps.data_breach_type')">
        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.data_breach_type_question') }}
          </FieldHeader>

          <RadioField v-model="formData.physicalBreach" :value="true">
            {{ t('incident.form.physical_breach') }}
          </RadioField>

          <RadioField v-model="formData.physicalBreach" :value="false">
            {{ t('incident.form.technical_breach') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.incident_type_question') }}
            <HelpTooltip tooltip-key="incident.breachKind" />
          </FieldHeader>

          <CheckboxField v-model="formData.confidentialityBreach">
            {{ t('incident.archive.private') }}
          </CheckboxField>

          <CheckboxField v-model="formData.availabilityBreach">
            {{ t('incident.archive.availability') }}
          </CheckboxField>

          <CheckboxField v-model="formData.integrityBreach">
            {{ t('incident.archive.integrity') }}
          </CheckboxField>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.issue_question') }}
            <HelpTooltip tooltip-key="incident.description" />
          </FieldHeader>
          <Field
            v-model="formData.title"
            v-validate="'required'"
            :error="errors.first('title')"
            :placeholder="t('incident.form.title_placeholder')"
            name="title"
            type="text"
          />
          <Field
            v-model="formData.description"
            type="textarea"
            :placeholder="t('shared.form.description_placeholder')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('incident.steps.responsible')">
        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.responsible_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.responsibleId">
            <option :value="null">
              {{ t('shared.form.select_responsible_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('incident.form.responsible_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.executing_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.executingId">
            <option :value="null">
              {{ t('shared.form.select_executing_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('incident.form.executing_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('incident.steps.when')">
        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.when_question') }}
            <HelpTooltip tooltip-key="incident.occuredAt" />
          </FieldHeader>
          <DatePicker
            v-model="formData.occuredAt"
            :max-date-validator="true"
            :placeholder="t('shared.form.select_date_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.realized_question') }}
            <HelpTooltip tooltip-key="incident.detectedAt" />
          </FieldHeader>
          <DatePicker
            v-model="formData.detectedAt"
            :max-date-validator="true"
            :placeholder="t('shared.form.select_date_placeholder')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('incident.steps.consequences')">
        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.consequence_question') }}
            <HelpTooltip tooltip-key="incident.consequences" />
          </FieldHeader>
          <Field
            v-model="formData.consequences"
            type="textarea"
            :placeholder="t('incident.form.consequence_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.measures_taken_question') }}
            <HelpTooltip tooltip-key="incident.mitigatingActions" />
          </FieldHeader>
          <Field
            v-model="formData.mitigatingActions"
            type="textarea"
            :placeholder="t('incident.form.measures_taken_placeholder')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step v-if="questions.length" :tep-name="t('incident.steps.other')">
        <FieldContainer v-for="question in questions" :key="question.id">
          <FieldHeader>{{ question.question }}</FieldHeader>
          <Field
            :placeholder="question.placeholder"
            :value="findOrBuildQuestionAnswer(question.id).answer"
            name="title"
            type="text"
            @input="
              value => {
                updateQuestionAnswer(question.id, value)
              }
            "
          />
        </FieldContainer>
        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('incident.steps.risk')">
        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.incident_stopped_question') }}
            <HelpTooltip tooltip-key="incident.stoppedAt" />
          </FieldHeader>
          <DatePicker
            v-model="formData.stoppedAt"
            :max-date-validator="true"
            :placeholder="t('shared.form.select_date_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.risk_probability_question') }}
            <HelpTooltip tooltip-key="incident.potentialRisk" />
          </FieldHeader>

          <RadioField v-model="formData.potentialRisk" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.potentialRisk" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer v-if="formData.potentialRisk">
          <FieldHeader>
            {{ t('incident.form.high_risk_question') }}
            <HelpTooltip tooltip-key="incident.potentialHighRisk" />
          </FieldHeader>

          <RadioField v-model="formData.potentialHighRisk" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.potentialHighRisk" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>

          <FieldDisclaimer v-if="formData.potentialHighRisk">
            {{ t('incident.form.high_risk_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-else>
            {{ t('incident.form.risk_help_text') }}
          </FieldDisclaimer>
        </FieldContainer>

        <FieldContainer v-if="formData.potentialRisk === false">
          <FieldHeader>
            {{ t('incident.form.assessment_explanation_question') }}
            <HelpTooltip tooltip-key="incident.potentialRiskReasoning" />
          </FieldHeader>
          <Field
            v-model="formData.potentialRiskReasoning"
            type="textarea"
            :placeholder="t('incident.form.assessment_explanation_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.follow_up_question') }}
            <HelpTooltip tooltip-key="incident.potentialRisk" />
          </FieldHeader>

          <RadioField v-model="formData.followUp" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.followUp" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer v-if="formData.followUp === true">
          <FieldHeader>{{
            t('incident.form.follow_up_necessary_question')
          }}</FieldHeader>
          <Field
            v-model="formData.followUpDescription"
            type="textarea"
            :placeholder="t('shared.form.description_placeholder')"
          />
        </FieldContainer>

        <FieldContainer v-else>
          <FieldHeader>{{
            t('incident.form.follow_up_not_necessary_question')
          }}</FieldHeader>
          <Field
            v-model="formData.noFollowUpDescription"
            type="textarea"
            :placeholder="t('shared.form.description_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('incident.form.incident_completed_question') }}
          </FieldHeader>

          <RadioField v-model="formData.completed" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.completed" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>{{ t('incident.form.upload_question') }}</FieldHeader>
          <FileUpload
            :existing-files="existingFiles"
            @onFilesChange="onFilesChange"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>
    </Steps>
  </ContentSection>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VueTypes from 'vue-types'
import { cloneDeep } from 'lodash'
import { Steps, Step } from '@/components/Steps'
import DatePicker from '@/components/DatePicker'
import HelpTooltip from '@/components/HelpTooltip'
import ContentSection from '@/components/ContentSection'
import FormControls from '@/components/FormControls'
import FieldContainer from '@/components/FieldContainer'
import FieldHeader from '@/components/FieldHeader'
import FieldDisclaimer from '@/components/FieldDisclaimer'
import Field from '@/components/Field'
import FormSelect from '@/components/FormSelect'
import CheckboxField from '@/components/CheckboxField'
import RadioField from '@/components/RadioField'
import withCustomQuestions from '@/mixins/withCustomQuestions'
import FileUpload from '@/components/FileUpload'

export default {
  name: 'IncidentsForm',
  components: {
    DatePicker,
    HelpTooltip,
    Steps,
    Step,
    ContentSection,
    FormControls,
    FieldContainer,
    FieldHeader,
    FieldDisclaimer,
    Field,
    CheckboxField,
    RadioField,
    FileUpload,
    FormSelect,
  },
  mixins: [withCustomQuestions],
  props: {
    incident: VueTypes.shape({
      id: String,
      title: VueTypes.oneOfType([String, null]),
      description: VueTypes.oneOfType([String, null]),
      occured_at: VueTypes.oneOfType([String, null]),
      detected_at: VueTypes.oneOfType([String, null]),
      stopped_at: VueTypes.oneOfType([String, null]),
      confidentiality_breach: VueTypes.oneOfType([Boolean, null]),
      availability_breach: VueTypes.oneOfType([Boolean, null]),
      integrity_breach: VueTypes.oneOfType([Boolean, null]),
      consequences: VueTypes.oneOfType([String, null]),
      mitigating_actions: VueTypes.oneOfType([String, null]),
      potential_risk: VueTypes.oneOfType([Boolean, null]),
      potential_high_risk: VueTypes.oneOfType([Boolean, null]),
      physical_breach: VueTypes.oneOfType([Boolean, null]),
      potential_risk_reasoning: VueTypes.oneOfType([String, null]),
      customQuestionAnswers: VueTypes.oneOfType([Array, null]),
      follow_up: VueTypes.oneOfType([String, null]),
      follow_up_description: VueTypes.oneOfType([String, null]),
      no_follow_up_description: VueTypes.oneOfType([String, null]),
      files: VueTypes.oneOfType([Array, null]),
    }).loose,
  },
  data() {
    return {
      formData: cloneDeep(this.incident),
      existingFiles: this.incident.files,
      cancelLink: { name: 'IncidentsArchive' },
    }
  },
  computed: {
    ...mapGetters(['questions', 'users']),

    isNew() {
      return !this.incident.id
    },
  },
  watch: {
    incident: function (newIncident) {
      this.formData = cloneDeep(newIncident)
    },
  },
  mounted() {
    this.fetchCustomQuestions('incidents')
    this.observeScroll()
  },
  methods: {
    ...mapActions(['fetchCustomQuestions', 'observeScroll']),
    save() {
      this.$validator
        .validateAll()
        .then(() => {
          this.$emit('submit', this.formData)
        })
        .catch(() => {
          console.log('errors exist', this.errors)
        })
    },
    onFilesChange(files) {
      this.formData.files = files
    },
  },
}
</script>
