import store from '@/store'

const attachRelations =
  (includeArray = []) =>
  model => {
    if (!model) return model

    const result = { ...model }

    const nestedIncludes = {}
    const rootIncludes = []

    // Determine root includes and nested includes
    includeArray.forEach(include => {
      const dotIndex = include.indexOf('.')

      if (dotIndex !== -1) {
        const root = include.substr(0, dotIndex)
        nestedIncludes[root] = nestedIncludes[root] || []
        nestedIncludes[root].push(include.substr(dotIndex + 1))
        rootIncludes.push(root)
      } else if (rootIncludes.indexOf(include) === -1) {
        rootIncludes.push(include)
      }
    })

    // Add each root include
    rootIncludes.forEach(include => {
      // If the model has a foreign key for the include, create a single relation (belongs to)
      if (model[`${include}Id`] !== undefined) {
        const relation = store.getters.relation(model, include)

        // Recursively add nested includes
        result[include] = attachRelations(nestedIncludes[include])(relation)
      }
      // Otherwise, assume that the relations is an array (has many)
      else {
        const relations = store.getters.relations(model, include)

        // Recursively add nested includes
        result[include] = relations.map(relation =>
          attachRelations(nestedIncludes[include])(relation)
        )
      }
    })

    return result
  }

export default attachRelations
