<template>
  <div class="ResourceActions">
    <slot />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'ResourceActions',
}
</script>
