<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'DocumentsArchive' }"
      :title="t('document.new.title')"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('document.archive.title')"
    />

    <DocumentForm :document="$data" @submit="createDocument" />
  </div>
</template>

<script>
import ResourceHeader from '@/components/ResourceHeader'
import DocumentForm from '@/forms/DocumentForm'
import { mapActions } from 'vuex'

export default {
  name: 'DocumentsNew',
  components: {
    ResourceHeader,
    DocumentForm,
  },
  data() {
    return {
      title: '',
      departmentId: '',
      nextRevisionDate: '',
      content: '',
      files: [],
      customQuestionAnswers: [],
      intervalDays: 30,
    }
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions(['createDocument', 'collapseSidebar']),
  },
}
</script>
