<template>
  <section class="Organizations">
    <div v-if="loading" class="content">
      {{ t('organization.archive.loading_title') }}
    </div>
    <div v-if="!loading" class="content">
      <div v-if="organizations.length">
        <h1>
          <span>{{ t('organization.archive.title') }}</span>
          <span class="strong">{{
            t('organization.archive.organization')
          }}</span>
        </h1>
        <div v-for="organization in organizations" :key="organization.id">
          <router-link
            :to="{ path: `/organizations/${organization.id}/dashboard` }"
          >
            {{ organization.name }}
          </router-link>
        </div>
      </div>

      <div v-else>
        <h1>
          <span>{{ t('organization.archive.without_organization') }}</span>
          <span class="strong">{{
            t('organization.archive.organization')
          }}</span>
          <span>{{ t('organization.archive.without_organization_2') }}</span>
        </h1>
      </div>
    </div>
    <div class="Sidebar">
      <ul class="log-out">
        <LocaleSelector :sidebar-expanded="true" />
        <li>
          <a href="https://compliancelog.dk/#/kontakt" target="_blank">
            <div class="bg" />
            <img
              style="width: 25px; left: 38px"
              class="inactive_img"
              src="../../components/Sidebar/assets/request.svg"
            />
            <span>{{ t('shared.navigation.help') }}</span>
          </a>
        </li>
        <li>
          <a href="#" @click="logOut()">
            <div class="bg" />
            <img
              style="width: 23px; left: 38px"
              class="inactive_img"
              src="../../components/Sidebar/assets/log_out.svg"
            />
            <span>{{ t('shared.navigation.log_out') }}</span>
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LocaleSelector from '@/components/LocaleSelector'

export default {
  name: 'Organizations',
  components: { LocaleSelector },
  computed: {
    ...mapGetters({
      loading: 'loadingOrganizations',
      organizations: 'organizations',
    }),
  },
  mounted() {
    this.$store.dispatch('fetchOrganizations')
  },
  methods: {
    ...mapActions(['logOut']),
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
