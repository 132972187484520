import DocumentsArchive from '@/pages/DocumentsArchive'
import DocumentsNew from '@/pages/DocumentsNew'
import DocumentsRevisions from '@/pages/DocumentsRevisions'
import DocumentsEdit from '@/pages/DocumentsEdit'

const routes = [
  {
    path: 'documents/new',
    component: DocumentsNew,
    name: 'DocumentsNew',
  },
  {
    path: 'documents',
    component: DocumentsArchive,
    name: 'DocumentsArchive',
  },
  {
    path: 'documents/:id/revisions',
    component: DocumentsRevisions,
    name: 'DocumentsRevisions',
  },
  {
    path: 'documents/:id/edit',
    component: DocumentsEdit,
    name: 'DocumentsEdit',
  },
]
export default routes
