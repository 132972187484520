<template>
  <div @click="$emit('click', $event)">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FinalizeSteps',
}
</script>
