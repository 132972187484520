<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'SystemsArchive' }"
      :title="t('system.new.title')"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('system.archive.title')"
    />

    <SystemForm :system="$data" @submit="createSystem" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SystemForm from '@/forms/SystemForm'
import ResourceHeader from '@/components/ResourceHeader'

export default {
  name: 'SystemsNew',
  components: {
    ResourceHeader,
    SystemForm,
  },
  data() {
    return {
      name: '',
      provider: '',
      description: '',
      hosted: false,
      hasAutomaticDeletion: false,
      automaticDeletionDescription: '',
      customQuestionAnswers: [],
      technical: true,
      hasDeletionProcedure: false,
      backup: false,
      backupDeletable: false,
      collectedDataTypes: [],
      inUseSafetyMeasures: [],
    }
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions(['createSystem', 'collapseSidebar']),
  },
}
</script>
