<template>
  <div class="ExternalLink">
    <li class="fa fa-link" />
    <a :target="target" :href="href">{{ label }}</a>
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'ExternalLink',
  props: {
    target: { type: String },
    href: { type: String },
    label: { type: String },
  },
}
</script>
