<template>
  <div class="HeaderTimestamp">
    <div v-if="date">
      {{ t('shared.header.last_edited') }}
      <span class="HeaderTimestamp__date">{{
        date | formatDateTimeHuman
      }}</span>
    </div>
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'HeaderTimestamp',
  props: {
    date: { type: String, required: true },
  },
}
</script>
