<template>
  <div>
    <ResourceHeader
      :back-link="{
        name: 'DataMappingsShow',
        params: { dataMappingId: this.$route.params.dataMappingId },
      }"
      :back-link-text="t('shared.back_to_resource')"
      :title="t('risk_assessment.new.title')"
      :title-prefix="dataMapping.title"
      :title-prefix-secondary="t('risk_assessment.title')"
    />

    <RiskAssessmentForm
      :risk-assessment="$data"
      @submit="createRiskAssessment"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RiskAssessmentForm from '@/forms/RiskAssessmentForm'
import ResourceHeader from '@/components/ResourceHeader'

export default {
  name: 'RiskAssessmentsNew',
  components: { ResourceHeader, RiskAssessmentForm },
  data() {
    return {
      title: '',
      description: '',
      probability: 'small',
      consequences: 'small',
      reasoning: '',
      status: 'draft',
    }
  },
  computed: {
    ...mapGetters(['dataMapping']),
  },
  beforeMount() {
    this.fetchDataMapping()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'createRiskAssessment',
      'collapseSidebar',
      'fetchDataMapping',
    ]),
  },
}
</script>
