<template>
  <Modal :title="t('organization.edit.tabs.departments.new.title')">
    <ModalCloseButton :to="{ name: 'OrganizationsEdit' }" />
    <div class="form__input-wrapper">
      <label>{{ t('organization.edit.tabs.departments.new.name') }}</label>
      <input v-model="formData.name" type="text" />
    </div>
    <ModalSaveButton @click="save()">
      {{ t('organization.edit.tabs.departments.new.submit') }}
    </ModalSaveButton>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import router from '../../routes'
import Modal from '@/components/Modal'
import ModalCloseButton from '@/components/ModalCloseButton'
import ModalSaveButton from '@/components/ModalSaveButton'

export default {
  name: 'DepartmentsNew',
  components: {
    Modal,
    ModalCloseButton,
    ModalSaveButton,
  },
  data() {
    return {
      formData: {
        name: '',
      },
    }
  },
  methods: {
    save() {
      this.createDepartment(this.formData)
        .then(() => {
          router.push({ name: 'OrganizationsEdit' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.department') +
              this.t('flash_messages.create.success'),
          })
          this.fetchDepartments()
        })
        .catch(e => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.department') +
              this.t('flash_messages.create.error'),
          })
        })
    },
    ...mapActions(['showFlashMessage', 'createDepartment', 'fetchDepartments']),
  },
}
</script>
