<template>
  <p class="FieldDisclaimer">
    <slot />
  </p>
</template>

<script>
import './style.scss'

export default {
  name: 'FieldDisclaimer',
}
</script>
