<template>
  <LogInModal>
    <p>{{ t('password_forgotten.description') }}</p>
    <form @submit="reset">
      <LogInField
        v-model="formData.email"
        :placeholder="t('password_forgotten.email')"
      />

      <LogInModalSaveButton>{{
        t('password_forgotten.reset')
      }}</LogInModalSaveButton>
      <LocaleSelectorLogin />
      <LogInModalBackLink :to="{ path: `/` }">
        {{ t('password_forgotten.to_front') }}
      </LogInModalBackLink>
    </form>
  </LogInModal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import LogInModal from '@/components/LogInModal'
import LogInField from '@/components/LogInField'
import LogInModalSaveButton from '@/components/LogInModalSaveButton'
import LogInModalBackLink from '@/components/LogInModalBackLink'
import LocaleSelectorLogin from '@/components/LocaleSelectorLogin'

import router from '@/routes'

export default {
  name: 'PasswordForgotten',
  components: {
    LogInModal,
    LogInField,
    LogInModalSaveButton,
    LogInModalBackLink,
    LocaleSelectorLogin,
  },
  data() {
    return {
      formData: {
        email: '',
      },
    }
  },
  computed: {
    ...mapState({}),
  },
  methods: {
    reset(event) {
      event.preventDefault()

      this.sendResetInstructions(this.formData)
        .then(() => {
          router.push({ name: 'LogIn' })
          this.showFlashMessage({
            message: this.t('flash_messages.custom.password_forgotten_success'),
          })
        })
        .catch(e => {
          this.showFlashMessage({
            message: this.t('flash_messages.custom.password_forgotten_error'),
          })
        })
    },
    ...mapActions(['showFlashMessage', 'sendResetInstructions']),
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
