<template>
  <div class="LogInModal">
    <Particles />
    <div class="LogInModal__inner fade_in_up">
      <img class="LogInModel__logo" src="./logo.svg" />
      <slot />
    </div>
  </div>
</template>

<script>
import Particles from '@/components/Particles'

export default {
  name: 'LogInModal',
  components: { Particles },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
