<template>
  <button class="ModalSaveButton" @click="$emit('click', $event)">
    <slot />
  </button>
</template>

<script>
import './style.scss'

export default {
  name: 'ModalSaveButton',
}
</script>
