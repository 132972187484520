import Vue from 'vue'
import api from '@/utils/api'
import attachRelations from '@/utils/attachRelations'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import wrapResourceRequest from '@/utils/wrapResourceRequest'

const type = 'mitigationMeasures'

const storeGetters = {
  loadingMitigationMeasures: (_state, getters) => getters.isLoading(type),

  mitigationMeasure: (_state, getters) =>
    getters.resource(type, getters.params.mitigationMeasureId),

  mitigationMeasures: (_state, getters) => {
    const mitigationMeasures = getters.resources(type)
    return mitigationMeasures.map(attachRelations(['riskAssessment']))
  },

  mitigationMeasuresForArchive:
    (_state, getters) =>
    ({ search, ascending }) => {
      const loweredSearch = search.toLowerCase()
      const sortDirection = ascending ? 1 : -1

      let archive = getters[type].slice()

      if (search) {
        archive = archive.filter(({ title, description, riskAssessment }) => {
          const riskAssessmentName = Vue.filter('formatRiskAssessment')(
            riskAssessment
          )

          return (
            (title || '').toLowerCase().includes(loweredSearch) ||
            (description || '').toLowerCase().includes(loweredSearch) ||
            riskAssessmentName.toLowerCase().includes(loweredSearch)
          )
        })
      }

      archive.sort((a, b) => sortDirection * (a.id - b.id))

      return archive
    },
}

const actions = {
  createMitigationMeasure: (
    { commit, state, getters, dispatch },
    mitigationMeasure
  ) => {
    const {
      params: { organizationId, riskAssessmentId, dataMappingId },
    } = getters
    const params = snakeCaseKeys({ mitigationMeasure })

    const request = api.post(
      `organizations/${organizationId}/data_mappings/${dataMappingId}/risk_assessments/${riskAssessmentId}/mitigation_measures`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  fetchMitigationMeasure: (
    { getters, getters: { organizationId } },
    id,
    riskAssessmentId,
    dataMappingId
  ) => {
    id = id || getters.params.mitigationMeasureId
    riskAssessmentId = riskAssessmentId || getters.params.riskAssessmentId
    dataMappingId = dataMappingId || getters.params.riskAssessment.dataMappingId

    const params = { include: ['risk_assessment'] }
    const request = api.get(
      `organizations/${organizationId}/data_mappings/${dataMappingId}/risk_assessments/${riskAssessmentId}/mitigation_measures/${id}`,
      { params }
    )
    return wrapResourceRequest(type, request)
  },

  saveMitigationMeasure: (
    { getters, getters: { organizationId } },
    mitigationMeasure
  ) => {
    const params = snakeCaseKeys({ mitigationMeasure })
    const dataMappingId = getters.params.dataMappingId

    const request = api.put(
      `organizations/${organizationId}/data_mappings/${dataMappingId}/risk_assessments/${mitigationMeasure.riskAssessmentId}/mitigation_measures/${mitigationMeasure.id}`,
      params
    )
    return wrapResourceRequest(type, request)
  },

  deleteMitigationMeasure: (
    { getters, getters: { organizationId } },
    mitigationMeasure
  ) => {
    const dataMappingId = getters.params.dataMappingId
    const request = api.delete(
      `organizations/${organizationId}/data_mappings/${dataMappingId}/risk_assessments/${mitigationMeasure.riskAssessmentId}/mitigation_measures/${mitigationMeasure.id}`
    )

    return wrapResourceRequest(type, request)
  },
}

export default {
  actions,
  getters: storeGetters,
}
