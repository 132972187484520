<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'SystemsArchive' }"
      :title="system && system.name"
      :timestamp="system && system.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('system.archive.title')"
    />

    <Loader v-if="!system" />
    <ContentSection v-else>
      <ResourceActions>
        <ButtonLink :to="{ name: 'SystemsReviews' }" type="primary">
          {{ t('shared.buttons.reviews') }}
        </ButtonLink>
        <ButtonLink :to="{ name: 'SystemsEdit' }" type="primary">
          {{ t('shared.actions.edit') }}
        </ButtonLink>
        <Button @click="handleDelete">
          {{ t('shared.actions.delete') }}
        </Button>
      </ResourceActions>

      <Columns>
        <div>
          <ColumnHeader>{{ system.name }}</ColumnHeader>

          <FieldSubHeader>{{ t('system.show.description') }}</FieldSubHeader>
          <ColumnParagraph>{{ system.description }}</ColumnParagraph>

          <FieldSubHeader>{{ t('system.show.system_type') }}</FieldSubHeader>
          <ColumnParagraph>{{
            system.technical ? 'Teknisk system' : 'Fysiske system'
          }}</ColumnParagraph>

          <FieldSubHeader>{{
            t('system.show.system_location')
          }}</FieldSubHeader>
          <ColumnParagraph v-if="system.hosted">
            {{ t('system.show.hosted') }}
          </ColumnParagraph>
          <ColumnParagraph v-else>
            {{ t('system.show.on_premise') }}
          </ColumnParagraph>

          <FieldSubHeader>{{ t('system.show.provider') }}</FieldSubHeader>
          <ColumnParagraph>{{ system.provider }}</ColumnParagraph>

          <FieldSubHeader>{{ t('system.show.responsible') }}</FieldSubHeader>
          <ColumnParagraph>{{ system.responsibleName }}</ColumnParagraph>

          <FieldSubHeader>{{ t('system.show.executing') }}</FieldSubHeader>
          <ColumnParagraph>{{ system.executingName }}</ColumnParagraph>

          <FieldSubHeader>{{
            t('system.show.first_review_at')
          }}</FieldSubHeader>
          <ColumnParagraph>{{ system.firstReviewAt }}</ColumnParagraph>

          <FieldSubHeader>{{
            t('system.show.revision_period')
          }}</FieldSubHeader>
          <ColumnParagraph>{{
            t('system.revision_periods.' + system.revisionPeriod)
          }}</ColumnParagraph>

          <FieldSubHeader>{{
            t('system.show.sensitive_confidential')
          }}</FieldSubHeader>
          <div v-if="collectedSensitiveDataTypes.length">
            <ColumnParagraph
              v-for="dataType in collectedSensitiveDataTypes"
              :key="dataType"
            >
              {{ t('data_types.' + dataType) }}
            </ColumnParagraph>
          </div>

          <FieldSubHeader>{{ t('system.show.general') }}</FieldSubHeader>
          <div
            v-if="
              [...collectedNonsensitiveDataTypes, ...customDataTypes].length
            "
          >
            <ColumnParagraph
              v-for="dataType in collectedNonsensitiveDataTypes"
              :key="dataType"
            >
              {{ t('data_types.' + dataType) }}
            </ColumnParagraph>
            <ColumnParagraph
              v-for="dataType in customDataTypes"
              :key="dataType"
            >
              {{ dataType }}
            </ColumnParagraph>
          </div>

          <FieldSubHeader>{{ t('system.show.security') }}</FieldSubHeader>
          <div v-if="system.inUseSafetyMeasures.length">
            <ColumnParagraph
              v-for="safetyMeasure in system.inUseSafetyMeasures"
              :key="safetyMeasure.id"
            >
              {{ t('safety_measures.' + safetyMeasure.translationKey) }}
            </ColumnParagraph>
          </div>

          <FieldSubHeader>{{
            t('system.show.deletion_procedure_title')
          }}</FieldSubHeader>
          <ColumnParagraph>{{
            system.hasDeletionProcedure
              ? t('shared.yes_reply')
              : t('shared.no_reply')
          }}</ColumnParagraph>

          <div
            v-if="
              system.hasDeletionProcedure && system.deletionDocumentProcedureId
            "
          >
            <FieldSubHeader>{{
              t('system.show.deletion_procedure')
            }}</FieldSubHeader>
            <ColumnParagraph>{{
              system.deletionProcedureTitle
            }}</ColumnParagraph>
          </div>

          <div v-else>
            <FieldSubHeader>{{
              t('system.show.deletion_check_description')
            }}</FieldSubHeader>
            <ColumnParagraph>{{
              system.deletionCheckDescription
            }}</ColumnParagraph>
          </div>

          <FieldSubHeader>{{ t('system.show.storage_period') }}</FieldSubHeader>
          <ColumnParagraph>{{ system.storagePeriod }}</ColumnParagraph>

          <div v-if="system.deletionProcedureDescription">
            <FieldSubHeader>{{
              t('system.show.deletion_description')
            }}</FieldSubHeader>
            <ColumnParagraph>{{
              system.deletionProcedureDescription
            }}</ColumnParagraph>
          </div>

          <FieldSubHeader>{{ t('system.show.backup') }}</FieldSubHeader>
          <ColumnParagraph>{{
            system.backup ? t('shared.yes_reply') : t('shared.no_reply')
          }}</ColumnParagraph>

          <div v-if="system.backup">
            <FieldSubHeader>{{
              t('system.show.backup_deletable')
            }}</FieldSubHeader>
            <ColumnParagraph>{{
              system.backupDeletable
                ? t('shared.yes_reply')
                : t('shared.no_reply')
            }}</ColumnParagraph>
          </div>

          <div v-if="system.backup && system.backupDeletionDescription">
            <FieldSubHeader>{{
              t('system.show.backup_deletion_period')
            }}</FieldSubHeader>
            <ColumnParagraph>{{
              system.backupDeletionDescription
            }}</ColumnParagraph>
          </div>

          <template v-for="question in questions">
            <div v-if="findQuestionAnswer(question.id)" :key="question.id">
              <FieldSubHeader>{{ question.question }}</FieldSubHeader>
              <ColumnParagraph>{{
                findQuestionAnswer(question.id).answer
              }}</ColumnParagraph>
            </div>
          </template>
        </div>
      </Columns>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import router from '../../routes'

import ResourceHeader from '@/components/ResourceHeader'
import ResourceActions from '@/components/ResourceActions'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ButtonLink from '@/components/ButtonLink'
import Button from '@/components/Button'
import FieldSubHeader from '@/components/FieldSubHeader'
import Columns from '@/components/Columns'
import ColumnHeader from '@/components/ColumnHeader'
import ColumnParagraph from '@/components/ColumnParagraph'
import { difference, uniq, intersection } from 'lodash'

export default {
  name: 'SystemsShow',
  components: {
    Loader,
    ResourceHeader,
    ResourceActions,
    ContentSection,
    ButtonLink,
    Button,
    FieldSubHeader,
    Columns,
    ColumnHeader,
    ColumnParagraph,
  },
  computed: {
    ...mapGetters(['system', 'questions']),

    ...mapState({
      sensitiveDataTypes: state => state.dataTypes.sensitive,
      nonsensitiveDataTypes: state => state.dataTypes.nonsensitive,
    }),

    collectedDataTypes() {
      const allCollectedDataTypes = this.system.collectedDataTypes.map(
        ({ dataTypeTranslationKey }) => {
          return dataTypeTranslationKey
        }
      )

      return uniq(allCollectedDataTypes)
    },

    collectedSensitiveDataTypes() {
      return intersection(this.collectedDataTypes, this.sensitiveDataTypes)
    },

    collectedNonsensitiveDataTypes() {
      return intersection(this.collectedDataTypes, this.nonsensitiveDataTypes)
    },

    customDataTypes() {
      return difference(this.collectedDataTypes, [
        ...this.sensitiveDataTypes,
        ...this.nonsensitiveDataTypes,
      ])
    },
  },
  mounted() {
    if (this.system) this.flushSystemRelations(this.system)
    this.collapseSidebar()
    this.fetchCustomQuestions('systems')
    this.fetchSystem()
  },
  methods: {
    ...mapActions([
      'fetchSystem',
      'collapseSidebar',
      'deleteSystem',
      'showFlashMessage',
      'flushSystemRelations',
      'fetchCustomQuestions',
    ]),

    handleDelete() {
      if (
        confirm(this.t('flash_messages.delete.confirmation') + this.system.name)
      ) {
        this.deleteSystem(this.system)
          .then(() => {
            router.push({ name: 'SystemsArchive' })
            this.showFlashMessage({
              message:
                this.t('flash_messages.system') +
                this.t('flash_messages.delete.success'),
            })
          })
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.system') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
    findQuestionAnswer(questionId) {
      const questionAnswer = this.system.customQuestionAnswers.find(
        customQuestionAnswer =>
          customQuestionAnswer.questionId === parseInt(questionId)
      )

      return questionAnswer
    },
  },
}
</script>
