import * as types from '../mutationTypes'

const initialState = {
  forSensitiveData: [],
  forNonsensitiveData: [],
}

const actions = {
  storeLegalBases({ commit }, { forSensitiveData, forNonsensitiveData }) {
    commit(types.STORE_LEGAL_BASES, { forSensitiveData, forNonsensitiveData })
  },
}

const mutations = {
  [types.STORE_LEGAL_BASES]: (
    state,
    { forSensitiveData, forNonsensitiveData }
  ) => {
    state.forSensitiveData = forSensitiveData
    state.forNonsensitiveData = forNonsensitiveData
  },
}

export default {
  state: initialState,
  actions,
  mutations,
}
