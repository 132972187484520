<template>
  <div>
    <Settings>
      <OrganizationForm
        :organization="organization"
        :is-saving="isSaving"
        @submit="handleOrganizationSubmit"
      />
      <OrganizationOfficerForm
        :organization="organization"
        :is-saving="isSaving"
        @submit="handleOrganizationSubmit"
      />
      <TableContainer>
        <TabsWrapper>
          <div
            :class="{ active: activeTab === 'users' }"
            class="tab"
            @click="activeTab = 'users'"
          >
            <p>{{ t('organization.edit.tabs.users_title') }}</p>
          </div>
          <div
            :class="{ active: activeTab === 'invites' }"
            class="tab"
            @click="activeTab = 'invites'"
          >
            <p>{{ t('organization.edit.tabs.invites_title') }}</p>
          </div>
          <div
            :class="{ active: activeTab === 'departments' }"
            class="tab"
            @click="activeTab = 'departments'"
          >
            <p>{{ t('organization.edit.tabs.departments_title') }}</p>
          </div>
        </TabsWrapper>
        <OrganizationUsersArchive v-if="activeTab === 'users'" />
        <InvitesArchive v-else-if="activeTab === 'invites'" />
        <DepartmentsArchive v-else />
      </TableContainer>
      <router-view />
    </Settings>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OrganizationForm from '@/forms/OrganizationForm'
import OrganizationOfficerForm from '@/forms/OrganizationOfficerForm'
import Settings from '@/components/Settings'
import TableContainer from '@/components/TableContainer'
import TabsWrapper from '@/components/TabsWrapper'
import OrganizationUsersArchive from '@/pages/OrganizationUsersArchive'
import InvitesArchive from '@/pages/InvitesArchive'
import DepartmentsArchive from '@/pages/DepartmentsArchive'

export default {
  name: 'OrganizationsEdit',
  components: {
    OrganizationForm,
    OrganizationOfficerForm,
    OrganizationUsersArchive,
    InvitesArchive,
    Settings,
    TableContainer,
    DepartmentsArchive,
    TabsWrapper,
  },
  data() {
    return {
      isSaving: false,
      activeTab: 'users',
    }
  },
  computed: {
    ...mapGetters(['organization']),
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions(['collapseSidebar', 'saveOrganization', 'showFlashMessage']),
    handleOrganizationSubmit({ organization }) {
      this.isSaving = true
      this.saveOrganization(organization)
        .then(() => {
          this.isSaving = false
          this.showFlashMessage({
            message:
              this.t('flash_messages.organization') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.isSaving = false
          this.showFlashMessage({
            message:
              this.t('flash_messages.organization') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
