import api from '../../utils/api'
import wrapResourceRequest from '@/utils/wrapResourceRequest'
import flushTypes from '@/utils/flushTypes'
import attachRelations from '@/utils/attachRelations'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import * as types from '../mutationTypes'
import humanizeErrors from '@/utils/humanizeErrors'

const type = 'organizationUsers'

const initialState = {
  errors: [],
}

const storeGetters = {
  loadingOrganizationUsers: (_state, getters) => getters.isLoading(type),

  organizationUsers: (_state, getters) => {
    const organization = getters.resources(type)
    return organization.map(attachRelations(['user']))
  },

  currentOrganizationUser: (_state, getters) => {
    const currentUserId = getters.session.userId
    return getters.organizationUsers.find(
      organizationUser => organizationUser.user.id === currentUserId
    )
  },

  organizationUser: (_state, getters) => {
    const organizationUserId = getters.params.id
    return getters.organizationUsers.find(
      organizationUser => organizationUser.id === organizationUserId
    )
  },
}

const actions = {
  fetchOrganizationUsers: ({ getters: { organizationId } }) => {
    const params = { include: ['user'] }
    const request = api
      .get(`organizations/${organizationId}/organization_users`, { params })
      .then(flushTypes([type, 'users']))

    return wrapResourceRequest(type, request)
  },

  saveOrganizationUser: ({ commit, getters }, organizationUser) => {
    const { organizationId } = getters
    const params = snakeCaseKeys({ organizationUser })

    const request = api
      .put(
        `organizations/${organizationId}/organization_users/${organizationUser.id}`,
        params
      )
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },

  createOrganizationUserInvite: ({ commit, getters }, invite) => {
    const { organizationId } = getters
    const params = snakeCaseKeys({ invite })

    const request = api
      .post(`organizations/${organizationId}/invites`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },

  deleteOrganizationUser: (
    { getters: { organizationId } },
    organization_user_id
  ) => {
    const request = api.delete(
      `organizations/${organizationId}/organization_users/${organization_user_id}`
    )
    return wrapResourceRequest(type, request)
  },
}

const mutations = {
  [types.ADD_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = humanizeErrors(response)
  },
  [types.REMOVE_RESOURCE_RESPONSE_ERRORS]: (state, response) => {
    state.errors = []
  },
}

export default {
  state: initialState,
  getters: storeGetters,
  actions,
  mutations,
}
