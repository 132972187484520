<template>
  <section class="InlineContent">
    <slot />
  </section>
</template>

<script>
import './style.scss'

export default {
  name: 'InlineContent',
}
</script>
