export default {
  methods: {
    findOrBuildQuestionAnswer(questionId) {
      let answer = this.formData.customQuestionAnswers.find(
        customQuestionAnswer =>
          customQuestionAnswer.questionId === parseInt(questionId)
      )

      if (!answer) {
        answer = { answer: '', questionId: parseInt(questionId) }
        this.formData.customQuestionAnswers.push(answer)
      }

      return answer
    },

    updateQuestionAnswer(questionId, value) {
      const questionAnswer = this.formData.customQuestionAnswers.find(
        customQuestionAnswer =>
          customQuestionAnswer.questionId === parseInt(questionId)
      )

      questionAnswer.answer = value
    },

    newCustomQuestionAnswer(questionId) {
      const questionAnswer = this.formData.customQuestionAnswers.push({
        answer: '',
        questionId: questionId,
      })

      return questionAnswer
    },
  },
}
