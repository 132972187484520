<template>
  <p
    :class="{ 'ListHeaderTab--active': active }"
    class="ListHeaderTab"
    @click="$emit('click', $event)"
  >
    <slot />
  </p>
</template>

<script>
import './style.scss'

export default {
  name: 'ListHeaderTab',
  props: {
    active: { type: Boolean },
  },
}
</script>
