<template>
  <ContentSection>
    <Steps
      :step-names="[
        t('data_mapping.steps.start'),
        t('data_mapping.steps.review'),
        t('data_mapping.steps.collected_information'),
        t('data_mapping.steps.source'),
        t('data_mapping.steps.sensitive_confidential'),
        t('data_mapping.steps.general'),
        t('data_mapping.steps.legal_basis'),
        t('data_mapping.steps.access_restrictions'),
        t('data_mapping.steps.disclosure'),
        t('data_mapping.steps.third_country'),
        t('data_mapping.steps.article_9'),
        t('data_mapping.steps.article_6'),
        t('data_mapping.steps.disclosure_legal_basis'),
        t('data_mapping.steps.system'),
        t('data_mapping.steps.data_processors'),
        t('data_mapping.steps.security'),
        t('data_mapping.steps.storage_period'),
        t('data_mapping.steps.other'),
      ]"
      :step-errors="errors"
    >
      <Step
        :step-name="t('data_mapping.steps.start')"
        :step-required-fields="['title', 'purpose']"
      >
        <FieldContainer>
          <FieldHeader
            >{{ t('data_mapping.form.title_question') }}
            <HelpTooltip tooltip-key="dataMapping.title"
          /></FieldHeader>
          <Field
            v-model="formData.title"
            :error="errors.first('title')"
            :class="hasError('title')"
            :placeholder="t('data_mapping.form.placeholders.title')"
            name="title"
            type="text"
          />
          <p>{{ errors.first('title') }}</p>
        </FieldContainer>
        <FieldContainer>
          <FieldHeader
            >{{ t('data_mapping.form.purpose_question')
            }}<HelpTooltip tooltip-key="dataMapping.purpose"
          /></FieldHeader>
          <Field
            v-model="formData.purpose"
            :error="errors.first('purpose')"
            :class="hasError('purpose')"
            :placeholder="t('data_mapping.form.placeholders.purpose')"
            name="purpose"
            type="textarea"
          />
        </FieldContainer>
        <FieldContainer>
          <FieldHeader
            >{{ t('data_mapping.form.department_question')
            }}<HelpTooltip tooltip-key="dataMapping.department"
          /></FieldHeader>
          <FormSelect v-model="formData.departmentId">
            <option
              v-for="department in departments"
              :key="department.id"
              :value="department.id"
            >
              {{ department.name }}
            </option>
          </FormSelect>
        </FieldContainer>
        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />

        <FieldContainer>
          <FieldHeader
            >{{ t('data_mapping.form.category_question')
            }}<HelpTooltip tooltip-key="dataMapping.category"
          /></FieldHeader>
          <FormSelect v-model="formData.customCategoryTitle">
            <option :value="null">
              {{ t('data_mapping.form.placeholders.select_category') }}
            </option>
            <option
              v-for="category in customCategories"
              :key="category.id"
              :value="category.title"
            >
              {{ category.title }}
            </option>
          </FormSelect>
        </FieldContainer>

        <CheckboxFieldCreator
          v-model="newCategoryName"
          :placeholder="t('data_mapping.form.placeholders.custom_category')"
          @create="() => addNewCategory()"
        />
      </Step>

      <Step :step-name="t('data_mapping.steps.review')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.responsible_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.responsibleId">
            <option :value="null">
              {{ t('shared.form.responsible_user_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>
            {{ t('data_mapping.form.responsible_user_question_help_text') }}
          </p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.executing_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.executingId">
            <option :value="null">
              {{ t('shared.form.executing_user_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('data_mapping.form.executing_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.first_control_date_question') }}
            <HelpTooltip tooltip-key="reviewProcess.firstReviewAt" />
          </FieldHeader>
          <DatePicker
            v-if="isNew || !formData.firstReviewAt"
            v-model="formData.firstReviewAt"
            :min-date-validator="true"
            :placeholder="t('data_mapping.form.placeholders.select_date')"
          />
          <FieldDisclaimer v-else>
            {{ formData.firstReviewAt | formatDateHuman }}
          </FieldDisclaimer>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.interval_question') }}
            <HelpTooltip tooltip-key="reviewProcess.intervalDays" />
          </FieldHeader>
          <IntervalField v-model="formData.intervalDays" />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <!-- DATA SUJBJECTS -->
      <Step :step-name="t('data_mapping.steps.collected_information')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.collect_information_about_name_question') }}
            <HelpTooltip tooltip-key="dataMapping.dataSubjectName" />
          </FieldHeader>
          <Field
            v-for="(dataSubject, index) in [
              ...formData.dataSubjects,
              newDataSubject,
            ]"
            :key="'name-' + index"
            v-model="dataSubject.name"
            :placeholder="
              t('data_mapping.form.placeholders.collect_information_about_name')
            "
            @input="trimDataSubjects"
            @blur="preventDuplicateDataSubjects"
          />
        </FieldContainer>
        <FieldContainer v-if="formData.dataSubjects.length">
          <FieldHeader>
            {{
              t(
                'data_mapping.form.collect_information_about_description_question'
              )
            }}
            <HelpTooltip tooltip-key="dataMapping.dataSubjectDescription" />
          </FieldHeader>
          <template v-for="(dataSubject, index) in formData.dataSubjects">
            <FieldSubHeader :key="'header-' + index">
              <strong
                ><em>{{ dataSubject.name }}</em></strong
              >
            </FieldSubHeader>
            <Field
              :key="'description-' + index"
              v-model="dataSubject.description"
              :placeholder="
                t(
                  'data_mapping.form.placeholders.collect_information_about_description'
                )
              "
              name="description"
              type="textarea"
            />
          </template>
        </FieldContainer>
        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <!-- DATA SOURCES -->
      <Step :step-name="t('data_mapping.steps.source')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.collect_information_from_question') }}
            <HelpTooltip tooltip-key="dataMapping.dataSources" />
          </FieldHeader>
          <Field
            v-for="(dataSource, index) in [
              ...formData.dataSources,
              newDataSource,
            ]"
            :key="index"
            v-model="dataSource.name"
            :placeholder="
              t('data_mapping.form.placeholders.collect_information_from')
            "
            @input="trimDataSources"
            @blur="preventDuplicateDataSources"
          />
        </FieldContainer>
        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <Step
        v-for="(dataSource, index) in formData.dataSources"
        :key="`data-source-${index}-sensitive`"
        :step-name="t('data_mapping.steps.sensitive_confidential')"
      >
        <FieldContainer>
          <StepBreadcrumbs
            :steps="formData.dataSources"
            :current-step="dataSource"
          />
          <FieldHeader>
            {{
              t('data_mapping.form.collected_sensitive_confidentional_question')
            }}
            <em>{{ dataSource.name }}?</em>
            <HelpTooltip tooltip-key="dataMapping.sensitiveDataTypes" />
          </FieldHeader>

          <CheckboxField
            v-for="dataType in sensitiveDataTypes"
            :key="dataType"
            :checked="
              !!dataSource.collectedDataTypes.find(
                dt => dt.dataTypeTranslationKey === dataType
              )
            "
            @change="
              () => toggleDataType(dataSource.collectedDataTypes, dataType)
            "
          >
            {{ t('data_types.' + dataType) }}
          </CheckboxField>
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <Step
        v-for="(dataSource, dataSourceIndex) in formData.dataSources"
        :key="`data-source-${dataSourceIndex}-nonsensitive`"
        :step-name="t('data_mapping.steps.general')"
      >
        <FieldContainer>
          <StepBreadcrumbs
            :steps="formData.dataSources"
            :current-step="dataSource"
          />
          <FieldHeader>
            {{ t('data_mapping.form.collected_general_question') }}
            <em>{{ dataSource.name }}?</em>
            <HelpTooltip tooltip-key="dataMapping.nonsensitiveDataTypes" />
          </FieldHeader>

          <CheckboxField
            v-for="dataType in nonsensitiveDataTypes"
            :key="dataType"
            :checked="
              !!dataSource.collectedDataTypes.find(
                dt => dt.dataTypeTranslationKey === dataType
              )
            "
            @change="
              () => toggleDataType(dataSource.collectedDataTypes, dataType)
            "
          >
            {{ t('data_types.' + dataType) }}
          </CheckboxField>

          <div v-if="customDataTypes.length > 0">
            <FieldSubHeader>
              <strong
                ><em>{{ t('data_mapping.form.custom_general') }}</em></strong
              >
            </FieldSubHeader>
            <CheckboxField
              v-for="dataType in customDataTypes"
              :key="dataType"
              :checked="
                !!dataSource.collectedDataTypes.find(
                  dt => dt.dataTypeTranslationKey === dataType
                )
              "
              @change="
                () => toggleDataType(dataSource.collectedDataTypes, dataType)
              "
            >
              {{ dataType }}
            </CheckboxField>
          </div>

          <CheckboxFieldCreator
            v-model="newDataTypeKey"
            :placeholder="
              t('data_mapping.form.placeholders.custom_collected_option')
            "
            @create="() => addNewDataType(dataSource.collectedDataTypes)"
          />
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <template v-for="(dataSource, dataSourceIndex) in formData.dataSources">
        <Step
          v-if="dataSource.collectedDataTypes.length"
          :key="`data-source-${dataSourceIndex}-legal-bases`"
          :step-name="t('data_mapping.steps.legal_basis')"
          :step-required-fields="['legal_basis_translation_key']"
        >
          <FieldContainer>
            <StepBreadcrumbs
              :steps="formData.dataSources"
              :current-step="dataSource"
            />
            <FieldHeader>
              {{ t('data_mapping.form.collected_legal_bases_question') }}
              <em>{{ dataSource.name }}?</em>
              <HelpTooltip tooltip-key="dataMapping.legalBasis" />
            </FieldHeader>

            <FieldSubHeader
              v-if="filterSensitive(dataSource.collectedDataTypes, true).length"
            >
              <strong
                ><em>{{
                  t('data_mapping.form.sensitive_confidential')
                }}</em></strong
              >
              <HelpTooltip tooltip-key="dataMapping.legalBasisSensitive" />
            </FieldSubHeader>

            <div
              v-for="dataType in filterSensitive(
                dataSource.collectedDataTypes,
                true
              )"
              :key="dataType.dataTypeTranslationKey"
            >
              <FieldSubHeader>{{
                t('data_types.' + dataType.dataTypeTranslationKey)
              }}</FieldSubHeader>

              <div class="LegalBasis__subheader">
                {{ t('data_mapping.form.article_6') }}
              </div>
              <div class="LegalBasis__general">
                <FormSelect v-model="dataType.generalLegalBasisTranslationKey">
                  <option
                    v-for="legalBasis in generalLegalBasesForSensitiveDataType()"
                    :key="legalBasis"
                    :value="legalBasis"
                  >
                    {{ t('legal_bases.' + legalBasis) }}
                  </option>
                </FormSelect>
                <button
                  class="ListItem__update-all"
                  @click="applyToAllGeneralSensitiveData(dataSource, dataType)"
                >
                  {{ t('data_mapping.form.apply_to_all') }}
                </button>

                <div
                  v-if="dataType.generalLegalBasisTranslationKey === 'other'"
                >
                  <br />
                  <Field
                    v-model="dataType.customGeneralLegalBasis"
                    :placeholder="
                      t('data_mapping.form.placeholders.custom_legal_basis')
                    "
                    name="customGeneralLegalBasis"
                    type="text"
                  />
                </div>
              </div>

              <div class="LegalBasis__subheader">
                {{ t('data_mapping.form.article_9') }}
              </div>
              <FormSelect v-model="dataType.legalBasisTranslationKey">
                <option
                  v-for="legalBasis in legalBasesForDataType(dataType)"
                  :key="legalBasis"
                  :value="legalBasis"
                >
                  {{ t('legal_bases.' + legalBasis) }}
                </option>
              </FormSelect>
              <button
                class="ListItem__update-all"
                @click="applyToAllDataTypes(dataSource, dataType, true)"
              >
                {{ t('data_mapping.form.apply_to_all') }}
              </button>

              <div v-if="dataType.legalBasisTranslationKey === 'other'">
                <br />
                <Field
                  v-model="dataType.customLegalBasis"
                  :placeholder="
                    t('data_mapping.form.placeholders.custom_legal_basis')
                  "
                  name="customLegalBasis"
                  type="text"
                />
              </div>
            </div>

            <FieldSubHeader
              v-if="
                filterSensitive(dataSource.collectedDataTypes, false).length
              "
            >
              <strong
                ><em>{{ t('data_mapping.form.general') }}</em></strong
              >
              <HelpTooltip tooltip-key="dataMapping.legalBasisNonSensitive" />
            </FieldSubHeader>
            <div
              v-for="dataType in filterSensitive(
                dataSource.collectedDataTypes,
                false
              )"
              :key="dataType.dataTypeTranslationKey"
            >
              <FieldSubHeader>{{
                t('data_types.' + dataType.dataTypeTranslationKey)
              }}</FieldSubHeader>

              <div class="LegalBasis__subheader">
                {{ t('data_mapping.form.article_6') }}
              </div>

              <FormSelect v-model="dataType.legalBasisTranslationKey">
                <option
                  v-for="legalBasis in legalBasesForDataType(dataType)"
                  :key="legalBasis"
                  :value="legalBasis"
                >
                  {{ t('legal_bases.' + legalBasis) }}
                </option>
              </FormSelect>
              <button
                class="ListItem__update-all"
                @click="applyToAllDataTypes(dataSource, dataType, false)"
              >
                {{ t('data_mapping.form.apply_to_all') }}
              </button>

              <div v-if="dataType.legalBasisTranslationKey === 'other'">
                <br />
                <Field
                  v-model="dataType.customLegalBasis"
                  :placeholder="
                    t('data_mapping.form.placeholders.custom_legal_basis')
                  "
                  name="customLegalBasis"
                  type="text"
                />
              </div>
            </div>

            <div
              v-for="dataType in filterCustom(
                dataSource.collectedDataTypes,
                true
              )"
              :key="dataType.dataTypeTranslationKey"
            >
              <FieldSubHeader>{{
                dataType.dataTypeTranslationKey
              }}</FieldSubHeader>

              <div class="LegalBasis__subheader">
                {{ t('data_mapping.form.article_6') }}
              </div>

              <FormSelect v-model="dataType.legalBasisTranslationKey">
                <option
                  v-for="legalBasis in legalBasesForDataType(dataType)"
                  :key="legalBasis"
                  :value="legalBasis"
                >
                  {{ t('legal_bases.' + legalBasis) }}
                </option>
              </FormSelect>
              <button
                class="ListItem__update-all"
                @click="applyToAllDataTypes(dataSource, dataType, false)"
              >
                {{ t('data_mapping.form.apply_to_all') }}
              </button>

              <div v-if="dataType.legalBasisTranslationKey === 'other'">
                <br />
                <Field
                  v-model="dataType.customLegalBasis"
                  :placeholder="
                    t('data_mapping.form.placeholders.custom_legal_basis')
                  "
                  name="customLegalBasis"
                  type="text"
                />
              </div>
            </div>
          </FieldContainer>

          <FormControls
            :is-saving="isSaving"
            :cancel-link="cancelLink"
            @finalize="save()"
          />
        </Step>
      </template>

      <!-- IN HOUSE VIEWERS AND DATA RECIPIENTS -->
      <Step :step-name="t('data_mapping.steps.access_restrictions')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.access_within_company_question') }}
            <HelpTooltip tooltip-key="dataMapping.inHouseViewers" />
          </FieldHeader>
          <Field
            v-for="(inHouseViewer, index) in [
              ...formData.inHouseViewers,
              newInHouseViewer,
            ]"
            :key="index"
            v-model="inHouseViewer.name"
            :placeholder="
              t('data_mapping.form.placeholders.access_within_company')
            "
            @input="trimInHouseViewers"
            @blur="preventDuplicateInHouseViewers"
          />
        </FieldContainer>

        <FieldContainer v-if="formData.inHouseViewers.length">
          <FieldHeader
            >{{ t('data_mapping.form.access_reason_question') }} ({{
              joinInHouseViewerNames()
            }})
            {{ t('data_mapping.form.access_reason_question_2') }}</FieldHeader
          >
          <Field
            v-model="formData.accessDescription"
            :placeholder="t('data_mapping.form.placeholders.access_desription')"
            name="accessDescription"
            type="textarea"
          />
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <!-- EU transfers -->

      <Step :step-name="t('data_mapping.steps.disclosure')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.disclosed_information_question') }}
          </FieldHeader>

          <RadioField v-model="formData.disclosedInformation" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.disclosedInformation" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer v-if="formData.disclosedInformation === true">
          <FieldHeader>
            {{ t('data_mapping.form.disclosed_to_question') }}
            <HelpTooltip tooltip-key="dataMapping.dataRecipients" />
          </FieldHeader>
          <Field
            v-for="(dataRecipient, index) in [
              ...formData.dataRecipients,
              newDataRecipient,
            ]"
            :key="index"
            v-model="dataRecipient.name"
            :placeholder="t('data_mapping.form.placeholders.disclosed_to')"
            @input="trimDataRecipients"
            @blur="preventDuplicateDataRecipients"
          />
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <!-- EU transfers -->
      <template v-for="dataSource in formData.dataSources">
        <Step
          v-if="formData.disclosedInformation === true"
          :key="dataSource.id"
          :step-name="t('data_mapping.steps.third_country')"
        >
          <FieldContainer>
            <StepBreadcrumbs
              :steps="formData.dataSources"
              :current-step="dataSource"
            />
            <FieldHeader>
              {{ t('data_mapping.form.third_country_question') }}
              <em>{{ dataSource.name }}</em>
              {{ t('data_mapping.form.third_country_question_2') }}
            </FieldHeader>

            <RadioField v-model="dataSource.hasEuTransfers" :value="true">
              {{ t('shared.yes_reply') }}
            </RadioField>
            <RadioField v-model="dataSource.hasEuTransfers" :value="false">
              {{ t('shared.no_reply') }}
            </RadioField>
          </FieldContainer>

          <FieldContainer v-if="dataSource.hasEuTransfers === true">
            <FieldHeader>
              {{ t('data_mapping.form.third_country_information_question') }}
            </FieldHeader>

            <CheckboxField
              v-for="collectedDataType in filterCustom(
                dataSource.collectedDataTypes,
                false
              )"
              :key="collectedDataType.id"
              v-model="collectedDataType.isTransferredInEu"
            >
              {{ t('data_types.' + collectedDataType.dataTypeTranslationKey) }}
            </CheckboxField>

            <CheckboxField
              v-for="collectedDataType in filterCustom(
                dataSource.collectedDataTypes,
                true
              )"
              :key="collectedDataType.id"
              v-model="collectedDataType.isTransferredInEu"
            >
              {{ collectedDataType.dataTypeTranslationKey }}
            </CheckboxField>
          </FieldContainer>

          <FieldContainer v-if="dataSource.hasEuTransfers === true">
            <FieldHeader>
              {{ t('data_mapping.form.transfer_basis_question') }}
            </FieldHeader>

            <Field
              v-model="dataSource.transferBasis"
              placeholder=""
              name="transferBasis"
              type="text"
            />
          </FieldContainer>

          <FormControls
            :is-saving="isSaving"
            :cancel-link="cancelLink"
            @finalize="save()"
          />
        </Step>
      </template>

      <!-- DATA RECIPIENTS -->
      <template
        v-for="(dataRecipient, dataRecipientIndex) in formData.dataRecipients"
      >
        <Step
          v-if="
            collectedSensitiveDataTypes.length &&
            formData.disclosedInformation === true
          "
          :key="`data-recipient-${dataRecipientIndex}-sensitive`"
          :step-name="t('data_mapping.steps.article_9')"
        >
          <FieldContainer>
            <StepBreadcrumbs
              :steps="formData.dataRecipients"
              :current-step="dataRecipient"
            />
            <FieldHeader>
              {{
                t(
                  'data_mapping.form.transfered_sensitive_confidentional_question'
                )
              }}
              <em>{{ dataRecipient.name }}?</em>
            </FieldHeader>

            <CheckboxField
              v-for="dataType in collectedSensitiveDataTypes"
              :key="dataType"
              :checked="
                !!dataRecipient.sentDataTypes.find(
                  dt => dt.dataTypeTranslationKey === dataType
                )
              "
              @change="
                () => toggleDataType(dataRecipient.sentDataTypes, dataType)
              "
            >
              {{ t('data_types.' + dataType) }}
            </CheckboxField>
          </FieldContainer>

          <FormControls
            :is-saving="isSaving"
            :cancel-link="cancelLink"
            @finalize="save()"
          />
        </Step>
      </template>

      <template
        v-for="(dataRecipient, dataRecipientIndex) in formData.dataRecipients"
      >
        <Step
          v-if="
            [...collectedNonsensitiveDataTypes, ...customDataTypes].length &&
            formData.disclosedInformation === true
          "
          :key="`data-recipient-${dataRecipientIndex}-nonsensitive`"
          :step-name="t('data_mapping.steps.article_6')"
        >
          <FieldContainer>
            <StepBreadcrumbs
              :steps="formData.dataRecipients"
              :current-step="dataRecipient"
            />
            <FieldHeader>
              {{ t('data_mapping.form.transfered_general_question') }}
              <em>{{ dataRecipient.name }}?</em>
            </FieldHeader>

            <CheckboxField
              v-for="dataType in collectedNonsensitiveDataTypes"
              :key="dataType"
              :checked="
                !!dataRecipient.sentDataTypes.find(
                  dt => dt.dataTypeTranslationKey === dataType
                )
              "
              @change="
                () => toggleDataType(dataRecipient.sentDataTypes, dataType)
              "
            >
              {{ t('data_types.' + dataType) }}
            </CheckboxField>

            <div v-if="customDataTypes.length > 0">
              <FieldSubHeader>
                <strong
                  ><em>{{ t('data_mapping.form.custom_general') }}</em></strong
                >
              </FieldSubHeader>
              <CheckboxField
                v-for="dataType in customDataTypes"
                :key="dataType"
                :checked="
                  !!dataRecipient.sentDataTypes.find(
                    dt => dt.dataTypeTranslationKey === dataType
                  )
                "
                @change="
                  () => toggleDataType(dataRecipient.sentDataTypes, dataType)
                "
              >
                {{ dataType }}
              </CheckboxField>
            </div>
          </FieldContainer>

          <FormControls
            :is-saving="isSaving"
            :cancel-link="cancelLink"
            @finalize="save()"
          />
        </Step>
      </template>

      <template
        v-for="(dataRecipient, dataRecipientIndex) in formData.dataRecipients"
      >
        <Step
          v-if="
            dataRecipient.sentDataTypes.length &&
            formData.disclosedInformation === true
          "
          :key="`data-recipient-${dataRecipientIndex}-legal-bases`"
          :step-name="t('data_mapping.steps.disclosure_legal_basis')"
          :step-required-names="['base']"
        >
          <FieldContainer>
            <StepBreadcrumbs
              :steps="formData.dataRecipients"
              :current-step="dataRecipient"
            />
            <FieldHeader>
              {{ t('data_mapping.form.sent_sensitive_confidential_question') }}
              <em>{{ dataRecipient.name }}?</em>
              <HelpTooltip tooltip-key="dataMapping.legalBasesForDataType" />
            </FieldHeader>
            <div
              v-if="filterSensitive(dataRecipient.sentDataTypes, true).length"
            >
              <FieldSubHeader>
                <strong
                  ><em>{{
                    t('data_mapping.form.sensitive_confidential')
                  }}</em></strong
                >
                <HelpTooltip tooltip-key="dataMapping.legalBasisNonSensitive" />
              </FieldSubHeader>
              <div
                v-for="dataType in filterSensitive(
                  dataRecipient.sentDataTypes,
                  true
                )"
                :key="dataType.dataTypeTranslationKey"
              >
                <FieldSubHeader>{{
                  t('data_types.' + dataType.dataTypeTranslationKey)
                }}</FieldSubHeader>
                <div class="LegalBasis__subheader">
                  {{ t('data_mapping.form.article_6') }}
                </div>
                <div class="LegalBasis__general">
                  <FormSelect
                    v-model="dataType.generalLegalBasisTranslationKey"
                  >
                    <option
                      v-for="legalBasis in generalLegalBasesForSensitiveDataType()"
                      :key="legalBasis"
                      :value="legalBasis"
                    >
                      {{ t('legal_bases.' + legalBasis) }}
                    </option>
                  </FormSelect>
                  <button
                    class="ListItem__update-all"
                    @click="
                      applyToAllGeneralSensitiveData(dataRecipient, dataType)
                    "
                  >
                    {{ t('data_mapping.form.apply_to_all') }}
                  </button>

                  <div
                    v-if="dataType.generalLegalBasisTranslationKey === 'other'"
                  >
                    <br />
                    <Field
                      v-model="dataType.customGeneralLegalBasis"
                      :placeholder="
                        t('data_mapping.form.placeholders.custom_legal_basis')
                      "
                      name="customGeneralLegalBasis"
                      type="text"
                    />
                  </div>
                </div>

                <div class="LegalBasis__subheader">
                  {{ t('data_mapping.form.article_9') }}
                </div>
                <FormSelect v-model="dataType.legalBasisTranslationKey">
                  <option
                    v-for="legalBasis in legalBasesForDataType(dataType)"
                    :key="legalBasis"
                    :value="legalBasis"
                  >
                    {{ t('legal_bases.' + legalBasis) }}
                  </option>
                </FormSelect>
                <button
                  class="ListItem__update-all"
                  @click="applyToAllDataTypes(dataRecipient, dataType, true)"
                >
                  {{ t('data_mapping.form.apply_to_all') }}
                </button>

                <div v-if="dataType.legalBasisTranslationKey === 'other'">
                  <br />
                  <Field
                    v-model="dataType.customLegalBasis"
                    :placeholder="
                      t('data_mapping.form.placeholders.custom_legal_basis')
                    "
                    name="customLegalBasis"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="
                [
                  ...filterSensitive(dataRecipient.sentDataTypes, false),
                  ...filterCustom(dataRecipient.sentDataTypes, true),
                ].length
              "
            >
              <FieldSubHeader>
                <strong
                  ><em>{{ t('data_mapping.form.general') }}</em></strong
                >
                <HelpTooltip tooltip-key="dataMapping.legalBasisNonSensitive" />
              </FieldSubHeader>
              <div
                v-for="dataType in filterSensitive(
                  dataRecipient.sentDataTypes,
                  false
                )"
                :key="dataType.dataTypeTranslationKey"
              >
                <FieldSubHeader>{{
                  t('data_types.' + dataType.dataTypeTranslationKey)
                }}</FieldSubHeader>
                <div class="LegalBasis__subheader">
                  {{ t('data_mapping.form.article_6') }}
                </div>
                <FormSelect v-model="dataType.legalBasisTranslationKey">
                  <option
                    v-for="legalBasis in legalBasesForDataType(dataType)"
                    :key="legalBasis"
                    :value="legalBasis"
                  >
                    {{ t('legal_bases.' + legalBasis) }}
                  </option>
                </FormSelect>
                <button
                  class="ListItem__update-all"
                  @click="applyToAllDataTypes(dataRecipient, dataType, false)"
                >
                  {{ t('data_mapping.form.apply_to_all') }}
                </button>

                <div v-if="dataType.legalBasisTranslationKey === 'other'">
                  <br />
                  <Field
                    v-model="dataType.customLegalBasis"
                    :placeholder="
                      t('data_mapping.form.placeholders.custom_legal_basis')
                    "
                    name="customLegalBasis"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div
              v-for="dataType in filterCustom(
                dataRecipient.sentDataTypes,
                true
              )"
              :key="dataType.dataTypeTranslationKey"
            >
              <FieldSubHeader>{{
                dataType.dataTypeTranslationKey
              }}</FieldSubHeader>
              <div class="LegalBasis__subheader">
                {{ t('data_mapping.form.article_6') }}
              </div>
              <FormSelect v-model="dataType.legalBasisTranslationKey">
                <option
                  v-for="legalBasis in legalBasesForDataType(dataType)"
                  :key="legalBasis"
                  :value="legalBasis"
                >
                  {{ t('legal_bases.' + legalBasis) }}
                </option>
              </FormSelect>
              <button
                class="ListItem__update-all"
                @click="applyToAllDataTypes(dataRecipient, dataType, false)"
              >
                {{ t('data_mapping.form.apply_to_all') }}
              </button>

              <div v-if="dataType.legalBasisTranslationKey === 'other'">
                <br />
                <Field
                  v-model="dataType.customLegalBasis"
                  :placeholder="
                    t('data_mapping.form.placeholders.custom_legal_basis')
                  "
                  name="customLegalBasis"
                  type="text"
                />
              </div>
            </div>
          </FieldContainer>

          <FormControls
            :is-saving="isSaving"
            :cancel-link="cancelLink"
            @finalize="save()"
          />
        </Step>
      </template>

      <!-- SYSTEMS -->
      <Step :step-name="t('data_mapping.steps.system')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.systems_question') }}
            <HelpTooltip tooltip-key="dataMapping.systems" />
          </FieldHeader>

          <CheckboxField
            v-for="system in systems"
            :key="system.id"
            v-model="formData.systemIds"
            :value="system.id"
          >
            {{ system.name }}
          </CheckboxField>
        </FieldContainer>

        <FieldContainer>
          <CheckboxField v-model="todos.system" style="margin-top: 20px">
            {{ t('data_mapping.form.placeholders.add_to_todo') }}
          </CheckboxField>
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <!-- DATA PROCESSORS -->
      <Step :step-name="t('data_mapping.steps.data_processors')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.data_porcessor_question') }}
            <HelpTooltip tooltip-key="dataMapping.dataProcessors" />
          </FieldHeader>

          <CheckboxField
            v-for="dataProcessor in dataProcessors"
            :key="dataProcessor.id"
            v-model="formData.dataProcessorIds"
            :value="dataProcessor.id"
          >
            {{ dataProcessor.name }}
          </CheckboxField>
        </FieldContainer>

        <FieldContainer>
          <CheckboxField v-model="todos.dataProcessor" style="margin-top: 20px">
            {{ t('data_mapping.form.placeholders.add_to_todo') }}
          </CheckboxField>
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <!--Safety Measures -->

      <Step :step-name="t('data_mapping.steps.security')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.security_measures_question') }}
          </FieldHeader>
          <FieldSubHeader>
            <strong><em>Tier 1</em></strong>
          </FieldSubHeader>
          <CheckboxField
            v-for="safetyMeasure in safetyMeasuresForTier(1)"
            :key="safetyMeasure.translationKey"
            :checked="
              !!formData.inUseSafetyMeasures.find(
                sm => sm.translationKey === safetyMeasure.translationKey
              )
            "
            @change="
              () =>
                toggleSafetyMeasure(
                  formData.inUseSafetyMeasures,
                  safetyMeasure.translationKey
                )
            "
          >
            {{ t('safety_measures.' + safetyMeasure.translationKey) }}
          </CheckboxField>

          <FieldSubHeader>
            <strong><em>Tier 2</em></strong>
          </FieldSubHeader>
          <CheckboxField
            v-for="safetyMeasure in safetyMeasuresForTier(2)"
            :key="safetyMeasure.translationKey"
            :checked="
              !!formData.inUseSafetyMeasures.find(
                sm => sm.translationKey === safetyMeasure.translationKey
              )
            "
            @change="
              () =>
                toggleSafetyMeasure(
                  formData.inUseSafetyMeasures,
                  safetyMeasure.translationKey
                )
            "
          >
            {{ t('safety_measures.' + safetyMeasure.translationKey) }}
          </CheckboxField>

          <FieldSubHeader>
            <strong><em>Tier 3</em></strong>
          </FieldSubHeader>
          <CheckboxField
            v-for="safetyMeasure in safetyMeasuresForTier(3)"
            :key="safetyMeasure.translationKey"
            :checked="
              !!formData.inUseSafetyMeasures.find(
                sm => sm.translationKey === safetyMeasure.translationKey
              )
            "
            @change="
              () =>
                toggleSafetyMeasure(
                  formData.inUseSafetyMeasures,
                  safetyMeasure.translationKey
                )
            "
          >
            {{ t('safety_measures.' + safetyMeasure.translationKey) }}
          </CheckboxField>

          <FieldSubHeader>
            <strong><em>Tier 4</em></strong>
          </FieldSubHeader>
          <CheckboxField
            v-for="safetyMeasure in safetyMeasuresForTier(4)"
            :key="safetyMeasure.translationKey"
            :checked="
              !!formData.inUseSafetyMeasures.find(
                sm => sm.translationKey === safetyMeasure.translationKey
              )
            "
            @change="
              () =>
                toggleSafetyMeasure(
                  formData.inUseSafetyMeasures,
                  safetyMeasure.translationKey
                )
            "
          >
            {{ t('safety_measures.' + safetyMeasure.translationKey) }}
          </CheckboxField>

          <div v-if="safetyMeasuresForTier('custom').length > 0">
            <FieldSubHeader>
              <strong
                ><em>{{
                  t('data_mapping.form.custom_security_measure')
                }}</em></strong
              >
            </FieldSubHeader>
            <CheckboxField
              v-for="safetyMeasure in safetyMeasuresForTier('custom')"
              :key="safetyMeasure"
              :checked="
                !!formData.inUseSafetyMeasures.find(
                  sm => sm.translationKey === safetyMeasure
                )
              "
              @change="
                () =>
                  toggleSafetyMeasure(
                    formData.inUseSafetyMeasures,
                    safetyMeasure
                  )
              "
            >
              {{ safetyMeasure }}
            </CheckboxField>
          </div>

          <CheckboxFieldCreator
            v-model="newSafetyMeasureKey"
            :placeholder="
              t('data_mapping.form.placeholders.custom_security_measure')
            "
            @create="() => addNewSafetyMeasure(formData.inUseSafetyMeasures)"
          />
        </FieldContainer>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <Step
        :step-name="t('data_mapping.steps.storage_period')"
        :step-required-fields="['storage_duration']"
      >
        <FieldContainer>
          <FieldHeader>
            <em>{{
              t('data_mapping.form.information_storage_period_question')
            }}</em>
            {{ t('data_mapping.form.information_storage_period_question_2') }}
            <HelpTooltip tooltip-key="dataMapping.storageDuration" />
          </FieldHeader>
          <Field
            v-model="formData.storageDuration"
            :error="errors.first('storageDuration')"
            :class="hasError('storage_duration')"
            :placeholder="
              t('data_mapping.form.placeholders.information_storage_period')
            "
            name="storageDuration"
            type="text"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_mapping.form.deletion_procedure_question') }}
            <em>{{ t('data_mapping.form.deletion_procedure_question_2') }}</em>
            {{ t('data_mapping.form.deletion_procedure_question_3') }}
            <HelpTooltip tooltip-key="dataMapping.deletionProcedure" />
          </FieldHeader>
          <FormSelect v-model="formData.deletionDocumentProcedureId">
            <option :value="null">
              {{ t('data_mapping.form.placeholders.select_procedure') }}
            </option>
            <option
              v-for="document in documentProcedures"
              :key="document.id"
              :value="document.id"
            >
              {{ document.title }}
            </option>
          </FormSelect>
        </FieldContainer>
        <CheckboxField
          v-model="todos.deletionProcedure"
          style="margin-top: 20px"
        >
          {{ t('data_mapping.form.placeholders.put_on_todo') }}
        </CheckboxField>

        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>

      <Step
        v-if="questions.length > 0"
        :step-name="t('data_mapping.steps.other')"
      >
        <FieldContainer v-for="question in questions" :key="question.id">
          <FieldHeader>{{ question.question }}</FieldHeader>
          <Field
            :placeholder="question.placeholder"
            :value="findOrBuildQuestionAnswer(question.id).answer"
            name="title"
            type="text"
            @input="
              value => {
                updateQuestionAnswer(question.id, value)
              }
            "
          />
        </FieldContainer>
        <FormControls
          :is-saving="isSaving"
          :cancel-link="cancelLink"
          @finalize="save()"
        />
      </Step>
    </Steps>
  </ContentSection>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { cloneDeep, difference, uniq, intersection } from 'lodash'
import VueTypes from 'vue-types'
import ContentSection from '@/components/ContentSection'
import FormControls from '@/components/FormControls'
import HelpTooltip from '@/components/HelpTooltip'
import FieldContainer from '@/components/FieldContainer'
import FieldHeader from '@/components/FieldHeader'
import FieldSubHeader from '@/components/FieldSubHeader'
import Field from '@/components/Field'
import FormSelect from '@/components/FormSelect'
import CheckboxField from '@/components/CheckboxField'
import CheckboxFieldCreator from '@/components/CheckboxFieldCreator'
import RadioField from '@/components/RadioField'
import withCustomQuestions from '@/mixins/withCustomQuestions'
import StepBreadcrumbs from '@/components/StepBreadcrumbs'
import FieldDisclaimer from '@/components/FieldDisclaimer'
import IntervalField from '@/components/IntervalField'
import DatePicker from '@/components/DatePicker'

import { Steps, Step } from '@/components/Steps'

const buildDataSource = () => ({
  name: '',
  hasEuTransfers: false,
  collectedDataTypes: [],
})
const buildDataRecipient = () => ({ name: '', sentDataTypes: [] })
const buildInHouseViewer = () => ({ name: '' })
const buildCategory = () => ({ title: '' })
const buildDataSubject = () => ({ name: '', description: '' })

export default {
  name: 'DataMappingForm',
  components: {
    ContentSection,
    Steps,
    Step,
    StepBreadcrumbs,
    FormControls,
    HelpTooltip,
    FieldContainer,
    FieldHeader,
    FieldSubHeader,
    Field,
    FormSelect,
    CheckboxField,
    CheckboxFieldCreator,
    RadioField,
    FieldDisclaimer,
    IntervalField,
    DatePicker,
  },
  mixins: [withCustomQuestions],
  props: {
    dataMapping: VueTypes.shape({
      id: String,
      title: String,
      purpose: String,
      dataSubject: String,
      departmentId: VueTypes.oneOfType([String, null]),
      disclosedInformation: VueTypes.oneOfType([Boolean, null]),
      dataSources: VueTypes.arrayOf(
        VueTypes.shape({
          name: String,
          hasEuTransfers: VueTypes.oneOfType([Boolean, null]),
          collectedDataTypes: VueTypes.arrayOf(
            VueTypes.shape({
              dataTypeTranslationKey: VueTypes.oneOfType([String, null]),
              legalBasisTranslationKey: String,
              customLegalBasis: VueTypes.oneOfType([String, null]),
              generalLegalBasis: VueTypes.oneOfType([String, null]),
              customGeneralLegalBasis: VueTypes.oneOfType([String, null]),
            }).loose
          ),
        }).loose
      ),
      dataRecipients: VueTypes.arrayOf(
        VueTypes.shape({
          name: String,
          sentDataTypes: VueTypes.arrayOf(
            VueTypes.shape({
              dataTypeTranslationKey: VueTypes.oneOfType([String, null]),
              legalBasisTranslationKey: String,
              customLegalBasis: VueTypes.oneOfType([String, null]),
              generalLegalBasis: VueTypes.oneOfType([String, null]),
              customGeneralLegalBasis: VueTypes.oneOfType([String, null]),
            }).loose
          ),
        }).loose
      ),
      inUseSafetyMeasures: VueTypes.arrayOf(
        VueTypes.shape({
          translationKey: String,
        }).loose
      ),
      inHouseViewers: VueTypes.arrayOf(VueTypes.shape({ name: String }).loose),
      systemIds: VueTypes.arrayOf(String).isRequired,
      dataProcessorIds: VueTypes.arrayOf(String).isRequired,
      storageDuration: String,
      deletionProcedureId: VueTypes.oneOfType([String, null]),
      responsibleId: VueTypes.oneOfType([String, null]),
      executingId: VueTypes.oneOfType([String, null]),
      dataSubjects: VueTypes.arrayOf(
        VueTypes.shape({
          name: String,
          description: VueTypes.oneOfType([String, null]),
        }).loose
      ),
    }).loose,
    isSaving: VueTypes.bool.isRequired,
  },
  data() {
    return {
      formData: cloneDeep(this.dataMapping),
      newDataSource: buildDataSource(),
      newDataSubject: buildDataSubject(),
      newCategory: buildCategory(),
      newInHouseViewer: buildInHouseViewer(),
      newDataRecipient: buildDataRecipient(),
      newDataTypeKey: '',
      newSafetyMeasureKey: '',
      newCategoryName: '',
      cancelLink: this.cancelLinkWithParams(),
      hasCategory: !!this.dataMapping.customCategoryTitle,
      todos: {
        system: false,
        dataProcessor: false,
        deletionProcedure: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'departments',
      'systems',
      'dataProcessors',
      'documents',
      'questions',
      'customCategories',
      'users',
    ]),

    ...mapState({
      expanded: state => !state.sidebar.expanded,
      sensitiveDataTypes: state => state.dataTypes.sensitive,
      nonsensitiveDataTypes: state => state.dataTypes.nonsensitive,
      legalBasesForSensitiveData: state => state.legalBases.forSensitiveData,
      legalBasesForNonsensitiveData: state =>
        state.legalBases.forNonsensitiveData,
      safetyMeasures: state => state.safetyMeasures,
    }),

    isNew() {
      return !this.dataMapping.id
    },

    collectedDataTypes() {
      const allCollectedDataTypes = this.formData.dataSources.reduce(
        (types, source) => {
          return types.concat(
            source.collectedDataTypes.map(dt => dt.dataTypeTranslationKey)
          )
        },
        []
      )
      return uniq(allCollectedDataTypes)
    },

    collectedSensitiveDataTypes() {
      return intersection(this.collectedDataTypes, this.sensitiveDataTypes)
    },

    collectedNonsensitiveDataTypes() {
      return difference(
        this.collectedDataTypes,
        this.sensitiveDataTypes,
        this.customDataTypes
      )
    },

    customDataTypes() {
      return difference(this.collectedDataTypes, [
        ...this.sensitiveDataTypes,
        ...this.nonsensitiveDataTypes,
      ])
    },
    documentProcedures() {
      let filteredDocuments = this.documents.filter(
        ({ category }) => category === 'procedure'
      )

      return filteredDocuments
    },
  },
  watch: {
    dataMapping: function (newDataMapping) {
      this.formData = cloneDeep(newDataMapping)
    },
    hasCategory: function (hasCategory) {
      if (!hasCategory) this.formData.customCategory = null
    },
  },
  mounted() {
    this.fetchDocuments()
    this.fetchSystems()
    this.fetchDataProcessors()
    this.fetchCustomQuestions('data_mappings')
    this.fetchCustomCategories()
    this.observeScroll()
  },
  methods: {
    ...mapActions([
      'fetchDocuments',
      'fetchSystems',
      'fetchDataProcessors',
      'fetchCustomQuestions',
      'fetchCustomCategories',
      'observeScroll',
    ]),

    // Handlers
    save() {
      this.$emit('submit', {
        dataMapping: this.formData,
        todos: this.todos,
      })
    },

    hasError(field) {
      let items = this.errors.items.map(item => item.field)

      if (items.includes(field)) {
        return 'Field--error'
      }
    },

    // Utilities

    hasThirdCountryTransfers() {
      if (this.formData.dataSources.find(ds => ds.hasEuTransfers === true)) {
        return true
      } else {
        return false
      }
    },
    legalBasesForDataType(dataType) {
      if (
        this.sensitiveDataTypes.indexOf(dataType.dataTypeTranslationKey) !== -1
      ) {
        return this.legalBasesForSensitiveData
      } else {
        return this.legalBasesForNonsensitiveData
      }
    },
    generalLegalBasesForSensitiveDataType() {
      return this.legalBasesForNonsensitiveData
    },

    applyToAllDataTypes(data, dataType, sensitive) {
      const legalBasisTranslationKey = dataType.legalBasisTranslationKey
      const customLegalBasis = dataType.customLegalBasis
      const dataTypes = sensitive
        ? this.collectedSensitiveDataTypes
        : [...this.collectedNonsensitiveDataTypes, ...this.customDataTypes]

      dataTypes.forEach(collectedDataType => {
        const newDataType = data.collectedDataTypes
          ? data.collectedDataTypes.find(
              dt => dt.dataTypeTranslationKey === collectedDataType
            )
          : data.sentDataTypes.find(
              dt => dt.dataTypeTranslationKey === collectedDataType
            )

        if (newDataType !== undefined) {
          newDataType.legalBasisTranslationKey = legalBasisTranslationKey
          if (legalBasisTranslationKey === 'other') {
            newDataType.customLegalBasis = customLegalBasis
          }
        }
      })

      this.$forceUpdate()
    },

    applyToAllGeneralSensitiveData(data, dataType) {
      const generalLegalBasisTranslationKey =
        dataType.generalLegalBasisTranslationKey
      const customGeneralLegalBasis = dataType.customGeneralLegalBasis
      const dataTypes = this.collectedSensitiveDataTypes

      dataTypes.forEach(collectedDataType => {
        const newDataType = data.collectedDataTypes
          ? data.collectedDataTypes.find(
              dt => dt.dataTypeTranslationKey === collectedDataType
            )
          : data.sentDataTypes.find(
              dt => dt.dataTypeTranslationKey === collectedDataType
            )

        if (newDataType !== undefined) {
          newDataType.generalLegalBasisTranslationKey =
            generalLegalBasisTranslationKey
          if (generalLegalBasisTranslationKey === 'other') {
            newDataType.customGeneralLegalBasis = customGeneralLegalBasis
          }
        }
      })

      this.$forceUpdate()
    },

    safetyMeasuresForTier(tier) {
      if (tier === 'custom') {
        const safetyMeasuresWithTiers = this.safetyMeasures.map(
          sm => sm.translationKey
        )
        const inUseSafetyMeasures = this.formData.inUseSafetyMeasures.map(
          sm => sm.translationKey
        )

        return inUseSafetyMeasures.filter(
          el => !safetyMeasuresWithTiers.includes(el)
        )
      } else {
        return this.safetyMeasures.filter(item => item.tier === tier)
      }
    },

    trimFormArrayByName(formDataKey, newKey, newCreator) {
      const { formData } = this

      formData[formDataKey] = formData[formDataKey].filter(
        ({ name }) => name !== ''
      )

      if (this[newKey].name) {
        formData[formDataKey].push(this[newKey])
        this[newKey] = newCreator()
      }
    },

    preventFormArrayDuplicatesByName(formDataKey) {
      const { [formDataKey]: array } = this.formData

      const names = array.map(d => d.name)

      let index = array.length
      while (index--) {
        const item = array[index]

        const duplicateIndex = names.indexOf(item.name)

        if (duplicateIndex !== -1 && duplicateIndex !== index) {
          if (!/ I*$/.test(item.name)) item.name += ' II'
          while (names.indexOf(item.name) !== -1) item.name += 'I'
        }
      }
    },

    toggleId(ids, id) {
      const index = ids.indexOf(id)
      if (index !== -1) {
        ids.splice(index, 1)
      } else {
        ids.push(id)
      }
    },

    filterSensitive(dataTypes, sensitive) {
      return dataTypes.filter(({ dataTypeTranslationKey }) => {
        const index = this.sensitiveDataTypes.indexOf(dataTypeTranslationKey)

        if (sensitive) {
          return index !== -1
        } else {
          const collectedIndex = this.customDataTypes.indexOf(
            dataTypeTranslationKey
          )

          if (collectedIndex >= 0) {
            return index !== -1
          } else {
            return index === -1
          }
        }
      })
    },

    filterCustom(dataTypes, onlyCustom) {
      return dataTypes.filter(({ dataTypeTranslationKey }) => {
        const index = this.customDataTypes.indexOf(dataTypeTranslationKey)

        if (onlyCustom) {
          return index !== -1
        } else {
          return index === -1
        }
      })
    },

    // IN HOUSE VIEWERS
    preventDuplicateInHouseViewers() {
      this.preventFormArrayDuplicatesByName('inHouseViewers')
    },

    trimInHouseViewers() {
      this.trimFormArrayByName(
        'inHouseViewers',
        'newInHouseViewer',
        buildInHouseViewer
      )
    },

    // DATA SOURCES
    preventDuplicateDataSources() {
      this.preventFormArrayDuplicatesByName('dataSources')
    },

    trimDataSources() {
      this.trimFormArrayByName('dataSources', 'newDataSource', buildDataSource)
      this.trimSentDataTypes()
    },

    trimDataSubjects() {
      this.trimFormArrayByName(
        'dataSubjects',
        'newDataSubject',
        buildDataSubject
      )
    },

    preventDuplicateDataSubjects() {
      this.preventFormArrayDuplicatesByName('dataSubjects')
    },

    toggleDataType(dataTypes, dataType) {
      const index = dataTypes.findIndex(
        dt => dt.dataTypeTranslationKey === dataType
      )

      if (index !== -1) {
        dataTypes.splice(index, 1)
      } else {
        dataTypes.push({ dataTypeTranslationKey: dataType })
      }
      this.trimSentDataTypes()
    },

    toggleSafetyMeasure(inUseSafetyMeasures, safetyMeasureTranslationKey) {
      const index = inUseSafetyMeasures.findIndex(
        sm => sm.translationKey === safetyMeasureTranslationKey
      )

      if (index !== -1) {
        inUseSafetyMeasures.splice(index, 1)
      } else {
        inUseSafetyMeasures.push({
          translationKey: safetyMeasureTranslationKey,
        })
      }
    },

    addNewDataType(dataTypes) {
      const dataType = this.newDataTypeKey
      if (dataType === '') return

      if (!dataTypes.find(dt => dt.dataTypeTranslationKey === dataType)) {
        dataTypes.push({ dataTypeTranslationKey: dataType })
      }
      this.newDataTypeKey = ''
    },

    addNewCategory() {
      const category = this.newCategoryName
      if (category === '') return

      if (!this.customCategories.find(ct => ct.title === category)) {
        this.customCategories.push({ title: category })
      }
      this.newCategoryName = ''
      return (this.formData.customCategoryTitle = category)
    },

    addNewSafetyMeasure(inUseSafetyMeasures) {
      const safetyMeasureKey = this.newSafetyMeasureKey
      if (safetyMeasureKey === '') return

      if (
        !inUseSafetyMeasures.find(sm => sm.translationKey === safetyMeasureKey)
      ) {
        inUseSafetyMeasures.push({ translationKey: safetyMeasureKey })
      }
      this.newSafetyMeasureKey = ''
    },

    // DATA RECIPIENTS
    preventDuplicateDataRecipients() {
      this.preventFormArrayDuplicatesByName('dataRecipients')
    },

    trimDataRecipients() {
      this.trimFormArrayByName(
        'dataRecipients',
        'newDataRecipient',
        buildDataRecipient
      )
    },

    // ENSURE THAT WE DON'T HAVE SENT DATA TYPES THAT HAVE NOT BEEN COLLECTED
    trimSentDataTypes() {
      this.formData.dataRecipients.forEach(({ sentDataTypes }) => {
        let index = sentDataTypes.length
        while (index--) {
          const dataType = sentDataTypes[index].dataTypeTranslationKey
          const collectedIndex = this.collectedDataTypes.indexOf(dataType)

          if (collectedIndex === -1) {
            console.log('Removing sent data type', dataType)
            sentDataTypes.splice(index, 1)
          }
        }
      })
    },

    joinInHouseViewerNames() {
      let str = []

      this.formData.inHouseViewers.forEach(ihv => str.push(ihv.name))

      return str.join(', ')
    },

    cancelLinkWithParams() {
      if (this.$route.params.dataMappingId) {
        return {
          name: 'DataMappingsShow',
          params: { dataMappingId: this.$route.params.dataMappingId },
        }
      } else {
        return { name: 'DataMappingsArchive' }
      }
    },
  },
}
</script>
