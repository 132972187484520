import Dashboard from '@/pages/Dashboard'

import TodosNew from '@/pages/TodosNew'
import TodosEdit from '@/pages/TodosEdit'

export default [
  { path: '', redirect: { name: 'Dashboard' } },
  {
    path: 'dashboard',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: 'todos/new',
        component: TodosNew,
        name: 'TodosNew',
      },
      {
        path: 'todos/:todoId/edit',
        component: TodosEdit,
        name: 'TodosEdit',
      },
    ],
  },
]
