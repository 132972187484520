import * as types from '../mutationTypes'
import api from '../../utils/api'
import router from '../../routes'
import i18n from '@/mixins/i18n.js.erb'

const savedSession = JSON.parse(localStorage.getItem('session') || '{}')

const initialState = {
  userId: null,
  token: null,
  ...savedSession,
}

const getters = {
  session: state => state,
  loggedIn: state => !!(state.userId && state.token),
  currentUser: state => state.currentUser,
}

const actions = {
  logIn({ commit, dispatch }, { email, password }) {
    api
      .post('session', { email, password })
      .then(response => {
        commit(types.STORE_SESSION, response.data)
        router.push({ name: 'OrganizationsArchive' })
        dispatch('showFlashMessage', {
          message: i18n.methods.t('flash_messages.custom.new_session_success'),
        })
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          dispatch('showFlashMessage', {
            message: i18n.methods.t('flash_messages.custom.new_session_error'),
          })
        }
      })
  },
  logOut({ commit, dispatch }) {
    const onLoggedOut = () => {
      commit(types.REMOVE_STORE_SESSION)
      commit(types.FLUSH_ALL_RESOURCES)
      router.push({ name: 'LogIn' })
      dispatch('showFlashMessage', {
        message: i18n.methods.t('flash_messages.custom.edit_session_success'),
      })
    }

    api.delete('session').then(onLoggedOut).catch(onLoggedOut)
  },
}

const mutations = {
  [types.STORE_SESSION]: (state, { data }) => {
    state.token = data.attributes.token
    state.userId = data.relationships.user.data.id
    state.currentUser = data.attributes.currentUser
    localStorage.setItem('session', JSON.stringify(state))
  },
  [types.REMOVE_STORE_SESSION]: state => {
    state.userId = null
    state.token = null
    state.currentUser = null
    localStorage.removeItem('session')
  },
}

export default {
  state: initialState,
  getters,
  actions,
  mutations,
}
