<template>
  <Loader v-if="loadingResources" />
  <TableWrapper v-else class="fade_in_up">
    <div v-if="resourceType === 'documents'">
      <div v-if="sortedDocuments(overdueDocuments).length > 0" class="wrapper">
        <h2>{{ title }}</h2>

        <table>
          <thead class="column-count-6">
            <th>{{ t('shared.table.title') }}</th>
            <th />
            <th>{{ t('shared.table.responsible') }}</th>
            <th>{{ t('shared.table.executing') }}</th>
            <th class="sortable" @click="sort()">
              {{ t('shared.table.due_date') }}<i :class="adjustSortCaret()" />
            </th>
            <th />
          </thead>
          <tbody class="column-count-6">
            <tr
              v-for="resource in sortedDocuments(overdueDocuments)"
              :key="resource.id"
            >
              <td>
                <router-link
                  :to="{ path: resourcePath + resource.id + '/revisions' }"
                >
                  {{ resource.title || resource.name }}
                </router-link>
              </td>
              <td>
                <Label v-if="resource.overdue" type="alert">{{
                  t('shared.table.overdue')
                }}</Label>
              </td>
              <td>{{ resource.responsibleName }}</td>
              <td>{{ resource.executingName }}</td>
              <td>{{ resource.nextRevisionAt | formatDateHuman }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{ path: resourcePath + resource.id + '/revisions' }"
                  :to-edit="{ path: resourcePath + resource.id + '/edit' }"
                  :delete-disabled="true"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-else>
      <div v-if="sortedResources(overdueResources).length > 0" class="wrapper">
        <h2>{{ title }}</h2>

        <table>
          <thead class="column-count-6">
            <th>{{ t('shared.table.title') }}</th>
            <th />
            <th>{{ t('shared.table.responsible') }}</th>
            <th>{{ t('shared.table.executing') }}</th>
            <th class="sortable" @click="sort()">
              {{ t('shared.table.due_date') }}<i :class="adjustSortCaret()" />
            </th>
            <th />
          </thead>
          <tbody class="column-count-6">
            <tr
              v-for="resource in sortedResources(overdueResources)"
              :key="resource.id"
            >
              <td>
                <router-link :to="{ path: resourcePath + resource.id }">
                  {{ resource.title || resource.name }}
                </router-link>
              </td>
              <td>
                <Label v-if="resource.overdue" type="alert">{{
                  t('shared.table.overdue')
                }}</Label>
              </td>
              <td>{{ resource.responsibleName }}</td>
              <td>{{ resource.executingName }}</td>
              <td>{{ resource.nextReviewAt | formatDateHuman }}</td>
              <td>
                <ArchiveTableDropdown
                  v-if="reviewPath"
                  :to-view="{ path: resourcePath + resource.id }"
                  :to-edit="{ path: resourcePath + resource.id + '/edit' }"
                  :to-review="{ path: resourcePath + resource.id + '/reviews' }"
                  :delete-disabled="true"
                />
                <ArchiveTableDropdown
                  v-else
                  :to-view="{ path: resourcePath + resource.id }"
                  :to-edit="{ path: resourcePath + resource.id + '/edit' }"
                  :delete-disabled="true"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </TableWrapper>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import Label from '@/components/Label'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import TableWrapper from '@/components/TableWrapper'
import Loader from '@/components/Loader'
import { isLessThenDate } from '@/utils/dateUtils'

export default {
  name: 'DueDateTable',
  components: {
    Label,
    ArchiveTableDropdown,
    TableWrapper,
    Loader,
  },
  props: {
    resourceType: { type: String },
    title: { type: String },
    reviewPath: { type: Boolean, default: true },
    resourcePath: { type: String },
    userId: { type: String },
  },
  data: () => ({
    currentSortDir: 'asc',
  }),
  computed: {
    overdueResources() {
      let resources = this.$store.getters[this.resourceType].slice()

      resources = resources.filter(
        ({ nextReviewAt, overdue, active }) =>
          active && (isLessThenDate(1, 'months', nextReviewAt) || overdue === true)
      )

      if (this.userId) {
        resources = resources.filter(({ responsibleId, executingId }) => {
          return responsibleId === +this.userId || executingId === +this.userId
        })
      }

      resources.sort((a, b) => {
        return 1 * moment.utc(a.nextReviewAt).diff(moment.utc(b.nextReviewAt))
      })

      return resources
    },

    overdueDocuments() {
      let resources = this.$store.getters[this.resourceType].slice()

      resources = resources.filter(
        ({ nextRevisionAt, overdue }) =>
          isLessThenDate(1, 'months', nextRevisionAt) || overdue === true
      )

      if (this.userId) {
        resources = resources.filter(({ responsibleId, executingId }) => {
          return responsibleId === +this.userId || executingId === +this.userId
        })
      }

      resources.sort((a, b) => {
        return (
          1 * moment.utc(a.nextRevisionAt).diff(moment.utc(b.nextRevisionAt))
        )
      })

      return resources
    },

    loadingResources() {
      return this.$store.getters.isLoading(this.resourceType)
    },
  },
  mounted() {
    switch (this.resourceType) {
      case 'dataMappings':
        this.fetchDataMappings()
        break
      case 'reviewProcesses':
        this.fetchReviewProcesses()
        break
      case 'systems':
        this.fetchSystems()
        break
      case 'dataProcessors':
        this.fetchDataProcessors()
        break
      case 'documents':
        this.fetchDocuments()
        break
    }
  },
  methods: {
    ...mapActions([
      'fetchDataMappings',
      'fetchDocuments',
      'fetchReviewProcesses',
      'fetchRequests',
      'fetchIncidents',
      'fetchSystems',
      'fetchDataProcessors',
    ]),

    adjustSortCaret() {
      if (this.currentSortDir === 'asc') {
        return 'fa fa-caret-up'
      } else {
        return 'fa fa-caret-down'
      }
    },

    sort() {
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
    },
    sortedResources(resources) {
      resources.sort((a, b) => {
        if (this.currentSortDir === 'asc') {
          return moment(a.nextReviewAt) - moment(b.nextReviewAt)
        } else {
          return moment(b.nextReviewAt) - moment(a.nextReviewAt)
        }
      })

      return resources
    },

    sortedDocuments(resources) {
      resources.sort((a, b) => {
        if (this.currentSortDir === 'asc') {
          return moment(a.nextRevisionAt) - moment(b.nextRevisionAt)
        } else {
          return moment(b.nextRevisionAt) - moment(a.nextRevisionAt)
        }
      })

      return resources
    },
  },
}
</script>
