<template>
  <v-popover
    :auto-hide="false"
    offset="16"
    class="HelpTooltip"
    placement="right"
  >
    <span class="HelpTooltip__trigger">
      <slot>
        <span v-if="content()" class="HelpTooltip__question-mark">?</span>
      </slot>
    </span>

    <template slot="popover">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p class="text-left" v-html="content()" />
      <a v-close-popover class="HelpTooltip__button">{{
        t('shared.close_tooltip')
      }}</a>
    </template>
  </v-popover>
</template>

<script>
import VueTypes from 'vue-types'
import tooltipText from '@/utils/tooltipText'
import danishTooltips from './tooltips_da'
import englishTooltips from './tooltips_en'
import germanTooltips from './tooltips_de'

export default {
  name: 'HelpTooltip',
  props: {
    tooltipKey: VueTypes.String,
  },

  methods: {
    content() {
      return tooltipText(this.tooltips(), this.tooltipKey)
    },
    tooltips() {
      switch (this.currentLocale()) {
        case 'da':
          return danishTooltips
        case 'en':
          return englishTooltips
        case 'de':
          return germanTooltips
      }
    },
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
