import Vue from 'vue'
import normalizeResponse from '@/utils/normalizeResponse'
import singularize from '@/utils/singularize'

import * as types from '../mutationTypes'

const initialState = () => ({
  byId: {},
  requests: {},
})

const mutations = {
  [types.ADD_RESOURCE_REQUEST]: (state, { type, request }) => {
    if (!state.requests[type]) Vue.set(state.requests, type, [])

    const requests = state.requests[type]
    requests.push(request)
  },

  [types.REMOVE_RESOURCE_REQUEST]: (state, { type, request }) => {
    const index = state.requests[type].indexOf(request)
    state.requests[type].splice(index, 1)
  },

  [types.UPDATE_RESOURCES]: (state, response) => {
    const { resources } = normalizeResponse(response.data)

    resources.forEach(resource => {
      const { id, type } = resource

      if (!state.byId[type]) Vue.set(state.byId, type, {})

      if (resource.deletedAt) {
        Vue.delete(state.byId[type], id)
      } else {
        Vue.set(state.byId[type], id, resource)
      }
    })
  },

  [types.FLUSH_RESOURCES]: (state, resources) => {
    if (!Array.isArray(resources)) resources = [resources]

    resources.forEach(resource => {
      const { id, type } = resource
      Vue.delete(state.byId[type], id)
    })
  },

  [types.FLUSH_RESOURCE_TYPES]: (state, flushTypes) => {
    if (!Array.isArray(flushTypes)) flushTypes = [flushTypes]
    flushTypes.forEach(type => {
      Vue.delete(state.byId, type)
    })
  },

  [types.FLUSH_ALL_RESOURCES]: state => {
    const s = initialState()
    Object.keys(s).forEach(key => {
      Vue.set(state, key, s[key])
    })
  },
}

const storeGetters = {
  resources: state => type => Object.values(state.byId[type] || {}),

  resource: state => (type, id) => (state.byId[type] || {})[id],

  relation: (_state, getters) => (resource, foreignName) => {
    const type = resource[`${foreignName}Type`]
    const id = resource[`${foreignName}Id`]
    return getters.resource(type, id)
  },

  relations: (_state, getters) => (resource, type, foreignName) => {
    foreignName = foreignName || singularize(resource.type)
    return getters
      .resources(type)
      .filter(
        relation =>
          relation[`${foreignName}Id`] === resource.id &&
          relation[`${foreignName}Type`] === resource.type
      )
  },

  isLoading: state => type => {
    const requests = state.requests[type] || []
    return requests.length !== 0 || false
  },
}

export default {
  state: initialState(),
  mutations,
  getters: storeGetters,
}
