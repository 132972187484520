<template>
  <h2 class="FieldHeader">
    <slot />
  </h2>
</template>

<script>
import './style.scss'

export default {
  name: 'FieldHeader',
}
</script>
