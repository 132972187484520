import api from '@/utils/api'
import snakeCaseKeys from '@/utils/snakeCaseKeys'
import appendPairToFormData from '@/utils/appendPairToFormData'
import appendFilesToFormData from '@/utils/appendFilesToFormData'
import * as types from '@/store/mutationTypes'

const type = 'revisions'

const toRevisionFormData = request => {
  const { files, ...attributes } = request
  const formData = new FormData()

  Object.entries(attributes).forEach(appendPairToFormData(formData, 'revision'))
  appendFilesToFormData(formData, 'revision', files)

  return formData
}

const storeGetters = {
  loadingRevisions: (_state, getters) => getters.isLoading(type),
}

const actions = {
  createRevision: ({ commit, getters }, revision) => {
    const { organizationId } = getters
    const params = toRevisionFormData(snakeCaseKeys(revision))

    const request = api
      .post(`organizations/${organizationId}/revisions`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },
  saveRevision: ({ commit, getters: { organizationId } }, revision) => {
    const params = toRevisionFormData(snakeCaseKeys(revision))

    const request = api
      .put(`organizations/${organizationId}/revisions/${revision.id}`, params)
      .then(response => {
        commit(types.UPDATE_RESOURCES, response)
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.REMOVE_RESOURCE_RESPONSE_ERRORS)
      })
      .catch(error => {
        commit(types.REMOVE_RESOURCE_REQUEST, { type, request })
        commit(types.ADD_RESOURCE_RESPONSE_ERRORS, error.response)
        throw error
      })
    commit(types.ADD_RESOURCE_REQUEST, { type, request })

    return request
  },
}

export default {
  actions,
  getters: storeGetters,
}
