<template>
  <ContentSection>
    <Steps
      :step-names="[
        t('request.steps.start'),
        t('request.steps.responsible'),
        t('request.steps.status'),
        t('request.steps.other'),
        t('request.steps.inquiry'),
      ]"
    >
      <Step :step-name="t('request.steps.start')">
        <FieldContainer>
          <FieldHeader>
            {{ t('request.form.request_from_question') }}
            <HelpTooltip tooltip-key="request.name" />
          </FieldHeader>
          <Field
            v-model="formData.name"
            v-validate="'required'"
            :error="errors.first('name')"
            :placeholder="t('request.placeholders.name')"
            name="name"
            type="text"
          />
          <Field
            v-model="formData.address"
            type="textarea"
            :placeholder="t('request.placeholders.street')"
          />
          <Field
            v-model="formData.email"
            :placeholder="t('request.placeholders.mail')"
          />
          <Field
            v-model="formData.phone"
            :placeholder="t('request.placeholders.phone_number')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('request.steps.responsible')">
        <FieldContainer>
          <FieldHeader>
            {{ t('request.form.responsible_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.responsibleId">
            <option :value="null">
              {{ t('shared.form.select_responsible_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('request.form.responsible_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('request.form.executing_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.executingId">
            <option :value="null">
              {{ t('shared.form.select_executing_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('request.form.executing_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('request.steps.status')">
        <FieldContainer>
          <FieldHeader
            >{{ t('request.form.request_type_question')
            }}<HelpTooltip tooltip-key="request.kind"
          /></FieldHeader>

          <RadioField v-model="formData.kind" value="right_of_access">
            {{ t('request.kind.right_of_access') }}
          </RadioField>

          <RadioField
            v-model="formData.kind"
            value="right_to_restrict_procesessing"
          >
            {{ t('request.kind.right_to_restrict_procesessing') }}
          </RadioField>

          <RadioField v-model="formData.kind" value="right_of_rectification">
            {{ t('request.kind.right_of_rectification') }}
          </RadioField>

          <RadioField v-model="formData.kind" value="right_to_erasure">
            {{ t('request.kind.right_to_erasure') }}
          </RadioField>

          <RadioField v-model="formData.kind" value="right_of_data_portability">
            {{ t('request.kind.right_of_data_portability') }}
          </RadioField>

          <RadioField v-model="formData.kind" value="other">
            {{ t('request.kind.other') }}
          </RadioField>

          <FieldDisclaimer v-if="formData.kind == 'right_of_access'">
            <strong>{{ t('request.kind.right_of_access') }}:</strong>
            {{ t('request.form.right_of_access_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer
            v-if="formData.kind == 'right_to_restrict_procesessing'"
          >
            <strong
              >{{ t('request.kind.right_to_restrict_procesessing') }}:</strong
            >
            {{ t('request.form.right_to_restrict_procesessing_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.kind == 'right_of_rectification'">
            <strong>{{ t('request.kind.right_of_rectification') }}:</strong>
            {{ t('request.form.right_of_rectification_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.kind == 'right_to_erasure'">
            <strong>{{ t('request.kind.right_to_erasure') }}:</strong>
            {{ t('request.form.right_to_erasure_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.kind == 'right_of_data_portability'">
            <strong>{{ t('request.kind.right_of_data_portability') }}:</strong>
            {{ t('request.form.right_of_data_portability_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.kind == 'other'">
            {{ t('request.form.other_help_text') }}
          </FieldDisclaimer>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader
            >{{ t('request.form.request_status_question')
            }}<HelpTooltip tooltip-key="request.status"
          /></FieldHeader>

          <RadioField
            v-model="formData.status"
            value="received"
            @change="clearStatusDates('received')"
          >
            {{ t('request.status.received') }}
          </RadioField>

          <RadioField
            v-model="formData.status"
            value="started"
            @change="clearStatusDates('started')"
          >
            {{ t('request.status.started') }}
          </RadioField>

          <RadioField v-model="formData.status" value="completed">
            {{ t('request.status.completed') }}
          </RadioField>

          <div
            v-if="
              ['received', 'started', 'completed'].indexOf(formData.status) !==
              -1
            "
          >
            <FieldSubHeader>{{
              t('request.form.received_date')
            }}</FieldSubHeader>
            <DatePicker
              v-model="formData.receivedAt"
              :max-date-validator="true"
              :placeholder="t('shared.form.select_date_placeholder')"
            />
          </div>

          <div v-if="['started', 'completed'].indexOf(formData.status) !== -1">
            <FieldSubHeader>{{
              t('request.form.started_date')
            }}</FieldSubHeader>
            <DatePicker
              v-model="formData.startedAt"
              :placeholder="t('shared.form.select_date_placeholder')"
            />
          </div>

          <div v-if="formData.status == 'completed'">
            <FieldSubHeader>{{
              t('request.form.completed_date')
            }}</FieldSubHeader>
            <DatePicker
              v-model="formData.completedAt"
              :placeholder="t('shared.form.select_date_placeholder')"
            />
          </div>
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step v-if="questions.length" :step-name="t('request.steps.other')">
        <FieldContainer v-for="question in questions" :key="question.id">
          <FieldHeader>{{ question.question }}</FieldHeader>
          <Field
            :placeholder="question.placeholder"
            :value="findOrBuildQuestionAnswer(question.id).answer"
            name="title"
            type="text"
            @input="
              value => {
                updateQuestionAnswer(question.id, value)
              }
            "
          />
        </FieldContainer>
        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('request.steps.inquiry')">
        <FieldContainer>
          <FieldHeader>
            {{ t('request.form.request_reffered_question') }}
            <HelpTooltip tooltip-key="request.concerns" />
          </FieldHeader>
          <Field
            v-model="formData.concerns"
            type="textarea"
            :placeholder="t('shared.form.enter_here_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('request.form.select_file_question') }}
            <HelpTooltip tooltip-key="request.files" />
          </FieldHeader>
          <FileUpload
            :existing-files="existingFiles"
            @onFilesChange="onFilesChange"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>
    </Steps>
  </ContentSection>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { cloneDeep } from 'lodash'

import ContentSection from '@/components/ContentSection'
import { Steps, Step } from '@/components/Steps'
import DatePicker from '@/components/DatePicker'
import FileUpload from '@/components/FileUpload'
import HelpTooltip from '@/components/HelpTooltip'
import FieldContainer from '@/components/FieldContainer'
import FieldHeader from '@/components/FieldHeader'
import FieldSubHeader from '@/components/FieldSubHeader'
import FieldDisclaimer from '@/components/FieldDisclaimer'
import Field from '@/components/Field'
import RadioField from '@/components/RadioField'
import FormControls from '@/components/FormControls'
import FormSelect from '@/components/FormSelect'
import withCustomQuestions from '@/mixins/withCustomQuestions'

export default {
  name: 'RequestForm',
  components: {
    ContentSection,
    FileUpload,
    DatePicker,
    HelpTooltip,
    FieldContainer,
    FieldHeader,
    FieldSubHeader,
    FieldDisclaimer,
    Field,
    RadioField,
    FormControls,
    Steps,
    Step,
    FormSelect,
  },
  mixins: [withCustomQuestions],
  props: {
    request: VueTypes.shape({
      id: String,
      name: String,
      address: String,
      phone: String,
      email: String,
      kind: String,
      status: String,
      concerns: String,
      ourReaction: String,
      receivedAt: VueTypes.oneOfType([String, null]),
      startedAt: VueTypes.oneOfType([String, null]),
      completedAt: VueTypes.oneOfType([String, null]),
      responsibleId: VueTypes.oneOfType([String, null]),
      executingId: VueTypes.oneOfType([String, null]),
      files: VueTypes.oneOfType([Array, null]),
      completedFiles: VueTypes.oneOfType([Array, null]),
      customQuestionAnswers: VueTypes.oneOfType([Array, null]),
    }).loose,
  },
  data() {
    return {
      formData: cloneDeep(this.request),
      existingFiles: this.request.files,
      existingCompletedFiles: this.request.completedFiles,
      isCompleting: this.$attrs.completing || false,
      cancelLink: { name: 'RequestsArchive' },
    }
  },
  computed: {
    ...mapGetters(['questions', 'users']),
  },
  watch: {
    request: function (newRequest) {
      this.formData = cloneDeep(newRequest)
    },
  },
  mounted() {
    this.collapseSidebar()
    this.fetchCustomQuestions('requests')
    this.observeScroll()
  },

  methods: {
    ...mapActions(['collapseSidebar', 'fetchCustomQuestions', 'observeScroll']),

    save() {
      this.$validator
        .validateAll()
        .then(() => {
          this.$emit('submit', this.formData)
        })
        .catch(() => {
          console.log('errors exist', this.errors)
        })
    },
    onFilesChange(files) {
      this.formData.files = files
    },
    onCompletedFilesChange(completedFiles) {
      this.formData.completedFiles = completedFiles
    },
    clearStatusDates(status) {
      if (this.formData.status === 'received') {
        this.formData.startedAt = null
        this.formData.completedAt = null
      } else if (this.formData.status === 'started') {
        this.formData.completedAt = null
      }
    },
  },
}
</script>
