<template>
  <TodoForm
    v-if="todo"
    :todo="todo"
    :is-saving="loadingTodos"
    @submit="handleSubmit"
  />
</template>

<script>
import router from '@/routes'
import TodoForm from '@/forms/TodoForm'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TodosEdit',
  components: {
    TodoForm,
  },
  computed: {
    ...mapGetters(['todo', 'loadingTodos']),
  },
  methods: {
    ...mapActions(['saveTodo', 'showFlashMessage']),

    handleSubmit(todo) {
      this.saveTodo(todo).then(payload => {
        if (payload instanceof Error) {
          let errors = payload.payload

          this.errors.clear()

          errors.map(error => {
            this.errors.add({ field: error.source.param })
          })

          let translated_error_messages = errors.map(error => {
            return this.t(
              `dashboard.todos.error_messages.${error.source.param}.${error.detail}`
            )
          })

          this.showFlashMessage({
            message: translated_error_messages.join('</br>'),
          })

          return todo
        }
        router.push({ name: 'Dashboard' })

        this.showFlashMessage({
          message:
            this.t('flash_messages.todo') +
            this.t('flash_messages.update.success'),
        })
      })
    },
  },
}
</script>
