<template>
  <div>
    <ResourceHeader
      :back-link="{
        name: 'DataMappingsShow',
        params: {
          dataMappingId: riskAssessment && riskAssessment.dataMappingId,
        },
      }"
      :title="riskAssessment && riskAssessment.title"
      :timestamp="riskAssessment && riskAssessment.updatedAt"
      :back-link-text="t('shared.back_to_resource')"
      :title-prefix="dataMapping.title"
      :title-prefix-secondary="t('risk_assessment.title')"
    />

    <Loader v-if="!riskAssessment" />
    <ContentSection v-else>
      <ArchiveActionSection>
        <ArchiveTitle>{{ riskAssessment.title }}</ArchiveTitle>
        <ButtonLink :to="{ name: 'RiskAssessmentsEdit' }" type="primary">
          {{ t('shared.actions.edit') }}
        </ButtonLink>
        <Button @click="handleDelete">
          {{ t('shared.actions.delete') }}
        </Button>
      </ArchiveActionSection>

      <div class="RiskAssessmentsShow__graph extended">
        <div class="row">
          <div class="col-md-3 14" style="border-top-left-radius: 30px">
            <div
              v-if="
                riskAssessment.probability === 'small' &&
                riskAssessment.consequences === 'catastrophic'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 24">
            <div
              v-if="
                riskAssessment.probability === 'moderate' &&
                riskAssessment.consequences === 'catastrophic'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 34">
            <div
              v-if="
                riskAssessment.probability === 'high' &&
                riskAssessment.consequences === 'catastrophic'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 44" style="border-top-right-radius: 30px">
            <div
              v-if="
                riskAssessment.probability === 'very_high' &&
                riskAssessment.consequences === 'catastrophic'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 13">
            <div
              v-if="
                riskAssessment.probability === 'small' &&
                riskAssessment.consequences === 'severe'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 23">
            <div
              v-if="
                riskAssessment.probability === 'moderate' &&
                riskAssessment.consequences === 'severe'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 33">
            <div
              v-if="
                riskAssessment.probability === 'high' &&
                riskAssessment.consequences === 'severe'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 43">
            <div
              v-if="
                riskAssessment.probability === 'very_high' &&
                riskAssessment.consequences === 'severe'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 12">
            <div
              v-if="
                riskAssessment.probability === 'small' &&
                riskAssessment.consequences === 'moderate'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 22">
            <div
              v-if="
                riskAssessment.probability === 'moderate' &&
                riskAssessment.consequences === 'moderate'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 32">
            <div
              v-if="
                riskAssessment.probability === 'high' &&
                riskAssessment.consequences === 'moderate'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 42">
            <div
              v-if="
                riskAssessment.probability === 'very_high' &&
                riskAssessment.consequences === 'moderate'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 11" style="border-bottom-left-radius: 30px">
            <div
              v-if="
                riskAssessment.probability === 'small' &&
                riskAssessment.consequences === 'small'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 21">
            <div
              v-if="
                riskAssessment.probability === 'moderate' &&
                riskAssessment.consequences === 'small'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 31">
            <div
              v-if="
                riskAssessment.probability === 'high' &&
                riskAssessment.consequences === 'small'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 41" style="border-bottom-right-radius: 30px">
            <div
              v-if="
                riskAssessment.probability === 'very_high' &&
                riskAssessment.consequences === 'small'
              "
              class="dot"
            >
              <router-link :to="{ name: 'RiskAssessmentsEdit' }">
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
        </div>
        <p class="x_axis">
          {{ t('risk_assessment.show.probability') }}
        </p>
        <p class="y_axis">
          {{ t('risk_assessment.show.consequence') }}
        </p>
      </div>

      <ArchiveActionSection>
        <ArchiveTitle>{{
          t('risk_assessment.show.mitigation_title')
        }}</ArchiveTitle>
        <ButtonLink :to="{ name: 'MitigationMeasuresNew' }" type="primary">
          {{ t('risk_assessment.show.new_mitigation_measure') }}
        </ButtonLink>
      </ArchiveActionSection>

      <TableWrapper v-if="riskAssessment.mitigationMeasures.length > 0">
        <table>
          <thead class="column-count-3">
            <th>{{ t('risk_assessment.show.title') }}</th>
            <th>{{ t('risk_assessment.show.last_edited') }}</th>
            <th />
          </thead>
          <tbody class="column-count-3">
            <tr
              v-for="mitigationMeasure in riskAssessment.mitigationMeasures"
              :key="mitigationMeasure.id"
            >
              <td>{{ mitigationMeasure.title }}</td>
              <td>{{ mitigationMeasure.updatedAt | formatDateHuman }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-edit="{
                    path: `${riskAssessment.id}/mitigation-measures/${mitigationMeasure.id}/edit`,
                  }"
                  :resource="mitigationMeasure"
                  @delete="handleMitigationMeasureDelete(mitigationMeasure)"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </ContentSection>
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'

import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ResourceHeader from '@/components/ResourceHeader'
import ButtonLink from '@/components/ButtonLink'
import Button from '@/components/Button'
import ArchiveTitle from '@/components/ArchiveTitle'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import TableWrapper from '@/components/TableWrapper'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'

export default {
  name: 'RiskAssessmentsShow',
  components: {
    Loader,
    ContentSection,
    ResourceHeader,
    ButtonLink,
    Button,
    ArchiveTitle,
    ArchiveActionSection,
    TableWrapper,
    ArchiveTableDropdown,
  },
  data: () => ({
    search: '',
    ascending: false,
  }),
  computed: {
    ...mapGetters(['riskAssessment', 'dataMapping']),
  },
  mounted() {
    this.collapseSidebar()
    this.fetchDataMapping()
    this.fetchRiskAssessment()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'fetchDataMapping',
      'fetchRiskAssessment',
      'deleteMitigationMeasure',
      'deleteRiskAssessment',
      'showFlashMessage',
    ]),

    handleDelete() {
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') +
            this.riskAssessment.title
        )
      ) {
        this.deleteRiskAssessment(this.riskAssessment)
          .then(() => {
            router.push({
              name: 'DataMappingsShow',
              params: { dataMappingId: this.$route.params.dataMappingId },
            })
            this.showFlashMessage({
              message:
                this.t('flash_messages.risk_assessment') +
                this.t('flash_messages.delete.success'),
            })
          })
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.risk_assessment') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },

    handleMitigationMeasureDelete(mitigationMeasure) {
      if (!confirm(this.t('flash_messages.custom.mitigation_measure_confirm')))
        return

      this.deleteMitigationMeasure(mitigationMeasure)
    },
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
