import snakeCase from './snakeCase'

const snakeCaseKeys = object => {
  if (!object) return object

  if (Array.isArray(object)) {
    return object.map(snakeCaseKeys)
  }

  if (object.constructor === Object) {
    return Object.entries(object).reduce((result, [key, value]) => {
      result[snakeCase(key)] = snakeCaseKeys(value)
      return result
    }, {})
  }

  return object
}

export default snakeCaseKeys
