<template>
  <footer v-if="hasSteps" class="FormControl">
    <PreviousStep class="FormControl__previous">
      <Button type="secondary">
        {{ t('shared.form_footer.previous') }}
      </Button>
    </PreviousStep>
    <NextStep class="FormControl__next">
      <Button type="secondary">
        {{ t('shared.form_footer.next') }}
      </Button>
    </NextStep>
    <router-link v-if="cancelLink" :to="cancelLink">
      <Button class="FormControl__cancel">
        {{ t('shared.form_footer.cancel') }}
      </Button>
    </router-link>
    <FinalizeSteps class="FormControl__finalize">
      <Button v-if="isSaving">
        {{ t('shared.form_footer.saving') }}
      </Button>
      <Button v-else type="primary" @click="$emit('finalize')">
        {{ t('shared.form_footer.finish') }}
      </Button>
    </FinalizeSteps>
  </footer>
  <footer v-else class="FormControl">
    <router-link v-if="cancelLink" :to="cancelLink">
      <Button class="FormControl__finalize">
        {{ t('shared.form_footer.cancel') }}
      </Button>
    </router-link>
    <Button v-if="isSaving" class="FormControl__finalize">
      {{ t('shared.form_footer.saving') }}
    </Button>
    <Button
      v-else
      class="FormControl__finalize"
      type="primary"
      @click="$emit('finalize')"
    >
      {{ t('shared.form_footer.finish') }}
    </Button>
  </footer>
</template>

<script>
import './style.scss'
import PreviousStep from '@/components/Steps/PreviousStep'
import NextStep from '@/components/Steps/NextStep'
import FinalizeSteps from '@/components/Steps/FinalizeSteps'
import Button from '@/components/Button'

export default {
  name: 'FormControl',
  components: { PreviousStep, NextStep, FinalizeSteps, Button },
  inject: { hasPreviousStep: { default: null } },
  props: {
    isSaving: { type: Boolean, defaultValue: false },
    cancelLink: { type: Object, required: false },
  },
  computed: {
    hasSteps() {
      // Render steps if step method has been provided
      return !!this.hasPreviousStep
    },
  },
}
</script>
