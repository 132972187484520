<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'DataProcessorsArchive' }"
      :back-link-text="t('shared.back_to_archive')"
      :title="t('data_processor.new.title')"
      :title-prefix="t('data_processor.archive.title')"
    />

    <DataProcessorForm :data-processor="$data" @submit="handleSubmit" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/routes'
import ResourceHeader from '@/components/ResourceHeader'
import DataProcessorForm from '@/forms/DataProcessorForm'

export default {
  name: 'DataProcessorNew',
  components: {
    DataProcessorForm,
    ResourceHeader,
  },
  data() {
    return {
      name: '',
      technical: 'other',
      purpose: '',
      status: 'draft',
      customQuestionAnswers: [],
    }
  },
  computed: {
    ...mapGetters(['departments']),
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'createDataProcessor',
      'collapseSidebar',
      'showFlashMessage',
    ]),

    handleSubmit(dataProcessor) {
      this.createDataProcessor(dataProcessor)
        .then(payload => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.data_processor') +
              this.t('flash_messages.create.success'),
          })
          router.push({
            name: 'DataProcessorsShow',
            params: { dataProcessorId: payload.data.id },
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.data_processor') +
              this.t('flash_messages.create.error'),
          })
        })
    },
  },
}
</script>
