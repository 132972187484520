<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'DocumentsArchive' }"
      :title="document && document.title"
      :timestamp="document && document.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('document.archive.title')"
    />

    <Loader v-if="!document" />
    <ContentSection v-else>
      <ResourceActions>
        <ButtonLink
          :to="{ name: 'DocumentsEdit', params: { id: document.id } }"
          type="primary"
        >
          {{ t('document.revisions.edit') }}
        </ButtonLink>
        <Button @click="handleDelete">
          {{ t('document.revisions.delete') }}
        </Button>
      </ResourceActions>

      <Columns>
        <div>
          <ColumnHeader>{{ t('document.revisions.logbook') }}</ColumnHeader>
          <List>
            <TimelineItem
              :type="canRevision ? 'current' : 'future'"
              :date="document.nextRevisionAt"
              :overdue="overdue"
            >
              {{
                canRevision
                  ? t('document.revisions.ready_for_check')
                  : t('document.revisions.upcoming_checks')
              }}
            </TimelineItem>

            <TimelineItem
              v-for="revision in sortedRevisions(document.revisions)"
              :key="revision.id"
              :date="revision.revisionedAt"
              :overdue="revision.overdue"
              :resource="revision"
              type="past"
            >
              {{ revision.description }}
              <div v-if="revision.files.length">
                <List>
                  <FileListItem
                    v-for="file in revision.files"
                    :key="file.name"
                    :file="file"
                  />
                </List>
              </div>
              <Button
                v-if="document.revisions[0] === revision"
                class="Button--sm-flex"
                @click="handleEdit(revision)"
              >
                {{ t('shared.reviews.edit') }}
              </Button>
            </TimelineItem>
          </List>
        </div>
        <div>
          <div v-if="canRevision || isEditing" class="log_form">
            <ColumnHeader>
              {{ t('document.revisions.describe_execution_title') }}
            </ColumnHeader>
            <Field
              v-model="formData.description"
              v-validate="'required'"
              type="textarea"
              :placeholder="t('shared.form.description_placeholder')"
              class="Field--textarea-wide"
              name="description"
              :error="errors.first('description')"
            />
            <p v-if="isEditing">
              {{ t('shared.reviews.edit_warning') }}
            </p>
            <FileUpload
              :existing-files="existingFiles"
              @onFilesChange="onFilesChange"
            />
            <Actions v-if="isEditing">
              <Button type="primary" @click="handleSave()">
                {{ t('shared.reviews.submit') }}
              </Button>
              <Button @click="resetFormData()">
                {{ t('shared.reviews.cancel') }}
              </Button>
            </Actions>
            <Actions v-else>
              <Button type="primary" @click="handleCreate()">
                {{ t('shared.reviews.submit') }}
              </Button>
            </Actions>
          </div>
          <div v-else>
            <ColumnHeader>
              {{ t('document.revisions.up_to_date_title') }}
            </ColumnHeader>
            <ColumnParagraph>
              {{ t('document.revisions.up_to_date_info') }}
            </ColumnParagraph>
          </div>
        </div>
      </Columns>

      <div v-if="document.externalLink">
        <ColumnHeader>{{ t('document.revisions.link') }}</ColumnHeader>
        <ExternalLink
          :target="document.externalLink"
          :href="document.externalLink"
          :label="document.externalLink"
        />
      </div>

      <div v-if="document.files.length">
        <ColumnHeader>{{ t('document.revisions.files') }}</ColumnHeader>
        <List>
          <FileListItem
            v-for="file in document.files"
            :key="file.name"
            :file="file"
          />
        </List>
      </div>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import i18n from '@/mixins/i18n.js.erb'

import ResourceHeader from '@/components/ResourceHeader'
import Loader from '@/components/Loader'
import ContentSection from '@/components/ContentSection'
import ResourceActions from '@/components/ResourceActions'
import ButtonLink from '@/components/ButtonLink'
import Button from '@/components/Button'
import ColumnHeader from '@/components/ColumnHeader'
import List from '@/components/List'
import ExternalLink from '@/components/ExternalLink'
import FileListItem from '@/components/FileListItem'
import TimelineItem from '@/components/TimelineItem'
import Columns from '@/components/Columns'
import ColumnParagraph from '@/components/ColumnParagraph'
import Field from '@/components/Field'
import Actions from '@/components/Actions'
import FileUpload from '@/components/FileUpload'

export default {
  name: 'DocumentsRevisions',
  components: {
    ResourceHeader,
    Loader,
    ContentSection,
    ResourceActions,
    ButtonLink,
    Button,
    ColumnHeader,
    List,
    ExternalLink,
    FileListItem,
    TimelineItem,
    Columns,
    ColumnParagraph,
    Field,
    Actions,
    FileUpload,
  },
  data() {
    return {
      formData: {
        description: '',
        revisionedAt: moment().format('YYYY-MM-DD'),
      },
      isEditing: false,
      existingFiles: [],
    }
  },
  computed: {
    ...mapGetters(['currentUser', 'document']),

    canRevision() {
      const revisionDate = moment(this.document.nextRevisionAt).format(
        'YYYYMMDD'
      )
      const currentDate = moment().format('YYYYMMDD')

      return parseInt(revisionDate) <= parseInt(currentDate)
    },

    overdue() {
      const revisionDate = moment(this.document.nextRevisionAt).format(
        'YYYYMMDD'
      )
      const currentDate = moment().format('YYYYMMDD')

      return parseInt(revisionDate) < parseInt(currentDate)
    },
  },
  beforeMount() {
    this.fetchDocument()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'fetchDocument',
      'collapseSidebar',
      'deleteDocument',
      'showFlashMessage',
      'createRevision',
      'saveRevision',
      'flushDocumentRelations',
    ]),

    handleCreate() {
      this.formData.document_id = this.document.id

      this.createRevision(this.formData)
        .then(() => {
          this.showFlashMessage({
            message:
              i18n.methods.t('shared.review') +
              i18n.methods.t('flash_messages.create.success'),
          })
          this.flushDocumentRelations(this.document)
          this.fetchDocument()
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              i18n.methods.t('shared.review') +
              i18n.methods.t('flash_messages.create.error'),
          })
        })
    },
    handleSave() {
      if (confirm(i18n.methods.t('shared.reviews.edit_confirmation'))) {
        this.saveRevision(this.formData)
          .then(() => {
            this.showFlashMessage({
              message:
                i18n.methods.t('shared.review') +
                i18n.methods.t('flash_messages.update.success'),
            })
            this.flushDocumentRelations(this.document)
            this.fetchDocument()
            this.resetFormData()
          })
          .catch(() => {
            this.showFlashMessage({
              message:
                i18n.methods.t('shared.review') +
                i18n.methods.t('flash_messages.update.error'),
            })
          })
      }
    },
    handleEdit(revision) {
      this.formData = cloneDeep(revision)
      this.formData.documentId = revision.revisableId
      this.formData.files = []
      this.isEditing = true
    },
    resetFormData() {
      this.formData.description = []
      this.formData.documentId = null
      this.formData.id = null
      this.formData.revisionedAt = moment().format('YYYY-MM-DD')
      this.isEditing = false
    },
    handleDelete() {
      if (
        confirm(`Er du sikker på at du vil slette "${this.document.title}"`)
      ) {
        this.deleteDocument(this.document)
          .then(() => {
            router.push({ name: 'DocumentsArchive' })
            this.showFlashMessage({ message: 'Dokumentet blev slettet' })
          })
          .catch(() =>
            this.showFlashMessage({ message: 'Dokumentet kunne ikke slettes' })
          )
      }
    },

    sortedRevisions(revisions) {
      revisions.sort((a, b) => {
        const sortDirection = -1

        return (
          sortDirection *
          moment.utc(a.revisionedAt).diff(moment.utc(b.revisionedAt))
        )
      })

      return revisions
    },
    onFilesChange(files) {
      this.formData.files = files
    },
  },
}
</script>
