<template>
  <label :class="{ 'Checkbox--checked': checked }" class="Checkbox">
    <input
      :checked="checked"
      type="checkbox"
      value="false"
      @change="$emit('change', $event.target.checked)"
    />
    <span><i class="fa fa-check" aria-hidden="true" /></span>
  </label>
</template>

<script>
import './style.scss'

export default {
  name: 'CheckBox',
  props: {
    checked: { type: Boolean },
  },
}
</script>
