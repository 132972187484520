<template>
  <section class="UsersNew">
    <Modal :title="t('user.form.title')">
      <div class="form__input-wrapper">
        <Field
          v-model="formData.name"
          fill
          type="text"
          :placeholder="t('user.form.name')"
        />
      </div>
      <div class="form__input-wrapper">
        <Field
          v-model="formData.password"
          fill
          type="password"
          :placeholder="t('user.form.password')"
        />
      </div>
      <div class="form__input-wrapper">
        <Field
          v-model="formData.password_confirmation"
          fill
          type="password"
          :placeholder="t('user.form.password_confirmation')"
        />
      </div>
      <ModalSaveButton @click="save()">
        {{ t('user.form.create') }}
      </ModalSaveButton>
    </Modal>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ModalSaveButton from '@/components/ModalSaveButton'
import Modal from '@/components/Modal'
import Field from '@/components/Field'
import router from '../../routes'

export default {
  name: 'UsersNew',
  components: {
    ModalSaveButton,
    Field,
    Modal,
  },
  data() {
    return {
      formData: {
        name: '',
        password: '',
        password_confirmation: '',
      },
    }
  },
  computed: {
    ...mapState({
      createdUser: state => state.user.createdUser,
    }),
  },
  mounted() {
    this.formData.token = this.$route.query.token
  },
  methods: {
    save() {
      if (this.name !== '' && this.password === this.password_confirmation) {
        this.createUser(this.formData)
          .then(() => {
            this.logIn({
              email: this.createdUser.email,
              password: this.formData.password,
            })
            router.push({ name: 'organizationsArchive' })
          })
          .catch(e => {
            this.showFlashMessage({
              message: this.t('flash_messages.custom.profile_error'),
            })
          })
      } else {
        this.showFlashMessage({
          message: this.t('flash_messages.custom.profile_field_error'),
        })
      }
    },
    ...mapActions(['showFlashMessage', 'createUser', 'logIn']),
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
