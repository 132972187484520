<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'DataProcessorsArchive' }"
      :title="dataProcessor && dataProcessor.name"
      :timestamp="dataProcessor && dataProcessor.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('data_processor.archive.title')"
    />

    <Loader v-if="!dataProcessor" />
    <DataProcessorForm
      v-else
      :data-processor="dataProcessor"
      :is-saving="loadingDataProcessors"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/routes'
import DataProcessorForm from '@/forms/DataProcessorForm'
import Loader from '@/components/Loader'
import ResourceHeader from '@/components/ResourceHeader'

export default {
  name: 'DataProcessorsEdit',
  components: { DataProcessorForm, Loader, ResourceHeader },
  computed: {
    ...mapGetters(['dataProcessor', 'loadingDataProcessors']),
  },
  beforeMount() {
    this.fetchDataProcessor()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'fetchDataProcessor',
      'saveDataProcessor',
      'showFlashMessage',
    ]),
    handleSubmit(system) {
      this.saveDataProcessor(system)
        .then(() => {
          router.push({ name: 'DataProcessorsArchive' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.data_processor') +
              this.t('flash_messages.update.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.data_processor') +
              this.t('flash_messages.update.error'),
          })
        })
    },
  },
}
</script>
