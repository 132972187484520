<template>
  <router-link :to="to" class="LogInModalBackLink">
    <slot />
  </router-link>
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'

export default {
  name: 'LogInModalBackLink',
  props: {
    to: VueTypes.any.isRequired,
  },
}
</script>
