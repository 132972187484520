<template>
  <ContentSection>
    <Steps
      :step-names="[
        t('data_processor.steps.start'),
        t('data_processor.steps.review'),
        t('data_processor.steps.other'),
      ]"
    >
      <Step :step-name="t('data_processor.steps.start')">
        <FieldContainer>
          <FieldHeader>
            {{ t('data_processor.form.name_question') }}
            <HelpTooltip tooltip-key="dataProcessor.name" />
          </FieldHeader>
          <Field
            v-model="formData.name"
            v-validate="'required'"
            :error="errors.first('name')"
            :placeholder="t('shared.form.enter_here_placeholder')"
            name="name"
            type="text"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_processor.form.type_question') }}
            <HelpTooltip tooltip-key="dataProcessor.technical" />
          </FieldHeader>

          <RadioField v-model="formData.technical" value="technical">
            {{ t('data_processor.form.technical') }}
          </RadioField>

          <RadioField v-model="formData.technical" value="other">
            {{ t('data_processor.form.other') }}
          </RadioField>

          <RadioField v-model="formData.technical" value="subprocessor">
            {{ t('data_processor.form.subprocessor') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_processor.form.purpose_question') }}
            <HelpTooltip tooltip-key="dataProcessor.purpose" />
          </FieldHeader>
          <Field
            v-model="formData.purpose"
            type="textarea"
            :placeholder="t('shared.form.enter_here_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_processor.form.risk_assessment_description_question') }}
          </FieldHeader>
          <Field
            v-model="formData.riskAssessment"
            type="textarea"
            :placeholder="t('shared.form.enter_here_placeholder')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('data_processor.steps.review')">
        <FieldContainer>
          <FieldHeader>
            <p>{{ t('data_processor.form.responsible_user_question') }}</p>
          </FieldHeader>

          <FormSelect v-model="formData.responsibleId">
            <option :value="null">
              {{ t('shared.form.select_responsible_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>
            {{ t('data_processor.form.responsible_user_question_help_text') }}
          </p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_processor.form.executing_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.executingId">
            <option :value="null">
              {{ t('shared.form.select_executing_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>
            {{ t('data_processor.form.executing_user_question_help_text') }}
          </p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_processor.form.first_control_date_question') }}
            <HelpTooltip tooltip-key="reviewProcess.firstReviewAt" />
          </FieldHeader>
          <FieldDisclaimer v-if="formData.firstReviewAt">
            {{ formData.firstReviewAt | formatDateHuman }}
          </FieldDisclaimer>
          <DatePicker
            v-else
            v-model="formData.firstReviewAt"
            :min-date-validator="true"
            :placeholder="t('shared.form.select_date_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('data_processor.form.interval_question') }}
            <HelpTooltip tooltip-key="reviewProcess.intervalDays" />
          </FieldHeader>
          <IntervalField v-model="formData.intervalDays" />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step
        v-if="questions.length"
        :step-name="t('data_processor.steps.other')"
      >
        <FieldContainer v-for="question in questions" :key="question.id">
          <FieldHeader>{{ question.question }}</FieldHeader>
          <Field
            :placeholder="question.placeholder"
            :value="findOrBuildQuestionAnswer(question.id).answer"
            name="title"
            type="text"
            @input="
              value => {
                updateQuestionAnswer(question.id, value)
              }
            "
          />
        </FieldContainer>
        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>
    </Steps>
  </ContentSection>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueTypes from 'vue-types'
import { cloneDeep } from 'lodash'
import ContentSection from '@/components/ContentSection'
import HelpTooltip from '@/components/HelpTooltip'
import FieldContainer from '@/components/FieldContainer'
import FieldHeader from '@/components/FieldHeader'
import Field from '@/components/Field'
import RadioField from '@/components/RadioField'
import FormControls from '@/components/FormControls'
import withCustomQuestions from '@/mixins/withCustomQuestions'
import DatePicker from '@/components/DatePicker'
import FormSelect from '@/components/FormSelect'
import FieldDisclaimer from '@/components/FieldDisclaimer'
import IntervalField from '@/components/IntervalField'

import { Steps, Step } from '@/components/Steps'

export default {
  name: 'DataProcessorForm',
  components: {
    HelpTooltip,
    ContentSection,
    FieldContainer,
    FieldHeader,
    Field,
    RadioField,
    FormControls,
    Steps,
    Step,
    DatePicker,
    FormSelect,
    FieldDisclaimer,
    IntervalField,
  },
  mixins: [withCustomQuestions],
  props: {
    dataProcessor: VueTypes.shape({
      id: String,
      name: String,
      technical: String,
      purpose: VueTypes.oneOfType([String, null]),
      riskAssessment: VueTypes.oneOfType([String, null]),
      updatedAt: String,
    }).loose,
  },
  data() {
    return {
      formData: cloneDeep(this.dataProcessor),
      cancelLink: { name: 'DataProcessorsArchive' },
    }
  },
  computed: {
    ...mapGetters(['questions', 'users']),
  },
  watch: {
    dataProcessor: function (newDataProcessor) {
      this.formData = cloneDeep(newDataProcessor)
    },
  },
  mounted() {
    this.fetchCustomQuestions('data_processors')
    this.observeScroll()
  },
  methods: {
    ...mapActions(['fetchCustomQuestions', 'observeScroll']),
    save() {
      this.$validator
        .validateAll()
        .then(() => {
          this.$emit('submit', this.formData)
        })
        .catch(() => {
          console.log('errors exist', this.errors)
        })
    },
  },
}
</script>
