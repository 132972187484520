<template>
  <div class="DataMappingDiagram">
    <div class="flow_headlines">
      <div class="kilde_headline">
        <h2>{{ t('data_mapping.show.collected_information') }}</h2>
      </div>
      <div class="virksomhed_headline">
        <h2>{{ t('data_mapping.show.internal_workflow') }}</h2>
      </div>
      <div class="modtager_headline">
        <h2>{{ t('data_mapping.show.transfered_data') }}</h2>
      </div>
    </div>
    <div class="flow" style="position: relative">
      <div class="kilde">
        <div
          v-for="dataSource in dataMapping.dataSources"
          :key="dataSource.id"
          class="diagram_item"
          style="position: relative"
        >
          <div class="item_data">
            <div class="item_header">
              <div>
                <h6>{{ t('data_mapping.show.source') }}</h6>
                <h4>
                  {{ dataSource.name }}
                </h4>
              </div>
              <span>
                <i
                  :class="
                    columnObtainedInformation.includes(dataSource.id) == true
                      ? 'fa fa-angle-up'
                      : 'fa fa-angle-down'
                  "
                  @click="expand(dataSource.id, columnObtainedInformation)"
                />
              </span>
            </div>

            <div
              v-if="columnObtainedInformation.includes(dataSource.id)"
              class="collapsible_data"
            >
              <div
                v-for="collectedDataType in collectedDataTypes(dataSource)"
                :key="collectedDataType.id"
              >
                <h5>
                  <i
                    v-if="
                      sensitiveDataTypes.includes(
                        collectedDataType.dataTypeTranslationKey
                      )
                    "
                    class="fa fa-lock"
                    style="margin-right: 5px; color: #f8c533"
                  />
                  <template
                    v-if="
                      isCustomDataType(collectedDataType.dataTypeTranslationKey)
                    "
                  >
                    {{ collectedDataType.dataTypeTranslationKey }}
                  </template>
                  <template v-else>
                    {{
                      t(
                        'data_types.' + collectedDataType.dataTypeTranslationKey
                      )
                    }}
                  </template>
                </h5>
                <div
                  v-if="
                    collectedDataType.generalLegalBasisTranslationKey &&
                    collectedDataType.generalLegalBasisTranslationKey == 'other'
                  "
                >
                  <p>
                    <b>{{ t('data_mapping.show.article_6') }}</b>
                    {{ collectedDataType.customGeneralLegalBasis }}
                  </p>
                </div>
                <div
                  v-else-if="collectedDataType.generalLegalBasisTranslationKey"
                >
                  <p>
                    <b>{{ t('data_mapping.show.article_6') }}</b>
                    {{
                      t(
                        'legal_bases.' +
                          collectedDataType.generalLegalBasisTranslationKey
                      )
                    }}
                  </p>
                </div>

                <div
                  v-if="collectedDataType.legalBasisTranslationKey == 'other'"
                >
                  <p>
                    <b
                      v-if="
                        sensitiveDataTypes.includes(collectedDataType.dataType)
                      "
                      >{{ t('data_mapping.show.article_9') }}</b
                    >
                    <b v-else>{{ t('data_mapping.show.article_6') }}</b>
                    {{ collectedDataType.customLegalBasis }}
                  </p>
                </div>
                <div v-else>
                  <p>
                    <b
                      v-if="
                        sensitiveDataTypes.includes(
                          collectedDataType.dataTypeTranslationKey
                        )
                      "
                      >{{ t('data_mapping.show.article_9') }}</b
                    >
                    <b v-else>{{ t('data_mapping.show.article_6') }}</b>
                    {{
                      t(
                        'legal_bases.' +
                          collectedDataType.legalBasisTranslationKey
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="virksomhed">
        <div class="diagram_item">
          <div class="item_data">
            <div class="item_header">
              <h6>{{ t('data_mapping.show.information_on') }}</h6>
              <i
                :class="
                  columnInternalWorkflow.includes(dataMapping.id) == true
                    ? 'fa fa-angle-up'
                    : 'fa fa-angle-down'
                "
                @click="expand(dataMapping.id, columnInternalWorkflow)"
              />
            </div>
            <h4>
              <div
                v-for="dataSubject in dataMapping.dataSubjects"
                :key="dataSubject.id"
              >
                {{ dataSubject.name }}
                <i
                  v-if="!dataSubject.description"
                  class="fa fa-exclamation-triangle warning"
                  @click="showWarning"
                />
              </div>
            </h4>
            <div
              v-if="columnInternalWorkflow.includes(dataMapping.id)"
              class="collapsible_data"
            >
              <div v-if="dataMapping.purpose">
                <h6>{{ t('data_mapping.show.purpose') }}</h6>
                <h5>{{ dataMapping.purpose }}</h5>
              </div>
              <div>
                <h6>{{ t('data_mapping.show.general') }}</h6>
                <h5>{{ dataMapping.department | formatDepartment }}</h5>
              </div>
              <div v-if="dataMapping.storageDuration">
                <h6>{{ t('data_mapping.show.storage_period') }}</h6>
                <h5>
                  {{ dataMapping.storageDuration }}
                  {{ t('data_mapping.show.years') }}
                </h5>
              </div>
              <div>
                <h6>{{ t('data_mapping.show.deletion_procedure') }}</h6>
                <h5 v-if="dataMapping.deletionDocumentProcedureId">
                  {{ t('shared.yes_reply') }}
                </h5>
                <h5 v-else>
                  {{ t('shared.no_reply') }}
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div v-if="dataMapping.inHouseViewers.length > 0" class="diagram_item">
          <div class="item_data">
            <h6>{{ t('data_mapping.show.internal_access') }}</h6>
            <div
              v-for="inHouseViewer in dataMapping.inHouseViewers"
              :key="inHouseViewer.id"
            >
              <h4>{{ inHouseViewer.name }}</h4>
            </div>
          </div>
        </div>
        <div v-if="systems.length > 0" class="diagram_item">
          <div class="item_data">
            <h6>{{ t('data_mapping.show.system') }}</h6>
            <div v-for="system in systems" :key="system.id">
              <h4>{{ system.name }}</h4>
            </div>
          </div>
        </div>
        <div v-if="dataProcessors.length > 0" class="diagram_item">
          <div class="item_data">
            <h6>{{ t('data_mapping.show.data_processors') }}</h6>
            <div
              v-for="dataProcessor in dataProcessors"
              :key="dataProcessor.id"
            >
              <h4>{{ dataProcessor.name }}</h4>
            </div>
          </div>
        </div>

        <div
          v-if="dataMapping.inUseSafetyMeasures.length > 0"
          class="diagram_item"
        >
          <div class="item_data">
            <div class="item_header">
              <div>
                <h6>{{ t('data_mapping.show.security') }}</h6>
                <h4>
                  {{ t('data_mapping.show.technical') }}
                </h4>
              </div>
              <span>
                <i
                  :class="
                    columnInternalWorkflow.includes('security') == true
                      ? 'fa fa-angle-up'
                      : 'fa fa-angle-down'
                  "
                  @click="expand('security', columnInternalWorkflow)"
                />
              </span>
            </div>
            <div
              v-if="columnInternalWorkflow.includes('security')"
              class="collapsible_data"
            >
              <div
                v-if="
                  safetyMeasuresForTier(1, dataMapping.inUseSafetyMeasures)
                    .length > 0
                "
              >
                <h6>Tier 1</h6>
                <div
                  v-for="inUseSafetyMeasure in safetyMeasuresForTier(
                    1,
                    dataMapping.inUseSafetyMeasures
                  )"
                  :key="inUseSafetyMeasure.id"
                >
                  <h5>
                    {{ t('safety_measures.' + inUseSafetyMeasure) }}
                  </h5>
                </div>
              </div>
              <div
                v-if="
                  safetyMeasuresForTier(2, dataMapping.inUseSafetyMeasures)
                    .length > 0
                "
              >
                <h6>Tier 2</h6>
                <div
                  v-for="inUseSafetyMeasure in safetyMeasuresForTier(
                    2,
                    dataMapping.inUseSafetyMeasures
                  )"
                  :key="inUseSafetyMeasure.id"
                >
                  <h5>
                    {{ t('safety_measures.' + inUseSafetyMeasure) }}
                  </h5>
                </div>
              </div>
              <div
                v-if="
                  safetyMeasuresForTier(3, dataMapping.inUseSafetyMeasures)
                    .length > 0
                "
              >
                <h6>Tier 3</h6>
                <div
                  v-for="inUseSafetyMeasure in safetyMeasuresForTier(
                    3,
                    dataMapping.inUseSafetyMeasures
                  )"
                  :key="inUseSafetyMeasure.id"
                >
                  <h5>
                    {{ t('safety_measures.' + inUseSafetyMeasure) }}
                  </h5>
                </div>
              </div>
              <div
                v-if="
                  safetyMeasuresForTier(4, dataMapping.inUseSafetyMeasures)
                    .length > 0
                "
              >
                <h6>Tier 4</h6>
                <div
                  v-for="inUseSafetyMeasure in safetyMeasuresForTier(
                    4,
                    dataMapping.inUseSafetyMeasures
                  )"
                  :key="inUseSafetyMeasure.id"
                >
                  <h5>
                    {{ t('safety_measures.' + inUseSafetyMeasure) }}
                  </h5>
                </div>
              </div>
              <div
                v-if="
                  safetyMeasuresForTier(
                    'custom',
                    dataMapping.inUseSafetyMeasures
                  ).length > 0
                "
              >
                <h6>{{ t('data_mapping.show.other') }}</h6>
                <div
                  v-for="inUseSafetyMeasure in safetyMeasuresForTier(
                    'custom',
                    dataMapping.inUseSafetyMeasures
                  )"
                  :key="inUseSafetyMeasure.id"
                >
                  <h5>
                    {{ inUseSafetyMeasure }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modtager">
        <div
          v-for="dataRecipient in dataMapping.dataRecipients"
          :key="dataRecipient.id"
          class="diagram_item"
          style="position: relative"
        >
          <div class="item_data">
            <div class="item_header">
              <div>
                <h6>{{ t('data_mapping.show.receiver') }}</h6>
                <h4>
                  {{ dataRecipient.name }}
                </h4>
              </div>
              <span>
                <i
                  :class="
                    columnDisclosedInformation.includes(dataRecipient.id) ==
                    true
                      ? 'fa fa-angle-up'
                      : 'fa fa-angle-down'
                  "
                  @click="expand(dataRecipient.id, columnDisclosedInformation)"
                />
              </span>
            </div>

            <div
              v-if="columnDisclosedInformation.includes(dataRecipient.id)"
              class="collapsible_data"
            >
              <div
                v-for="sentDataType in sentDataTypes(dataRecipient)"
                :key="sentDataType.id"
              >
                <h5>
                  <i
                    v-if="
                      sensitiveDataTypes.includes(
                        sentDataType.dataTypeTranslationKey
                      )
                    "
                    class="fa fa-lock"
                    style="margin-right: 5px; color: #f8c533"
                  />
                  <template
                    v-if="isCustomDataType(sentDataType.dataTypeTranslationKey)"
                  >
                    {{ sentDataType.dataTypeTranslationKey }}
                  </template>
                  <template v-else>
                    {{ t('data_types.' + sentDataType.dataTypeTranslationKey) }}
                  </template>
                </h5>
                <div
                  v-if="
                    sentDataType.generalLegalBasisTranslationKey &&
                    sentDataType.generalLegalBasisTranslationKey == 'other'
                  "
                >
                  <p>
                    <b>{{ t('data_mapping.show.article_6') }}</b>
                    {{ sentDataType.customGeneralLegalBasis }}
                  </p>
                </div>
                <div v-else-if="sentDataType.generalLegalBasisTranslationKey">
                  <p>
                    <b>{{ t('data_mapping.show.article_6') }}</b>
                    {{
                      t(
                        'legal_bases.' +
                          sentDataType.generalLegalBasisTranslationKey
                      )
                    }}
                  </p>
                </div>

                <div v-if="sentDataType.legalBasisTranslationKey == 'other'">
                  <p>
                    <b
                      v-if="
                        sensitiveDataTypes.includes(
                          sentDataType.dataTypeTranslationKey
                        )
                      "
                      >{{ t('data_mapping.show.article_9') }}</b
                    >
                    <b v-else>{{ t('data_mapping.show.article_6') }}</b>
                    {{ sentDataType.customLegalBasis }}
                  </p>
                </div>
                <div v-else>
                  <p>
                    <b
                      v-if="
                        sensitiveDataTypes.includes(
                          sentDataType.dataTypeTranslationKey
                        )
                      "
                      >{{ t('data_mapping.show.article_9') }}</b
                    >
                    <b v-else>{{ t('data_mapping.show.article_6') }}</b>
                    {{
                      t('legal_bases.' + sentDataType.legalBasisTranslationKey)
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tredjeland">
          <template v-for="dataSource in dataMapping.dataSources">
            <div
              v-if="dataSource.hasEuTransfers == true"
              :key="dataSource.id"
              class="diagram_item"
              style="position: relative"
            >
              <div class="item_data">
                <div class="item_header">
                  <div>
                    <h6>{{ t('data_mapping.show.receiver') }}</h6>
                    <h4>
                      <i class="fa fa-globe" style="margin-right: 5px" />
                      {{ t('data_mapping.show.third_country') }}
                    </h4>
                  </div>
                  <span>
                    <i
                      :class="
                        columnDisclosedInformation.includes(dataSource.id) ==
                        true
                          ? 'fa fa-angle-up'
                          : 'fa fa-angle-down'
                      "
                      @click="expand(dataSource.id, columnDisclosedInformation)"
                    />
                  </span>
                </div>

                <div
                  v-if="columnDisclosedInformation.includes(dataSource.id)"
                  class="collapsible_data"
                >
                  <h6>{{ t('data_mapping.show.from_source') }}</h6>
                  <h5>{{ dataSource.name }}</h5>
                  <h6>{{ t('data_mapping.show.transfered_information') }}</h6>
                  <div
                    v-for="collectedDataType in collectedDataTypes(dataSource)"
                    :key="collectedDataType.id"
                  >
                    <div v-if="collectedDataType.isTransferredInEu == true">
                      <h5>
                        <template
                          v-if="
                            isCustomDataType(
                              collectedDataType.dataTypeTranslationKey
                            )
                          "
                        >
                          {{ collectedDataType.dataTypeTranslationKey }}
                        </template>
                        <template v-else>
                          {{
                            t(
                              'data_types.' +
                                collectedDataType.dataTypeTranslationKey
                            )
                          }}
                        </template>
                      </h5>
                    </div>
                  </div>
                  <div class="transfer_basis">
                    <h6>{{ t('data_mapping.show.legal_basis') }}</h6>
                    <h5>
                      {{ dataSource.transferBasis }}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueTypes from 'vue-types'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'DataMappingDiagram',
  props: {
    dataMapping: VueTypes.shape({
      title: String,
      purpose: String,
      storageDuration: String,
      deletionDocumentProcedureId: VueTypes.oneOfType([String, null]),
      dataSources: VueTypes.arrayOf(
        VueTypes.shape({
          name: String,
          collectedDataTypes: VueTypes.arrayOf(
            VueTypes.shape({
              dataType: String,
              legalBasisTranslationKey: String,
              generaLegalBasisTranslationKey: VueTypes.oneOfType([
                String,
                null,
              ]),
              customLegalBasis: VueTypes.oneOfType([String, null]),
            }).loose
          ),
        }).loose
      ),
      dataSubjects: VueTypes.arrayOf(
        VueTypes.shape({
          name: String,
          description: VueTypes.oneOfType([String, null]),
        }).loose
      ),
      dataRecipients: VueTypes.arrayOf(
        VueTypes.shape({
          name: String,
          sentDataTypes: VueTypes.arrayOf(
            VueTypes.shape({
              dataType: String,
              legalBasisTranslationKey: String,
              generalLegalBasisTranslationKey: VueTypes.oneOfType([
                String,
                null,
              ]),
              customLegalBasis: VueTypes.oneOfType([String, null]),
            }).loose
          ),
        }).loose
      ),
      inHouseViewers: VueTypes.arrayOf(VueTypes.shape({ name: String }).loose),
      dataMappingSystems: VueTypes.arrayOf(
        VueTypes.shape({
          system: VueTypes.shape({ name: String }).loose,
        }).loose
      ),
      dataMappingDataProcessors: VueTypes.arrayOf(
        VueTypes.shape({
          dataProcessor: VueTypes.shape({ name: String }).loose,
        }).loose
      ),
    }).loose,
  },
  data() {
    return {
      columnObtainedInformation: [],
      columnInternalWorkflow: [],
      columnDisclosedInformation: [],
    }
  },
  computed: {
    ...mapState({
      sensitiveDataTypes: state => state.dataTypes.sensitive,
      nonsensitiveDataTypes: state => state.dataTypes.nonsensitive,
      safetyMeasures: state => state.safetyMeasures,
    }),
    systems() {
      return this.dataMapping.dataMappingSystems.map(dms => dms.system)
    },
    dataProcessors() {
      return this.dataMapping.dataMappingDataProcessors.map(
        dmdp => dmdp.dataProcessor
      )
    },
  },
  methods: {
    ...mapActions(['showFlashMessage']),
    expand: function (id, array) {
      if (array.includes(id)) {
        array.splice(array.indexOf(id), 1)
      } else {
        array.push(id)
      }
    },
    showWarning() {
      this.showFlashMessage({
        message: this.t(
          'flash_messages.custom.missing_data_subject_description'
        ),
      })
    },
    collectedDataTypes: function (dataSource) {
      return dataSource.collectedDataTypes
    },
    sentDataTypes: function (dataRecipient) {
      return dataRecipient.sentDataTypes
    },
    isCustomDataType: function (dataTypeTranslationKey) {
      const index = [
        ...this.nonsensitiveDataTypes,
        ...this.sensitiveDataTypes,
      ].indexOf(dataTypeTranslationKey)

      return index === -1 ? true : false
    },

    safetyMeasuresForTier(tier, inUseSafetyMeasures) {
      if (tier === 'custom') {
        const safetyMeasuresWithTiers = this.safetyMeasures.map(
          sm => sm.translationKey
        )
        const inUseSafetyMeasureKeys = inUseSafetyMeasures.map(
          sm => sm.translationKey
        )
        const nonCustomSafetyMeasures = safetyMeasuresWithTiers.filter(el =>
          inUseSafetyMeasureKeys.includes(el)
        )

        return inUseSafetyMeasureKeys.filter(
          el => !nonCustomSafetyMeasures.includes(el)
        )
      } else {
        const inUseSafetyMeasureKeys = inUseSafetyMeasures.map(
          sm => sm.translationKey
        )
        const tierSafetyMeasures = this.safetyMeasures
          .filter(item => item.tier === tier)
          .map(sm => sm.translationKey)

        return tierSafetyMeasures.filter(el =>
          inUseSafetyMeasureKeys.includes(el)
        )
      }
    },
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
