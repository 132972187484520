<template>
  <div class="form">
    <h3>{{ t('organization.edit.data_protector_title') }}</h3>

    <RadioField
      v-model="formData.dataOfficer"
      :value="true"
      @change="enableSave"
    >
      {{ t('shared.yes_reply') }}
    </RadioField>
    <RadioField
      v-model="formData.dataOfficer"
      :value="false"
      @change="enableSave"
    >
      {{ t('shared.no_reply') }}
    </RadioField>
    <br />
    <br />
    <div v-if="formData.dataOfficer === true">
      <div class="form__input-wrapper">
        <label>{{ t('organization.edit.dpo_name') }}</label>
        <input v-model="formData.officerName" type="text" @input="enableSave" />
      </div>
      <div class="form__input-wrapper">
        <label>{{ t('organization.edit.dpo_address') }}</label>
        <input
          v-model="formData.officerAddress"
          type="text"
          @input="enableSave"
        />
      </div>
      <div class="form__input-wrapper">
        <label>{{ t('organization.edit.dpo_phone_number') }}</label>
        <input
          v-model="formData.officerPhoneNumber"
          type="text"
          @input="enableSave"
        />
      </div>
      <div class="form__input-wrapper">
        <label>{{ t('organization.edit.dpo_email') }}</label>
        <input
          v-model="formData.officerEmail"
          type="text"
          @input="enableSave"
        />
      </div>
    </div>
    <div v-if="formData.dataOfficer === false">
      <div class="form__input-wrapper">
        <label>{{ t('organization.edit.no_dpo_reason') }}</label>
        <input
          v-model="formData.noOfficerReason"
          type="text"
          @input="enableSave"
        />
      </div>
    </div>

    <button v-if="isSaving" class="button--save">
      {{ t('organization.edit.saving') }}
    </button>
    <button
      v-else
      :disabled="disabled"
      class="button--save"
      @click="handleOrganizationSubmit()"
    >
      {{ t('organization.edit.save') }}
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'

import RadioField from '@/components/RadioField'

const buildOrganization = () => ({
  officerName: '',
  officerAddress: '',
  officerPhoneNumber: '',
  officerEmail: '',
  noOfficerReason: '',
})

export default {
  name: 'OrganizationOfficerForm',
  components: {
    RadioField,
  },
  props: {
    organization: { type: Object, required: false, default: buildOrganization },
    isSaving: { type: Boolean, required: true },
  },
  data() {
    return {
      formData: cloneDeep(this.organization),
      disabled: true,
    }
  },
  computed: {
    ...mapState({
      expanded: state => !state.sidebar.expanded,
    }),
  },
  methods: {
    handleOrganizationSubmit() {
      this.$emit('submit', {
        organization: this.formData,
      })
      this.disabled = true
    },
    enableSave() {
      this.disabled = false
    },
  },
}
</script>
