<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'DataMappingsArchive' }"
      :title="dataMapping && dataMapping.title"
      :timestamp="dataMapping && dataMapping.updatedAt"
      :back-link-text="t('shared.back_to_archive')"
      :title-prefix="t('data_mapping.archive.title')"
    />

    <Loader v-if="!dataMapping" />
    <ContentSection v-else>
      <ArchiveActionSection>
        <ArchiveTitle>
          {{ dataMapping.title }}
          <i
            v-if="!dataMapping.anyRiskAssessments"
            class="fa fa-exclamation-triangle RiskWarning"
            @click="showWarning"
          />
        </ArchiveTitle>
        <ButtonLink :to="{ name: 'DataMappingsReviews' }" type="primary">
          {{ t('shared.buttons.reviews') }}
        </ButtonLink>
        <ButtonLink
          :to="{
            name: 'DataMappingsEdit',
            params: { dataMappingId: dataMapping.id },
          }"
          type="primary"
        >
          {{ t('shared.actions.edit') }}
        </ButtonLink>
        <Button @click="handleDelete">
          {{ t('shared.actions.delete') }}
        </Button>
      </ArchiveActionSection>
      <DataMappingDiagram :data-mapping="dataMapping" />
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('risk_assessment.title') }}</ArchiveTitle>
        <ButtonLink
          :to="{
            name: 'RiskAssessmentsNew',
            params: { dataMappingId: dataMapping.id },
          }"
          type="primary"
        >
          {{ t('risk_assessment.create_new') }}
        </ButtonLink>
      </ArchiveActionSection>
      <div class="RiskAssessmentsShow__graph">
        <div class="row">
          <div class="col-md-3 14" style="border-top-left-radius: 30px">
            <div v-for="dot in riskAssessments14" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 24">
            <div v-for="dot in riskAssessments24" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 34">
            <div v-for="dot in riskAssessments34" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 44" style="border-top-right-radius: 30px">
            <div v-for="dot in riskAssessments44" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 13">
            <div v-for="dot in riskAssessments13" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 23">
            <div v-for="dot in riskAssessments23" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 33">
            <div v-for="dot in riskAssessments33" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 43">
            <div v-for="dot in riskAssessments43" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 12">
            <div v-for="dot in riskAssessments12" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 22">
            <div v-for="dot in riskAssessments22" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 32">
            <div v-for="dot in riskAssessments32" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 42">
            <div v-for="dot in riskAssessments42" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 11" style="border-bottom-left-radius: 30px">
            <div v-for="dot in riskAssessments11" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 21">
            <div v-for="dot in riskAssessments21" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 31">
            <div v-for="dot in riskAssessments31" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
          <div class="col-md-3 41" style="border-bottom-right-radius: 30px">
            <div v-for="dot in riskAssessments41" :key="dot.id" class="dot">
              <router-link
                :to="{ path: `${dataMapping.id}/risk-assessments/${dot.id}` }"
              >
                <div class="outer" />
                <div class="inner" />
                <span />
              </router-link>
            </div>
          </div>
        </div>
        <p class="x_axis">
          {{ t('risk_assessment.show.probability') }}
        </p>
        <p class="y_axis">
          {{ t('risk_assessment.show.consequence') }}
        </p>
      </div>

      <TableWrapper
        v-if="
          riskAssessments43.length > 0 ||
          riskAssessments34.length > 0 ||
          riskAssessments44.length > 0
        "
      >
        <h2>{{ t('risk_assessment.high_risk') }}</h2>
        <table>
          <thead class="column-count-2">
            <th />
            <th />
          </thead>
          <tbody class="column-count-2">
            <tr
              v-for="riskAssessment in riskAssessments44"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments34"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments43"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>

      <TableWrapper
        v-if="
          riskAssessments13.length > 0 ||
          riskAssessments14.length > 0 ||
          riskAssessments22.length > 0 ||
          riskAssessments23.length > 0 ||
          riskAssessments24.length > 0 ||
          riskAssessments31.length > 0 ||
          riskAssessments32.length > 0 ||
          riskAssessments33.length > 0 ||
          riskAssessments41.length > 0 ||
          riskAssessments42.length > 0
        "
      >
        <h2>{{ t('risk_assessment.medium_risk') }}</h2>
        <table>
          <thead class="column-count-2">
            <th />
            <th />
          </thead>
          <tbody class="column-count-2">
            <tr
              v-for="riskAssessment in riskAssessments42"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments41"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments33"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments32"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments31"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments24"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments23"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments22"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments14"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments13"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>

      <TableWrapper
        v-if="
          riskAssessments11.length > 0 ||
          riskAssessments12.length > 0 ||
          riskAssessments21.length > 0
        "
      >
        <h2>{{ t('risk_assessment.low_risk') }}</h2>
        <table>
          <thead class="column-count-2">
            <th />
            <th />
          </thead>
          <tbody class="column-count-2">
            <tr
              v-for="riskAssessment in riskAssessments21"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments12"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
            <tr
              v-for="riskAssessment in riskAssessments11"
              :key="riskAssessment.id"
            >
              <td>{{ riskAssessment.title }}</td>
              <td>
                <ArchiveTableDropdown
                  :to-view="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}`,
                  }"
                  :to-edit="{
                    path: `${dataMapping.id}/risk-assessments/${riskAssessment.id}/edit`,
                  }"
                  :resource="riskAssessment"
                  @delete="handleRiskAssessmentDelete"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </TableWrapper>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import router from '../../routes'

import Loader from '@/components/Loader'
import DataMappingDiagram from '@/components/DataMappingDiagram'
import ResourceHeader from '@/components/ResourceHeader'
import ContentSection from '@/components/ContentSection'
import ButtonLink from '@/components/ButtonLink'
import Button from '@/components/Button'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import ArchiveTitle from '@/components/ArchiveTitle'
import TableWrapper from '@/components/TableWrapper'

export default {
  name: 'DataMappingShow',
  components: {
    ContentSection,
    ButtonLink,
    Button,
    Loader,
    DataMappingDiagram,
    ResourceHeader,
    ArchiveTableDropdown,
    ArchiveActionSection,
    ArchiveTitle,
    TableWrapper,
  },
  data: () => ({
    search: '',
    ascending: false,
  }),
  computed: {
    ...mapGetters(['dataMapping']),

    ...mapState({
      expanded: state => !state.sidebar.expanded,
      sensitiveDataTypes: state => state.dataTypes.sensitive,
    }),

    loading() {
      return this.$store.getters.loadingRiskAssessments
    },
    riskAssessments() {
      return this.dataMapping.riskAssessments
    },
    riskAssessments11() {
      const result = this.riskAssessments.filter(
        item => item.probability === 'small' && item.consequences === 'small'
      )

      return result
    },
    riskAssessments12() {
      const result = this.riskAssessments.filter(
        item => item.probability === 'small' && item.consequences === 'moderate'
      )

      return result
    },
    riskAssessments13() {
      const result = this.riskAssessments.filter(
        item => item.probability === 'small' && item.consequences === 'severe'
      )

      return result
    },
    riskAssessments14() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'small' && item.consequences === 'catastrophic'
      )

      return result
    },
    riskAssessments21() {
      const result = this.riskAssessments.filter(
        item => item.probability === 'moderate' && item.consequences === 'small'
      )

      return result
    },
    riskAssessments22() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'moderate' && item.consequences === 'moderate'
      )

      return result
    },
    riskAssessments23() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'moderate' && item.consequences === 'severe'
      )

      return result
    },
    riskAssessments24() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'moderate' &&
          item.consequences === 'catastrophic'
      )

      return result
    },
    riskAssessments31() {
      const result = this.riskAssessments.filter(
        item => item.probability === 'high' && item.consequences === 'small'
      )

      return result
    },
    riskAssessments32() {
      const result = this.riskAssessments.filter(
        item => item.probability === 'high' && item.consequences === 'moderate'
      )

      return result
    },
    riskAssessments33() {
      const result = this.riskAssessments.filter(
        item => item.probability === 'high' && item.consequences === 'severe'
      )

      return result
    },
    riskAssessments34() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'high' && item.consequences === 'catastrophic'
      )

      return result
    },
    riskAssessments41() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'very_high' && item.consequences === 'small'
      )

      return result
    },
    riskAssessments42() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'very_high' && item.consequences === 'moderate'
      )

      return result
    },
    riskAssessments43() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'very_high' && item.consequences === 'severe'
      )

      return result
    },
    riskAssessments44() {
      const result = this.riskAssessments.filter(
        item =>
          item.probability === 'very_high' &&
          item.consequences === 'catastrophic'
      )

      return result
    },
  },
  beforeMount() {
    // Ensure that we always start out with a fresh version of the data mapping
    if (this.dataMapping) this.flushDataMappingRelations(this.dataMapping)
    this.fetchDataMapping()
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'collapseSidebar',
      'flushDataMappingRelations',
      'fetchDataMapping',
      'deleteDataMapping',
      'showFlashMessage',
      'deleteRiskAssessment',
    ]),

    handleDelete() {
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') + this.dataMapping.title
        )
      ) {
        this.deleteDataMapping(this.dataMapping)
          .then(() => {
            router.push({ name: 'DataMappingsArchive' })
            this.showFlashMessage({
              message:
                this.t('flash_messages.data_mapping') +
                this.t('flash_messages.delete.success'),
            })
          })
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.data_mapping') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
    showWarning() {
      this.showFlashMessage({
        message: this.t('flash_messages.custom.missing_risk_assessments'),
      })
    },
    handleRiskAssessmentDelete(id) {
      const riskAssessment = this.$store.getters.resource('riskAssessments', id)
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') + riskAssessment.title
        )
      ) {
        this.deleteRiskAssessment(riskAssessment)
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.risk_assessment') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.risk_assessment') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },
  },
}
</script>
