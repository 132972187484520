<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'RequestsArchive' }"
      :back-link-text="t('shared.back_to_archive')"
      :title="t('request.new.title')"
      :title-prefix="t('request.archive.title')"
    />

    <RequestForm :request="$data" @submit="createRequest" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ResourceHeader from '@/components/ResourceHeader'
import RequestForm from '@/forms/RequestForm/RequestForm'

export default {
  name: 'RequestsNew',
  components: {
    ResourceHeader,
    RequestForm,
  },
  data() {
    return {
      name: '',
      address: '',
      phone: '',
      email: '',
      kind: '',
      status: '',
      concerns: '',
      ourReaction: '',
      receivedAt: '',
      startedAt: '',
      completedAt: '',
      files: [],
      customQuestionAnswers: [],
    }
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions(['createRequest', 'collapseSidebar']),
  },
}
</script>
