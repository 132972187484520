<template>
  <section class="FileUpload">
    <ul class="FileUpload__list">
      <li
        v-for="file in existingFiles"
        :key="file.name"
        class="FileUpload__list-item"
      >
        <a
          :href="file.url"
          target="_blank"
          class="FileUpload__list-item__link"
          >{{ file.name }}</a
        >
      </li>
      <li v-for="file in files" :key="file.name" class="FileUpload__list-item">
        <div>
          <span>{{ file.name }}</span>
          <button
            type="button"
            class="FileUpload__list-item__close"
            @click="onFileRemove(file.name)"
          >
            <span>&times;</span>
          </button>
        </div>
      </li>
    </ul>
    <input id="file" type="file" multiple name="file" @change="onFilesChange" />
    <label for="file" class="FileUpload__instruction-label">{{
      t('file_upload.instructions')
    }}</label>
  </section>
</template>

<script>
export default {
  name: 'FileUpload',
  props: {
    existingFiles: {
      type: Array,
      default() {
        return []
      },
      required: false,
    },
  },
  data() {
    return {
      files: [],
      createdFiles: [],
    }
  },
  methods: {
    onFilesChange(event) {
      this.files = event.target.files
      this.$emit('onFilesChange', this.files)
    },
    onFileRemove(fileNameToRemove) {
      const files = []
      for (let x = 0; x < this.files.length; x++) {
        if (this.files[x].name !== fileNameToRemove) {
          files.push(this.files[x])
        }
      }
      this.files = files
      this.$emit('onFilesChange', this.files)
    },
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
