import RequestsArchive from '@/pages/RequestsArchive'
import RequestsNew from '@/pages/RequestsNew'
import RequestsShow from '@/pages/RequestsShow'
import RequestsEdit from '@/pages/RequestsEdit'
import RequestsManage from '@/pages/RequestsManage'

const routes = [
  {
    path: 'requests/new',
    component: RequestsNew,
    name: 'RequestsNew',
  },
  {
    path: 'requests',
    component: RequestsArchive,
    name: 'RequestsArchive',
  },
  {
    path: 'requests/:requestId',
    component: RequestsShow,
    name: 'RequestsShow',
  },
  {
    path: 'requests/:requestId/edit',
    component: RequestsEdit,
    name: 'RequestsEdit',
  },
  {
    path: 'requests/:requestId/manage',
    component: RequestsManage,
    name: 'RequestsManage',
  },
]
export default routes
