import DataProcessorsArchive from '@/pages/DataProcessorsArchive'
import DataProcessorsNew from '@/pages/DataProcessorsNew'
import DataProcessorsShow from '@/pages/DataProcessorsShow'
import DataProcessorsEdit from '@/pages/DataProcessorsEdit'
import DataProcessorsReviews from '@/pages/DataProcessorsReviews'

import DataProcessingAgreementsNew from '@/pages/DataProcessingAgreementsNew'
import DataProcessingAgreementsEdit from '@/pages/DataProcessingAgreementsEdit'

const routes = [
  {
    path: 'data-processors/new',
    component: DataProcessorsNew,
    name: 'DataProcessorsNew',
  },
  {
    path: 'data-processors',
    component: DataProcessorsArchive,
    name: 'DataProcessorsArchive',
  },
  {
    path: 'data-processors/:dataProcessorId',
    component: DataProcessorsShow,
    name: 'DataProcessorsShow',
    children: [
      {
        path: 'data-processing-agreements/new',
        component: DataProcessingAgreementsNew,
        name: 'DataProcessingAgreementsNew',
      },
      {
        path: 'data-processing-agreements/:dataProcessingAgreementId',
        component: DataProcessingAgreementsEdit,
        name: 'DataProcessingAgreementsEdit',
      },
    ],
  },
  {
    path: 'data-processors/:dataProcessorId/edit',
    component: DataProcessorsEdit,
    name: 'DataProcessorsEdit',
  },
  {
    path: 'data-processors/:dataProcessorId/reviews',
    component: DataProcessorsReviews,
    name: 'DataProcessorsReviews',
  },
]
export default routes
