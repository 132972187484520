<template>
  <div v-if="hasPreviousStep()" @click="goToPreviousStep">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Step',
  inject: ['goToPreviousStep', 'hasPreviousStep'],
}
</script>
