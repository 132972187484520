<template>
  <div>
    <FlowSaveClose
      v-if="!isStepping"
      v-bind="$props"
      @finalize="handleFinalize"
    >
      <slot />
    </FlowSaveClose>
    <Steps
      v-if="isStepping"
      v-bind="$props"
      :show-controls="true"
      @finalize="handleFinalize"
    >
      <slot />
    </Steps>
  </div>
</template>

<script>
import FlowSaveClose from '@/components/Flow/FlowSaveClose'
import Steps from '@/components/Steps/Steps'

export default {
  name: 'Flow',
  components: {
    FlowSaveClose,
    Steps,
  },
  props: {
    isStepping: { type: Boolean, defaultValue: true },
    request: { type: Object },
    // props for inner components
    cancelLink: { type: Object, required: false },
    stepNames: { type: Array },
    stepErrors: { type: Array, optional: true },
  },
  methods: {
    handleFinalize() {
      this.$emit('finalize')
    },
  },
}
</script>
