<template>
  <div class="ArchiveHeader">
    <Header>
      <slot name="header-title" />
      <HeaderActions>
        <div class="actions">
          <slot />
          <PartnerLogo />
        </div>
      </HeaderActions>
    </Header>
  </div>
</template>

<script>
import Header from '@/components/Header'
import HeaderActions from '@/components/HeaderActions'
import PartnerLogo from '@/components/PartnerLogo'
import './style.scss'

export default {
  name: 'ArchiveHeaderWithSlots',
  components: {
    Header,
    HeaderActions,
    PartnerLogo,
  },
}
</script>
