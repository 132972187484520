<template>
  <div>
    <ArchiveHeader v-model="search" />

    <Loader v-if="loading" />
    <ContentSection v-else class="fade_in_right">
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('document.archive.title') }}</ArchiveTitle>
        <ArchiveCreateButton :to="{ name: 'DocumentsNew' }" />
      </ArchiveActionSection>

      <TableWrapper v-if="filteredDocuments('political').length">
        <div class="wrapper">
          <h2>{{ t('document.categories.politics') }}</h2>
          <table>
            <thead class="column-count-3">
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-3">
              <tr
                v-for="document in filteredDocuments('political')"
                :key="document.id"
              >
                <td>
                  <router-link
                    :to="{ path: `documents/${document.id}/revisions` }"
                  >
                    {{ document.title }}
                  </router-link>
                  <a
                    v-if="document.externalLink"
                    :target="document.externalLink"
                    :href="document.externalLink"
                    ><span class="fa fa-link"
                  /></a>
                </td>
                <td v-if="document.nextRevisionDate">
                  {{ t('document.archive.next_revision') }}
                  {{ document.nextRevisionDate | formatDateHuman }}
                </td>
                <td v-else />
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `documents/${document.id}/revisions` }"
                    :to-edit="{ path: `documents/${document.id}/edit` }"
                    :resource="document"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
      <TableWrapper v-if="filteredDocuments('procedure').length">
        <div class="wrapper">
          <h2>{{ t('document.categories.procedure') }}</h2>
          <table>
            <thead class="column-count-3">
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-3">
              <tr
                v-for="document in filteredDocuments('procedure')"
                :key="document.id"
              >
                <td>
                  <router-link
                    :to="{ path: `documents/${document.id}/revisions` }"
                  >
                    {{ document.title }}
                  </router-link>
                  <a
                    v-if="document.externalLink"
                    :target="document.externalLink"
                    :href="document.externalLink"
                    ><span class="fa fa-link"
                  /></a>
                </td>
                <td v-if="document.nextRevisionDate">
                  {{ t('document.archive.next_revision') }}
                  {{ document.nextRevisionDate | formatDateHuman }}
                </td>
                <td v-else />
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `documents/${document.id}/revisions` }"
                    :to-edit="{ path: `documents/${document.id}/edit` }"
                    :resource="document"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
      <TableWrapper v-if="filteredDocuments('awareness').length">
        <div class="wrapper">
          <h2>{{ t('document.categories.awareness') }}</h2>
          <table>
            <thead class="column-count-3">
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-3">
              <tr
                v-for="document in filteredDocuments('awareness')"
                :key="document.id"
              >
                <td>
                  <router-link
                    :to="{ path: `documents/${document.id}/revisions` }"
                  >
                    {{ document.title }}
                  </router-link>
                  <a
                    v-if="document.externalLink"
                    :target="document.externalLink"
                    :href="document.externalLink"
                    ><span class="fa fa-link"
                  /></a>
                </td>
                <td v-if="document.nextRevisionDate">
                  {{ t('document.archive.next_revision') }}
                  {{ document.nextRevisionDate | formatDateHuman }}
                </td>
                <td v-else />
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `documents/${document.id}/revisions` }"
                    :to-edit="{ path: `documents/${document.id}/edit` }"
                    :resource="document"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
      <TableWrapper v-if="filteredDocuments('data_processor').length">
        <div class="wrapper">
          <h2>{{ t('document.categories.data_processor') }}</h2>
          <table>
            <thead class="column-count-3">
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-3">
              <tr
                v-for="document in filteredDocuments('data_processor')"
                :key="document.id"
              >
                <td>
                  <router-link
                    :to="{ path: `documents/${document.id}/revisions` }"
                  >
                    {{ document.title }}
                  </router-link>
                  <a
                    v-if="document.externalLink"
                    :target="document.externalLink"
                    :href="document.externalLink"
                    ><span class="fa fa-link"
                  /></a>
                </td>
                <td v-if="document.nextRevisionDate">
                  {{ t('document.archive.next_revision') }}
                  {{ document.nextRevisionDate | formatDateHuman }}
                </td>
                <td v-else />
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `documents/${document.id}/revisions` }"
                    :to-edit="{ path: `documents/${document.id}/edit` }"
                    :resource="document"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
      <TableWrapper v-if="filteredDocuments('other').length">
        <div class="wrapper">
          <h2>{{ t('document.categories.other') }}</h2>
          <table>
            <thead class="column-count-3">
              <th />
              <th />
              <th />
            </thead>
            <tbody class="column-count-3">
              <tr
                v-for="document in filteredDocuments('other')"
                :key="document.id"
              >
                <td>
                  <router-link
                    :to="{ path: `documents/${document.id}/revisions` }"
                  >
                    {{ document.title }}
                  </router-link>
                  <a
                    v-if="document.externalLink"
                    :target="document.externalLink"
                    :href="document.externalLink"
                    ><span class="fa fa-link"
                  /></a>
                </td>
                <td v-if="document.nextRevisionDate">
                  {{ t('document.archive.next_revision') }}
                  {{ document.nextRevisionDate | formatDateHuman }}
                </td>
                <td v-else />
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `documents/${document.id}/revisions` }"
                    :to-edit="{ path: `documents/${document.id}/edit` }"
                    :resource="document"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Loader from '@/components/Loader'
import ArchiveHeader from '@/components/ArchiveHeader'
import ArchiveTitle from '@/components/ArchiveTitle'
import ContentSection from '@/components/ContentSection'
import ArchiveCreateButton from '@/components/ArchiveCreateButton'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import TableWrapper from '@/components/TableWrapper'
import ArchiveActionSection from '@/components/ArchiveActionSection'

export default {
  name: 'DocumentsArchive',
  components: {
    Loader,
    ArchiveHeader,
    ArchiveTitle,
    ContentSection,
    ArchiveCreateButton,
    ArchiveTableDropdown,
    ArchiveActionSection,
    TableWrapper,
  },
  data: () => ({
    search: '',
    ascending: true,
  }),
  computed: {
    loading() {
      return this.$store.getters.loadingDocuments
    },
    documents() {
      return this.$store.getters.documentsForArchive({
        search: this.search,
        ascending: this.ascending,
      })
    },
  },
  mounted() {
    this.fetchDocuments()
    this.expandSidebar()
  },
  methods: {
    ...mapActions([
      'fetchDocuments',
      'expandSidebar',
      'deleteDocument',
      'showFlashMessage',
    ]),

    handleDelete(id) {
      const document = this.$store.getters.resource('documents', id)
      if (
        confirm(this.t('flash_messages.delete.confirmation') + document.title)
      ) {
        this.deleteDocument(document)
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.document') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.document') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },

    filteredDocuments(categoryName) {
      let filteredDocuments = this.documents.filter(
        ({ category }) => category === categoryName
      )

      return filteredDocuments
    },
  },
}
</script>
