<template>
  <h3 class="FieldSubHeader">
    <slot />
  </h3>
</template>

<script>
import './style.scss'

export default {
  name: 'FieldSubHeader',
}
</script>
