<template>
  <div class="LocaleSelectorLogin">
    <ul>
      <li
        :class="currentLocale() === 'da' ? 'current' : ''"
        @click="setLocale('da')"
      >
        <span>DK</span>
      </li>
      <li
        :class="currentLocale() === 'en' ? 'current' : ''"
        @click="setLocale('en')"
      >
        <span>EN</span>
      </li>
      <li
        :class="currentLocale() === 'de' ? 'current' : ''"
        @click="setLocale('de')"
      >
        <span>DE</span>
      </li>
      <li
        :class="currentLocale() === 'fr' ? 'current' : ''"
        @click="setLocale('fr')"
      >
        <span>FR</span>
      </li>      
      <li
        :class="currentLocale() === 'pl' ? 'current' : ''"
        @click="setLocale('pl')"
      >
        <span>PL</span>
      </li>       
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LocaleSelectorLogin',
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
