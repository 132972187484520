<template>
  <input
    v-if="type !== 'textarea'"
    :class="{ 'Field--fill': fill, 'Field--error': !!error }"
    :type="type"
    :value="value"
    :placeholder="placeholder"
    :name="name"
    class="Field"
    @input="updateValue"
    @change="updateValue"
    @blur="$emit('blur', $event.target.value)"
  />
  <textarea
    v-else
    :class="{ 'Field--fill': fill, 'Field--error': !!error }"
    :value="value"
    :placeholder="placeholder"
    :name="name"
    class="Field Field--textarea"
    @input="updateValue"
    @change="updateValue"
    @blur="$emit('blur', $event.target.value)"
  />
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'

export default {
  name: 'Field',
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  props: {
    type: { type: String, required: false, defaultValue: 'text' },
    value: VueTypes.any,
    placeholder: { type: String, required: false, defaultValue: '' },
    fill: { type: Boolean, required: false, defaultValue: false },
    name: { type: String },
    error: { type: String, required: false },
  },
  methods: {
    updateValue(e) {
      this.$emit('input', e.target.value)
    },
  },
}
</script>
