<template>
  <ul class="List">
    <slot />
  </ul>
</template>

<script>
import './style.scss'

export default {
  name: 'List',
}
</script>
