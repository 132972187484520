<template>
  <div>
    <Loader v-if="!organization" />
    <div
      v-else
      :class="{ 'subscribtion-wrapper--accepted': requestSubscription }"
      class="subscribtion-wrapper"
    >
      <div v-if="!requestSubscription" class="subscribtion-wrapper-content">
        <img src="./hourglass.svg" />
        <h1>{{ t('subscribe.title') }}</h1>
        <p>
          {{ t('subscribe.description') }}<span>{{ t('subscribe.link') }}</span>
        </p>
        <Button
          class="Button--primary"
          @click="requestSubscription = !requestSubscription"
        >
          {{ t('subscribe.create') }}
        </Button>
        <Button class="Button--dark" @click="declineSubscription">
          {{ t('subscribe.decline') }}
        </Button>
      </div>
      <div
        v-else
        class="subscribtion-wrapper-content subscribtion-wrapper--accepted-content"
      >
        <h1>{{ t('subscribe.form.title') }}</h1>
        <SubscriptionForm
          :organization="organization"
          @submit="handleOrganizationSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import './style.scss'
import SubscriptionForm from '@/forms/SubscriptionForm'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/Loader'
import Button from '@/components/Button'

export default {
  name: 'Subscribe',
  components: {
    Loader,
    Button,
    SubscriptionForm,
  },
  data() {
    return {
      requestSubscription: false,
      acceptedTerms: false,
    }
  },
  computed: {
    ...mapGetters(['organization']),
  },
  mounted() {
    this.fetchOrganization()
  },
  methods: {
    ...mapActions([
      'fetchOrganization',
      'saveOrganization',
      'showFlashMessage',
    ]),
    handleOrganizationSubmit({ organization }) {
      this.saveOrganization(organization)
        .then(() => {
          this.$router.push({ path: 'dashboard' })
        })
        .catch(() => {
          this.showFlashMessage({
            message: this.t('flash_messages.custom.subscribe_error'),
          })
        })
    },
    declineSubscription() {
      this.$router.push({
        path: '/',
      })
    },
  },
}
</script>
