<template>
  <div class="StepBreadcrumbs">
    <h3
      v-for="(item, index) in steps"
      :key="index"
      :class="
        item == currentStep
          ? 'StepBreadcrumb__item active'
          : 'StepBreadcrumb__item'
      "
    >
      {{ item.name }}
    </h3>
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'StepBreadcrumbs',
  props: {
    steps: { type: Array },
    currentStep: { type: Object },
  },
}
</script>
