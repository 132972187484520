const type = 'users'

const storeGetters = {
  users: (_state, getters) => getters.resources(type),

  userOptions: (_state, getters) => [
    { name: 'Vælg en ansvarlig', id: null },
    ...getters.users,
  ],
}

export default {
  getters: storeGetters,
}
