import PasswordReset from '@/pages/PasswordReset'
import PasswordForgotten from '@/pages/PasswordForgotten'

const routes = [
  {
    path: '/password/reset',
    component: PasswordReset,
    name: 'PasswordReset',
  },
  {
    path: '/password/forgotten',
    component: PasswordForgotten,
    name: 'PasswordForgotten',
  },
]

export default routes
