const normalizeEntity = ({ type, id, attributes, relationships }) => {
  const normalized = { type, id, ...attributes }

  if (relationships) {
    Object.keys(relationships).forEach(key => {
      const { data } = relationships[key]

      // This is intentional, because the arrays of ids can easily get out of sync.
      // To prevent such a situation "has_many" relations must instead be selected
      // by filtering all records that might be related.
      // If performance becomes an issue, we should look at it later.
      if (Array.isArray(data)) return

      if (!data) {
        normalized[`${key}Id`] = null
      } else if (typeof data === 'object') {
        normalized[`${key}Id`] = data.id
        if (data.type) {
          normalized[`${key}Type`] = data.type
        }
      }
    })
  }

  return normalized
}

const normalizeResponse = payload => {
  const normalized = {
    resources: [],
    errors: null,
  }

  if (payload.errors) {
    normalized.errors = payload.errors
    return normalized
  }

  if (!payload.data) {
    return normalized
  }

  const { data, included } = payload
  const collection = Array.isArray(data) ? data : [data]

  normalized.resources = collection.concat(included || []).map(normalizeEntity)

  return normalized
}

export default normalizeResponse
