<template>
  <label
    :class="{
      'RadioField--checked': shouldBeChecked,
      'RadioField--inline': inline,
    }"
    class="RadioField"
  >
    <input
      :value="value"
      :checked="shouldBeChecked"
      type="radio"
      @change="$emit('change', value)"
    />
    <span class="RadioField__radio"
      ><i class="fa fa-check" aria-hidden="true"
    /></span>
    <span class="RadioField__description"><slot /></span>
  </label>
</template>

<script>
import './style.scss'
import VueTypes from 'vue-types'

export default {
  name: 'RadioField',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: VueTypes.any,
    modelValue: VueTypes.any,
    checked: { type: Boolean },
    inline: { type: Boolean },
  },
  computed: {
    shouldBeChecked() {
      return this.modelValue === this.value
    },
  },
}
</script>
