<template>
  <div class="HeaderActions">
    <slot />
  </div>
</template>

<script>
import './style.scss'

export default {
  name: 'HeaderActions',
}
</script>
