<template>
  <section
    :class="{ 'ContentSection--expanded': expanded }"
    class="ContentSection"
  >
    <slot />
  </section>
</template>

<script>
import './style.scss'
import { mapState } from 'vuex'

export default {
  name: 'ContentSection',
  computed: mapState({
    expanded: state => !state.sidebar.expanded,
  }),
}
</script>
