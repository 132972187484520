<template>
  <div :class="{ 'Header--expanded': expanded }" class="Header">
    <slot />
  </div>
</template>

<script>
import './style.scss'
import { mapState } from 'vuex'

export default {
  name: 'Header',
  computed: mapState({
    expanded: state => !state.sidebar.expanded,
  }),
}
</script>
