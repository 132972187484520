<template>
  <li class="DataProcessingAgreementItem">
    <router-link
      :to="{
        name: 'DataProcessingAgreementsEdit',
        params: { dataProcessingAgreementId: dataProcessingAgreement.id },
      }"
      class="DataProcessingAgreementItem__link"
    >
      <h3 class="DataProcessingAgreementItem__title">
        {{ dataProcessingAgreement.title }}
      </h3>
      <p class="DataProcessingAgreementItem__timestamp">
        {{ t('data_processor_agreement_item.last_edited') }}
        <em>{{ dataProcessingAgreement.updatedAt | formatDateHuman }}</em>
      </p>
      <p
        v-if="dataProcessingAgreement.dataProcessingAgreementSystems.length"
        class="DataProcessingAgreementItem__systems"
      >
        {{ t('data_processor_agreement_item.systems') }}
        <Label
          v-for="dataProcessingAgreementSystem in dataProcessingAgreement.dataProcessingAgreementSystems"
          :key="dataProcessingAgreementSystem.id"
        >
          {{
            dataProcessingAgreementSystem.system &&
            dataProcessingAgreementSystem.system.name
          }}
        </Label>
      </p>
    </router-link>

    <div class="DataProcessingAgreementItem__delete" @click="$emit('delete')">
      {{ t('shared.actions.delete') }}
    </div>
  </li>
</template>

<script>
import './style.scss'
import Label from '@/components/Label'

export default {
  name: 'DataProcessingAgreementItem',
  components: { Label },
  props: {
    dataProcessingAgreement: { type: Object, required: true },
  },
}
</script>
