<template>
  <LogInModal>
    <p>
      {{ t('password_reset.description') }}
      <strong>{{ t('password_reset.description_2') }}</strong
      >.
    </p>

    <form @submit="save">
      <LogInField
        v-model="formData.password"
        :placeholder="t('password_reset.new_password')"
        type="password"
      />

      <LogInField
        v-model="formData.passwordConfirmation"
        :placeholder="t('password_reset.confirm_password')"
        type="password"
      />

      <LogInModalSaveButton :disabled="!canSave()">
        {{ t('password_reset.save') }}
      </LogInModalSaveButton>
      <LocaleSelectorLogin />
      <LogInModalBackLink :to="{ path: `/` }">
        {{ t('password_reset.to_front') }}
      </LogInModalBackLink>
    </form>
  </LogInModal>
</template>

<script>
import './style.scss'
import { mapActions, mapGetters } from 'vuex'
import LogInModal from '@/components/LogInModal'
import LogInField from '@/components/LogInField'
import LogInModalSaveButton from '@/components/LogInModalSaveButton'
import LogInModalBackLink from '@/components/LogInModalBackLink'
import LocaleSelectorLogin from '@/components/LocaleSelectorLogin'

import router from '@/routes'

export default {
  name: 'PasswordReset',
  components: {
    LogInModal,
    LogInField,
    LogInModalSaveButton,
    LogInModalBackLink,
    LocaleSelectorLogin,
  },
  data() {
    return {
      formData: {
        password: '',
        passwordConfirmation: '',
      },
    }
  },
  computed: {
    ...mapGetters(['query']),
  },
  methods: {
    ...mapActions(['showFlashMessage', 'resetPassword']),

    canSave() {
      if (this.formData.password.length < 6) return false
      if (this.formData.password !== this.formData.passwordConfirmation)
        return false
      return true
    },

    save(event) {
      event.preventDefault()

      this.resetPassword({
        token: this.query.token,
        passwordParams: this.formData,
      })
        .then(() => {
          router.push({ name: 'LogIn' })
          this.showFlashMessage({
            message: this.t('flash_messages.custom.password_reset_success'),
          })
        })
        .catch(e => {
          this.showFlashMessage({
            message: this.t('flash_messages.custom.password_reset_success'),
          })
        })
    },
  },
}
</script>
