<template>
  <div class="form">
    <h3>{{ t('organization.edit.title') }}</h3>
    <div class="form__input-wrapper">
      <label>{{ t('organization.edit.organization_title') }}</label>
      <input v-model="formData.name" type="text" @input="enableSave" />
    </div>
    <div class="form__input-wrapper">
      <label>{{ t('organization.edit.cvr') }}</label>
      <input v-model="formData.cvr" type="text" @input="enableSave" />
    </div>
    <div class="form__input-wrapper">
      <label>{{ t('organization.edit.phone_number') }}</label>
      <input v-model="formData.phoneNumber" type="text" @input="enableSave" />
    </div>
    <button v-if="isSaving" class="button--save">
      {{ t('organization.edit.saving') }}
    </button>
    <button
      v-else
      :disabled="disabled"
      class="button--save"
      @click="handleOrganizationSubmit()"
    >
      {{ t('organization.edit.save') }}
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'

const buildOrganization = () => ({
  name: '',
  cvr: '',
  phoneNumber: '',
})

export default {
  name: 'OrganizationForm',
  components: {},
  props: {
    organization: { type: Object, required: false, default: buildOrganization },
    isSaving: { type: Boolean, required: true },
  },
  data() {
    return {
      formData: cloneDeep(this.organization),
      disabled: true,
    }
  },
  computed: {
    ...mapState({
      expanded: state => !state.sidebar.expanded,
    }),
  },
  methods: {
    handleOrganizationSubmit() {
      this.$emit('submit', {
        organization: this.formData,
      })
      this.disabled = true
    },
    enableSave() {
      this.disabled = false
    },
  },
}
</script>
