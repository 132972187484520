<template>
  <div>
    <ResourceHeader
      :back-link="{ name: 'ReviewProcessesArchive' }"
      :back-link-text="t('shared.back_to_archive')"
      :title="t('review_process.new.title')"
      :title-prefix="t('review_process.archive.title')"
    />

    <ReviewProcessForm
      :review-process="reviewProcess"
      :is-saving="loadingReviewProcesses"
      @submit="handleSubmit"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '../../routes'
import ReviewProcessForm from '@/forms/ReviewProcessForm'
import ResourceHeader from '@/components/ResourceHeader'

export default {
  name: 'ReviewProcessesNew',
  components: { ResourceHeader, ReviewProcessForm },
  data() {
    return {
      reviewProcess: {
        userId: null,
        dataMappingId: null,
        procedureId: null,
        title: '',
        description: '',
        firstReviewAt: '',
        intervalDays: 7,
        customQuestionAnswers: [],
        active: true,
        recurring: true,
        dataProcessorId: this.$route.query['data-processor-id'] || null,
      },
    }
  },
  computed: {
    ...mapGetters(['loadingReviewProcesses']),
  },
  mounted() {
    this.collapseSidebar()
  },
  methods: {
    ...mapActions([
      'createReviewProcess',
      'collapseSidebar',
      'showFlashMessage',
    ]),

    handleSubmit(reviewProcess) {
      this.createReviewProcess(reviewProcess)
        .then(() => {
          router.push({ name: 'ReviewProcessesArchive' })
          this.showFlashMessage({
            message:
              this.t('flash_messages.review_process') +
              this.t('flash_messages.create.success'),
          })
        })
        .catch(() => {
          this.showFlashMessage({
            message:
              this.t('flash_messages.review_process') +
              this.t('flash_messages.create.error'),
          })
        })
    },
  },
}
</script>
