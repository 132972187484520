<template>
  <ContentSection>
    <Steps
      :step-names="[
        t('system.steps.start'),
        t('system.steps.review'),
        t('system.steps.sensitive_confidential'),
        t('system.steps.general'),
        t('system.steps.security'),
        t('system.steps.deletion'),
        t('system.steps.other'),
      ]"
    >
      <Step :step-name="t('system.steps.start')">
        <FieldContainer>
          <FieldHeader
            >{{ t('system.form.name_question')
            }}<HelpTooltip tooltip-key="system.name"
          /></FieldHeader>
          <Field
            v-model="formData.name"
            v-validate="'required'"
            :error="errors.first('name')"
            :placeholder="t('shared.form.enter_here_placeholder')"
            name="name"
            type="text"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.type_question') }}
          </FieldHeader>

          <RadioField v-model="formData.technical" :value="true">
            {{ t('system.form.technical') }}
          </RadioField>

          <RadioField v-model="formData.technical" :value="false">
            {{ t('system.form.physical') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader
            >{{ t('system.form.system_located_question')
            }}<HelpTooltip tooltip-key="system.hosted"
          /></FieldHeader>
          <RadioField v-model="formData.hosted" :value="false">
            {{ t('system.archive.on_premise') }}
          </RadioField>
          <RadioField v-model="formData.hosted" :value="true">
            {{ t('system.archive.hosted') }}
          </RadioField>

          <FieldDisclaimer v-if="formData.hosted == false">
            <strong>{{ t('system.archive.on_premise') }}</strong>
            {{ t('system.form.on_premise_help_text') }}
          </FieldDisclaimer>
          <FieldDisclaimer v-if="formData.hosted == true">
            <strong>{{ t('system.archive.hosted') }}</strong>
            {{ t('system.form.hosted_help_text') }}
          </FieldDisclaimer>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader
            >{{ t('system.form.system_provider_question')
            }}<HelpTooltip tooltip-key="system.provider"
          /></FieldHeader>
          <Field
            v-model="formData.provider"
            :placeholder="t('shared.form.enter_here_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader
            >{{ t('system.form.description_question')
            }}<HelpTooltip tooltip-key="system.description"
          /></FieldHeader>
          <Field
            v-model="formData.description"
            type="textarea"
            :placeholder="t('shared.form.enter_here_placeholder')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('system.steps.review')">
        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.responsible_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.responsibleId">
            <option :value="null">
              {{ t('shared.form.select_responsible_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('system.form.responsible_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.executing_user_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.executingId">
            <option :value="null">
              {{ t('shared.form.select_executing_placeholder') }}
            </option>
            <option v-for="user in users" :key="user.id" :value="user.id">
              {{ user.name }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldDisclaimer>
          <p>{{ t('system.form.executing_user_question_help_text') }}</p>
        </FieldDisclaimer>

        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.first_control_date_question') }}
            <HelpTooltip tooltip-key="reviewProcess.firstReviewAt" />
          </FieldHeader>
          <DatePicker
            v-if="isNew || !formData.firstReviewAt"
            v-model="formData.firstReviewAt"
            :min-date-validator="true"
            :placeholder="t('shared.form.select_date_placeholder')"
          />
          <FieldDisclaimer v-else>
            {{ formData.firstReviewAt | formatDateHuman }}
          </FieldDisclaimer>
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.revision_period_question') }}
          </FieldHeader>

          <FormSelect v-model="formData.revisionPeriod">
            <option :value="null">
              {{ t('system.form.revision_period_placeholder') }}
            </option>
            <option
              v-for="revisionPeriod in revisionPeriods"
              :key="revisionPeriod.value"
              :value="revisionPeriod.value"
            >
              {{
                t('system.revision_periods.' + revisionPeriod.translation_key)
              }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('system.steps.sensitive_confidential')">
        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.sensitive_confidentional_question') }}
          </FieldHeader>

          <CheckboxField
            v-for="dataType in sensitiveDataTypes"
            :key="dataType"
            :checked="
              !!formData.collectedDataTypes.find(
                dt => dt.dataTypeTranslationKey === dataType
              )
            "
            @change="
              () => toggleDataType(formData.collectedDataTypes, dataType)
            "
          >
            {{ t('data_types.' + dataType) }}
          </CheckboxField>
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('system.steps.general')">
        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.general_question') }}
          </FieldHeader>

          <CheckboxField
            v-for="dataType in nonsensitiveDataTypes"
            :key="dataType"
            :checked="
              !!formData.collectedDataTypes.find(
                dt => dt.dataTypeTranslationKey === dataType
              )
            "
            @change="
              () => toggleDataType(formData.collectedDataTypes, dataType)
            "
          >
            {{ t('data_types.' + dataType) }}
          </CheckboxField>

          <div v-if="customDataTypes.length > 0">
            <FieldSubHeader>
              <strong
                ><em>{{ t('data_mapping.form.custom_general') }}</em></strong
              >
            </FieldSubHeader>
            <CheckboxField
              v-for="dataType in customDataTypes"
              :key="dataType"
              :checked="
                !!formData.collectedDataTypes.find(
                  dt => dt.dataTypeTranslationKey === dataType
                )
              "
              @change="
                () => toggleDataType(formData.collectedDataTypes, dataType)
              "
            >
              {{ dataType }}
            </CheckboxField>
          </div>

          <CheckboxFieldCreator
            v-model="newDataTypeKey"
            :placeholder="t('system.form.custom_general_option_placeholder')"
            @create="() => addNewDataType(formData.collectedDataTypes)"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('system.steps.security')">
        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.security_question') }}
          </FieldHeader>
          <FieldSubHeader>
            <strong><em>Tier 1</em></strong>
          </FieldSubHeader>
          <CheckboxField
            v-for="safetyMeasure in safetyMeasuresForTier(1)"
            :key="safetyMeasure.translationKey"
            :checked="
              !!formData.inUseSafetyMeasures.find(
                sm => sm.translationKey === safetyMeasure.translationKey
              )
            "
            @change="
              () =>
                toggleSafetyMeasure(
                  formData.inUseSafetyMeasures,
                  safetyMeasure.translationKey
                )
            "
          >
            {{ t('safety_measures.' + safetyMeasure.translationKey) }}
          </CheckboxField>

          <FieldSubHeader>
            <strong><em>Tier 2</em></strong>
          </FieldSubHeader>
          <CheckboxField
            v-for="safetyMeasure in safetyMeasuresForTier(2)"
            :key="safetyMeasure.translationKey"
            :checked="
              !!formData.inUseSafetyMeasures.find(
                sm => sm.translationKey === safetyMeasure.translationKey
              )
            "
            @change="
              () =>
                toggleSafetyMeasure(
                  formData.inUseSafetyMeasures,
                  safetyMeasure.translationKey
                )
            "
          >
            {{ t('safety_measures.' + safetyMeasure.translationKey) }}
          </CheckboxField>

          <FieldSubHeader>
            <strong><em>Tier 3</em></strong>
          </FieldSubHeader>
          <CheckboxField
            v-for="safetyMeasure in safetyMeasuresForTier(3)"
            :key="safetyMeasure.translationKey"
            :checked="
              !!formData.inUseSafetyMeasures.find(
                sm => sm.translationKey === safetyMeasure.translationKey
              )
            "
            @change="
              () =>
                toggleSafetyMeasure(
                  formData.inUseSafetyMeasures,
                  safetyMeasure.translationKey
                )
            "
          >
            {{ t('safety_measures.' + safetyMeasure.translationKey) }}
          </CheckboxField>

          <FieldSubHeader>
            <strong><em>Tier 4</em></strong>
          </FieldSubHeader>
          <CheckboxField
            v-for="safetyMeasure in safetyMeasuresForTier(4)"
            :key="safetyMeasure.translationKey"
            :checked="
              !!formData.inUseSafetyMeasures.find(
                sm => sm.translationKey === safetyMeasure.translationKey
              )
            "
            @change="
              () =>
                toggleSafetyMeasure(
                  formData.inUseSafetyMeasures,
                  safetyMeasure.translationKey
                )
            "
          >
            {{ t('safety_measures.' + safetyMeasure.translationKey) }}
          </CheckboxField>

          <div v-if="safetyMeasuresForTier('custom').length > 0">
            <FieldSubHeader>
              <strong
                ><em>{{ t('system.form.custom_security_option') }}</em></strong
              >
            </FieldSubHeader>
            <CheckboxField
              v-for="safetyMeasure in safetyMeasuresForTier('custom')"
              :key="safetyMeasure"
              :checked="
                !!formData.inUseSafetyMeasures.find(
                  sm => sm.translationKey === safetyMeasure
                )
              "
              @change="
                () =>
                  toggleSafetyMeasure(
                    formData.inUseSafetyMeasures,
                    safetyMeasure
                  )
              "
            >
              {{ safetyMeasure }}
            </CheckboxField>
          </div>

          <CheckboxFieldCreator
            v-model="newSafetyMeasureKey"
            :placeholder="t('system.form.custom_security_placeholder')"
            @create="() => addNewSafetyMeasure(formData.inUseSafetyMeasures)"
          />
        </FieldContainer>
        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step :step-name="t('system.steps.deletion')">
        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.deletion_procedure_question') }}
          </FieldHeader>

          <RadioField v-model="formData.hasDeletionProcedure" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.hasDeletionProcedure" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer v-if="formData.hasDeletionProcedure">
          <FieldHeader>{{
            t('system.form.select_deletion_procedure_question')
          }}</FieldHeader>

          <FormSelect v-model="formData.deletionDocumentProcedureId">
            <option :value="null">
              {{ t('system.form.select_deletion_procedure_placeholder') }}
            </option>
            <option
              v-for="document in documentProcedures"
              :key="document.id"
              :value="document.id"
            >
              {{ document.title }}
            </option>
          </FormSelect>
        </FieldContainer>

        <FieldContainer v-else>
          <FieldHeader>{{
            t('system.form.describe_deletion_system_question')
          }}</FieldHeader>
          <Field
            v-model="formData.deletionCheckDescription"
            type="textarea"
            :placeholder="t('shared.form.description_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>{{
            t('system.form.describe_storage_period_question')
          }}</FieldHeader>
          <Field
            v-model="formData.storagePeriod"
            type="textarea"
            :placeholder="t('shared.form.description_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>{{
            t('system.form.describe_deletion_check_process_question')
          }}</FieldHeader>
          <Field
            v-model="formData.deletionProcedureDescription"
            type="textarea"
            :placeholder="t('shared.form.description_placeholder')"
          />
        </FieldContainer>

        <FieldContainer>
          <FieldHeader>
            {{ t('system.form.backup_question') }}
          </FieldHeader>

          <RadioField v-model="formData.backup" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.backup" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer v-if="formData.backup">
          <FieldHeader>
            {{ t('system.form.backup_deletable_question') }}
          </FieldHeader>

          <RadioField v-model="formData.backupDeletable" :value="true">
            {{ t('shared.yes_reply') }}
          </RadioField>
          <RadioField v-model="formData.backupDeletable" :value="false">
            {{ t('shared.no_reply') }}
          </RadioField>
        </FieldContainer>

        <FieldContainer v-if="formData.backup">
          <FieldHeader>{{
            t('system.form.backup_deletion_period_question')
          }}</FieldHeader>
          <Field
            v-model="formData.backupDeletionDescription"
            type="textarea"
            :placeholder="t('shared.form.description_placeholder')"
          />
        </FieldContainer>

        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>

      <Step v-if="questions.length" :step-name="t('system.steps.other')">
        <FieldContainer v-for="question in questions" :key="question.id">
          <FieldHeader>{{ question.question }}</FieldHeader>
          <Field
            :placeholder="question.placeholder"
            :value="findOrBuildQuestionAnswer(question.id).answer"
            name="title"
            type="text"
            @input="
              value => {
                updateQuestionAnswer(question.id, value)
              }
            "
          />
        </FieldContainer>
        <FormControls :cancel-link="cancelLink" @finalize="save()" />
      </Step>
    </Steps>
  </ContentSection>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import VueTypes from 'vue-types'
import { cloneDeep, difference, uniq, intersection } from 'lodash'
import HelpTooltip from '@/components/HelpTooltip'

import { Steps, Step } from '@/components/Steps'
import ContentSection from '@/components/ContentSection'
import FieldContainer from '@/components/FieldContainer'
import FieldHeader from '@/components/FieldHeader'
import FieldDisclaimer from '@/components/FieldDisclaimer'
import Field from '@/components/Field'
import FieldSubHeader from '@/components/FieldSubHeader'
import FormControls from '@/components/FormControls'
import RadioField from '@/components/RadioField'
import withCustomQuestions from '@/mixins/withCustomQuestions'
import FormSelect from '@/components/FormSelect'
import CheckboxField from '@/components/CheckboxField'
import CheckboxFieldCreator from '@/components/CheckboxFieldCreator'
import DatePicker from '@/components/DatePicker'

export default {
  name: 'SystemForm',
  components: {
    HelpTooltip,
    Steps,
    Step,
    ContentSection,
    FieldContainer,
    FieldHeader,
    FieldDisclaimer,
    Field,
    FormControls,
    RadioField,
    FormSelect,
    CheckboxField,
    CheckboxFieldCreator,
    FieldSubHeader,
    DatePicker,
  },
  mixins: [withCustomQuestions],
  props: {
    system: VueTypes.shape({
      id: String,
      name: String,
      provider: String,
      description: String,
      hosted: VueTypes.oneOfType([Boolean, null]),
      technical: VueTypes.oneOfType([Boolean, null]),
      backup: VueTypes.oneOfType([Boolean, null]),
      storage_period: VueTypes.oneOfType([String, null]),
      backup_deletion_description: VueTypes.oneOfType([String, null]),
      deletion_check_description: VueTypes.oneOfType([String, null]),
      deletion_procedure_description: VueTypes.oneOfType([String, null]),
      backupDeletable: VueTypes.oneOfType([Boolean, null]),
      revisionPeriod: VueTypes.oneOfType([String, null]),
      responsibleId: VueTypes.oneOfType([String, null]),
      executingId: VueTypes.oneOfType([String, null]),
      deletionDocumentProcedureId: VueTypes.oneOfType([String, null]),
    }).loose,
  },
  data() {
    return {
      formData: cloneDeep(this.system),
      newDataTypeKey: '',
      newSafetyMeasureKey: '',
      cancelLink: { name: 'SystemsArchive' },
    }
  },
  computed: {
    ...mapGetters(['documents', 'questions', 'users']),

    ...mapState({
      sensitiveDataTypes: state => state.dataTypes.sensitive,
      nonsensitiveDataTypes: state => state.dataTypes.nonsensitive,
      safetyMeasures: state => state.safetyMeasures,
    }),

    isNew() {
      return !this.system.id
    },

    collectedDataTypes() {
      const allCollectedDataTypes = this.formData.collectedDataTypes.map(
        ({ dataTypeTranslationKey }) => {
          return dataTypeTranslationKey
        }
      )

      return uniq(allCollectedDataTypes)
    },

    collectedSensitiveDataTypes() {
      return intersection(this.collectedDataTypes, this.sensitiveDataTypes)
    },

    collectedNonsensitiveDataTypes() {
      return difference(
        this.formData.collectedDataTypes,
        this.sensitiveDataTypes
      )
    },

    customDataTypes() {
      return difference(this.collectedDataTypes, [
        ...this.sensitiveDataTypes,
        ...this.nonsensitiveDataTypes,
      ])
    },

    revisionPeriods() {
      return [
        { value: 'quarter', translation_key: 'quarter' },
        { value: 'half_year', translation_key: 'half_year' },
        { value: 'year', translation_key: 'year' },
        { value: 'never', translation_key: 'never' },
      ]
    },

    documentProcedures() {
      let filteredDocuments = this.documents.filter(
        ({ category }) => category === 'procedure'
      )

      return filteredDocuments
    },
  },
  watch: {
    system: function (newSystem) {
      this.formData = cloneDeep(newSystem)
    },
  },
  mounted() {
    this.fetchCustomQuestions('systems')
    this.fetchDocuments()
    this.observeScroll()
  },
  methods: {
    ...mapActions(['fetchCustomQuestions', 'fetchDocuments', 'observeScroll']),

    save() {
      this.$validator
        .validateAll()
        .then(() => {
          this.$emit('submit', this.formData)
        })
        .catch(() => {
          console.log('errors exist', this.errors)
        })
    },

    toggleDataType(dataTypes, dataType) {
      const index = dataTypes.findIndex(
        dt => dt.dataTypeTranslationKey === dataType
      )

      if (index !== -1) {
        dataTypes.splice(index, 1)
      } else {
        dataTypes.push({ dataTypeTranslationKey: dataType })
      }
    },

    addNewDataType(dataTypes) {
      const dataType = this.newDataTypeKey
      if (dataType === '') return

      if (!dataTypes.find(dt => dt.dataTypeTranslationKey === dataType)) {
        dataTypes.push({ dataTypeTranslationKey: dataType })
      }
      this.newDataTypeKey = ''
    },

    toggleSafetyMeasure(inUseSafetyMeasures, safetyMeasureTranslationKey) {
      const index = inUseSafetyMeasures.findIndex(
        sm => sm.translationKey === safetyMeasureTranslationKey
      )

      if (index !== -1) {
        inUseSafetyMeasures.splice(index, 1)
      } else {
        inUseSafetyMeasures.push({
          translationKey: safetyMeasureTranslationKey,
        })
      }
    },

    addNewSafetyMeasure(inUseSafetyMeasures) {
      const safetyMeasureKey = this.newSafetyMeasureKey
      if (safetyMeasureKey === '') return

      if (
        !inUseSafetyMeasures.find(sm => sm.translationKey === safetyMeasureKey)
      ) {
        inUseSafetyMeasures.push({ translationKey: safetyMeasureKey })
      }
      this.newSafetyMeasureKey = ''
    },

    safetyMeasuresForTier(tier) {
      if (tier === 'custom') {
        const safetyMeasuresWithTiers = this.safetyMeasures.map(
          sm => sm.translationKey
        )
        const inUseSafetyMeasures = this.formData.inUseSafetyMeasures.map(
          sm => sm.translationKey
        )

        return inUseSafetyMeasures.filter(
          el => !safetyMeasuresWithTiers.includes(el)
        )
      } else {
        return this.safetyMeasures.filter(item => item.tier === tier)
      }
    },
  },
}
</script>
