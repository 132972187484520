import * as types from '../mutationTypes'

const initialState = {
  expanded: false,
}

const actions = {
  expandSidebar({ commit }) {
    commit(types.EXPAND_SIDEBAR)
  },

  collapseSidebar({ commit }) {
    commit(types.COLLAPSE_SIDEBAR)
  },
}

const mutations = {
  [types.EXPAND_SIDEBAR]: state => {
    state.expanded = true
  },

  [types.COLLAPSE_SIDEBAR]: state => {
    state.expanded = false
  },
}

export default {
  state: initialState,
  actions,
  mutations,
}
