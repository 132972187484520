<template>
  <div class="form">
    <div class="form__input-wrapper">
      <label>{{ t('organization_user.edit.name') }}</label>
      <input v-model="formData.name" type="text" @input="enableSave" />
    </div>
    <div class="form__input-wrapper">
      <label>{{ t('organization_user.edit.email') }}</label>
      <input v-model="formData.email" type="text" @input="enableSave" />
    </div>
    <div v-if="isResetting">
      <div class="form__input-wrapper">
        <label>{{ t('organization_user.edit.new_password') }}</label>
        <input
          v-model="formData.password"
          type="password"
          @input="enableSave"
        />
      </div>
      <div class="form__input-wrapper">
        <label>{{ t('organization_user.edit.password_confirmation') }}</label>
        <input
          v-model="formData.password_confirmation"
          type="password"
          @input="enableSave"
        />
      </div>
    </div>
    <div v-else class="form__input-wrapper">
      <label>{{ t('organization_user.edit.password') }}</label>
      <input type="password" placeholder="*********" disabled="true" />
      <span class="reset-password" @click="resetPassword">{{
        t('organization_user.edit.reset')
      }}</span>
    </div>
    <button v-if="isSaving" class="button--save">
      {{ t('organization_user.edit.saving') }}
    </button>
    <button
      v-else
      :disabled="disabled"
      class="button--save"
      @click="handleUserSubmit()"
    >
      {{ t('organization_user.edit.save') }}
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'

const buildUser = () => ({
  name: '',
  email: '',
})

export default {
  name: 'Form',
  components: {},
  props: {
    user: { type: Object, required: false, default: buildUser },
    isSaving: { type: Boolean, required: true },
  },
  data() {
    return {
      formData: cloneDeep(this.user),
      disabled: true,
      isResetting: false,
    }
  },
  computed: {
    ...mapState({
      expanded: state => !state.sidebar.expanded,
    }),
  },
  methods: {
    handleUserSubmit() {
      this.$emit('submit', {
        user: this.formData,
      })
      this.disabled = true
      this.isResetting = false
      this.formData.password = this.user.password
      this.formData.password_confirmation = null
    },
    enableSave() {
      this.disabled = false
    },
    resetPassword() {
      if (this.isResetting === false) {
        this.isResetting = true
      } else {
        this.isResetting = false
      }
    },
  },
}
</script>
