<template>
  <Modal
    :title="
      isNew
        ? t('mitigation_measure.form.title')
        : t('mitigation_measure.edit.title')
    "
  >
    <ModalCloseButton :to="{ name: 'RiskAssessmentsShow' }" />

    <Field
      v-model="formData.title"
      v-validate="'required'"
      :error="errors.first('title')"
      fill
      type="text"
      :placeholder="t('mitigation_measure.form.title_placeholder')"
      name="title"
    />

    <Field
      v-model="formData.description"
      fill
      type="textarea"
      :placeholder="t('shared.form.description_placeholder')"
    />

    <FieldSubHeader>
      {{ t('mitigation_measure.form.probability_question') }}
    </FieldSubHeader>
    <div>
      <RadioField
        v-for="probability in probabilities"
        :key="probability.value"
        v-model="formData.probability"
        :value="probability.value"
        inline
      >
        {{ t('risk_assessment.probability.' + probability.translation_key) }}
      </RadioField>
    </div>

    <FieldSubHeader>
      {{ t('mitigation_measure.form.consequence_question') }}
    </FieldSubHeader>
    <div>
      <RadioField
        v-for="consequence in consequences"
        :key="consequence.value"
        v-model="formData.consequences"
        :value="consequence.value"
        inline
      >
        {{ t('risk_assessment.consequences.' + consequence.translation_key) }}
      </RadioField>
    </div>
    <ModalSaveButton @click="$emit('submit', formData)">
      {{
        isNew
          ? t('mitigation_measure.form.submit')
          : t('mitigation_measure.edit.submit')
      }}
    </ModalSaveButton>
  </Modal>
</template>

<script>
import VueTypes from 'vue-types'
import { cloneDeep } from 'lodash'

import Modal from '@/components/Modal'
import ModalCloseButton from '@/components/ModalCloseButton'
import ModalSaveButton from '@/components/ModalSaveButton'
import Field from '@/components/Field'
import RadioField from '@/components/RadioField'
import FieldSubHeader from '@/components/FieldSubHeader'

export default {
  name: 'MitigationMeasureForm',
  components: {
    Modal,
    ModalCloseButton,
    ModalSaveButton,
    Field,
    RadioField,
    FieldSubHeader,
  },
  props: {
    mitigationMeasure: VueTypes.shape({
      id: String,
      title: String,
      description: String,
      probability: String,
      consequences: String,
    }).loose,
    isSaving: VueTypes.bool.isRequired,
  },

  data() {
    return {
      formData: cloneDeep(this.mitigationMeasure),
    }
  },
  computed: {
    isNew() {
      return !this.mitigationMeasure.id
    },
    probabilities() {
      return [
        { value: 'none', translation_key: 'none' },
        { value: 'small', translation_key: 'low' },
        { value: 'moderate', translation_key: 'moderate' },
        { value: 'high', translation_key: 'high' },
        { value: 'very_high', translation_key: 'very_high' },
      ]
    },
    consequences() {
      return [
        { value: 'none', translation_key: 'none' },
        { value: 'small', translation_key: 'low' },
        { value: 'moderate', translation_key: 'moderate' },
        { value: 'high', translation_key: 'serious' },
        { value: 'very_high', translation_key: 'catastrophic' },
      ]
    },
  },
}
</script>
