<template>
  <li class="TodoListItemNew">
    <div class="TodoListItemNew__plus">
      <span />
      <span />
    </div>

    <h5>{{ t('dashboard.todos.new_todo_placeholder') }}</h5>
  </li>
</template>

<script>
import './style.scss'

export default {
  name: 'TodoListItemNew',
}
</script>
