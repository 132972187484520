// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './components/App'
import router from './routes'
import Vuex from 'vuex'
import store from './store'
import { sync } from 'vuex-router-sync'
import moment from 'moment'
import VTooltip from 'v-tooltip'
import VeeValidate from 'vee-validate'
import i18n from '@/mixins/i18n.js.erb'

// Set default moment locale
moment.locale('da')
i18n.methods.setLocale(localStorage.getItem('compliancelog-locale'))

// Filters
import './filters/formatDateHuman'
import './filters/formatDepartment'
import './filters/formatUser'
import './filters/formatTechnicalDataProcessor'
import './filters/formatHostedSystem'
import './filters/formatRiskAssessment'

sync(store, router)
Vue.use(Vuex)
Vue.mixin(i18n)

const tooltipOptions = {
  popover: {
    defaultInnerClass: 'popover-inner',
  },
}

Vue.use(VTooltip, tooltipOptions)
Vue.use(VeeValidate)

// Fetch initial props from tag
const appElement = document.getElementById('app')
const appProps = JSON.parse(appElement.getAttribute('data'))

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App, { props: appProps }),
})
