<template>
  <div>
    <ArchiveHeader v-model="search" />

    <Loader v-if="loading" />
    <ContentSection v-else class="fade_in_right">
      <ArchiveActionSection>
        <ArchiveTitle>{{ t('data_processor.archive.title') }}</ArchiveTitle>
        <ArchiveCreateButton :to="{ name: 'DataProcessorsNew' }" />
      </ArchiveActionSection>

      <TableWrapper v-if="filteredDataProcessors('technical').length">
        <div class="wrapper">
          <h2>{{ t('data_processor.archive.technical') }}</h2>
          <table>
            <thead class="column-count-2">
              <th />
              <th />
            </thead>
            <tbody class="column-count-2">
              <tr
                v-for="dataProcessor in filteredDataProcessors('technical')"
                :key="dataProcessor.id"
              >
                <td>
                  <router-link
                    :to="{ path: `data-processors/${dataProcessor.id}` }"
                  >
                    {{ dataProcessor.name }}
                    <i
                      v-if="!hasAgreementAttachements(dataProcessor)"
                      class="fa fa-exclamation-triangle RiskWarning"
                    />
                  </router-link>
                </td>
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `data-processors/${dataProcessor.id}` }"
                    :to-edit="{
                      path: `data-processors/${dataProcessor.id}/edit`,
                    }"
                    :to-review="{
                      path: `data-processors/${dataProcessor.id}/reviews`,
                    }"
                    :resource="dataProcessor"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>

      <TableWrapper v-if="filteredDataProcessors('other').length">
        <div class="wrapper">
          <h2>{{ t('data_processor.archive.other') }}</h2>
          <table>
            <thead class="column-count-2">
              <th />
              <th />
            </thead>
            <tbody class="column-count-2">
              <tr
                v-for="dataProcessor in filteredDataProcessors('other')"
                :key="dataProcessor.id"
              >
                <td>
                  <router-link
                    :to="{ path: `data-processors/${dataProcessor.id}` }"
                  >
                    {{ dataProcessor.name }}
                    <i
                      v-if="!hasAgreementAttachements(dataProcessor)"
                      class="fa fa-exclamation-triangle RiskWarning"
                    />
                  </router-link>
                </td>
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `data-processors/${dataProcessor.id}` }"
                    :to-edit="{
                      path: `data-processors/${dataProcessor.id}/edit`,
                    }"
                    :to-review="{
                      path: `data-processors/${dataProcessor.id}/reviews`,
                    }"
                    :resource="dataProcessor"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>

      <TableWrapper v-if="filteredDataProcessors('subprocessor').length">
        <div class="wrapper">
          <h2>{{ t('data_processor.archive.subprocessor') }}</h2>
          <table>
            <thead class="column-count-2">
              <th />
              <th />
            </thead>
            <tbody class="column-count-2">
              <tr
                v-for="dataProcessor in filteredDataProcessors('subprocessor')"
                :key="dataProcessor.id"
              >
                <td>
                  <router-link
                    :to="{ path: `data-processors/${dataProcessor.id}` }"
                  >
                    {{ dataProcessor.name }}
                    <i
                      v-if="!hasAgreementAttachements(dataProcessor)"
                      class="fa fa-exclamation-triangle RiskWarning"
                    />
                  </router-link>
                </td>
                <td>
                  <ArchiveTableDropdown
                    :to-view="{ path: `data-processors/${dataProcessor.id}` }"
                    :to-edit="{
                      path: `data-processors/${dataProcessor.id}/edit`,
                    }"
                    :to-review="{
                      path: `data-processors/${dataProcessor.id}/reviews`,
                    }"
                    :resource="dataProcessor"
                    @delete="handleDelete"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </TableWrapper>
    </ContentSection>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import Loader from '@/components/Loader'
import ArchiveHeader from '@/components/ArchiveHeader'
import ArchiveTitle from '@/components/ArchiveTitle'
import ContentSection from '@/components/ContentSection'
import ArchiveCreateButton from '@/components/ArchiveCreateButton'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import ArchiveActionSection from '@/components/ArchiveActionSection'
import TableWrapper from '@/components/TableWrapper'

export default {
  name: 'DataProcessorsArchive',
  components: {
    Loader,
    ArchiveHeader,
    ArchiveTitle,
    ContentSection,
    ArchiveCreateButton,
    ArchiveTableDropdown,
    ArchiveActionSection,
    TableWrapper,
  },
  data: () => ({
    search: '',
    ascending: true,
  }),
  computed: {
    loading() {
      return this.$store.getters.loadingDataProcessors
    },
    dataProcessors() {
      return this.$store.getters.dataProcessorsForArchive({
        search: this.search,
        ascending: this.ascending,
      })
    },
  },
  mounted() {
    this.fetchDataProcessors()
    this.expandSidebar()
  },
  methods: {
    ...mapActions([
      'fetchDataProcessors',
      'expandSidebar',
      'deleteDataProcessor',
      'showFlashMessage',
    ]),

    handleDelete(id) {
      const dataProcessor = this.$store.getters.resource('dataProcessors', id)
      if (
        confirm(
          this.t('flash_messages.delete.confirmation') + dataProcessor.name
        )
      ) {
        this.deleteDataProcessor(dataProcessor)
          .then(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.data_processor_agreement') +
                this.t('flash_messages.delete.success'),
            })
          )
          .catch(() =>
            this.showFlashMessage({
              message:
                this.t('flash_messages.data_processor_agreement') +
                this.t('flash_messages.delete.error'),
            })
          )
      }
    },

    filteredDataProcessors(filterBy) {
      const filteredDataProcessors = this.dataProcessors.filter(
        ({ technical }) => technical === filterBy
      )

      return filteredDataProcessors
    },

    hasAgreementAttachements(processor) {
      return processor.dataProcessingAgreements.length > 0
    },
  },
}
</script>
