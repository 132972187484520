<template>
  <div :class="{ collapsed: !expanded }" class="Sidebar">
    <router-link :to="{ path: baseRoute }" class="logo">
      <img src="./assets/logo.svg" />
    </router-link>
    <ul class="general">
      <li>
        <router-link :to="{ path: `${baseRoute}/dashboard` }">
          <div class="bg" />
          <img
            style="width: 24px; left: 38px"
            class="active_img"
            src="./assets/dashboard_active.svg"
          />
          <img
            style="width: 24px; left: 38px"
            class="inactive_img"
            src="./assets/dashboard.svg"
          />
          <span>{{ t('shared.navigation.start') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{ path: `${baseRoute}/data-mappings` }">
          <div class="bg" />
          <img
            style="width: 29px; left: 36px"
            class="active_img"
            src="./assets/data_mapping_active.svg"
          />
          <img
            style="width: 29px; left: 36px"
            class="inactive_img"
            src="./assets/data_mapping.svg"
          />
          <span>{{ t('shared.navigation.data_mappings') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{ path: `${baseRoute}/documents` }">
          <div class="bg" />
          <img
            style="width: 23px; left: 39px"
            class="active_img"
            src="./assets/document_active.svg"
          />
          <img
            style="width: 23px; left: 39px"
            class="inactive_img"
            src="./assets/document.svg"
          />
          <span>{{ t('shared.navigation.documents') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{ path: `${baseRoute}/review-processes` }">
          <div class="bg" />
          <img
            style="width: 25px; left: 38px"
            class="active_img"
            src="./assets/reviewprocess_active.svg"
          />
          <img
            style="width: 25px; left: 38px"
            class="inactive_img"
            src="./assets/reviewprocess.svg"
          />
          <span>{{ t('shared.navigation.review_processes') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{ path: `${baseRoute}/requests` }">
          <div class="bg" />
          <img
            style="width: 25px; left: 38px"
            class="active_img"
            src="./assets/request_active.svg"
          />
          <img
            style="width: 25px; left: 38px"
            class="inactive_img"
            src="./assets/request.svg"
          />
          <span>{{ t('shared.navigation.requests') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{ path: `${baseRoute}/incidents` }">
          <div class="bg" />
          <img
            style="width: 24px; left: 38px"
            class="active_img"
            src="./assets/incident_active.svg"
          />
          <img
            style="width: 24px; left: 38px"
            class="inactive_img"
            src="./assets/incident.svg"
          />
          <span>{{ t('shared.navigation.incidents') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{ path: `${baseRoute}/systems` }">
          <div class="bg" />
          <img
            style="width: 27px; left: 38px"
            class="active_img"
            src="./assets/system_active.svg"
          />
          <img
            style="width: 27px; left: 38px"
            class="inactive_img"
            src="./assets/system.svg"
          />
          <span>{{ t('shared.navigation.systems') }}</span>
        </router-link>
      </li>

      <li>
        <router-link :to="{ path: `${baseRoute}/data-processors` }">
          <div class="bg" />
          <img
            style="width: 25px; left: 38px"
            class="active_img"
            src="./assets/data_processor_active.svg"
          />
          <img
            style="width: 25px; left: 38px"
            class="inactive_img"
            src="./assets/data_processor.svg"
          />
          <span>{{ t('shared.navigation.data_processors') }}</span>
        </router-link>
      </li>
    </ul>
    <ul class="log-out">
      <LocaleSelector :sidebar-expanded="expanded" />
      <li>
        <a href="https://compliancelog.dk/#/kontakt" target="_blank">
          <div class="bg" />
          <img
            style="width: 25px; left: 38px"
            class="inactive_img"
            src="./assets/request.svg"
          />
          <span>{{ t('shared.navigation.help') }}</span>
        </a>
      </li>
      <li v-if="!expanded">
        <a href="#" @click="expandSidebar()">
          <div class="bg" />
          <img
            style="
              width: 23px;
              left: 37px;
              top: 13px;
              transform: rotate(180deg);
            "
            class="inactive_img"
            src="./assets/arrow.svg"
          />
          <span>{{ t('shared.navigation.log_out') }}</span>
        </a>
      </li>
      <li v-if="expanded">
        <a href="#" @click="logOut()">
          <div class="bg" />
          <img
            style="width: 23px; left: 38px"
            class="inactive_img"
            src="./assets/log_out.svg"
          />
          <span>{{ t('shared.navigation.log_out') }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import LocaleSelector from '@/components/LocaleSelector'

export default {
  name: 'Sidebar',
  components: { LocaleSelector },
  computed: mapState({
    expanded: state => state.sidebar.expanded,
    baseRoute: state => `/organizations/${state.route.params.organizationId}`,
  }),
  methods: {
    ...mapActions(['logOut', 'expandSidebar']),
  },
}
</script>

<style lang="scss">
@import 'style.scss';
</style>
