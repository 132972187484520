import IncidentsArchive from '@/pages/IncidentsArchive'
import IncidentsNew from '@/pages/IncidentsNew'
import IncidentsShow from '@/pages/IncidentsShow'
import IncidentsEdit from '@/pages/IncidentsEdit'

const routes = [
  {
    path: 'incidents/new',
    component: IncidentsNew,
    name: 'IncidentsNew',
  },
  {
    path: 'incidents',
    component: IncidentsArchive,
    name: 'IncidentsArchive',
  },
  {
    path: 'incidents/:incidentId',
    component: IncidentsShow,
    name: 'IncidentsShow',
  },
  {
    path: 'incidents/:incidentId/edit',
    component: IncidentsEdit,
    name: 'IncidentsEdit',
  },
]
export default routes
