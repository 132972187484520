<template>
  <div class="SortedReviewTable">
    <div class="wrapper">
      <h2>{{ title }}</h2>

      <table>
        <thead class="column-count-5">
          <th>{{ t('shared.table.title') }}</th>
          <th>{{ t('shared.table.responsible') }}</th>
          <th>{{ t('shared.table.executing') }}</th>
          <th v-if="inactive" />
          <th v-else class="sortable" @click="sort()">
            {{ t('shared.table.due_date') }}<i :class="adjustSortCaret()" />
          </th>
          <th />
        </thead>
        <tbody class="column-count-5">
          <tr
            v-for="reviewProcess in sortedReviewProcesses(reviewProcesses)"
            :key="reviewProcess.id"
          >
            <td>
              <router-link
                :to="{ path: `review-processes/${reviewProcess.id}` }"
              >
                {{ reviewProcess.title }}
                <Label
                  v-if="reviewProcess.overdue && reviewProcess.active"
                  type="alert"
                  >{{ t('shared.table.overdue') }}</Label
                >
              </router-link>
            </td>
            <td>{{ reviewProcess.responsibleName }}</td>
            <td>{{ reviewProcess.executingName }}</td>
            <td v-if="inactive" />
            <td v-else>
              {{ reviewProcess.nextReviewAt | formatDateHuman }}
            </td>
            <td>
              <ArchiveTableDropdown
                v-if="inactive"
                :to-view="{ path: `review-processes/${reviewProcess.id}` }"
                :to-edit="{ path: `review-processes/${reviewProcess.id}/edit` }"
                :resource="reviewProcess"
                @delete="handleDelete"
              />
              <ArchiveTableDropdown
                v-else
                :to-view="{ path: `review-processes/${reviewProcess.id}` }"
                :to-edit="{ path: `review-processes/${reviewProcess.id}/edit` }"
                :resource="reviewProcess"
                :to-custom-title="t('shared.actions.deactivate')"
                @delete="handleDelete"
                @custom="handleCustom"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import ArchiveTableDropdown from '@/components/ArchiveTableDropdown'
import Label from '@/components/Label'

export default {
  name: 'SortedReviewTable',
  components: {
    ArchiveTableDropdown,
    Label,
  },
  props: {
    reviewProcesses: { type: Array },
    title: { type: String },
    inactive: { type: Boolean, default: false },
  },
  data: () => ({
    currentSortDir: 'asc',
  }),
  methods: {
    ...mapActions([
      'deleteReviewProcess',
      'saveReviewProcess',
      'showFlashMessage',
    ]),

    handleDelete(id) {
      const reviewProcessObject = this.$store.getters.reviewProcesses.find(
        reviewProcess => reviewProcess.id === id
      )
      if (
        confirm(
          `Er du sikker på at du vil slette "${reviewProcessObject.title}"`
        )
      ) {
        this.deleteReviewProcess(reviewProcessObject)
          .then(() =>
            this.showFlashMessage({ message: 'Kontrollen blev slettet' })
          )
          .catch(() =>
            this.showFlashMessage({ message: 'Kontrollen kunne ikke slettes' })
          )
      }
    },
    handleCustom(id) {
      let reviewProcessClone = cloneDeep(
        this.$store.getters.reviewProcesses.find(
          reviewProcess => reviewProcess.id === id
        )
      )
      reviewProcessClone.active = false

      this.saveReviewProcess(reviewProcessClone)
        .then(() =>
          this.showFlashMessage({ message: 'Kontrol er deaktiveret' })
        )
        .catch(() =>
          this.showFlashMessage({ message: 'Kunne ikke deaktivere kontrol' })
        )
    },

    adjustSortCaret() {
      if (this.currentSortDir === 'asc') {
        return 'fa fa-caret-up'
      } else {
        return 'fa fa-caret-down'
      }
    },

    sort() {
      this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
    },

    sortedReviewProcesses(reviewProcesses) {
      reviewProcesses.sort((a, b) => {
        if (this.currentSortDir === 'asc') {
          return moment(a.nextReviewAt) - moment(b.nextReviewAt)
        } else {
          return moment(b.nextReviewAt) - moment(a.nextReviewAt)
        }
      })

      return reviewProcesses
    },
  },
}
</script>
