import UsersEdit from '@/pages/UsersEdit'

const routes = [
  {
    path: 'user/edit',
    component: UsersEdit,
    name: 'UsersEdit',
  },
]
export default routes
